import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';

import Table from '@components/Table';
import StatusCircle from '@common/StatusCircle/StatusCircle';
import ReusableButton from '@common/Button/Button';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';

import {
  deleteHelpdeskType,
  helpdeskTypesToggleState,
} from '@services/requests/helpdeskTypesAI';
import { actions } from '@store/actions';
import { TableTypes } from '@components/Table/constants';
import { initialValues } from './helpers';

import AgentFormAI from './components/AgentFormAI';
import Actions from './components/Actions';

import useStyles from './styles';

const CustomAgentAI = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [item, setItem] = useState(null);
  const [removalId, setRemovalId] = useState(false);
  const [processing, setProcessing] = useState(false);

  const handleToggleState = useCallback(
    row => {
      const isVisible = !row.isVisible;
      helpdeskTypesToggleState(row.id, isVisible).then(() => {
        dispatch(actions.updateItemInList({ ...row, isVisible }));
      });
    },
    [dispatch],
  );

  const handleDelete = useCallback(() => {
    setProcessing(true);
    deleteHelpdeskType(removalId).then(() => {
      dispatch(actions.removeItemFromList({ id: removalId }));
      setProcessing(false);
      setRemovalId(null);
      enqueueSnackbar(`Successfully deleted`, {
        variant: 'success',
      });
    });
  }, [dispatch, enqueueSnackbar, removalId]);

  const columns = [
    {
      name: 'Name',
      key: 'name',
      sortable: true,
      searchable: true,
      minWidth: 200,
    },
    {
      name: 'Tenant Name',
      key: 'tenantName',
      sortable: true,
      searchable: true,
      minWidth: 200,
    },
    {
      name: 'Key',
      key: 'key',
      searchable: true,
      minWidth: 200,
    },
    {
      name: 'Visible',
      key: 'isVisible',
      width: 100,
      minWidth: 100,
      isCustomAccessor: true,
      accessor: row => (
        <div className={classes.alignCenter}>
          <StatusCircle
            handleClick={() => handleToggleState(row)}
            row={row}
            rowKey="isVisible"
          />
        </div>
      ),
    },
    {
      name: 'Actions',
      key: 'actions',
      type: TableTypes.actions,
      width: 100,
      accessor: row => (
        <Actions row={row} setRemovalId={setRemovalId} setItem={setItem} />
      ),
    },
  ];

  return (
    <div className={classes.container}>
      <div className={classes.actions}>
        <ReusableButton
          viewType="blue"
          onClick={() => {
            setItem(initialValues);
          }}
          label="Add New"
          id="aiAssists-add-new"
        />
      </div>
      <Table
        resource="/HelpdeskAiAssistantTypes"
        columns={columns}
        defaultSort={{ fieldName: 'name', order: 'ASC' }}
      />

      {!!item && (
        <AgentFormAI
          item={item}
          setModalData={() => {
            setItem(null);
          }}
        />
      )}
      {!!removalId && (
        <ConfirmDialog
          open
          onAccept={handleDelete}
          title="Confirm Action"
          onCancel={() => {
            setRemovalId(null);
          }}
          content="Are you sure you want to delete this item?"
          loading={processing}
        />
      )}
    </div>
  );
};

export default CustomAgentAI;
