import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import { usePermissions, useRedirect } from 'react-admin';
import { partial } from 'lodash';
import { useSnackbar } from 'notistack';

import {
  SECURITY_INTEGRATION,
  SECURITY_INTEGRATION_CREATE,
} from '@constants/routes';
import { basePermissions } from '@constants/permissions';
import {
  ThunkDeleteSecurityIntegration,
  ThunkGetSecurityIntegrations,
} from '@store/slices/securityIntegrations/thunks';

import Card from '@components/DataSources/Integrations/components/Card';
import AddCard from '@components/DataSources/Integrations/components/AddCard';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';
import Loading from '@common/Loading/Loading';
import { getIntegrationImage } from './helpers';

import useStyles from './styles';

const SecurityIntegrations = () => {
  const redirect = useRedirect();
  const classes = useStyles();
  const { permissions } = usePermissions();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const [integrationForDelete, setIntegrationForDelete] = useState('');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const [data, setData] = useState({});

  const allowCreate = useMemo(
    () => permissions && permissions.integrations[basePermissions.create],
    [permissions],
  );

  const dispatch = useDispatch();

  const onMount = useCallback(async () => {
    await dispatch(ThunkGetSecurityIntegrations())
      .unwrap()
      .then(d => {
        setData(d);
      });
  }, [dispatch]);

  useEffect(() => {
    onMount();
    const hasError = location.search.includes('errorMessage');
    if (hasError) {
      const error = decodeURIComponent(
        location.search.replace('?errorMessage=', ''),
      );
      enqueueSnackbar(error, { variant: 'error' });
    }
  }, [enqueueSnackbar, location.search, onMount]);

  const isDataLoaded = Array.isArray(data);

  if (isDataLoaded && !data?.length) {
    return allowCreate ? (
      <Redirect to={SECURITY_INTEGRATION_CREATE} />
    ) : (
      <span className={classes.noIntegrations}>
        No integrations in the current tenant
      </span>
    );
  }

  const handleAddClick = () => {
    redirect(SECURITY_INTEGRATION_CREATE);
  };

  const editHandler = (itemId, event) => {
    event.stopPropagation();
    redirect(`${SECURITY_INTEGRATION}/${itemId}`);
  };

  const deleteHandler = async () => {
    await dispatch(ThunkDeleteSecurityIntegration(integrationForDelete));
    setDeleteDialogOpen(false);
    onMount();
  };

  const openDeleteDialog = (itemId, event) => {
    event.stopPropagation();
    setIntegrationForDelete(itemId);
    setDeleteDialogOpen(true);
  };

  const onCancelDeleteDialog = () => {
    setIntegrationForDelete('');
    setDeleteDialogOpen(false);
  };

  const content = () => {
    if (isDataLoaded) {
      return (
        <div className={classes.div}>
          {data.map(i => (
            <Card
              key={i.id}
              item={i}
              readOnly={false}
              getImage={name => getIntegrationImage(name, true)}
              handleClick={partial(editHandler, i.id)}
              editHandler={partial(editHandler, i.id)}
              deleteHandler={partial(openDeleteDialog, i.id)}
            />
          ))}
          <div>
            {allowCreate && <AddCard handleAddClick={handleAddClick} />}
            <ConfirmDialog
              open={deleteDialogOpen}
              setOpen={setDeleteDialogOpen}
              onAccept={() => deleteHandler()}
              onCancel={() => onCancelDeleteDialog()}
              title="Delete current alert"
              content="Warning! This operation will delete current integration and cannot be undone."
            />
          </div>
        </div>
      );
    }
    return <Loading />;
  };
  return content();
};

export default SecurityIntegrations;
