import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ThunkGetPhoneNumbersCountries } from '@store/slices/bufferPhoneNumbers/thunks';

import ViewAutoComplete from '@ui/components/common/ViewAutoComplete/ViewAutoComplete';
import Input from '@ui/components/Auth/Common/Input';

import { useTableContext } from '../../../../hooks/table';
import {
  phoneNumberTypesAutocomplete,
  searchTypesAutocomplete,
} from '../constants';

import useStyles from '../styles';

const MAX_PHONE_SEARCH_LIMIT = 5;

const filterNames = {
  region: 'region',
  numberType: 'numberType',
  searchType: 'searchType',
  search: 'search',
};

const PurchaseFilters = () => {
  const [region, setRegion] = useState({});
  const [searchType, setSearchType] = useState(searchTypesAutocomplete[1]);
  const [searchValue, setSearchValue] = useState();
  const [numberType, setNumberType] = useState(phoneNumberTypesAutocomplete[0]);
  const [loading, setLoading] = useState(true);
  const [countries, setCountries] = useState([]);

  const classes = useStyles();
  const dispatch = useDispatch();

  const { setFilteringObject, filtering } = useTableContext();

  const handleChange = (item, setter, filterName) => {
    if (filterName === filterNames.search) {
      let newValue = item?.value;
      if (newValue?.length > MAX_PHONE_SEARCH_LIMIT) {
        newValue = newValue.substring(0, MAX_PHONE_SEARCH_LIMIT);
      }
      setter({ value: newValue });
    } else {
      setter(item);
    }
    setFilteringObject(filtering, filterName, item?.value);
  };

  const onMount = useCallback(() => {
    setLoading(true);
    dispatch(ThunkGetPhoneNumbersCountries())
      .unwrap()
      .then(res => {
        setRegion(res[0]);
        setCountries(res);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <div className={classes.filterContainer}>
      <ViewAutoComplete
        options={countries}
        onChange={item => {
          handleChange(item, setRegion, filterNames.region);
        }}
        currentView={region}
        textFieldLabel="Region"
        disabled={loading}
        disableClearable
      />
      <ViewAutoComplete
        options={phoneNumberTypesAutocomplete}
        onChange={item => {
          handleChange(item, setNumberType, filterNames.numberType);
        }}
        currentView={numberType}
        textFieldLabel="Number Type"
        disableClearable
      />
      <ViewAutoComplete
        options={searchTypesAutocomplete}
        onChange={item => {
          handleChange(item, setSearchType, filterNames.searchType);
        }}
        currentView={searchType}
        textFieldLabel="Search Type"
      />

      <Input
        meta={{ touched: false }}
        input={{
          ...searchValue,
          onChange: e => {
            handleChange(
              { value: e.target.value },
              setSearchValue,
              filterNames.search,
            );
          },
        }}
        placeholder="Search"
      />
    </div>
  );
};

export default PurchaseFilters;
