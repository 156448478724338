import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteConversationTemplate } from '@services/requests/messaging';
import { MESSAGE_TEMPLATES } from '@store/slices/resources';

export const ThunkDeleteMessageTemplate = createAsyncThunk(
  `${MESSAGE_TEMPLATES}/deleteMessage`,
  async id => {
    await deleteConversationTemplate(id);
    return id;
  },
);

export default {};
