export const TableTypes = {
  boolean: 'boolean',
  actions: 'actions',
  bulk: 'bulkActions',
  dropdown: 'dropDown',
  customAccessor: 'customAccessor',
  phoneNumber: 'phoneNumber',
  date: 'date',
};

export const OrderTypes = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export const TablePaginationTypes = [10, 20, 40, 100, 200];

export const BulkTypeWidth = 50;

export const TableDebounceDelay = 500;
