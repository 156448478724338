import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  getConnectwiseMembers,
  getConnectwiseCompanies,
  baseFieldName,
} from '@components/Ticketing/Integrations/pages/updateCrm/CrmDefaults/SubForms/helper';
import { Typography } from '@material-ui/core';
import { Field } from 'react-final-form';
import DropDown from '@components/Auth/Common/DropDown';
import useStyles from '../../styles';

const requiredFields = {
  memberId: 'memberId',
  companyId: 'companyId',
};

const ConnectwiseSubForm = ({ crmId }) => {
  const classes = useStyles();

  const [membersOptions, setMembersOptions] = useState([]);
  const [companiesOptions, setCompaniesOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    Promise.all([
      getConnectwiseMembers(crmId, true),
      getConnectwiseCompanies(crmId),
    ])
      .then(resp => {
        setMembersOptions(resp[0]);
        setCompaniesOptions(resp[1]);
      })
      .catch(e => console.error(e.message))
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      <div>
        <Typography className={classes.inputLabel}>
          Time Entry Member
        </Typography>
        <Field
          id={baseFieldName + requiredFields.memberId}
          name={baseFieldName + requiredFields.memberId}
          styleType="main"
          inputView="text"
          component={DropDown}
          options={membersOptions}
          fullWidth
          disabled={loading}
        />
      </div>
      <div>
        <Typography className={classes.inputLabel}>
          Time Entry Company
        </Typography>
        <Field
          id={baseFieldName + requiredFields.companyId}
          name={baseFieldName + requiredFields.companyId}
          styleType="main"
          inputView="text"
          component={DropDown}
          options={companiesOptions}
          fullWidth
          disabled={loading}
        />
      </div>
    </>
  );
};

ConnectwiseSubForm.propTypes = {
  crmId: PropTypes.number.isRequired,
};

export default ConnectwiseSubForm;
