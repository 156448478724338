import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { useSnackbar } from 'notistack';

import {
  ThunkCreateOrUpdateTeamsBotConfig,
  ThunkDownloadTeamsBotZip,
  ThunkGetTeamsBotConfig,
  ThunkRemoveTeamsBotImage,
  ThunkUploadImage,
} from '@store/slices/microsoft/thunks';
import { ThunkGetClientPortalConfigurations } from '@store/slices/clientPortalAdmin/thunks';
import { pricePlanInfo } from '@store/selectors';

import { PricePlans } from '@components/Layout/SideBar/sideBarItems';

import { getTenantIdFromStorage } from '@services/api';

import Loading from '@common/Loading/Loading';
import FieldWrapper from '@common/form/FieldWrapper';
import Input from '@components/Auth/Common/Input';
import ReusableButton from '@common/Button/Button';
import DropDown from '@components/Auth/Common/DropDown';
import FileUploader, {
  imageTypes,
} from '@components/LiveChatConfigurations/components/FileUploader';

import useSharedStyles from '@components/CrmTicket/sharedStyles';

import maxLengthValidator from '@utils/validators/maxLengthValidator';
import useStyles from './styles';
import urlValidator from '../../../../utils/validators/urlValidator';

const maxLenValidationTextFunc = len =>
  `This field has a maximum length of ${len} characters`;
const urlValidationErrorText = 'Please specify a valid website URL';

const TeamsBotConfiguration = () => {
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [config, setConfig] = useState(undefined);
  const [processingZip, setProcessingZip] = useState(false);
  const [clientPortals, setClientPortals] = useState([]);

  const dispatch = useDispatch();
  const classes = useStyles();
  const sharedClasses = useSharedStyles();
  const { enqueueSnackbar } = useSnackbar();
  const plan = useSelector(pricePlanInfo);

  const downloadZip = () => {
    setProcessingZip(true);
    dispatch(ThunkDownloadTeamsBotZip())
      .unwrap()
      .finally(() => {
        setProcessingZip(false);
      });
  };

  const submit = values => {
    setProcessing(true);

    dispatch(
      ThunkCreateOrUpdateTeamsBotConfig({
        ...values,
        clientPortalId:
          plan?.name === PricePlans.Pro ? values.clientPortalId : null,
      }),
    )
      .unwrap()
      .then(() => {
        enqueueSnackbar(
          `Configuration ${!config ? 'created' : 'updated'} successfully`,
          {
            variant: 'success',
          },
        );

        if (!config) {
          // when we create a new config, we need to change this state so client download Zip and update the config
          setConfig(values);
        }
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const validate = values => ({
    name: maxLengthValidator(values.name, 30, true, maxLenValidationTextFunc),
    descriptionShort: maxLengthValidator(
      values.descriptionShort,
      80,
      true,
      maxLenValidationTextFunc,
    ),
    descriptionLong: maxLengthValidator(
      values.descriptionLong,
      4000,
      true,
      maxLenValidationTextFunc,
    ),
    webSiteUrl: urlValidator(values.webSiteUrl, true, urlValidationErrorText),
    privacyUrl: urlValidator(values.privacyUrl, true, urlValidationErrorText),
    termsOfServiceUrl: urlValidator(
      values.termsOfServiceUrl,
      true,
      urlValidationErrorText,
    ),
  });

  const onMount = useCallback(() => {
    setLoading(true);
    Promise.all([
      dispatch(ThunkGetTeamsBotConfig()).unwrap(),
      dispatch(ThunkGetClientPortalConfigurations()).unwrap(),
    ])
      .then(([res, clientPortalConfigs]) => {
        setClientPortals(
          clientPortalConfigs.map(({ id, portalName }) => ({
            value: id,
            label: portalName,
          })),
        );

        if (typeof res === 'object') {
          setConfig(res);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (loading) return <Loading />;
  return (
    <Form
      onSubmit={submit}
      initialValues={config}
      validate={validate}
      render={({ handleSubmit, form }) => (
        <form onSubmit={handleSubmit} className={classes.form}>
          <h3>Teams Bot Configuration</h3>

          <div className={classes.fileUploader}>
            <FieldWrapper
              label="Color Icon"
              labelSize={12}
              contentSize={12}
              fullWidth
              content={
                <FileUploader
                  name="logo"
                  resourceId={getTenantIdFromStorage()}
                  handleUpload={({ file }) => {
                    return ThunkUploadImage({
                      file,
                      maxHeight: 192,
                      maxWidth: 192,
                    });
                  }}
                  handleRemove={ThunkRemoveTeamsBotImage}
                  imageWidth="150px"
                  maxImageSize={30720}
                  formats={[imageTypes.png]}
                  onUploadCallBack={res => {
                    form.change('logo', res);
                  }}
                  customText="Displays in the Store and in most scenarios. Icon must be 192x192 pixels total with a 96x96-pixel symbol in the center."
                />
              }
            />

            <FieldWrapper
              label="Outline Icon"
              labelSize={12}
              contentSize={12}
              fullWidth
              content={
                <FileUploader
                  name="outlineLogo"
                  resourceId={getTenantIdFromStorage()}
                  handleUpload={({ file }) => {
                    return ThunkUploadImage({
                      file,
                      maxHeight: 32,
                      maxWidth: 32,
                    });
                  }}
                  handleRemove={ThunkRemoveTeamsBotImage}
                  imageWidth="150px"
                  maxImageSize={30720}
                  formats={[imageTypes.png]}
                  onUploadCallBack={res => {
                    form.change('outlineLogo', res);
                  }}
                  customText="Displays primarily on the left side of Teams when your app is in use. Icon must be 32x32 pixels and either white or transparent."
                />
              }
            />
          </div>

          <FieldWrapper
            label="Name"
            labelSize={12}
            contentSize={12}
            fullWidth
            isRequired
            content={
              <Field
                placeholder="Name"
                name="name"
                id="name"
                render={Input}
                disabled={processing}
              />
            }
          />

          {plan?.name === PricePlans.Pro && (
            <FieldWrapper
              label="Associated Client Portal"
              labelSize={12}
              contentSize={12}
              fullWidth
              isRequired
              content={
                <Field
                  placeholder="Associated Client Portal"
                  name="clientPortalId"
                  id="clientPortalId"
                  render={DropDown}
                  options={clientPortals}
                  disabled={processing}
                />
              }
            />
          )}

          <FieldWrapper
            label="Description (short)"
            labelSize={12}
            contentSize={12}
            fullWidth
            isRequired
            content={
              <Field
                placeholder="Description (short)"
                name="descriptionShort"
                id="descriptionShort"
                render={Input}
                disabled={processing}
              />
            }
          />

          <FieldWrapper
            label="Description (long)"
            labelSize={12}
            contentSize={12}
            fullWidth
            isRequired
            content={
              <Field
                placeholder="Description (long)"
                name="descriptionLong"
                id="descriptionLong"
                multiline
                minRows={3}
                maxRows={3}
                render={Input}
                disabled={processing}
              />
            }
          />

          <FieldWrapper
            label="Website URL"
            labelSize={12}
            contentSize={12}
            fullWidth
            isRequired
            content={
              <Field
                placeholder="Website URL"
                name="webSiteUrl"
                id="webSiteUrl"
                render={Input}
                disabled={processing}
              />
            }
          />

          <FieldWrapper
            label="Privacy URL"
            labelSize={12}
            contentSize={12}
            fullWidth
            isRequired
            content={
              <Field
                placeholder="Privacy URL"
                name="privacyUrl"
                id="privacyUrl"
                render={Input}
                disabled={processing}
              />
            }
          />

          <FieldWrapper
            label="Terms of service URL"
            labelSize={12}
            contentSize={12}
            fullWidth
            isRequired
            content={
              <Field
                placeholder="Terms of service URL"
                name="termsOfServiceUrl"
                id="termsOfServiceUrl"
                render={Input}
                disabled={processing}
              />
            }
          />

          <div className={sharedClasses.dialogActionsContainer}>
            {config && (
              <ReusableButton
                label="Get Zip File"
                onClick={downloadZip}
                disabled={processing || processingZip}
                loading={processingZip}
              />
            )}
            <ReusableButton
              disabled={processing || processingZip}
              loading={processing}
              label={config ? 'Update' : 'Create'}
              type="submit"
              viewType="blue"
            />
          </div>
        </form>
      )}
    />
  );
};

export default TeamsBotConfiguration;
