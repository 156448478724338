import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Input from '@common/Input/Input';
import { Field } from 'react-final-form';
import { makeStyles, Menu, MenuItem } from '@material-ui/core';
import FieldWrapper from '@common/form/FieldWrapper';
import DropDown from '@components/Auth/Common/DropDown';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  fieldRow: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const crmInitState = {
  mouseX: null,
  mouseY: null,
  key: null,
};

const fieldMenuInitState = {
  mouseX: null,
  mouseY: null,
  key: null,
};

const InputFields = ({
  inputFields,
  form,
  inputOptions,
  crmVariables,
  baseFieldName = 'input',
}) => {
  const classes = useStyles();
  const [crmMenuPosition, setCrmMenuPosition] = useState(crmInitState);
  const [fieldMenuPosition, setFieldMenuPosition] = useState(
    fieldMenuInitState,
  );

  const getMultiline = useCallback(displayType => {
    return displayType === 4;
  }, []);

  const handleCrmMenuClose = () => setCrmMenuPosition(crmInitState);
  const handleFieldMenuClose = () => setFieldMenuPosition(fieldMenuInitState);

  const handleFieldContextClick = (event, key) => {
    event.preventDefault();
    setFieldMenuPosition({
      mouseX: event.clientX + 2,
      mouseY: event.clientY + 4,
      key,
    });
  };

  const getInputType = type => {
    let inputType = 'text';
    switch (Number(type)) {
      case 0:
        inputType = 'text';
        break;
      case 1:
        inputType = 'password';
        break;
      case 2:
        inputType = 'number';
        break;
      case 3:
        inputType = 'dropDown';
        break;
      case 4:
        inputType = 'text';
        break;
      default:
        inputType = 'text';
        break;
    }
    return inputType;
  };

  const getInputComponent = type => {
    switch (Number(type)) {
      case 3:
        return DropDown;
      default:
        return Input;
    }
  };

  const populateValues = values => {
    values.forEach(val => {
      if (
        form.getRegisteredFields().includes(`${baseFieldName}.${val.label}`)
      ) {
        form.change(`${baseFieldName}.${val.label}`, val.value);
      }
    });
    handleCrmMenuClose();
  };

  const populateFieldValue = (value, isAdjust = false) => {
    if (form.getRegisteredFields().includes(fieldMenuPosition.key)) {
      if (isAdjust) {
        const oldValue = form.getFieldState(fieldMenuPosition.key).value || '';
        const newValue = `${oldValue}${value}`;
        form.change(fieldMenuPosition.key, newValue);
      } else {
        form.change(fieldMenuPosition.key, value);
      }
    }
    handleFieldMenuClose();
  };

  return (
    <div className={classes.container}>
      <FieldWrapper
        label="PSA Integration"
        classNameLabelOuter={classes.alignLeft}
        labelSize={2}
        contentSize={10}
        content={
          <Field
            id="crmSourceId"
            name="crmSourceId"
            component={DropDown}
            valueName="id"
            labelName="name"
            options={crmVariables}
          />
        }
      />
      {inputFields.map(i => {
        const key = `${baseFieldName}.${i.name}`;
        return (
          <FieldWrapper
            key={key}
            label={i.label}
            content={
              <div
                className={classes.fieldRow}
                onContextMenu={e => handleFieldContextClick(e, key)}
              >
                <Field
                  inputView="text"
                  styleType="main"
                  fullWidth
                  id={key}
                  name={key}
                  type={getInputType(i.displayType)}
                  component={getInputComponent(i.displayType)}
                  options={i.pickListOptions ?? []}
                  multiline={getMultiline(i.displayType)}
                  rows={3}
                  variant="outlined"
                />
                <Menu
                  keepMounted
                  elevation={1}
                  open={
                    fieldMenuPosition.mouseY !== null &&
                    inputOptions &&
                    inputOptions.length
                  }
                  onClose={handleCrmMenuClose}
                  anchorReference="anchorPosition"
                  anchorPosition={
                    fieldMenuPosition.mouseY !== null &&
                    fieldMenuPosition.mouseX !== null
                      ? {
                          top: fieldMenuPosition.mouseY,
                          left: fieldMenuPosition.mouseX,
                        }
                      : undefined
                  }
                >
                  {inputOptions.map(opt => (
                    <MenuItem
                      key={opt.label}
                      onClick={() =>
                        populateFieldValue(
                          opt.value,
                          getMultiline(i.displayType),
                        )
                      }
                    >
                      {opt.label}
                    </MenuItem>
                  ))}
                </Menu>
              </div>
            }
          />
        );
      })}
      <Menu
        keepMounted
        elevation={1}
        open={crmMenuPosition.mouseY !== null}
        onClose={handleCrmMenuClose}
        anchorReference="anchorPosition"
        anchorPosition={
          crmMenuPosition.mouseY !== null && crmMenuPosition.mouseX !== null
            ? { top: crmMenuPosition.mouseY, left: crmMenuPosition.mouseX }
            : undefined
        }
      >
        {crmVariables.map(opt => (
          <MenuItem
            key={opt.label}
            onClick={() => {
              populateValues(opt.value);
            }}
          >
            {opt.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

InputFields.propTypes = {
  inputFields: PropTypes.arrayOf(PropTypes.string),
  form: PropTypes.objectOf(PropTypes.any),
  inputOptions: PropTypes.arrayOf(PropTypes.any),
  crmVariables: PropTypes.arrayOf(PropTypes.any),
  baseFieldName: PropTypes.string,
};

export default InputFields;
