import React, { useCallback, useState } from 'react';
import Loading from '@common/Loading/Loading';
import useAsync from '@services/api/common/useAsync';
import { getSettingById } from './helpers';
import NotificationSettingsForm from './Form/NotificationSettingsForm';

const UpdateNotificationSettings = ({ match }) => {
  const [data, setData] = useState(null);
  const getData = useCallback(() => getSettingById(match.params.id), [match]);
  const loading = useAsync(getData, setData);
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <NotificationSettingsForm data={data} id={match.params.id} />
      )}
    </>
  );
};

export default UpdateNotificationSettings;
