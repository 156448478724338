import { makeRequest } from '@services/requests/makeRequest';

export const getIntegrations = () =>
  makeRequest('GET', `/SecurityIntegrations/`);

export const getMsAuthSetuperLink = () =>
  makeRequest('GET', `/SecurityIntegrations/microsoftSetupLink`);

export const getMsAuthRedirectUri = () =>
  makeRequest('GET', `/MicrosoftAuthGdapIntegrations/appRedirectUri`);

export const setupMsAuthGdapIntegration = data =>
  makeRequest('POST', `/MicrosoftAuthGdapIntegrations/setup`, data);

export const getIntegration = id =>
  makeRequest('GET', `/SecurityIntegrations/${id}`);

export const addIntegration = data =>
  makeRequest('POST', `/SecurityIntegrations/`, data);

export const updateIntegration = data =>
  makeRequest('PUT', `/SecurityIntegrations/${data.id}`, data);

export const deleteIntegration = id =>
  makeRequest('DELETE', `/SecurityIntegrations/${id}`);

export const deleteMicrosoftAuthIntegration = id =>
  makeRequest('DELETE', `/MicrosoftAuthSetupConfiguration/${id}`);
