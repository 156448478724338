import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import ActionButton from '@common/buttons/ActionButton/ActionButton';
import InputWithAutocomplete from '@common/Input/InputWithAutocomplete';
import DeepTemplateMenu from '@components/Ticketing/Notifications/components/DeepTemplateMenu';
import iconPlus from '@assets/icons/plusGrey.svg';

import {
  getEmailGroupVariables,
  getUsersForPhone,
} from '@components/Ticketing/Notifications/TicketSettings/helpers';
import { currentUserDataSelector } from '@store/selectors';
import useAsync from '@services/api/common/useAsync';
import { formatLabelWithPhone, stripSpacesDashesBrackets } from './helpers';

import useStyles from '../styles';

const initMenuPosition = {
  mouseX: null,
  mouseY: null,
};

const PhoneInput = ({
  disabled,
  form,
  templateVariables,
  name = 'phoneNumber',
}) => {
  const classes = useStyles();
  const tenantCountryCode = useSelector(currentUserDataSelector)
    ?.tenantCountryCode;

  const [menuPosition, setMenuPosition] = useState(initMenuPosition);
  const [userOptions, setUsersOptions] = useState([]);
  const optionsLoading = useAsync(getUsersForPhone, setUsersOptions);
  const [groupsOptions, setGroupsOptions] = useState([]);

  const groupsLoading = useAsync(getEmailGroupVariables, setGroupsOptions);

  const setDefaultMenuPosition = () => setMenuPosition(initMenuPosition);

  const options = useMemo(
    () =>
      userOptions.length
        ? userOptions
            .map(option => formatLabelWithPhone(option, tenantCountryCode))
            .sort((a, b) => a.name.localeCompare(b.name))
        : [],
    [tenantCountryCode, userOptions],
  );

  const getOptionValue = useCallback(
    i => (typeof i === 'object' ? i.value : i),
    [],
  );

  const filterOptions = useCallback((options1, { inputValue }) => {
    const searchTerm = inputValue.toLowerCase();
    return options1.filter(option => {
      const labelWithFormatNumber =
        typeof option === 'object' ? option.label : option;

      return (
        labelWithFormatNumber.toLowerCase().startsWith(searchTerm) ||
        stripSpacesDashesBrackets(option.number).includes(
          stripSpacesDashesBrackets(searchTerm),
        )
      );
    });
  }, []);

  // add element by pressing space
  const handleKeyDown = useCallback(
    (event, setInputValue) => {
      // add a number by pressing the space bar
      if (event.key === ' ' && !Number.isNaN(+event.target.value)) {
        const fieldValue = form.getFieldState(name)?.value || [];
        const inputValue = event.target.value.trim();
        if (inputValue) {
          const newValue = [
            ...fieldValue,
            { value: inputValue, label: inputValue },
          ];
          form.change(name, newValue.map(getOptionValue));
          setInputValue('');
        }
      }
    },
    [form, getOptionValue, name],
  );

  return (
    <>
      <ActionButton
        handler={event =>
          setMenuPosition({
            mouseX: event.clientX + 2,
            mouseY: event.clientY + 4,
          })
        }
        icon={<img src={iconPlus} alt="iconPlus" />}
        toolTip="Add template variable"
        disabled={disabled}
      />
      <InputWithAutocomplete
        name={name}
        options={options}
        loading={optionsLoading || groupsLoading}
        disableCloseOnSelect
        freeSolo
        getOptionValue={getOptionValue}
        getOptionLabel={i => (typeof i === 'object' ? i.label : i)}
        filterOptions={filterOptions}
        limitTags={4}
        onKeyDown={handleKeyDown}
        className={classes.inputWithAutocomplete}
      />
      <DeepTemplateMenu
        menuPosition={menuPosition}
        setDefaultMenuPosition={setDefaultMenuPosition}
        form={form}
        name={name}
        templateVariables={templateVariables}
        groupsVariables={groupsOptions}
      />
    </>
  );
};

PhoneInput.propTypes = {
  disabled: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  form: PropTypes.objectOf(PropTypes.any),
  // eslint-disable-next-line react/forbid-prop-types
  templateVariables: PropTypes.arrayOf(PropTypes.any),
  name: PropTypes.string,
};

export default PhoneInput;
