import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { EditButton, useTranslate, useVersion } from 'react-admin';

import roles from '@constants/roles';

import exporter, { retrieveConfigFromHeaders } from '@utils/exporter';
import { getDataFromToken } from '@utils/getDataFromToken';

import MuiVirtualizedTable from '@common/MuiVirtualizedTable';
import BulkActionButtons from '@common/BulkActionButtons';
import iconEdit from '@assets/icons/edit.svg';
import DateTimeField from '@common/TableComponents/DateTimeField';
import TableLabel from '@common/TableLabel/TableLabel';

import LoginAsButton from './components/LoginAsButton';
import ListActions from './components/ListActions';
import DeleteActionField from './components/DeleteActionField';
import Switch2mfaButton from './components/Switch2mfaButton';

import useStyles from './styles';

const exportHeaders = [
  { id: 'id', name: 'resources.users.fields.id' },
  { id: 'firstName', name: 'resources.users.fields.firstName' },
  { id: 'lastName', name: 'resources.users.fields.lastName' },
  { id: 'email', name: 'resources.users.fields.email' },
  { id: 'isActive', name: 'resources.users.fields.isActive' },
  { id: 'roleId', name: 'resources.users.fields.roleId' },
  { id: 'updatedAt', name: 'resources.users.fields.updatedAt' },
];

const UsersList = () => {
  const classes = useStyles();
  const translate = useTranslate();
  const version = useVersion();
  const exporterConfig = retrieveConfigFromHeaders(exportHeaders, translate);
  const { userId } = getDataFromToken();

  const userRole = useMemo(() => {
    const { roleId } = getDataFromToken();

    return {
      isSuperAdmin: roleId === roles.SUPER_ADMIN,
      isAdmin: roleId === roles.SUPER_ADMIN || roleId === roles.TENANT_ADMIN,
    };
  }, []);

  const columns = [
    {
      name: <TableLabel item={{ name: 'Name', field: 'name' }} />,
      key: 'name',
      width: 260,
    },
    {
      name: <TableLabel item={{ name: 'Email', field: 'email' }} />,
      key: 'email',
      width: 260,
    },
    {
      name: 'Role',
      key: 'role',
      width: 260,
    },
    userRole.isAdmin && {
      name: 'MFA',
      key: 'mfa',
      accessor: rowData => <Switch2mfaButton record={rowData} />,
      width: 50,
    },
    userRole.isSuperAdmin && {
      name: <TableLabel item={{ name: 'Tenant', field: 'tenant' }} />,
      key: 'tenant',
      width: 260,
    },
    userRole.isSuperAdmin && {
      name: 'Login',
      key: 'login',
      accessor: rowData => <LoginAsButton record={rowData} />,
      width: 50,
    },
    {
      name: 'Last Login',
      key: 'lastLoginDateTime',
      accessor: rowData => (
        <DateTimeField record={rowData} source="lastLoginDateTime" />
      ),
    },
    userRole.isAdmin && {
      name: 'Actions',
      key: 'actions',
      accessor: rowData => (
        <>
          <EditButton
            label=""
            icon={<img src={iconEdit} alt="iconEdit" />}
            record={rowData}
            basePath="users"
            className={classes.editButton}
          />
          <DeleteActionField record={rowData} />
        </>
      ),
      width: 200,
    },
  ].filter(p => p);

  const actionComponents = useMemo(
    () => ({
      bulkActions: userRole.isAdmin ? (
        <BulkActionButtons excludeFromIdList={userId} />
      ) : null,
      hideCreateButton: !userRole.isAdmin,
    }),
    [userRole, userId],
  );

  return (
    <MuiVirtualizedTable
      key={version}
      defaultFilters={{ isActive: true }}
      defaultSort={{ field: 'id', order: 'DESC' }}
      bulkActions={actionComponents.bulkActions}
      actions={
        <ListActions hideCreateButton={actionComponents.hideCreateButton} />
      }
      additionalControllerProps={{
        exporter: exporter(exporterConfig, ['updatedAt']),
      }}
      columns={columns}
      resource="users"
    />
  );
};

UsersList.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

export default UsersList;
