const urlValidator = (url, isRequired = true, text = 'Invalid url') => {
  if (!url && isRequired) {
    return 'Required';
  }
  return /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/.test(url)
    ? undefined
    : text;
};

export default urlValidator;
