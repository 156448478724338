import React from 'react';
import PropTypes from 'prop-types';
import MuiPhoneNumber from 'material-ui-phone-number';
import { Field, Form } from 'react-final-form';
import { useSelector } from 'react-redux';

import { voiceSettings } from '@store/selectors/notifications';
import { actions } from '@store/actions';

import {
  getDmVoiceSettings,
  postDmVoiceSettings,
} from '@components/Ticketing/Notifications/TicketSettings/helpers';
import { getTemplates } from '@components/Ticketing/Notifications/DmSettings/helpers';

import useTicketSetting from '@components/Ticketing/Notifications/hooks/useTicketSetting';
import useTemplateVariables from '@components/Ticketing/Notifications/hooks/useTemplateVariables';
import useDisabled from '@components/Hooks/useDisabled';

import { phoneWithCharactersValidator } from '@utils/validators';
import { handleDoWChange } from '@components/Ticketing/Notifications/utils';
import { basePermissions } from '@constants/permissions';

import Paper from '@common/Paper/Paper';
import FieldWrapper from '@common/form/FieldWrapper';
import {
  InputWithTemplate,
  IsScheduledSelector,
  ScheduledPartOfForm,
  SubFormButtons,
} from '@components/Ticketing/Notifications/TicketSettings/components/subComponents';
import Loading from '@common/Loading/Loading';

import useStyles from './styles';

const VoiceSettings = ({ match }) => {
  const classes = useStyles();
  const storedData = useSelector(voiceSettings);

  const { submit, loading, handleBack, data, setData } = useTicketSetting({
    storedData,
    paramsId: match.params.id,
    action: actions.saveVoiceSetting,
    tileKey: 'isVoice',
    apiPostRequest: postDmVoiceSettings,
    apiGetSetting: getDmVoiceSettings,
  });

  const { templateVariables } = useTemplateVariables({ func: getTemplates });

  const onDoWChange = handleDoWChange(setData);

  const { editMode, disabled } = useDisabled(
    loading,
    'notificationSettings',
    basePermissions.update,
  );

  const dataLoaded = typeof data.id !== 'undefined';

  return dataLoaded ? (
    <Paper>
      <Form
        onSubmit={submit}
        validate={values => ({
          phoneNumber: phoneWithCharactersValidator(
            values.phoneNumber,
            10,
            true,
          ),
        })}
        initialValues={data}
        render={({ handleSubmit, values, form }) => (
          <form onSubmit={handleSubmit}>
            <div>
              <Field
                name="phoneNumber"
                render={({ input, meta }) => (
                  <MuiPhoneNumber
                    {...input}
                    name="phoneNumber"
                    defaultCountry="us"
                    disabled={disabled}
                    error={meta.touched && meta.error}
                    helperText={meta.touched && meta.error ? meta.error : ''}
                    className={classes.phoneNumber}
                  />
                )}
              />
              <FieldWrapper
                label="Message"
                labelSize={3}
                content={
                  <InputWithTemplate
                    name="template"
                    disabled={disabled}
                    form={form}
                    templateVariables={templateVariables}
                  />
                }
              />
              <FieldWrapper
                label="Recover Message"
                labelSize={3}
                content={
                  <InputWithTemplate
                    name="recoverTemplate"
                    disabled={disabled}
                    form={form}
                    templateVariables={templateVariables}
                  />
                }
              />
              <IsScheduledSelector values={values} disabled={disabled} />
              <ScheduledPartOfForm
                data={data}
                values={values}
                disabled={disabled}
                onDoWChange={onDoWChange}
              />
            </div>
            <SubFormButtons
              loading={loading}
              handleBack={handleBack}
              editMode={editMode}
            />
          </form>
        )}
      />
    </Paper>
  ) : (
    <Loading />
  );
};

VoiceSettings.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string }),
  }),
};

export default VoiceSettings;
