import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Input,
  InputAdornment,
  Tooltip,
} from '@material-ui/core';
import cx from 'classnames';
import {
  Close as CloseIcon,
  ExpandMoreOutlined,
  Search as SearchIcon,
} from '@material-ui/icons';
import { cloneDeep } from 'lodash';
import { Link, useLocation } from 'react-router-dom';

import { currentUserDataSelector, pricePlanInfo } from '@store/selectors';
import storage from '@constants/localStorage';

import { sidebarContent } from '@components/Layout/SideBar/sideBarItems';
import { getDataFromToken } from '@utils/getDataFromToken';

import useStyles from './styles';

const MIN_SEARCH_TEXT_LENGTH = 2;

const SideBar = React.memo(() => {
  const [expandedPanels, setExpandedPanels] = useState([]);
  const [searchText, setSearchText] = useState('');

  const sidebarOpen = useSelector(state => state.admin.ui.sidebarOpen);
  const location = useLocation();
  const classes = useStyles({ sidebarOpen });

  const currentUser = useSelector(currentUserDataSelector);
  const plan = useSelector(pricePlanInfo) || {};

  const sideBarItems = useMemo(() => {
    const { roleId } = getDataFromToken();
    const allowedResources = localStorage.getItem(storage.ALLOWED_RESOURCES);

    if (!currentUser || plan.isSubscriptionEnabled === false) return [];

    return (
      cloneDeep(
        sidebarContent.map(sideBarItem => {
          if (sideBarItem.items?.length) {
            sideBarItem.items.sort((a, b) => a.label.localeCompare(b.label));
          }
          return sideBarItem;
        }),
      )
        // removing whole menus first by price plan and roleId
        .filter(
          item =>
            item.permissions.includes(roleId) &&
            item.pricePlans.includes(plan.name),
        )
        // removing menus by dependencies
        .filter(item => {
          if (item?.dependencies.length > 0) {
            return item.dependencies.some(dep =>
              allowedResources.includes(dep),
            );
          }
          return true;
        })
        // removing sub menus by price plan and roleId
        .map(item => {
          if (item?.items?.length) {
            return {
              ...item,
              items: item.items.filter(
                subItem =>
                  subItem.permissions.includes(roleId) &&
                  subItem.pricePlans.includes(plan.name),
              ),
            };
          }
          return item;
        })
        // removing sub menus by dependencies
        .map(item => {
          if (item?.items?.length) {
            return {
              ...item,
              items: item.items.filter(subItem => {
                if (subItem?.dependencies.length > 0) {
                  return subItem.dependencies.some(dep =>
                    allowedResources.includes(dep),
                  );
                }
                return true;
              }),
            };
          }
          return item;
        })
        .filter(
          item => typeof item?.items === 'undefined' || item?.items?.length,
        )
    );
  }, [currentUser, plan?.name]);

  const handleOpened = label => {
    if (expandedPanels.includes(label)) {
      setExpandedPanels(panels => panels.filter(i => i !== label));
    } else {
      setExpandedPanels(panels => [...panels, label]);
    }
  };

  const handleSearch = text => {
    setSearchText(text);
  };

  const dropSearch = () => {
    if (searchText !== '') {
      handleSearch('');
    }
  };

  const handleFilteringAfterSearch = i => {
    if (searchText.length < MIN_SEARCH_TEXT_LENGTH) {
      return true;
    }

    // filtering sidebar items without sub items
    if (!i.items && i.label.toLowerCase().includes(searchText)) {
      return true;
    }

    if (i.items) {
      const subItems = i.items.filter(subItem => {
        return subItem.label.toLowerCase().includes(searchText);
      });
      if (subItems.length > 0) {
        return true;
      }
    }

    return false;
  };

  return (
    <div className={classes.sideBar} style={{ position: 'relative' }}>
      <div className={cx(classes.sideBarSearch)}>
        {sidebarOpen && (
          <Input
            autoComplete="off"
            id="input"
            type="text"
            fullWidth
            placeholder="Search"
            className={classes.sideBarSearchInput}
            inputProps={{ 'aria-label': 'naked' }}
            endAdornment={
              <label htmlFor="input">
                <InputAdornment position="start">
                  {searchText !== '' ? (
                    <CloseIcon
                      className={classes.sideBarSearchIcon}
                      onClick={dropSearch}
                    />
                  ) : (
                    <SearchIcon className={classes.sideBarSearchIcon} />
                  )}
                </InputAdornment>
              </label>
            }
            onChange={e => {
              handleSearch(e.target.value.toLowerCase());
            }}
            value={searchText}
          />
        )}
      </div>

      {sideBarItems.filter(handleFilteringAfterSearch).map(item => {
        return (
          <>
            {item?.items ? (
              <Accordion
                className={cx(classes.accordion, {
                  [classes.expandedAccordion]:
                    expandedPanels.includes(item?.label) ||
                    searchText?.length >= MIN_SEARCH_TEXT_LENGTH,
                  [classes.activeAccordion]: item?.items.some(
                    subItem => subItem.path === location.pathname,
                  ),
                })}
                TransitionProps={{ unmountOnExit: true }}
                onChange={() => {
                  handleOpened(item?.label);
                }}
                expanded={
                  expandedPanels.includes(item?.label) ||
                  searchText?.length >= MIN_SEARCH_TEXT_LENGTH
                }
                id={`sidebar-button-${item?.label}`}
              >
                <AccordionSummary>
                  <div className={classes.sidebarItem}>
                    <Tooltip
                      title={!sidebarOpen ? item?.label : ''}
                      placement="right"
                    >
                      {item.icon}
                    </Tooltip>

                    <div>{sidebarOpen && item?.label}</div>
                    <div
                      style={{
                        marginLeft: 'auto',
                        transform: expandedPanels.includes(item?.label)
                          ? 'rotate(180deg)'
                          : 'unset',
                      }}
                    >
                      <ExpandMoreOutlined
                        style={{
                          translate: expandedPanels.includes(item?.label)
                            ? 'rotate(0deg)'
                            : 'rotate(90deg',
                        }}
                      />
                    </div>
                  </div>
                </AccordionSummary>

                <AccordionDetails
                  style={{
                    padding: '0px',
                    display: 'flex',
                    gap: '5px',
                    flexDirection: 'column',
                  }}
                >
                  {item.items
                    .filter(i => {
                      if (searchText.length < MIN_SEARCH_TEXT_LENGTH) {
                        return true;
                      }
                      return i.label.toLowerCase().includes(searchText);
                    })
                    .map(subItem => (
                      <Link
                        id={`sidebar-link-${subItem?.path}`}
                        key={subItem.label}
                        to={subItem.path}
                        className={cx(
                          classes.sidebarItem,
                          classes.subItem,
                          classes.hoverAccordion,
                          {
                            [classes.activeItem]:
                              location.pathname === subItem?.path,
                          },
                        )}
                      >
                        <Tooltip
                          title={!sidebarOpen ? subItem?.label : ''}
                          placement="right"
                        >
                          {subItem?.icon}
                        </Tooltip>
                        {sidebarOpen && <div>{subItem?.label}</div>}
                      </Link>
                    ))}
                </AccordionDetails>
              </Accordion>
            ) : (
              <Link
                className={cx(
                  classes.sidebarItem,
                  classes.hoverAccordion,
                  classes.noSubItem,
                  {
                    [classes.activeItem]: location.pathname === item?.path,
                  },
                )}
                to={item?.path}
                id={`sidebar-link-${item?.label}`}
              >
                <Tooltip
                  title={!sidebarOpen ? item?.label : ''}
                  placement="right"
                >
                  {item?.icon}
                </Tooltip>
                <div>{item?.label}</div>
              </Link>
            )}
          </>
        );
      })}
    </div>
  );
});

export default SideBar;
