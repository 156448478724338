import { createAsyncThunk } from '@reduxjs/toolkit';
import { TWILIO_ACCOUNTS } from '@store/slices/resources';
import { deleteTwilioAccount } from '@services/requests/twilioAccounts';

export const ThunkDeleteTwilioAccount = createAsyncThunk(
  `${TWILIO_ACCOUNTS}/deleteTwilioAccount`,
  async id => {
    await deleteTwilioAccount(id);
    return id;
  },
);

export default {};
