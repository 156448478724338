import { createAsyncThunk } from '@reduxjs/toolkit';
import { MESSAGE_TEMPLATES } from '@store/slices/resources';
import { getTemplateVariables } from '@services/requests/smsConversations';

export const ThunkGetMessageTemplateVariables = createAsyncThunk(
  `${MESSAGE_TEMPLATES}/getMessageTemplatesVariables`,
  async () => getTemplateVariables(),
);

export default {};
