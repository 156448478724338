import React, { useCallback, useMemo, useState } from 'react';
import { Step, StepLabel, Stepper } from '@material-ui/core';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { pricePlanInfo } from '@store/selectors';
import { getDataFromToken } from '@utils/getDataFromToken';

import VerificationSettings from '@components/Settings/VerificationSettings/VerificationSettings';
import { PricePlans } from '@components/Layout/SideBar/sideBarItems';
import OutgoingMail from '@ui/pages/OutgoingMail';
import StepperHeader from './StepperHeader';
import PsaDefaultSetup from '../steps/PsaDefaultSetup';
import UserSetup from '../steps/UserSetup';
import MessagingSetup from '../steps/MessagingSetup';
import InviteUsers from '../steps/InviteUsers';

import { steps } from '../constants/steps';

import useStyles from '../styles';

const CustomStepper = ({ handleClose }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [moveForwardIsDisabled, setDisabledMoveForward] = useState(false);

  const plan = useSelector(pricePlanInfo);
  const { roleId } = getDataFromToken();
  const classes = useStyles();

  const activeSteps = useMemo(
    () =>
      steps.filter(
        i => i.pricePlans.includes(plan?.name) && i.roles.includes(roleId),
      ),
    [plan, roleId],
  );

  const handleForward = useCallback(() => {
    const active = activeSteps.findIndex(i => i.id === activeStep);
    const next = activeSteps[active + 1];

    if (next) {
      setActiveStep(next.id);
    } else {
      handleClose();
    }
  }, [activeStep, activeSteps, handleClose]);

  const handleBackward = useCallback(() => {
    const active = activeSteps.findIndex(i => i.id === activeStep);
    const prev = activeSteps[active - 1];

    if (prev) {
      setActiveStep(prev.id);
    }
  }, [activeStep, activeSteps]);

  const disableMoveForward = useCallback(() => {
    setDisabledMoveForward(true);
  }, []);

  const enableMoveForward = useCallback(() => {
    setDisabledMoveForward(false);
  }, []);

  const content = useMemo(() => {
    switch (activeStep) {
      case 0:
        return (
          <UserSetup
            disableMoveForward={disableMoveForward}
            enableMoveForward={enableMoveForward}
          />
        );
      case 1:
        return (
          <PsaDefaultSetup
            handleForward={handleForward}
            skipDefaultCreation={plan.name !== PricePlans.Pro}
          />
        );
      case 2:
        return <MessagingSetup handleForward={handleForward} />;
      case 3:
        return <InviteUsers handleForward={handleForward} />;
      case 4:
        return (
          <VerificationSettings
            skipBorders
            customClass={classes.verificationSettingsWrapper}
          />
        );
      case 5:
        return <OutgoingMail useRelativeSizing />;
      default:
        return <></>;
    }
  }, [
    activeStep,
    disableMoveForward,
    enableMoveForward,
    handleForward,
    classes.verificationSettingsWrapper,
    plan?.name,
  ]);

  return (
    <>
      {activeSteps.length > 1 && (
        <div className={classes.stepperContainer}>
          <Stepper
            activeStep={activeSteps.findIndex(i => i.id === activeStep)}
            className={classes.stepper}
          >
            {activeSteps.map(({ label }) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <StepperHeader
            lastStep={activeSteps[activeSteps.length - 1].id === activeStep}
            handleForward={handleForward}
            handleBackward={handleBackward}
            handleClose={handleClose}
            activeStep={activeStep}
            disableMoveForward={moveForwardIsDisabled}
          />
        </div>
      )}
      <div>{content}</div>
    </>
  );
};

CustomStepper.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default CustomStepper;
