import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Step, StepLabel, Stepper } from '@material-ui/core';

import { SECURITY_INTEGRATION } from '@constants/routes';
import { securityIntegrationSources } from '@constants/integrationSources';

import M365Dialog from '@ui/pages/securityIntegrations/components/M365Dialog/M365Dialog';
import SelectIntegration from './components/SelectIntegration';
import BaseSettings from './components/BaseSettings';

import useStyles from './styles';

const getSteps = () => ['Select integration', 'Input base settings'];

const CreateSecurityIntegrations = () => {
  const { push } = useHistory();

  const [integrationType, setIntegrationType] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const [openM365Dialog, setOpenM365Dialog] = useState(false);

  const steps = getSteps();

  const classes = useStyles();

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleBaseSettingsSubmit = () => {
    push(SECURITY_INTEGRATION);
  };

  const handleSelectType = name => {
    if (name === securityIntegrationSources.MicrosoftAuthenticator.name) {
      setOpenM365Dialog(true);
    } else {
      setIntegrationType(name);
      handleNext();
    }
  };

  const getStepContent = step => {
    switch (step) {
      case 0:
        return <SelectIntegration handleSelect={handleSelectType} />;
      case 1:
        return (
          <BaseSettings
            integrationType={integrationType}
            onSubmit={handleBaseSettingsSubmit}
            onBack={handleBack}
          />
        );
      default:
        return <div />;
    }
  };

  return (
    <div>
      <Stepper activeStep={activeStep} classes={{ root: classes.root }}>
        {steps.map(label => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div>{getStepContent(activeStep)}</div>
      <M365Dialog
        onClose={() => setOpenM365Dialog(false)}
        open={openM365Dialog}
      />
    </div>
  );
};

export default CreateSecurityIntegrations;
