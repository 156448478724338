import React, { memo } from 'react';

import SimpleTable from '@components/Triggers/pages/Incident/components/SimpleTable';

import { FilteredContactsSelector } from '@store/slices/dynamicCrmContacts';

const FilteredList = () => {
  return (
    <SimpleTable
      selector={FilteredContactsSelector}
      columns={[
        { label: 'Name', value: 'name' },
        { label: 'Type', value: 'phoneType' },
        { label: 'Phone', value: 'phone' },
      ]}
    />
  );
};

export default memo(FilteredList);
