import { createAsyncThunk } from '@reduxjs/toolkit';
import { OPTIN_SETTINGS } from '@store/slices/resources';
import { getOptInPhoneTypes } from '@services/requests/optInSettings';

export const ThunkGetOptInPhoneTypes = createAsyncThunk(
  `${OPTIN_SETTINGS}/getOptInPhoneTypes`,
  crmId => getOptInPhoneTypes(crmId),
);

export default {};
