import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontStyle: 'normal',
    height: '94vh',
    overflowY: 'scroll',
  },
  block: {
    textAlign: 'center',
    margin: '25px 0',
    [theme.breakpoints.down('xs')]: {
      padding: '0.25rem',
    },
    padding: '1.25rem',
    backgroundColor: theme.palette.tenantSettings.background,
    borderRadius: '0.8125rem',
    minWidth: '35vw',
  },
  formContentWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  gridContainer: {
    marginTop: '10px',
    [theme.breakpoints.down('xs')]: {
      width: '95%',
    },
  },
  fieldLabelOuter: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '0.5rem',
    padding: '10px',
    columnGap: '0.5rem',
  },
  reactQuillClassName: {
    '& .ql-container': {
      height: '150px',
      maxWidth: '532px',
    },
  },
  emailRetentionField: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
  },
}));

export default useStyles;
