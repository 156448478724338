import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { IconButton, Popover, TextField } from '@material-ui/core';

import TimePicker from '@common/DateRangePicker/components/TimePicker';
import CancelIcon from '@material-ui/icons/Close';
import DayPicker from './components/DayPicker';
import Root from './components/Root';

import { getDateTab } from './helper';
import {
  DATE_FORMAT,
  DEFAULT_TAB_INDEX,
  popoverPosition,
  resetDateRangeValue,
  TIME_FROM,
  TIME_TO,
} from './constants';

import useStyles from './styles';

const DateRangePicker = ({ input, ...props }) => {
  const classes = useStyles();

  const defaultRange = useMemo(() => {
    const from = input.value?.from
      ? moment(input.value.from)
          .local()
          .toDate()
      : undefined;
    const to = input.value?.to
      ? moment(input.value.to)
          .local()
          .toDate()
      : undefined;

    return { from, to };
  }, [input.value]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [tabIndex, setTabIndex] = useState(DEFAULT_TAB_INDEX);
  const [range, setRange] = useState(defaultRange);

  const [fromTime, setFromTime] = useState(TIME_FROM);
  const [toTime, setToTime] = useState(TIME_TO);

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (defaultRange.from && defaultRange.to) {
      setTabIndex(
        getDateTab(moment(defaultRange.from), moment(defaultRange.to)),
      );
    } else {
      setTabIndex(DEFAULT_TAB_INDEX);
    }
  }, [defaultRange]);

  const handleOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleReset = e => {
    e.stopPropagation();
    setRange(resetDateRangeValue);
    setToTime(TIME_TO);
    setFromTime(TIME_FROM);
    input.onChange(resetDateRangeValue);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setRange(defaultRange);
    if (defaultRange.from && defaultRange.to) {
      setTabIndex(
        getDateTab(moment(defaultRange.from), moment(defaultRange.to)),
      );
    } else {
      setTabIndex(DEFAULT_TAB_INDEX);
    }
  };

  const handleApply = useCallback(() => {
    const utcRange = {
      from: range.from
        ? moment(range.from)
            .set({
              hour: moment(fromTime, 'HH:mm').hour(),
              minute: moment(fromTime, 'HH:mm').minute(),
              second: 0,
              millisecond: 0,
            })
            .utc()
            .toISOString()
        : undefined,
      to: range.to
        ? moment(range.to)
            .set({
              hour: moment(toTime, 'HH:mm').hour(),
              minute: moment(toTime, 'HH:mm').minute(),
              second: 59,
              millisecond: 999,
            })
            .utc()
            .toISOString()
        : undefined,
    };

    input.onChange(utcRange);
    setAnchorEl(null);
  }, [fromTime, input, range, toTime]);

  return (
    <div className={classes.dateRangePicker}>
      <TextField
        label="Select date"
        value={
          range.from
            ? `${moment(range.from).format(DATE_FORMAT)}${
                range.to ? ` - ${moment(range.to).format(DATE_FORMAT)}` : ''
              }`
            : ''
        }
        onClick={handleOpen}
        fullWidth
        InputProps={{
          readOnly: true,
          endAdornment: range.from ? (
            <IconButton className={classes.resetButton} onClick={handleReset}>
              <CancelIcon />
            </IconButton>
          ) : (
            undefined
          ),
        }}
        size="small"
        className={classes.input}
        {...props}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        {...popoverPosition}
      >
        <Root
          tabIndex={tabIndex}
          handleApply={handleApply}
          handleClose={handleClose}
          setTabIndex={setTabIndex}
          setRange={setRange}
        >
          <div className={classes.dayPicker}>
            <DayPicker
              range={range}
              setRange={setRange}
              setTabIndex={setTabIndex}
            />
            <TimePicker
              setFromTime={setFromTime}
              setToTime={setToTime}
              fromTime={fromTime}
              toTime={toTime}
            />
          </div>
        </Root>
      </Popover>
    </div>
  );
};

DateRangePicker.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.shape({
      from: PropTypes.string,
      to: PropTypes.string,
    }),
    onChange: PropTypes.func,
  }),
};

export default DateRangePicker;
