import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'start',
    padding: '0px',
    margin: '20px 80px',
    fontStyle: 'normal',
  },
  listActionContainer: {
    marginBottom: '10px',
  },
  block: {
    textAlign: 'center',
    margin: '25px 0',
    width: '50rem',
  },
  form: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '3rem 1.25rem',
    backgroundColor: 'white',
    borderRadius: '0.8125rem',
  },
  contentFormWrapper: {
    width: '100%',
  },
  ticketSubject: {
    display: 'flex',
    alignItems: 'center',
  },
  inputSubjectWrapper: {
    padding: 0,
  },
  button: {
    '&:last-child': {
      marginLeft: '1.875rem',
    },
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '2.5rem',
  },
  plusIcon: {
    fill: theme.palette.info.main,
    marginRight: '0.5rem',
  },
  openTextDecorator: {
    marginTop: '8px',
    fontStyle: 'normal',
  },
  header: {
    fontStyle: 'normal',
    marginBottom: '1rem',
  },
  phoneNumber: {
    '& .MuiFormHelperText-root': {
      marginTop: 0,
    },
  },
}));

export default useStyles;
