import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { TWILIO_ACCOUNTS } from '@services/requests/requestResources';

import Table from '@components/Table';
import { TableTypes } from '@components/Table/constants';
import TwilioAccountNumberForm from './components/TwilioAccountNumberForm';
import Actions from './components/Actions';

import useStyles from './styles';

const TwilioAccountsNumbers = ({ match }) => {
  const {
    params: { id },
  } = match;
  const classes = useStyles();

  const [item, setItem] = useState(null);

  return (
    <div className={classes.container}>
      <Table
        resource={`${TWILIO_ACCOUNTS}/${id}/numbers`}
        columns={[
          {
            name: 'Phone Number',
            key: 'number',
            filterName: 'filter',
            type: TableTypes.phoneNumber,
            searchable: true,
          },
          {
            name: 'SID',
            key: 'sid',
          },
          {
            name: 'Actions',
            key: 'Actions',
            type: TableTypes.actions,
            accessor: row => (
              <Actions row={row} twilioAccountId={id} setItem={setItem} />
            ),
            width: 100,
          },
        ]}
      />

      {!!item && (
        <TwilioAccountNumberForm
          item={item}
          setModalData={() => {
            setItem(null);
          }}
        />
      )}
    </div>
  );
};

TwilioAccountsNumbers.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
    url: PropTypes.string,
  }),
};

export default TwilioAccountsNumbers;
