import React from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuItem } from '@material-ui/core';
import NestedMenuItem from 'material-ui-nested-menu-item';

const DeepTemplateMenu = ({
  menuPosition,
  setDefaultMenuPosition,
  form,
  name,
  templateVariables,
  groupsVariables,
  overrideValue = false,
}) => {
  const applyValue = i => {
    const val = form.getFieldState(name).value || '';
    let newValue;
    if (Array.isArray(val)) {
      newValue = overrideValue ? [i.value] : [...val, i.value];
    } else {
      newValue = overrideValue ? i.value : `${val}${i.value}`;
    }
    form.change(name, newValue);
  };

  return (
    <Menu
      keepMounted
      id="simple-menu"
      open={menuPosition.mouseY !== null}
      onClose={setDefaultMenuPosition}
      anchorReference="anchorPosition"
      anchorPosition={
        menuPosition.mouseY !== null && menuPosition.mouseX !== null
          ? { top: menuPosition.mouseY, left: menuPosition.mouseX }
          : undefined
      }
    >
      <NestedMenuItem
        label="Variables"
        parentMenuOpen={menuPosition.mouseY !== null}
      >
        {templateVariables.map(i => (
          <MenuItem
            key={i.value}
            onClick={() => {
              setDefaultMenuPosition();
              applyValue(i);
            }}
          >
            {i.label}
          </MenuItem>
        ))}
      </NestedMenuItem>
      <NestedMenuItem
        label="Notification groups"
        parentMenuOpen={menuPosition.mouseY !== null}
      >
        {groupsVariables.map(i => (
          <MenuItem
            key={i.value}
            onClick={() => {
              setDefaultMenuPosition();
              applyValue(i);
            }}
          >
            {i.label}
          </MenuItem>
        ))}
      </NestedMenuItem>
    </Menu>
  );
};

DeepTemplateMenu.propTypes = {
  menuPosition: PropTypes.shape({
    mouseX: PropTypes.number,
    mouseY: PropTypes.number,
  }),
  setDefaultMenuPosition: PropTypes.func,
  form: PropTypes.objectOf(PropTypes.any),
  name: PropTypes.string,
  templateVariables: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  groupsVariables: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
};

export default DeepTemplateMenu;
