import React from 'react';
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';

import { TypeLabelValue } from '@common/propTypes/common';

const RadioInput = ({ label = '', choices = [], input, meta, className }) => {
  const isError = meta && meta.error && meta.touched;
  return (
    <FormControl component="fieldset" className={className}>
      {label && <FormLabel>{label}</FormLabel>}
      <div style={isError ? { borderBottom: '2px solid red' } : undefined}>
        <RadioGroup
          row
          value={input.value}
          onChange={e => input.onChange(e.target.value)}
        >
          {choices.map(choice => (
            <FormControlLabel
              key={choice.value}
              value={choice.value}
              label={choice.label}
              control={<Radio />}
            />
          ))}
        </RadioGroup>
      </div>
    </FormControl>
  );
};

RadioInput.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  choices: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: TypeLabelValue }),
  ),
  input: PropTypes.arrayOf(
    PropTypes.shape({ onChange: PropTypes.func, value: PropTypes.string }),
  ),
  meta: PropTypes.arrayOf(
    PropTypes.shape({ error: PropTypes.string, touched: PropTypes.bool }),
  ),
};

export default RadioInput;
