import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';

import ActionButton from '@common/buttons/ActionButton/ActionButton';
import Input from '@components/Auth/Common/Input';
import FieldWrapper from '@common/form/FieldWrapper';
import iconPlus from '@assets/icons/plusGrey.svg';

import useStyles from '../styles';

const FieldComponent = ({ label, setAnchorFunc, fieldName, processing }) => {
  const classes = useStyles();
  return (
    <FieldWrapper
      label={label}
      labelSize={3}
      contentSize={9}
      showLabel
      isRequired
      content={
        <div className={classes.template}>
          <ActionButton
            handler={event => setAnchorFunc(event.currentTarget)}
            icon={<img src={iconPlus} alt="iconPlus" />}
            toolTip="Add template variable"
            disabled={processing}
          />
          <Field
            name={fieldName}
            id={fieldName}
            render={Input}
            multiline
            minRows={2}
            maxRows={5}
            disabled={processing}
          />
        </div>
      }
    />
  );
};

FieldComponent.propTypes = {
  label: PropTypes.string.isRequired,
  setAnchorFunc: PropTypes.func.isRequired,
  fieldName: PropTypes.string.isRequired,
  processing: PropTypes.bool.isRequired,
};

export default FieldComponent;
