import React, { useMemo } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { sortOrders } from '@constants/filters';
import roles from '@constants/roles';
import { TableTypes } from '@components/Table/constants';
import { getDataFromToken } from '@utils/getDataFromToken';

import TenantPanel from '@components/Billing/SmsLogs/TenantPanel';
import StatusesAccessor from '@common/MuiVirtualizedTable/components/accessors/StatusesAccessor';
import Table from '@components/Table';
import DateTimeField from '@common/TableComponents/DateTimeField';

import { events, eventsOptions } from './events';

import useStyles from '../../Admin/styles';

const List = ({ resource }) => {
  const classes = useStyles();

  const columns = useMemo(
    () => [
      {
        name: 'Date',
        key: 'dateTime',
        type: TableTypes.date,
        sortable: true,
        minWidth: 275,
        width: 275,
        isCustomAccessor: true,
        accessor: rowData => (
          <DateTimeField record={rowData} key="dateTime" source="dateTime" />
        ),
      },
      {
        name: 'Recipient',
        key: 'to',
        width: 200,
        minWidth: 200,
        isPhoneNumber: true,
        sortable: true,
        searchable: true,
      },
      {
        name: 'Status',
        sortable: true,
        searchable: true,
        type: TableTypes.dropdown,
        dropDownValues: eventsOptions,
        filterByAutocomplete: 'status',
        key: 'status',
        isCustomAccessor: true,
        accessor: row => <StatusesAccessor item={row.status} array={events} />,
        width: 175,
        minWidth: 175,
      },
      {
        name: 'Message',
        key: 'message',
        sortable: true,
        searchable: true,
        minWidth: 250,
      },
      {
        name: 'Error message',
        key: 'errorMessage',
        sortable: true,
        searchable: true,
        minWidth: 200,
        width: 200,
      },
    ],
    [],
  );

  const { roleId } = getDataFromToken();
  const isSuperAdmin = roleId === roles.SUPER_ADMIN;

  return (
    <div
      className={cx(classes.container, {
        [classes.height]: !isSuperAdmin,
      })}
    >
      <Table
        actionsComponent={isSuperAdmin ? <TenantPanel /> : undefined}
        defaultSort={{
          fieldName: 'dateTime',
          order: sortOrders.desc,
        }}
        columns={columns}
        resource={resource}
      />
    </div>
  );
};

List.propTypes = {
  resource: PropTypes.string,
};

export default List;
