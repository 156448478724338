import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { DYNAMIC_CRM_CONTACTS } from '@store/slices/resources';
import { ThunkGetCrmSources } from '@store/slices/treeView/thunks';
import {
  ThunkGetDynamicCrmFilters,
  ThunkGetFilterSetsAutocomplete,
  ThunkAddDynamicCrmContact,
  ThunkGetFilteredContacts,
  ThunkDeleteDynamicCrmFilters,
  ThunkGetDynamicCrmContact,
  ThunkEditDynamicCrmContact,
} from './thunks';

const initialState = {
  filterSetsAutocomplete: [],
  dynamicCrmContact: { filters: [] },
  dynamicCrmFilters: [],
  filteredContacts: [],
  filteredData: [],
  loading: false,
  processing: false,
};

const dynamicCrmContacts = createSlice({
  name: DYNAMIC_CRM_CONTACTS,
  initialState,
  reducers: {
    resetFilteredContactsData: state => {
      state.filteredContacts = [];
      state.dynamicCrmFilters = [];
    },
  },
  extraReducers: builder => {
    builder
      .addCase(
        ThunkGetFilterSetsAutocomplete.fulfilled,
        (state, { payload }) => {
          state.filterSetsAutocomplete = payload;
          state.loading = false;
        },
      )
      .addCase(ThunkGetDynamicCrmFilters.fulfilled, (state, { payload }) => {
        state.dynamicCrmFilters = payload;
        state.processing = false;
      })
      .addCase(ThunkAddDynamicCrmContact.fulfilled, state => {
        state.processing = false;
        state.filteredContacts = [];
      })
      .addCase(ThunkGetFilteredContacts.fulfilled, (state, { payload }) => {
        state.filteredContacts = payload;
        state.processing = false;
      })
      .addCase(ThunkDeleteDynamicCrmFilters.fulfilled, (state, { payload }) => {
        state.filterSetsAutocomplete = state.filterSetsAutocomplete.filter(
          ({ value }) => value !== payload,
        );
        state.processing = false;
      })
      .addCase(ThunkGetDynamicCrmContact.fulfilled, (state, { payload }) => {
        state.dynamicCrmContact = payload;
        state.loading = false;
      })
      .addCase(ThunkGetCrmSources.fulfilled, state => {
        state.loading = false;
        state.processing = false;
      })
      .addMatcher(
        isAnyOf(
          ThunkGetFilterSetsAutocomplete.pending,
          ThunkGetDynamicCrmContact.pending,
          ThunkGetCrmSources.pending,
        ),
        state => {
          state.loading = true;
        },
      )
      .addMatcher(
        isAnyOf(
          ThunkAddDynamicCrmContact.pending,
          ThunkGetFilteredContacts.pending,
          ThunkDeleteDynamicCrmFilters.pending,
          ThunkEditDynamicCrmContact.pending,
          ThunkGetDynamicCrmFilters.pending,
        ),
        state => {
          state.processing = true;
        },
      );
  },
});

export default dynamicCrmContacts.reducer;

export const { resetFilteredContactsData } = dynamicCrmContacts.actions;

export const FilterSetsAutocompleteSelector = state =>
  state[DYNAMIC_CRM_CONTACTS].filterSetsAutocomplete;

export const FilteredContactsSelector = state =>
  state[DYNAMIC_CRM_CONTACTS].filteredContacts;

export const DynamicCrmContactSelector = state =>
  state[DYNAMIC_CRM_CONTACTS].dynamicCrmContact;

export const DynamicCrmLoadingSelector = state =>
  state[DYNAMIC_CRM_CONTACTS].loading;

export const DynamicCrmProcessingSelector = state =>
  state[DYNAMIC_CRM_CONTACTS].processing;

export const DynamicCrmFiltersSelector = state =>
  state[DYNAMIC_CRM_CONTACTS].dynamicCrmFilters;
