import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    padding: '30px',
  },
  form: {
    width: '70%',
    display: 'flex',
    gap: '24px',
    flexDirection: 'column',
  },
  actions: {
    marginTop: '25px',
  },
  template: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'stretch',
  },
}));

export default useStyles;
