import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { actions } from '@store/actions';
import { addIncidentAssignees } from '@ui/components/Triggers/helpers';
import { getDataFromToken } from '@utils/getDataFromToken';

const AcknowledgeDialog = ({ open, setOpen, incidentId, isAcknowledged }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { userId } = getDataFromToken();

  const handleAcknowledgeDialogConfirmation = () => {
    const incidentIds = `incidentId=${incidentId}`;
    addIncidentAssignees(
      userId,
      incidentIds,
      !isAcknowledged ? 'add' : 'remove',
    )
      .then(res => {
        res.forEach(item => {
          dispatch(actions.updateItemInList(item));
        });
      })
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }))
      .finally(() => setOpen(false));
  };

  useEffect(() => {
    handleAcknowledgeDialogConfirmation();
  }, []);

  return <></>;
};

export default AcknowledgeDialog;

AcknowledgeDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  incidentId: PropTypes.string.isRequired,
  isAcknowledged: PropTypes.bool.isRequired,
};
