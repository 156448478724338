import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { enqueueSnackbar } from 'notistack';
import cx from 'classnames';

import { ThunkGetPhoneNumbers } from '@store/slices/phoneNumbers/thunks';
import { ThunkGetNotificationGroupsAutocomplete } from '@store/slices/notificationGroups/thunks';
import { ThunkGetCrmDefaultsExtended } from '@store/slices/crm/thunks';
import {
  ThunkAddMessagingConfiguration,
  ThunkGetSmsConfigurationDefaultValues,
  ThunkUpdateMessagingConfiguration,
} from '@store/slices/messaging/thunks';
import { ThunkGetCrmOptions } from '@store/slices/crmTicket/thunks';

import {
  PhoneNumbersListSelector,
  PhoneNumbersLoadingSelector,
} from '@store/slices/phoneNumbers';
import { NotificationGroupsOptionsSelector } from '@store/slices/notificationGroups';
import { currentUserDataSelector } from '@store/selectors';

import DropDown from '@ui/components/Auth/Common/DropDown';
import ReusableButton from '@ui/components/common/Button/Button';
import Switch from '@common/FilterInputs/Switch';
import FieldWrapper from '@ui/components/common/form/FieldWrapper';
import Input from '@ui/components/Auth/Common/Input';
import nationalFormatPhoneNumber from '@utils/nationalFormatPhoneNumber';
import TemplateMenu from '@components/Ticketing/Notifications/components/TemplateMenu';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import { validateRequired } from '@common/functions/validators';
import iconPlus from '@assets/icons/plusGrey.svg';
import { maxLengthProps } from '@utils/maxLengthProps';
import { requiredValidator } from '@utils/validators';

import crmSources from '@constants/crmSources';
import { MAX_SMS_VALUE_COUNT } from '@constants/common';
import { TEXT_CREATE_SUCESS } from '@constants/texts/common';

import useStyles from '../styles';

const MessagingConfigurationForm = ({
  setOpen = () => {},
  item = null,
  fromWizard = false,
  handleForward = () => {},
  phoneNumber = null,
  setIsNewConfiguration,
}) => {
  const [processing, setProcessing] = useState(false);
  const [crmDefaults, setCrmDefaults] = useState([]);
  const [messageAnchorEl, setMessageAnchorEl] = useState(null);

  const [
    smsConfigurationDefaultValues,
    setSmsConfigurationDefaultValues,
  ] = useState([]);
  const [crmOptions, setCrmOptions] = useState([]);

  const classes = useStyles();
  const dispatch = useDispatch();
  const phonesList = useSelector(PhoneNumbersListSelector);
  const phoneNumbersLoading = useSelector(PhoneNumbersLoadingSelector);
  const notificationGroups = useSelector(NotificationGroupsOptionsSelector);
  const tenantCountryCode = useSelector(currentUserDataSelector)
    ?.tenantCountryCode;

  const phonesOptions = useMemo(() => {
    const phoneListOptions = phonesList.map(list => ({
      value: list.id,
      label: nationalFormatPhoneNumber(list.phoneNumber, tenantCountryCode),
      icon: (
        <img
          alt={list.countryCode}
          className={classes.icon}
          src={`https://flagcdn.com/w20/${list.countryCode.toLowerCase()}.png`}
        />
      ),
    }));
    return fromWizard && phoneNumber
      ? [
          { value: phoneNumber.id, label: phoneNumber.phoneNumber },
          ...phoneListOptions,
        ]
      : phoneListOptions;
  }, [phonesList, fromWizard, phoneNumber, classes.icon, tenantCountryCode]);

  const submit = useCallback(
    values => {
      setProcessing(true);
      if (!item || fromWizard) {
        const payload = {
          ...values,
          crmSourceId: values.crmId,
          crmType: crmOptions.find(i => +i.crmId === +values.crmId)?.type,
        };

        dispatch(ThunkAddMessagingConfiguration(payload))
          .unwrap()
          .then(() => {
            setOpen(false);
            if (setIsNewConfiguration) {
              setIsNewConfiguration(prevState => !prevState);
            }
            if (fromWizard) {
              handleForward();
            }
            enqueueSnackbar(TEXT_CREATE_SUCESS, { variant: 'success' });
          })
          .finally(() => {
            setProcessing(false);
          });
      } else {
        dispatch(
          ThunkUpdateMessagingConfiguration({
            id: item.id,
            payload: { ...item, ...values },
          }),
        )
          .unwrap()
          .then(() => {
            setOpen(false);
          })
          .finally(() => {
            setProcessing(false);
          });
      }
    },
    [dispatch, setOpen, item, fromWizard, crmOptions],
  );

  const onMount = useCallback(() => {
    dispatch(ThunkGetPhoneNumbers());
    dispatch(ThunkGetNotificationGroupsAutocomplete());
    dispatch(ThunkGetCrmOptions())
      .unwrap()
      .then(res => {
        setCrmOptions(res);
      });
    dispatch(ThunkGetCrmDefaultsExtended())
      .unwrap()
      .then(res => {
        setCrmDefaults(res);
      });
    dispatch(ThunkGetSmsConfigurationDefaultValues())
      .unwrap()
      .then(res => {
        setSmsConfigurationDefaultValues(res);
      });
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  const validate = values => {
    return {
      name: requiredValidator(values.name),
      crmSourceId: validateRequired(values.crmSourceId),
      crmDefaultId: validateRequired(values.crmDefaultId),
      mainPhoneNumberId: item
        ? undefined
        : validateRequired(values.mainPhoneNumberId),
    };
  };

  return (
    <Form
      onSubmit={submit}
      initialValues={item || smsConfigurationDefaultValues}
      validate={validate}
      render={({ handleSubmit, values, form }) => (
        <form onSubmit={handleSubmit}>
          <div className={classes.configurationFormGrid}>
            <div>
              <FieldWrapper
                label="Name"
                labelSize={5}
                contentSize={7}
                showLabel
                isRequired
                fullWidth
                classNameLabelInner={classes.alignLeft}
                content={
                  <Field
                    name="name"
                    id="name"
                    render={Input}
                    disabled={processing}
                  />
                }
              />

              <FieldWrapper
                label="PSA"
                labelSize={5}
                contentSize={7}
                showLabel
                isRequired
                classNameLabelInner={classes.alignLeft}
                content={
                  <Field
                    name="crmSourceId"
                    id="crmSourceId"
                    render={DropDown}
                    options={crmOptions}
                    disabled={processing}
                    labelName="crmName"
                    valueName="crmId"
                  />
                }
              />

              <FieldWrapper
                label="PSA Default"
                labelSize={5}
                contentSize={7}
                showLabel
                isRequired
                classNameLabelInner={classes.alignLeft}
                content={
                  <Field
                    name="crmDefaultId"
                    id="crmDefaultId"
                    labelName="defaultName"
                    valueName="defaultId"
                    render={DropDown}
                    options={crmDefaults.filter(
                      option => +option.psaId === +values.crmSourceId,
                    )}
                    disabled={
                      (!values.crmSourceId && values.crmSourceId !== 0) ||
                      processing
                    }
                  />
                }
              />
              {!item ? (
                <>
                  <FieldWrapper
                    label="Phone Number"
                    labelSize={5}
                    contentSize={7}
                    showLabel
                    isRequired
                    classNameLabelInner={classes.alignLeft}
                    classNameContainer={cx({
                      [classes.phoneNumberField]:
                        !phonesOptions.length && !phoneNumbersLoading,
                    })}
                    content={
                      <Field
                        name="mainPhoneNumberId"
                        id="mainPhoneNumberId"
                        render={DropDown}
                        options={phonesOptions}
                        disabled={processing}
                      />
                    }
                  />
                  {!phonesOptions.length && !phoneNumbersLoading && (
                    <div className={classes.noOptionText}>
                      There are no available phone numbers. Please contact
                      Support to have a phone number assigned to your account.
                    </div>
                  )}
                </>
              ) : (
                <FieldWrapper
                  label="Phone Number"
                  labelSize={5}
                  contentSize={7}
                  showLabel
                  isRequired
                  classNameLabelInner={classes.alignLeft}
                  content={
                    <Field
                      name="mainPhoneNumber"
                      id="mainPhoneNumber"
                      render={Input}
                      disabled={processing || item}
                    />
                  }
                />
              )}
              {!fromWizard && (
                <FieldWrapper
                  label="Notification group"
                  labelSize={5}
                  contentSize={7}
                  showLabel
                  infoText="Select for notifying users of a Notification Group"
                  classNameLabelInner={classes.alignLeft}
                  content={
                    <Field
                      name="notificationGroupId"
                      id="notificationGroupId"
                      render={DropDown}
                      options={notificationGroups}
                      disabled={processing}
                      allowEmpty
                      onInputChange={v => {
                        if (!v) form.change('notificationGroupId', null);
                      }}
                    />
                  }
                />
              )}

              <FieldWrapper
                label="Block creation [min]"
                labelSize={5}
                contentSize={7}
                showLabel
                infoText="Deny to create new channel from the same number. 0 - disabled"
                classNameLabelInner={classes.alignLeft}
                content={
                  <Field
                    name="creationDelayLengthMin"
                    id="creationDelayLengthMin"
                    render={Input}
                    type="number"
                    inputView="number"
                    disabled={processing}
                  />
                }
              />

              <FieldWrapper
                label="Block message"
                labelSize={5}
                contentSize={7}
                showLabel
                infoText="User will receive this message in case of blocking"
                classNameLabelInner={classes.alignLeft}
                content={
                  <Field
                    name="creationDelayMessage"
                    id="creationDelayMessage"
                    render={Input}
                    disabled={processing}
                    multiline
                    rows={4}
                    dateProps={{ maxLength: MAX_SMS_VALUE_COUNT }}
                    helperText={`${
                      values.creationDelayMessage
                        ? values.creationDelayMessage.length
                        : 0
                    }/${MAX_SMS_VALUE_COUNT}`}
                  />
                }
              />
            </div>
            <div>
              <FieldWrapper
                label="Create Ticket from Text Message"
                labelSize={9}
                contentSize={2}
                showLabel
                classNameLabelInner={classes.alignLeft}
                content={
                  <Field
                    name="allowUsersCreateChannel"
                    id="allowUsersCreateChannel"
                    type="checkbox"
                    render={Switch}
                    disabled={processing}
                  />
                }
              />
              <FieldWrapper
                label="Restrict to Known Contacts"
                labelSize={9}
                contentSize={2}
                showLabel
                classNameLabelInner={classes.alignLeft}
                content={
                  <Field
                    name="allowOnlyPsaUsersCreateChannel"
                    id="allowOnlyPsaUsersCreateChannel"
                    type="checkbox"
                    render={Switch}
                    disabled={processing}
                  />
                }
              />
              <FieldWrapper
                label="Confirm user messages to channel"
                labelSize={9}
                contentSize={2}
                showLabel
                classNameLabelInner={classes.alignLeft}
                content={
                  <Field
                    name="sendConfirmationBack"
                    id="sendConfirmationBack"
                    type="checkbox"
                    render={Switch}
                    disabled={processing}
                  />
                }
              />
              <FieldWrapper
                label="Put channel's link to ticket"
                labelSize={9}
                contentSize={2}
                showLabel
                classNameLabelInner={classes.alignLeft}
                content={
                  <Field
                    name="channelLinkToTicket"
                    id="channelLinkToTicket"
                    type="checkbox"
                    render={Switch}
                    disabled={processing}
                  />
                }
              />

              <FieldWrapper
                label="Allow technicians auto assignment"
                labelSize={9}
                contentSize={2}
                showLabel
                classNameLabelInner={classes.alignLeft}
                content={
                  <Field
                    name="autoAssignTechnition"
                    id="autoAssignTechnition"
                    type="checkbox"
                    render={Switch}
                    disabled={processing}
                  />
                }
              />

              <FieldWrapper
                label="Send initial message"
                labelSize={9}
                contentSize={2}
                showLabel
                classNameLabelInner={classes.alignLeft}
                infoText="Initial message from configuration phone number to the user"
                content={
                  <Field
                    name="addInitialMessage"
                    id="addInitialMessage"
                    type="checkbox"
                    render={Switch}
                    disabled={processing}
                  />
                }
              />
              <FieldWrapper
                label="Initial Message"
                labelSize={12}
                contentSize={12}
                fullWidth
                classNameContainer={classes.formItem}
                classNameLabelContainer={classes.dialogLabelContainer}
                classNameLabelInner={classes.dialogLabelText}
                content={
                  <div className={classes.initialMessage}>
                    <ActionButton
                      handler={event => setMessageAnchorEl(event.currentTarget)}
                      icon={<img src={iconPlus} alt="iconPlus" />}
                      toolTip="Add initial message"
                      disabled={!values.addInitialMessage || processing}
                    />
                    <Field
                      name="initialMessage"
                      id="initialMessage"
                      render={Input}
                      multiline
                      minRows={3}
                      maxRows={5}
                      {...maxLengthProps(values.initialMessage)}
                      disabled={!values.addInitialMessage || processing}
                    />
                  </div>
                }
              />
              {crmOptions.find(i => i.crmId === values.crmId)?.type ===
                crmSources.Autotask.idx && (
                <>
                  <FieldWrapper
                    label="Shift ticket time when SMS received"
                    labelSize={9}
                    contentSize={2}
                    showLabel
                    classNameLabelInner={classes.alignLeft}
                    content={
                      <Field
                        name="shiftTicketTime"
                        id="shiftTicketTime"
                        type="checkbox"
                        render={Switch}
                        disabled={processing}
                      />
                    }
                  />

                  {values.shiftTicketTime && (
                    <Field
                      id="shiftTimeValue"
                      name="shiftTimeValue"
                      showLabel
                      type="time"
                      component={Input}
                    />
                  )}
                </>
              )}
            </div>
          </div>

          <div className={classes.dialogActions}>
            {!fromWizard && (
              <ReusableButton
                label="Cancel"
                onClick={() => {
                  setOpen(false);
                }}
                disabled={processing}
              />
            )}

            <ReusableButton
              label="Submit"
              type="submit"
              disabled={processing}
            />
          </div>
          <TemplateMenu
            anchorEl={messageAnchorEl}
            setAnchorEl={setMessageAnchorEl}
            form={form}
            name="initialMessage"
            templateVariables={[
              // eslint-disable-next-line no-template-curly-in-string
              { value: '${poolNumber}', label: 'Pool number' },
              // eslint-disable-next-line no-template-curly-in-string
              { value: '${ticketId}', label: 'TicketId' },
            ]}
          />
        </form>
      )}
    />
  );
};

MessagingConfigurationForm.propTypes = {
  setOpen: PropTypes.bool.isRequired,
  item: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    crmType: PropTypes.number,
    crmDefaultId: PropTypes.number,
    mainPhoneNumberId: PropTypes.number,
    mainPhoneNumber: PropTypes.string,
    allowUsersCreateChannel: PropTypes.bool,
    sendConfirmationBack: PropTypes.bool,
    channelLinkToTicket: PropTypes.string,
    shiftTicketTime: PropTypes.string,
    shiftTimeValue: PropTypes.string,
    notificationGroupId: PropTypes.number,
  }),
  fromWizard: PropTypes.bool,
  handleForward: PropTypes.func,
  setIsNewConfiguration: PropTypes.func,
  phoneNumber: PropTypes.shape({
    dedicatedNumber: PropTypes.string,
    id: PropTypes.string,
  }),
};

export default MessagingConfigurationForm;
