import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import useStyles from './progressStyles';

const CircularStatic = ({ progress, customHeader }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Box position="relative" display="inline-flex">
        <CircularProgress variant="determinate" value={progress} />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="caption" component="div" color="textSecondary">
            {`${Math.round(progress)}%`}
          </Typography>
        </Box>
      </Box>
      <h1>{customHeader || 'Loading'}</h1>
    </div>
  );
};

CircularProgress.propTypes = {
  progress: PropTypes.number,
  customHeader: PropTypes.string,
};

export default CircularStatic;
