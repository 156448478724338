import React, { useCallback, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import CancelIcon from '@material-ui/icons/Cancel';

import appConfig from '@configs/appConfig';
import LocalStorage from '@constants/localStorage';

import Loading from '@common/Loading/Loading';

import { getDataFromToken } from '@utils/getDataFromToken';

import useStyles from './styles';

const PodDialog = ({ onClose, psaId, itemId, itemType, psa, page }) => {
  const [loading, setLoading] = useState(true);

  const classes = useStyles({ loading });
  const token = localStorage.getItem(LocalStorage.ACCESS_TOKEN);
  const refreshToken = localStorage.getItem(LocalStorage.REFRESH_TOKEN);
  const { userId, tenantId } = getDataFromToken();

  const generatePodPath = useCallback(
    () =>
      `/externalLogin?token=${token}&refreshToken=${refreshToken}&userId=${userId}&tenantId=${tenantId}&psaId=${psaId}&itemId=${itemId}&itemType=${itemType}&page=${page}&psa=${psa}`,
    [token, refreshToken, userId, tenantId, psaId, itemId, itemType, page, psa],
  );

  return (
    <Dialog
      open
      onClose={onClose}
      PaperComponent={props => (
        <div>
          <div className={classes.button}>
            <Tooltip title="Close">
              <CancelIcon onClick={onClose} className={classes.closeIcon} />
            </Tooltip>
          </div>
          <div className={classes.iframeWrapper}>{props.children}</div>
        </div>
      )}
    >
      <iframe
        src={appConfig.podUrl + generatePodPath()}
        title="PodDialog"
        onLoad={() => {
          setLoading(false);
        }}
        style={{
          width: loading ? '1px' : '100%',
          height: loading ? '1px' : '100%',
          borderRadius: '5px',
          border: 'none',
        }}
      />
      {loading && <Loading />}
    </Dialog>
  );
};

PodDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  psaId: PropTypes.number.isRequired,
  itemId: PropTypes.number.isRequired,
  itemType: PropTypes.string.isRequired,
  psa: PropTypes.string.isRequired,
  page: PropTypes.string,
};

export default PodDialog;
