import { createAsyncThunk } from '@reduxjs/toolkit';
import { TWILIO_ACCOUNTS } from '@store/slices/resources';
import { getMessagingServices } from '@services/requests/twilioAccounts';

export const ThunkGetTwilioAccountsServices = createAsyncThunk(
  `${TWILIO_ACCOUNTS}/getTwilioAccountsServices `,
  id => getMessagingServices(id),
);

export default {};
