import React, { memo } from 'react';
import PropTypes from 'prop-types';

import useStyles from '../../styles';

const TimePicker = ({ setFromTime, setToTime, fromTime, toTime }) => {
  const classes = useStyles();

  return (
    <div className={classes.timePickerWrapper}>
      <div className={classes.timePicker}>
        <input
          type="time"
          value={fromTime}
          onChange={e => setFromTime(e.target.value)}
        />
      </div>
      <div className={classes.timePicker}>
        <input
          type="time"
          value={toTime}
          onChange={e => setToTime(e.target.value)}
        />
      </div>
    </div>
  );
};

TimePicker.propTypes = {
  setFromTime: PropTypes.func.isRequired,
  setToTime: PropTypes.func.isRequired,
  fromTime: PropTypes.string.isRequired,
  toTime: PropTypes.string.isRequired,
};

export default memo(TimePicker);
