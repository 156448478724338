import { createAsyncThunk } from '@reduxjs/toolkit';
import { getOptInSettings } from '@services/requests/optIn';
import { OPT_IN } from '@store/slices/resources';

/**
 * @param {string} key
 */
export const ThunkGetOptInSettingsForForm = createAsyncThunk(
  `${OPT_IN}/getOptInSettings`,
  async key => {
    const res = getOptInSettings(key);
    return res;
  },
);

export default {};
