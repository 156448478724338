import { filterFieldsType } from './constants';

export const transformFilterPayload = filters =>
  filters.map(filter => {
    let { value } = filter;
    if (filter.filterDataType === filterFieldsType.Boolean) {
      value = value === 'true';
    }
    return {
      id: filter.id,
      value,
    };
  });

export const transformFilterFormValue = (
  dynamicCrmFilters,
  dynamicCrmContact,
) =>
  dynamicCrmContact.filters.reduce((acc, filter) => {
    const existedFilter = dynamicCrmFilters.find(
      contact => contact.id === filter.id,
    );
    if (existedFilter) {
      let { value } = filter;
      if (existedFilter.filterDataType === filterFieldsType.Boolean) {
        value = filter.value ? 'true' : 'false';
      }
      acc.push({ ...existedFilter, ...filter, value });
    }
    return acc;
  }, []);

export const transformFilterOperationLabel = data => {
  const { filterDataType, filterOperation, options = [] } = data;

  if (
    filterDataType === filterFieldsType.String &&
    filterOperation === 'Like'
  ) {
    return 'contains';
  }

  const isBooleanEqual =
    filterDataType === filterFieldsType.Boolean && filterOperation === 'Equal';
  const isOptionDependent =
    ['Equal', 'In'].includes(filterOperation) && options.length > 0;

  if (isBooleanEqual || isOptionDependent || filterOperation === 'Custom') {
    return '';
  }

  return filterOperation;
};
