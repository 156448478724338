import { createAsyncThunk } from '@reduxjs/toolkit';
import { getFilterSetsAutocomplete } from '@services/requests/dynamicCrmContacts';
import { DYNAMIC_CRM_CONTACTS } from '@store/slices/resources';

export const ThunkGetFilterSetsAutocomplete = createAsyncThunk(
  `${DYNAMIC_CRM_CONTACTS}/getFilterSetsAutocomplete`,
  () => {
    return getFilterSetsAutocomplete();
  },
);

export default {};
