import phoneValidator from '@utils/validators/phoneValidator';

const requiredPhoneListValidator = value => {
  if (!value || !Array.isArray(value) || !value.length) {
    return 'Required';
  }

  const errorArray = value
    .map(item => {
      const numberError = phoneValidator(item, undefined, true, /[^+\d]/);
      return !numberError || (item.startsWith('{$') && item.endsWith('}'))
        ? null
        : item;
    })
    .filter(item => !!item);

  return errorArray.length
    ? `Invalid phone or template: ${errorArray.join(', ')}`
    : undefined;
};

export default requiredPhoneListValidator;
