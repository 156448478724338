import { createAsyncThunk } from '@reduxjs/toolkit';
import { getFilteredData } from '@services/requests/dynamicCrmContacts';
import { DYNAMIC_CRM_CONTACTS } from '@store/slices/resources';

export const ThunkGetFilteredData = createAsyncThunk(
  `${DYNAMIC_CRM_CONTACTS}/getFilteredData`,
  id => {
    return getFilteredData(id);
  },
);

export default {};
