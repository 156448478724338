const maxLengthValidator = (
  value,
  length = 25,
  isRequired = true,
  outputTextFunc = len => `field has max length ${len} symbols`,
) => {
  if (isRequired && !value) {
    return 'Required';
  }

  if (value && value.length > length) {
    return outputTextFunc(length);
  }

  return undefined;
};

export default maxLengthValidator;
