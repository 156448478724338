/* eslint-disable no-prototype-builtins */
export const signalRMessageEvents = Object.freeze({
  MessageParsed: {
    getText: boxName => `New messages parsed for ${boxName}`,
    getLink: boxId => `#/mailbox/${boxId}/emails`,
  },
});

export const getPredefinedEvent = msg => {
  const data = msg.split('#');
  if (signalRMessageEvents.hasOwnProperty(data[0])) {
    const i = signalRMessageEvents[data[0]];
    return {
      id: msg,
      text: i.getText(data[2]),
      link: i.getLink(data[1]),
      mailBoxId: data[1],
      letterId: +data[3],
      tenantId: data[4],
    };
  }
  return null;
};

export const getWebHookEvent = msg => {
  const data = msg.split('#');
  if (data.length) {
    return {
      tenantId: data[0],
      webHookId: data[1],
    };
  }
  return null;
};
