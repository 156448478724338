import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import { enqueueSnackbar } from 'notistack';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

import { SmsTemplateSelector } from '@store/slices/optIn';
import { ThunkAddOptInSmsTemplate } from '@store/slices/optIn/thunks';

import { TEXT_UPDATE_SUCESS } from '@constants/texts/common';
import { requiredValidator } from '@utils/validators';

import ReusableButton from '@common/Button/Button';
import TemplateMenu from '@components/Ticketing/Notifications/components/TemplateMenu';
import FieldWrapper from '@ui/components/common/form/FieldWrapper';
import HtmlInput from '@ui/components/common/HtmlInput';
import FieldComponent from './FieldComponent';

import useStyles from '../styles';

const templateVariables = [{ label: 'Url template', value: '$<url>' }];
const htmlTemplateVariables = [{ label: 'Url template', value: '►url◄' }];

const OptInTemplate = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const data = useSelector(SmsTemplateSelector);

  const [processing, setProcessing] = useState(false);
  const [smsAnchorEl, setSmsAnchorEl] = useState(null);
  const [ticketAnchorEl, setTicketAnchorEl] = useState(null);
  const [emailSubjectAnchorEl, setEmailSubjectAnchorEl] = useState(null);
  const [emailHeaderAnchorEl, setEmailHeaderAnchorEl] = useState(null);

  const inputs = [
    {
      label: 'SMS Template',
      action: setSmsAnchorEl,
      anchor: smsAnchorEl,
      fieldName: 'smsTemplate',
    },
    {
      label: 'PSA Ticket Note Template',
      action: setTicketAnchorEl,
      anchor: ticketAnchorEl,
      fieldName: 'ticketNoteTemplate',
    },
    {
      label: 'Email Subject Template',
      action: setEmailSubjectAnchorEl,
      anchor: emailSubjectAnchorEl,
      fieldName: 'emailSubjectTemplate',
    },
    {
      label: 'Email Header Template',
      action: setEmailHeaderAnchorEl,
      anchor: emailHeaderAnchorEl,
      fieldName: 'emailHeaderTemplate',
    },
  ];

  const submit = values => {
    setProcessing(true);
    dispatch(ThunkAddOptInSmsTemplate({ payload: values }))
      .unwrap()
      .then(() => enqueueSnackbar(TEXT_UPDATE_SUCESS, { variant: 'success' }))
      .finally(() => {
        setProcessing(false);
      });
  };

  const validate = values => {
    return {
      smsTemplate: requiredValidator(values.smsTemplate),
      ticketNoteTemplate: requiredValidator(values.ticketNoteTemplate),
    };
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        Opt-in Template
      </AccordionSummary>

      <AccordionDetails>
        <Form
          onSubmit={submit}
          validate={validate}
          initialValues={data}
          render={({ handleSubmit, form }) => (
            <form onSubmit={handleSubmit} className={classes.form}>
              {inputs.map(i => (
                <FieldComponent
                  key={i.fieldName}
                  label={i.label}
                  setAnchorFunc={i.action}
                  fieldName={i.fieldName}
                  processing={processing}
                />
              ))}
              <FieldWrapper
                label="Email Body Template *"
                labelSize={3}
                contentSize={9}
                content={
                  <div className={classes.template}>
                    <HtmlInput
                      name="emailBodyTemplate"
                      form={form}
                      templateVariables={htmlTemplateVariables}
                    />
                  </div>
                }
              />
              <div className={classes.actions}>
                <ReusableButton
                  label="Submit"
                  type="submit"
                  loading={processing}
                  disabled={processing}
                />
              </div>
              {inputs.map(i => (
                <TemplateMenu
                  key={i.fieldName}
                  anchorEl={i.anchor}
                  setAnchorEl={i.action}
                  form={form}
                  name={i.fieldName}
                  templateVariables={templateVariables}
                />
              ))}
            </form>
          )}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default OptInTemplate;
