import React, { useCallback, useEffect, useState, memo, useRef } from 'react';
import { translate } from 'react-admin';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import {
  Checkbox,
  FormHelperText,
  ListItem,
  TextField,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import cx from 'classnames';
import {
  TypeInput,
  TypeLabelValue,
  TypeMeta,
  TypeSize,
} from '@common/propTypes/common';
import { axiosInstance } from '@components/Table/axios';
import Loader from '@assets/loader.gif';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import useStyles from '@components/Auth/Common/dropDownStyles';

const AutocompleteWithPagination = ({
  items,
  disabled,
  input,
  size = 'medium',
  classNameWrapper,
  meta,
  getOptionLabel = o => {
    return o.inputValue || o.label || o;
  },
  label,
  variant = 'outlined',
  listItemClassName,
  pageSize = 20,
  resource,
  totalOptionsCount,
  filterName = 'label',
  multiple,
  ...props
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [options, setOptions] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [value, setValue] = useState(multiple ? [] : '');

  const classes = useStyles();
  const isError = meta && meta.error && meta.touched;
  const errorMessage =
    meta?.error && meta.error.startsWith('ra.')
      ? translate(meta.error)
      : meta?.error;

  const getValue = useCallback(
    (val, list) => {
      return multiple
        ? val || []
        : list.find(option => option.value === val)?.label || '';
    },
    [multiple],
  );

  const onChange = (event, data) => {
    const targetValue = multiple
      ? data?.map(el => el.value || el)
      : data?.value;
    input.onChange(targetValue);
    setValue(getValue(targetValue, options));
  };

  const fetch = useCallback(
    async (currentPage = 0, filtering = {}, isSetupTotalCount = false) => {
      if (!loading) {
        setLoading(true);
      }
      try {
        const { data, headers } = await axiosInstance.request({
          method: 'GET',
          url: resource,
          params: {
            _start: currentPage * pageSize,
            _end: currentPage * pageSize + pageSize,
            ...filtering,
          },
        });
        if (isSetupTotalCount) {
          setTotalCount(+headers.get('x-total-count'));
        }
        return data;
      } catch (e) {
        enqueueSnackbar(e.response?.data?.message || 'Something went wrong', {
          variant: 'error',
        });
        return [];
      } finally {
        setLoading(false);
      }
    },
    [enqueueSnackbar, pageSize, resource],
  );

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (items) {
      setOptions(items);
    } else if (isFirstRender.current) {
      fetch(undefined, undefined, true).then(res => {
        setOptions(res);
        setValue(getValue(input.value, res));
      });
    }
    isFirstRender.current = false;
  }, [fetch, getValue, input.value, items]);

  useEffect(() => {
    if (totalOptionsCount) {
      setTotalCount(totalOptionsCount);
    }
  }, [totalOptionsCount]);

  return (
    <div className={classes.fieldWrapper}>
      <Autocomplete
        variant="outlined"
        className={cx(
          classes.formControl,
          classes.selectWrapper,
          classNameWrapper,
        )}
        fullWidth
        multiple={multiple}
        classes={{ popper: listItemClassName }}
        size={size}
        id="group-by-autocomplete"
        forcePopupIcon
        options={options}
        getOptionLabel={getOptionLabel}
        getOptionSelected={
          multiple
            ? (option, val) => {
                return option.value === val;
              }
            : undefined
        }
        renderInput={params => {
          return (
            <>
              <TextField
                {...params}
                variant={variant}
                label={label}
                error={!!isError}
              />
            </>
          );
        }}
        noOptionsText={
          loading ? (
            <div className={classes.loader}>
              <img src={Loader} className={classes.img} alt="Loading" />
            </div>
          ) : (
            'No options'
          )
        }
        disableCloseOnSelect={multiple}
        renderOption={(option, { selected }) => (
          <>
            <ListItem
              component="div"
              className={cx({ [classes.loadWrapper]: loading })}
            >
              <div>
                {multiple && (
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon />}
                    checkedIcon={<CheckBoxIcon />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                )}
                {option.label}
              </div>
              {option === options[options.length - 1] && loading && (
                <div className={classes.loader}>
                  <img src={Loader} className={classes.img} alt="Loading" />
                </div>
              )}
            </ListItem>
          </>
        )}
        disabled={disabled}
        {...input}
        value={value}
        onChange={onChange}
        onInputChange={(event, newInputValue) => {
          if (debounceTimeout) {
            clearTimeout(debounceTimeout);
          }
          setSearchValue(newInputValue);
          const isBeSearch = totalCount !== options.length;

          if (isBeSearch) {
            setLoading(true);
          }

          const timeoutId = setTimeout(() => {
            if (isBeSearch) {
              fetch(0, { [filterName]: newInputValue })
                .then(res => {
                  setOptions(
                    multiple
                      ? prevOptions => {
                          return [...prevOptions, ...res].reduce(
                            (acc, item) => {
                              if (!acc.find(opt => opt.value === item.value)) {
                                acc.push(item);
                              }
                              return acc;
                            },
                            [],
                          );
                        }
                      : res,
                  );
                })
                .then(() => {
                  setPage(0);
                });
            }
          }, 500);

          setDebounceTimeout(timeoutId);
        }}
        ListboxProps={{
          onScroll: event => {
            if (
              Math.floor(event.target.scrollHeight) -
                Math.floor(event.target.scrollTop) -
                Math.floor(event.target.clientHeight + 1) <=
                1 &&
              !loading &&
              totalCount > options.length
            ) {
              fetch(page + 1, { [filterName]: searchValue })
                .then(data => setOptions(prevList => [...prevList, ...data]))
                .then(() => {
                  setPage(prevPage => prevPage + 1);
                });
            }
          },
        }}
        {...props}
      />
      {isError && (
        <FormHelperText error={isError} className={classes.helperText}>
          {errorMessage}
        </FormHelperText>
      )}
    </div>
  );
};

AutocompleteWithPagination.propTypes = {
  input: TypeInput,
  meta: TypeMeta,
  size: TypeSize,
  items: PropTypes.arrayOf(TypeLabelValue),
  disabled: PropTypes.bool,
  classNameWrapper: PropTypes.string,
  getOptionLabel: PropTypes.func,
  label: PropTypes.string,
  variant: PropTypes.string,
  listItemClassName: PropTypes.string,
  pageSize: PropTypes.number,
  resource: PropTypes.string,
  totalOptionsCount: PropTypes.number,
  filterName: PropTypes.string,
  multiple: PropTypes.bool,
};

export default memo(AutocompleteWithPagination);
