import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-final-form';
import { useDispatch } from 'react-redux';

import FileUploader from '@common/FileUploader/FileUploader';
import ReusableButton from '@common/Button/Button';
import beautifyBytes from '@components/LiveChatConfigurations/helpers';

import useStyles from './styles';

export const imageTypes = {
  jpeg: { type: 'image/jpeg', name: 'JPEG' },
  png: { type: 'image/png', name: 'PNG' },
};

const presets = {
  maxSize: 2000000,
  formats: [imageTypes.png, imageTypes.jpeg],
};

const getFormats = formats => formats.map(format => format.type).join(', ');

const getDescription = (formats, maxSize, sizing) => {
  const verb = formats?.length === 1 ? ' is ' : 's are';
  const formatNames = formats.map(format => format.name).join(' or ');
  const maxSizeText = beautifyBytes(maxSize);
  return `The accepted file format${verb} ${formatNames}. Max file size ${maxSizeText}. Your image will be automatically resized ${
    sizing ? `to ${sizing}` : 'for the best fit.'
  }.`;
};

const FileUploaderComponent = ({
  name = 'logoPath',
  onUploadCallBack,
  resourceId,
  handleUpload,
  handleRemove,
  imageWidth = undefined,
  className,
  maxImageSize = presets.maxSize,
  formats = presets.formats,
  sizing = '',
  customText = undefined,
}) => {
  const classes = useStyles();
  const [processing, setProcessing] = useState(false);
  const { getState, change } = useForm();
  const { values } = getState();

  const dispatch = useDispatch();

  const handleImageUpload = useCallback(
    file => {
      dispatch(handleUpload({ resourceId, file }))
        .unwrap()
        .then(res => {
          const url = URL.createObjectURL(file);
          change(name, url);
          if (onUploadCallBack) {
            onUploadCallBack(res);
          }
        });
    },
    [dispatch, resourceId],
  );

  const handleImageRemove = useCallback(async () => {
    setProcessing(true);
    await dispatch(handleRemove({ resourceId }))
      .unwrap()
      .then(() => {
        change(name, null);
      })
      .finally(() => {
        setProcessing(false);
      });
  }, [dispatch, resourceId, name]);

  const getContent = useMemo(() => {
    if (values[name]) {
      return (
        <div
          style={{ display: 'flex', justifyContent: 'space-between' }}
          className={className}
        >
          <img
            src={values[name]}
            alt="widget"
            style={{ width: imageWidth ?? '40px' }}
          />
          <ReusableButton
            label="Remove"
            onClick={handleImageRemove}
            loading={processing}
            disabled={processing}
          />
        </div>
      );
    }
    return (
      <FileUploader
        handleUpload={handleImageUpload}
        type="file"
        accept={getFormats(formats)}
        maxSizeBytes={maxImageSize}
        maxFiles={1}
      />
    );
  }, [
    values,
    name,
    handleImageUpload,
    imageWidth,
    handleImageRemove,
    processing,
    className,
  ]);

  return (
    <>
      <div>{getContent}</div>
      <div className={classes.imageText}>
        {customText || getDescription(formats, maxImageSize, sizing)}
      </div>
    </>
  );
};

FileUploaderComponent.propTypes = {
  name: PropTypes.string,
  onUploadCallBack: PropTypes.func,
  resourceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  handleUpload: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
  imageWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  maxImageSize: PropTypes.number,
  formats: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  sizing: PropTypes.string,
  customText: PropTypes.string,
};

export default FileUploaderComponent;
