import React from 'react';
import PropTypes from 'prop-types';

import crmSources from '@constants/crmSources';

import ReusableButton from '@common/Button/Button';

import useStyles from './styles';

const crmsWithSyncModal = [
  crmSources.ConnectWise.name,
  crmSources.Autotask.name,
  crmSources.Halo.name,
  crmSources.SyncroMsp.name,
];

const SyncButtons = ({
  allowUpdate,
  crmType,
  processing,
  syncing,
  openSyncDialog,
  syncCompaniesSubmitHandler,
  syncUsersHandler,
}) => {
  const classes = useStyles();

  if (!allowUpdate) {
    return null;
  }

  if (crmType === crmSources.ImportIntegration.name) {
    return null;
  }

  if (crmsWithSyncModal.includes(crmType)) {
    return (
      <ReusableButton
        label="Sync Data"
        onClick={openSyncDialog}
        size="lg"
        disabled={processing || syncing}
        loading={syncing}
      />
    );
  }

  if (!crmsWithSyncModal.includes(crmType)) {
    return (
      <>
        <ReusableButton
          onClick={() => {
            syncCompaniesSubmitHandler({
              types: [],
              statuses: [],
            });
          }}
          size="xl"
          classNameWrapper={classes.button}
          disabled={processing || syncing}
          loading={syncing}
          label="Sync Companies"
        />
        <ReusableButton
          onClick={syncUsersHandler}
          size="xl"
          classNameWrapper={classes.button}
          disabled={processing || syncing}
          loading={syncing}
          label="Sync Users"
        />
      </>
    );
  }

  return null;
};

SyncButtons.propTypes = {
  allowUpdate: PropTypes.bool.isRequired,
  crmType: PropTypes.string.isRequired,
  processing: PropTypes.bool.isRequired,
  syncing: PropTypes.bool.isRequired,
  openSyncDialog: PropTypes.func.isRequired,
  syncCompaniesSubmitHandler: PropTypes.func.isRequired,
  syncUsersHandler: PropTypes.func.isRequired,
};

export default SyncButtons;
