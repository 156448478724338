import React from 'react';
import PropTypes from 'prop-types';

import getFormattedDate from '@utils/getFormattedDate';

export const ItemType = {
  string: val => val,
  boolean: val => (val ? 'Yes' : 'No'),
  dateTime: val => getFormattedDate(val),
};

const ViewDetailsItem = ({ name, value, type = ItemType.string }) => {
  return (
    <>
      <p>
        <span>{name}</span>
        <span>:&nbsp;</span>
        <span>{type(value)}</span>
      </p>
    </>
  );
};

export default ViewDetailsItem;

ViewDetailsItem.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  type: PropTypes.any,
};
