import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { Field, Form, FormSpy } from 'react-final-form';
import cx from 'classnames';
import { useSnackbar } from 'notistack';

import Input from '@components/Auth/Common/Input';
import Loading from '@ui/components/common/Loading/Loading';
import AutocompleteFormInput from '@ui/components/common/AutocompleteFormInput/AutocompleteFormInput';
import DropDown from '@ui/components/Auth/Common/DropDown';
import ReusableButton from '@ui/components/common/Button/Button';

import HaloRequiredFields from '@components/Ticketing/Integrations/pages/HaloIntegration/components/HaloRequiredFields';
import extractRequiredFields from '@components/Ticketing/Integrations/pages/HaloIntegration/helpers';
import {
  haloRequiredFieldsType,
  initialValues,
  uniqueRequiredFields,
  validation,
} from './haloDefaultsForm';
import {
  getHaloCrmCategories,
  getHaloCrmClients,
  getHaloCrmImpacts,
  getHaloCrmRequiredFields,
  getHaloCrmSites,
  getHaloCrmStatuses,
  getHaloCrmTeams,
  getHaloCrmTicketTypes,
  getHaloCrmUrgency,
  getHaloCrmUsers,
  getHaloPriorities,
  getHaloServiceLevelAgreements,
  validateHaloIntegrationDefaults,
} from '../../helpers';
import useStyles from '../updateCrm/styles';
import crmDefaultFields from '../updateCrm/CrmDefaults/constants';

const HaloDefaults = ({
  onSubmit,
  onBack,
  crmId,
  data: editItem = undefined,
}) => {
  const [loading, setLoading] = useState(true);
  const [subLoading, setSubLoading] = useState(true);
  const [validated, setValidated] = useState(false);
  const [validationLoading, setValidationLoading] = useState(false);
  const [crmClients, setCrmClients] = useState([]);
  const [crmCategories, setCrmCategories] = useState([]);
  const [crmImpacts, setCrmImpacts] = useState([]);
  const [crmUrgency, setCrmUrgency] = useState([]);
  const [requiredFields, setRequiredFields] = useState([]);
  const [crmTicketTypes, setCrmTicketTypes] = useState([]);
  const [crmTeams, setCrmTeams] = useState([]);
  const [crmStatuses, setCrmStatuses] = useState([]);
  const [crmSites, setCrmSites] = useState([]);
  const [crmUsers, setCrmUsers] = useState([]);
  const [serviceLevelAgreements, setServiceLevelAgreements] = useState([]);
  const [priorities, setPriorities] = useState([]);
  const [clientId, setClientId] = useState();
  const [siteId, setSiteId] = useState();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const handleClientSelect = id => {
    setSubLoading(true);
    setSiteId(undefined);
    setCrmUsers([]);
    setClientId(id);
    getHaloCrmSites(crmId, id)
      .then(sites => {
        setCrmSites(sites);
      })
      .finally(() => {
        setSubLoading(false);
      });
  };

  const handleSiteSelect = (id, client = clientId) => {
    setSubLoading(true);
    setSiteId(id);
    getHaloCrmUsers(crmId, client, id)
      .then(sites => {
        setCrmUsers(sites);
      })
      .finally(() => {
        setSubLoading(false);
      });
  };

  const getHaloRequiredFields = ticketId => {
    setSubLoading(true);
    getHaloCrmRequiredFields(crmId, ticketId)
      .then(res => {
        const fieldList = extractRequiredFields(res);

        fieldList.forEach(({ field }) => {
          if (field === haloRequiredFieldsType.category) {
            getHaloCrmCategories(crmId, ticketId).then(categories => {
              setCrmCategories(categories);
            });
          }
          if (field === haloRequiredFieldsType.impact && !crmImpacts.length) {
            getHaloCrmImpacts(crmId).then(impacts => {
              setCrmImpacts(impacts);
            });
          }
          if (field === haloRequiredFieldsType.urgency && !crmUrgency.length) {
            getHaloCrmUrgency(crmId).then(urgency => {
              setCrmUrgency(urgency);
            });
          }
        });
        setRequiredFields(res);
      })
      .finally(() => {
        setSubLoading(false);
      });
  };

  const submit = ({ defaultCompany, defaultBoard, ...rest }) => {
    setLoading(true);
    const payload = {
      defaultCompany: crmClients.filter(
        client => Number(client.value) === Number(clientId),
      )[0].label,
      defaultBoard: crmTeams.filter(
        team => Number(team.id) === Number(defaultBoard),
      )[0].name,
      ...rest,
    };
    onSubmit(payload);
  };

  const handleValidate = ({ defaultCompany, defaultBoard, ...rest }) => {
    setValidationLoading(true);
    const payload = {
      defaultCompany: crmClients.filter(
        client => Number(client.value) === Number(clientId),
      )[0].label,
      defaultBoard: crmTeams.filter(
        team => Number(team.id) === Number(defaultBoard),
      )[0].name,
      ...rest,
    };

    validateHaloIntegrationDefaults(crmId, payload)
      .then(() => {
        setValidated(true);
      })
      .catch(err => {
        enqueueSnackbar(err.message, { variant: 'error' });
      })
      .finally(() => {
        setValidationLoading(false);
      });
  };

  const onMount = useCallback(() => {
    setLoading(true);

    if (editItem?.typeId) {
      getHaloRequiredFields(editItem.typeId);
    }

    if (editItem?.crmSpecificSettings?.serviceLevelAgreementId) {
      getHaloPriorities(
        crmId,
        editItem.crmSpecificSettings.serviceLevelAgreementId,
      ).then(res => {
        setPriorities(res);
      });
    }

    Promise.all([
      getHaloCrmClients(crmId),
      getHaloCrmTicketTypes(crmId),
      getHaloCrmTeams(crmId),
      getHaloCrmStatuses(crmId),
      getHaloServiceLevelAgreements(crmId),
    ])
      .then(([clients, ticketTypes, teams, statuses, levelAgreements]) => {
        setCrmClients(
          clients.map(client => ({ label: client.name, value: client.id })),
        );
        setCrmTicketTypes(
          ticketTypes.map(type => ({ label: type.name, value: type.id })),
        );
        setCrmTeams(teams);
        setCrmStatuses(
          statuses.map(status => ({ label: status.name, value: status.id })),
        );
        setServiceLevelAgreements(levelAgreements);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [crmId, editItem]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  useEffect(() => {
    if (
      editItem &&
      Object.keys(editItem).length > 0 &&
      Object.getPrototypeOf(editItem) === Object.prototype &&
      crmClients.length > 0
    ) {
      const defaultCompany = crmClients.find(
        client => client.label === editItem[crmDefaultFields.defaultCompany],
      )?.value;
      if (defaultCompany) {
        handleClientSelect(defaultCompany);
        handleSiteSelect(
          editItem[crmDefaultFields.ticketCategoryId],
          defaultCompany,
        );
      } else {
        enqueueSnackbar('Check if selected client exists on PSA.', {
          variant: 'warning',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editItem, crmClients]);

  if (loading) return <Loading />;

  return (
    <div className={classes.container}>
      <div className={classes.block}>
        <Form
          onSubmit={handleValidate}
          initialValues={
            editItem
              ? {
                  ...editItem,
                  [crmDefaultFields.defaultBoard]: Number(
                    crmTeams.find(
                      team =>
                        team.name === editItem[crmDefaultFields.defaultBoard],
                    )?.id,
                  ),
                }
              : initialValues
          }
          validate={values =>
            validation(values, extractRequiredFields(requiredFields))
          }
          render={({ handleSubmit, form, values }) => (
            <form onSubmit={handleSubmit}>
              <div className={classes.form}>
                <div>
                  <Typography className={classes.inputLabel}>Name:</Typography>
                  <Field
                    id="name"
                    name="name"
                    component={Input}
                    placeholder="Name"
                    fullWidth
                    disabled={loading}
                  />
                </div>
                <div>
                  <Typography className={classes.inputLabel}>
                    Catch-all Company:
                  </Typography>
                  <Field
                    id={crmDefaultFields.defaultCompany}
                    name={crmDefaultFields.defaultCompany}
                    size="small"
                    component={AutocompleteFormInput}
                    items={crmClients}
                    disabled={loading}
                    suppressInlineLabel
                    getOptionLabel={i => i.label}
                    input={{
                      value: clientId,
                      onChange: value => {
                        form.change(crmDefaultFields.defaultCompany, value);
                        handleClientSelect(value);
                      },
                    }}
                  />
                </div>
                <div>
                  <Typography className={classes.inputLabel}>Site:</Typography>
                  <Field
                    id={crmDefaultFields.ticketCategoryId}
                    name={crmDefaultFields.ticketCategoryId}
                    size="small"
                    labelName="name"
                    valueName="id"
                    component={DropDown}
                    options={crmSites}
                    disabled={loading || subLoading}
                    input={{
                      value: siteId,
                      onChange: e => {
                        handleSiteSelect(e.target.value);
                        form.change(
                          crmDefaultFields.ticketCategoryId,
                          e.target.value,
                        );
                      },
                    }}
                  />
                </div>
                <div>
                  <Typography className={classes.inputLabel}>User:</Typography>
                  <Field
                    id={crmDefaultFields.sourceId}
                    name={crmDefaultFields.sourceId}
                    size="small"
                    labelName="name"
                    valueName="id"
                    component={DropDown}
                    options={crmUsers}
                    disabled={loading || subLoading || !siteId}
                  />
                </div>
                <div>
                  <Typography className={classes.inputLabel}>Team:</Typography>
                  <Field
                    id={crmDefaultFields.defaultBoard}
                    name={crmDefaultFields.defaultBoard}
                    size="small"
                    labelName="name"
                    valueName="id"
                    component={DropDown}
                    options={crmTeams}
                    disabled={loading}
                  />
                </div>
                <div>
                  <Typography className={classes.inputLabel}>
                    New Ticket Status:
                  </Typography>
                  <Field
                    id={crmDefaultFields.openBoardStatusId}
                    name={crmDefaultFields.openBoardStatusId}
                    size="small"
                    component={DropDown}
                    options={crmStatuses}
                    disabled={loading}
                  />
                </div>
                <div>
                  <Typography className={classes.inputLabel}>
                    Acknowledged Status:
                  </Typography>
                  <Field
                    id={crmDefaultFields.acknowledgedBoardStatusId}
                    name={crmDefaultFields.acknowledgedBoardStatusId}
                    size="small"
                    component={DropDown}
                    options={crmStatuses}
                    disabled={loading}
                  />
                </div>
                <div>
                  <Typography className={classes.inputLabel}>
                    SMS received Status:
                  </Typography>
                  <Field
                    id={crmDefaultFields.smsReceivedBoardStatusId}
                    name={crmDefaultFields.smsReceivedBoardStatusId}
                    size="small"
                    component={DropDown}
                    options={[{ value: undefined, label: '-' }, ...crmStatuses]}
                    disabled={loading}
                  />
                </div>
                <div>
                  <Typography className={classes.inputLabel}>
                    Closed Ticket Status:
                  </Typography>
                  <Field
                    id={crmDefaultFields.closedBoardStatusId}
                    name={crmDefaultFields.closedBoardStatusId}
                    size="small"
                    component={DropDown}
                    options={crmStatuses}
                    disabled={loading}
                  />
                </div>
                <div>
                  <Typography className={classes.inputLabel}>
                    Ticket Type:
                  </Typography>
                  <Field
                    id={crmDefaultFields.typeId}
                    name={crmDefaultFields.typeId}
                    size="small"
                    component={DropDown}
                    allowEmpty
                    options={crmTicketTypes}
                    onInputChange={ticketType => {
                      if (ticketType) {
                        getHaloRequiredFields(ticketType);
                      }
                      if (requiredFields.length) {
                        Object.values(uniqueRequiredFields).forEach(
                          fieldName => {
                            if (values[fieldName]) {
                              form.change(fieldName, null);
                            }
                            if (values.crmSpecificSettings) {
                              form.change(`crmSpecificSettings`, {});
                            }
                          },
                        );
                      }
                    }}
                    disabled={loading}
                  />
                </div>
                <div>
                  <Typography className={classes.inputLabel}>
                    Ticket Service Level Agreements:
                  </Typography>
                  <Field
                    id="crmSpecificSettings.serviceLevelAgreementId"
                    name="crmSpecificSettings.serviceLevelAgreementId"
                    size="small"
                    component={DropDown}
                    onInputChange={value => {
                      if (value) {
                        getHaloPriorities(crmId, value).then(res => {
                          setPriorities(res);
                        });
                      } else {
                        form.change(
                          'crmSpecificSettings.serviceLevelAgreementId',
                          null,
                        );
                      }
                      if (values.ticketPriority) {
                        form.change(crmDefaultFields.ticketPriority, null);
                      }
                    }}
                    labelName="name"
                    valueName="id"
                    allowEmpty
                    options={serviceLevelAgreements}
                    disabled={loading}
                  />
                </div>
                <div>
                  <Typography className={classes.inputLabel}>
                    Ticket Priority:
                  </Typography>
                  <Field
                    id={crmDefaultFields.ticketPriority}
                    name={crmDefaultFields.ticketPriority}
                    size="small"
                    component={DropDown}
                    labelName="name"
                    valueName="id"
                    allowEmpty
                    options={priorities}
                    disabled={loading}
                  />
                </div>
                {!!requiredFields.length && (
                  <HaloRequiredFields
                    requiredFields={requiredFields}
                    loading={loading}
                    crmImpacts={crmImpacts}
                    crmCategories={crmCategories}
                    crmUrgency={crmUrgency}
                  />
                )}
              </div>

              <div className={classes.buttonsWrapper}>
                <ReusableButton
                  size="md"
                  type="submit"
                  label="Validate"
                  classNameWrapper={cx(
                    classes.buttonCancel,
                    classes.buttonStyle,
                  )}
                  disabled={subLoading || loading}
                  loading={validationLoading}
                />
                <ReusableButton
                  size="md"
                  type="button"
                  label="resources.buttons.cancel"
                  classNameWrapper={cx(
                    classes.buttonCancel,
                    classes.buttonStyle,
                  )}
                  onClick={onBack}
                />
                <ReusableButton
                  size="md"
                  classNameWrapper={classes.buttonStyle}
                  viewType="black"
                  type="button"
                  label="resources.buttons.submit"
                  onClick={() => {
                    submit(values);
                  }}
                  disabled={!validated}
                />
              </div>
              <FormSpy
                onChange={() => {
                  setValidated(false);
                }}
              />
            </form>
          )}
        />
      </div>
    </div>
  );
};

HaloDefaults.propTypes = {
  onSubmit: PropTypes.func,
  onBack: PropTypes.func,
  crmId: PropTypes.number,
  data: PropTypes.shape({
    id: PropTypes.number,
    crmSourceId: PropTypes.number,
    name: PropTypes.string,
    defaultCompany: PropTypes.string,
    defaultBoard: PropTypes.string,
    default: PropTypes.bool,
    openBoardStatusId: PropTypes.number,
    typeId: PropTypes.number,
    sourceId: PropTypes.number,
    ticketCategoryId: PropTypes.number,
    closedBoardStatusId: PropTypes.number,
  }),
};
export default HaloDefaults;
