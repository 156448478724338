import React from 'react';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { PhonelinkLock as PhonelinkLockIcon } from '@material-ui/icons';
import roles from '@constants/roles';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import { actions } from '@store/actions';
import { getDataFromToken } from '@utils/getDataFromToken';
import useStyles from './styles';
import { toggleMfauth } from './helpers';

const Switch2mfaButton = ({ record }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { roleId: currentRoleId } = getDataFromToken();
  const dispatch = useDispatch();

  const handleClick = async () => {
    const payload = {
      UserId: record.id,
      EnableMfa: !record.multiFactorEnabled,
    };
    const newRecord = {
      ...record,
      multiFactorEnabled: !record.multiFactorEnabled,
      multiFactorSetupNeeded: !record.multiFactorEnabled,
    };

    if (await toggleMfauth(payload)) {
      dispatch(actions.updateItemInList(newRecord));
      return;
    }
    enqueueSnackbar('Unable to change type', { variant: 'error' });
  };

  const getButtonStyle = () => {
    return record.multiFactorEnabled && record.multiFactor
      ? classes.vpnIcon
      : record.multiFactorEnabled && record.multiFactorSetupNeeded
      ? classes.greenVpnIcon
      : classes.disabledVpnIcon;
  };

  const getButtonHint = () => {
    return record.multiFactorEnabled && record.multiFactor
      ? 'MFA Configured'
      : record.multiFactorEnabled && record.multiFactorSetupNeeded
      ? 'MFA Setup needed'
      : 'MFA disabled';
  };

  if (currentRoleId > roles.TENANT_ADMIN) {
    return null;
  }

  return (
    <div>
      <ActionButton
        icon={<PhonelinkLockIcon className={getButtonStyle()} />}
        handler={handleClick}
        toolTip={getButtonHint()}
      />
    </div>
  );
};

Switch2mfaButton.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    role: PropTypes.string,
    tenant: PropTypes.string,
    multiFactorEnabled: PropTypes.bool,
  }),
};

export default Switch2mfaButton;
