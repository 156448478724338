import React, { useState, memo } from 'react';
import { useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import FieldWrapper from '@common/form/FieldWrapper';
import ReusableButton from '@common/Button/Button';
import Modal from '@ui/common/Modal/Modal';
import AutocompleteFormInput from '@common/AutocompleteFormInput/AutocompleteFormInput';

import { importPhoneNumber } from '@services/requests/twilioAccounts';
import { TwilioAccountsServicesSelector } from '@store/slices/twilioAccounts';
import { validate } from '../helpers';

import useStyles from '../styles';

const TwilioAccountNumberForm = ({ item, setModalData }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const services = useSelector(TwilioAccountsServicesSelector);

  const [processing, setProcessing] = useState(false);

  const submit = values => {
    setProcessing(true);
    importPhoneNumber(values).then(() => {
      setModalData(null);
      enqueueSnackbar('Successfully imported', {
        variant: 'success',
      });
    });
  };

  return (
    <Modal
      open
      setOpen={() => setModalData(null)}
      title="Import phone number"
      isCloseButton={false}
    >
      <Form
        onSubmit={submit}
        initialValues={item}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className={classes.form}>
            <FieldWrapper
              label="PhoneNumber"
              labelSize={12}
              contentSize={12}
              showLabel
              content={<div>{item.phoneNumber}</div>}
            />
            <Field
              name="messagingServiceSid"
              id="messagingServiceSid"
              label="Select messaging service"
              component={AutocompleteFormInput}
              size="small"
              items={services}
            />
            <div className={classes.dialogActionsContainer}>
              <ReusableButton
                label="Close"
                onClick={() => {
                  setModalData(null);
                }}
                disabled={processing}
              />
              <ReusableButton
                label="Import"
                type="submit"
                disabled={processing}
                viewType="blue"
              />
            </div>
          </form>
        )}
      />
    </Modal>
  );
};

TwilioAccountNumberForm.propTypes = {
  setModalData: PropTypes.func.isRequired,
  item: PropTypes.shape({
    twilioAccountId: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
  }).isRequired,
};

export default memo(TwilioAccountNumberForm);
