import React from 'react';

import {
  ADMIN_PARSERS,
  CUSTOM_AGENT_AI,
  API_KEYS,
  AT_HOOKS,
  AUTOTASK_SMS,
  BILLING,
  BILLING_LINKS,
  BLOCKED_SENDERS,
  CLIENT_PORTAL_CHATS,
  CLIENT_PORTAL_CONFIGS,
  CLIENT_PORTAL_DEFAULTS,
  CLIENT_PORTAL_USERS,
  COMPANIES_VERIFICATION_SETTINGS,
  COMPANY_MAPPING,
  COUPONS,
  CREATE_TICKET,
  CUSTOM_FIELDS,
  CUSTOM_PALETTE,
  CUSTOM_TYPES,
  CW_HOOKS,
  DASHBOARD,
  DATA,
  DATA_SOURCES_INTEGRATION,
  DATA_VIEWS,
  DEMO_LINKS,
  DEVICE_MONITORS,
  DOCUMENTS,
  DYNAMIC_CRM_FILTERS,
  EMAIL_SETTINGS,
  ESCALATION_RULES,
  FILE_UPLOAD,
  FTP_CONNECTIONS,
  GENERIC_SOURCES,
  HELPDESK_AI,
  INCIDENTS_LIST,
  INTEGRATIONS,
  INVITED_USERS,
  ISSUE_TYPES,
  LIVE_CHAT,
  LIVE_CHAT_CONVERSATIONS,
  MAIL_BOXES,
  MESSAGING_CHANNELS,
  MESSAGING_CONFIGURATION,
  MESSAGING_NUMBERS,
  MESSAGING_TEMPLATES,
  NOTIFICATION_LOGS,
  NOTIFICATIONS_DATA,
  NOTIFICATIONS_GROUPS,
  OPT_IN_LIST,
  OPTIN_SETTINGS,
  OUTGOING_MAIL,
  PARSING_STATUS,
  PASSWORDS,
  PSA_CONTACTS,
  PSA_TECH_VERIFICATION,
  PSA_TICKETS,
  PSA_TICKETS_LOGS,
  PURCHASE_PHONE_NUMBERS,
  ROADMAP,
  SCHEDULER_PAGE,
  SECURE_DATA_SETTINGS,
  SECURITY_INTEGRATION,
  SETUP_WIZARD,
  SMS_BLACK_LIST,
  SMS_CAMPAIGN_BUILDER,
  SMS_CAMPAIGNS,
  SMS_LOGS,
  SUBSCRIPTIONS,
  TEAMS_BOT_CONFIGURATION,
  TEAMS_CONNECTIONS,
  TEMPLATES,
  TENANT_MODULES,
  TENANT_MODULES_TEMPLATE,
  TENANT_SETTINGS,
  TENANT_STATISTIC,
  TENANTS,
  TEST_EMAIL,
  TRIGGER,
  USER_ACTIVITY_LOGS,
  USERS,
  VERIFICATION_HISTORY,
  VERIFICATION_SETTINGS,
  WEB_HOOKS,
  TWILIO_ACCOUNTS,
  MESSAGE_TEMPLATES,
} from '@constants/routes';

import { ReactComponent as NewContactIcon } from '@assets/icons/updated/contacts.svg';
import { ReactComponent as NewMessagingIcon } from '@assets/icons/updated/mail.svg';
import { ReactComponent as NewTicketingIcon } from '@assets/icons/updated/tickets.svg';
import { ReactComponent as NewTeamsIcon } from '@assets/icons/updated/groups.svg';
import { ReactComponent as NewClientPortalIcon } from '@assets/icons/updated/clients.svg';
import { ReactComponent as NewVerificationIcon } from '@assets/icons/updated/shield_person.svg';
import { ReactComponent as NewDataIcon } from '@assets/icons/updated/table.svg';
import { ReactComponent as NewIncidentsIcon } from '@assets/icons/updated/emergency.svg';
import { ReactComponent as NewIntegrationsIcon } from '@assets/icons/updated/integrations.svg';
import { ReactComponent as NewAdminIcon } from '@assets/icons/updated/settings.svg';
import { ReactComponent as NewDataViewIcon } from '@assets/icons/updated/database.svg';
import { ReactComponent as NewNotificationsIcon } from '@assets/icons/updated/notifications_active.svg';
import { ReactComponent as NewSettingsIcon } from '@assets/icons/updated/manufactoring.svg';
import { ReactComponent as ForumIcon } from '@assets/icons/updated/forum.svg';
import { ReactComponent as EditNoteIcon } from '@assets/icons/updated/edit_note.svg';
import { ReactComponent as LogIcon } from '@assets/icons/updated/query_stat.svg';
import { ReactComponent as CellTowerIcon } from '@assets/icons/updated/cell_tower.svg';
import { ReactComponent as EmailCheckIcon } from '@assets/icons/updated/email_read.svg';
import { ReactComponent as EmailCrossIcon } from '@assets/icons/updated/email_cross.svg';
import { ReactComponent as PersonCrossIcon } from '@assets/icons/updated/person_cancel.svg';
import { ReactComponent as FactoryIcon } from '@assets/icons/updated/factory.svg';
import { ReactComponent as AddTicketIcon } from '@assets/icons/updated/assignment_add.svg';
import { ReactComponent as PersonIcon } from '@assets/icons/updated/group.svg';
import { ReactComponent as TwilioIcon } from '@assets/icons/updated/twilio.svg';
import { ReactComponent as PersonAddIcon } from '@assets/icons/updated/person_add.svg';
import { ReactComponent as NotificationCircleIcon } from '@assets/icons/updated/circle_notifications.svg';
import { ReactComponent as CalendarIcon } from '@assets/icons/updated/calendar.svg';
import { ReactComponent as TechVerificationIcon } from '@assets/icons/updated/support_agent.svg';
import { ReactComponent as HistoryIcon } from '@assets/icons/updated/history.svg';
import { ReactComponent as PolicyIcon } from '@assets/icons/updated/policy.svg';
import { ReactComponent as IncomingEmailIcon } from '@assets/icons/updated/incoming.svg';
import { ReactComponent as DashboardIcon } from '@assets/icons/updated/dashboard.svg';
import { ReactComponent as CustomTypesIcon } from '@assets/icons/updated/custom_type.svg';
import { ReactComponent as DeviceMonitorIcon } from '@assets/icons/updated/device_monitor.svg';
import { ReactComponent as MailboxIcon } from '@assets/icons/updated/mailbox.svg';
import { ReactComponent as WebHookIcon } from '@assets/icons/updated/webhook.svg';
import { ReactComponent as AppBotIcon } from '@assets/icons/updated/app_bot.svg';
import { ReactComponent as AiAgentIcon } from '@assets/icons/updated/ai-agent.svg';
import { ReactComponent as AlertIntegrationIcon } from '@assets/icons/updated/assignment_late.svg';
import { ReactComponent as SecureIntegrationIcon } from '@assets/icons/updated/phonelink_lock.svg';
import { ReactComponent as UploadFileIcon } from '@assets/icons/updated/upload_file.svg';
import { ReactComponent as WarningIcon } from '@assets/icons/updated/warning.svg';
import { ReactComponent as PlayIcon } from '@assets/icons/updated/play_circle.svg';
import { ReactComponent as RulesIcon } from '@assets/icons/updated/rule.svg';
import { ReactComponent as PaymentIcon } from '@assets/icons/updated/payments.svg';
import { ReactComponent as KeyIcon } from '@assets/icons/updated/key.svg';
import { ReactComponent as MapIcon } from '@assets/icons/updated/map.svg';
import { ReactComponent as PaletteIcon } from '@assets/icons/updated/palette.svg';
import { ReactComponent as FtpIcon } from '@assets/icons/updated/cable.svg';
import { ReactComponent as ParserIcon } from '@assets/icons/updated/parcers.svg';
import { ReactComponent as LinkIcon } from '@assets/icons/updated/link.svg';
import { ReactComponent as PhoneIcon } from '@assets/icons/updated/phone.svg';
import { ReactComponent as FilterIcon } from '@assets/icons/filter.svg';

export const allUsersPermission = [1, 2, 3];
export const adminsPermission = [1, 2];
export const superAdminPermission = [1];

export const res = {
  mailBoxes: 'mailBoxes',
  webHooks: 'webHooks',
  genericSources: 'genericSources',
  triggers: 'triggers',
  uptimeMonitors: 'uptimeMonitors',
  smsMessaging: 'smsMessaging',
  chats: 'chats',
  liveChats: 'liveChats',
  alertSystemIntegrations: 'alertSystemIntegrations',
  webHookIntegrations: 'webHookIntegrations',
  autotaskNoteToSms: 'autotaskNoteToSms',
};

export const PricePlans = {
  Free: 'Free',
  VerificationPlus: 'Verification Plus',
  Pro: 'Pro',
};

export const allPricePlans = Object.values(PricePlans);

export const sidebarContent = [
  {
    label: 'Contacts',
    path: PSA_CONTACTS,
    icon: <NewContactIcon />,
    permissions: allUsersPermission,
    dependencies: [],
    pricePlans: allPricePlans,
  },
  {
    label: 'Messaging',
    icon: <NewMessagingIcon />,
    permissions: allUsersPermission,
    dependencies: [],
    pricePlans: [PricePlans.VerificationPlus, PricePlans.Pro],
    items: [
      {
        path: MESSAGING_CONFIGURATION,
        label: 'Configurations',
        icon: <NewSettingsIcon />,
        dependencies: [res.smsMessaging],
        permissions: adminsPermission,
        pricePlans: [PricePlans.Pro],
      },
      {
        path: DYNAMIC_CRM_FILTERS,
        label: 'Dynamic Filters',
        icon: <FilterIcon />,
        dependencies: [res.smsMessaging],
        permissions: adminsPermission,
        pricePlans: [PricePlans.Pro],
      },
      {
        path: MESSAGING_CHANNELS,
        label: 'Messaging Channels',
        icon: <ForumIcon />,
        dependencies: [res.smsMessaging],
        pricePlans: [PricePlans.Pro],
        permissions: allUsersPermission,
      },
      {
        path: MESSAGE_TEMPLATES,
        label: 'Message Templates',
        icon: <EditNoteIcon />,
        dependencies: [],
        permissions: adminsPermission,
        pricePlans: [PricePlans.Pro],
      },
      {
        path: SMS_CAMPAIGN_BUILDER,
        label: 'Broadcast Messages',
        icon: <CellTowerIcon />,
        dependencies: [],
        pricePlans: [PricePlans.Pro],
        permissions: allUsersPermission,
      },
      {
        path: SMS_CAMPAIGNS,
        label: 'Messaging Groups',
        icon: <NewTeamsIcon />,
        dependencies: [],
        pricePlans: [PricePlans.Pro],
        permissions: allUsersPermission,
      },
      {
        path: SMS_BLACK_LIST,
        label: 'Opted-Out Recipients',
        icon: <EmailCrossIcon />,
        dependencies: [],
        pricePlans: [PricePlans.VerificationPlus, PricePlans.Pro],
        permissions: allUsersPermission,
      },
      {
        path: BLOCKED_SENDERS,
        label: 'Blocked Senders',
        icon: <PersonCrossIcon />,
        dependencies: [],
        permissions: allUsersPermission,
        pricePlans: [PricePlans.Pro],
      },
      {
        path: OPTIN_SETTINGS,
        label: 'Opt-in Settings',
        icon: <NewSettingsIcon />,
        dependencies: [],
        permissions: adminsPermission,
        pricePlans: [PricePlans.VerificationPlus, PricePlans.Pro],
      },
      {
        path: SMS_LOGS,
        label: 'Messaging Logs',
        icon: <LogIcon />,
        dependencies: [],
        permissions: allUsersPermission,
        pricePlans: [PricePlans.Pro],
      },
      {
        path: OPT_IN_LIST,
        label: 'Opt-in Data',
        icon: <EmailCheckIcon />,
        dependencies: [],
        permissions: allUsersPermission,
        pricePlans: [PricePlans.VerificationPlus, PricePlans.Pro],
      },
    ],
  },
  {
    label: 'Ticketing',
    icon: <NewTicketingIcon />,
    permissions: allUsersPermission,
    dependencies: [],
    pricePlans: allPricePlans,
    items: [
      {
        path: PSA_TICKETS_LOGS,
        label: 'Ticket Logs',
        icon: <LogIcon />,
        dependencies: [],
        permissions: allUsersPermission,
        pricePlans: allPricePlans,
      },
      {
        path: COMPANY_MAPPING,
        label: 'Company Mapping',
        icon: <FactoryIcon />,
        dependencies: [
          res.mailBoxes,
          res.webHooks,
          res.genericSources,
          res.triggers,
          res.uptimeMonitors,
        ],
        permissions: adminsPermission,
        pricePlans: [PricePlans.Pro],
      },
      {
        path: PSA_TICKETS,
        label: 'PSA Tickets',
        icon: <NewTicketingIcon />,
        dependencies: [res.smsMessaging],
        permissions: allUsersPermission,
        pricePlans: allPricePlans,
      },
      {
        path: MESSAGING_TEMPLATES,
        label: 'Templates',
        icon: <EditNoteIcon />,
        dependencies: [res.smsMessaging],
        permissions: adminsPermission,
        pricePlans: allPricePlans,
      },
      {
        path: CREATE_TICKET,
        label: 'Quick Ticket',
        icon: <AddTicketIcon />,
        dependencies: [],
        pricePlans: [PricePlans.VerificationPlus, PricePlans.Pro],
        permissions: allUsersPermission,
      },
    ],
  },
  {
    label: 'Teams',
    icon: <NewTeamsIcon />,
    permissions: allUsersPermission,
    dependencies: [],
    pricePlans: allPricePlans,
    items: [
      {
        path: USERS,
        label: 'Users',
        icon: <PersonIcon />,
        dependencies: [],
        permissions: allUsersPermission,
        pricePlans: allPricePlans,
      },
      {
        path: INVITED_USERS,
        label: 'Invite Users',
        icon: <PersonAddIcon />,
        dependencies: [],
        permissions: allUsersPermission,
        pricePlans: allPricePlans,
      },
      {
        path: NOTIFICATIONS_GROUPS,
        label: 'Notification Groups',
        icon: <NotificationCircleIcon />,
        dependencies: [
          res.mailBoxes,
          res.webHooks,
          res.genericSources,
          res.triggers,
          res.uptimeMonitors,
        ],
        permissions: allUsersPermission,
        pricePlans: [PricePlans.Pro],
      },
      {
        path: LIVE_CHAT,
        label: 'Live Chats',
        icon: <ForumIcon />,
        dependencies: [res.chats],
        permissions: allUsersPermission,
        pricePlans: [PricePlans.Pro],
      },
      {
        path: LIVE_CHAT_CONVERSATIONS,
        label: 'Live Chat Conversations',
        icon: <EditNoteIcon />,
        dependencies: [res.chats],
        permissions: allUsersPermission,
        pricePlans: [PricePlans.Pro],
      },
      {
        path: SCHEDULER_PAGE,
        label: 'Scheduling',
        icon: <CalendarIcon />,
        dependencies: [
          res.mailBoxes,
          res.webHooks,
          res.genericSources,
          res.triggers,
          res.uptimeMonitors,
        ],
        permissions: allUsersPermission,
        pricePlans: [PricePlans.Pro],
      },
      {
        path: USER_ACTIVITY_LOGS,
        label: 'Activity Logs',
        icon: <LogIcon />,
        permissions: adminsPermission,
        dependencies: [],
        pricePlans: allPricePlans,
      },
    ],
  },
  {
    label: 'Client Portal',
    icon: <NewClientPortalIcon />,
    permissions: allUsersPermission,
    dependencies: [],
    pricePlans: [PricePlans.Pro],
    items: [
      {
        path: EMAIL_SETTINGS,
        label: 'Invite Email Template',
        icon: <EditNoteIcon />,
        dependencies: [],
        permissions: adminsPermission,
        pricePlans: [PricePlans.Pro],
      },
      {
        path: CLIENT_PORTAL_CHATS,
        label: 'Chats',
        icon: <ForumIcon />,
        dependencies: [],
        permissions: allUsersPermission,
        pricePlans: [PricePlans.Pro],
      },
      {
        path: CLIENT_PORTAL_CONFIGS,
        label: 'Configurations',
        icon: <NewSettingsIcon />,
        dependencies: [],
        permissions: adminsPermission,
        pricePlans: [PricePlans.Pro],
      },
      {
        path: CLIENT_PORTAL_DEFAULTS,
        label: 'Ticket Defaults',
        icon: <EditNoteIcon />,
        dependencies: [],
        permissions: adminsPermission,
        pricePlans: [PricePlans.Pro],
      },
      {
        path: ISSUE_TYPES,
        label: 'Issue Types',
        icon: <NewTicketingIcon />,
        dependencies: [],
        permissions: adminsPermission,
        pricePlans: [PricePlans.Pro],
      },
      {
        path: CLIENT_PORTAL_USERS,
        label: 'Portal Users',
        icon: <PersonIcon />,
        dependencies: [],
        permissions: allUsersPermission,
        pricePlans: [PricePlans.Pro],
      },
      {
        path: TEAMS_BOT_CONFIGURATION,
        label: 'Teams Bot Configuration',
        icon: <NewSettingsIcon />,
        dependencies: [],
        permissions: adminsPermission,
        pricePlans: [PricePlans.Pro],
      },
    ],
  },
  {
    label: 'Verification',
    icon: <NewVerificationIcon />,
    permissions: allUsersPermission,
    dependencies: [],
    pricePlans: allPricePlans,
    items: [
      {
        path: PSA_TECH_VERIFICATION,
        label: 'Tech Verification',
        icon: <TechVerificationIcon />,
        dependencies: [],
        permissions: allUsersPermission,
        pricePlans: [PricePlans.VerificationPlus, PricePlans.Pro],
      },
      {
        path: VERIFICATION_HISTORY,
        label: 'Verification History',
        icon: <HistoryIcon />,
        dependencies: [],
        permissions: allUsersPermission,
        pricePlans: [PricePlans.VerificationPlus, PricePlans.Pro],
      },
      {
        path: VERIFICATION_SETTINGS,
        label: 'Verification Settings',
        icon: <NewSettingsIcon />,
        dependencies: [],
        permissions: adminsPermission,
        pricePlans: allPricePlans,
      },
      {
        path: COMPANIES_VERIFICATION_SETTINGS,
        label: 'Policies',
        icon: <PolicyIcon />,
        dependencies: [],
        permissions: adminsPermission,
        pricePlans: [PricePlans.VerificationPlus, PricePlans.Pro],
      },
    ],
  },
  {
    path: HELPDESK_AI,
    label: 'AI VoiceAssist',
    icon: <AiAgentIcon />,
    dependencies: [],
    permissions: adminsPermission,
    pricePlans: [PricePlans.VerificationPlus, PricePlans.Pro],
  },
  {
    path: NOTIFICATIONS_DATA,
    label: 'Notifications',
    icon: <NewNotificationsIcon />,
    permissions: allUsersPermission,
    pricePlans: [PricePlans.Pro],
    dependencies: [
      res.mailBoxes,
      res.webHooks,
      res.genericSources,
      res.triggers,
      res.uptimeMonitors,
    ],
  },
  {
    label: 'Data',
    icon: <NewDataIcon />,
    permissions: allUsersPermission,
    dependencies: [],
    pricePlans: [PricePlans.Pro],
    items: [
      {
        path: DATA,
        label: 'Data',
        icon: <NewDataIcon />,
        dependencies: [res.mailBoxes, res.webHooks, res.genericSources],
        permissions: allUsersPermission,
        pricePlans: allPricePlans,
      },
      {
        path: DATA_VIEWS,
        label: 'Data Views',
        icon: <NewDataViewIcon />,
        dependencies: [res.mailBoxes, res.webHooks, res.genericSources],
        permissions: allUsersPermission,
        pricePlans: allPricePlans,
      },
      {
        path: DOCUMENTS,
        label: 'Inbound Emails',
        icon: <IncomingEmailIcon />,
        dependencies: [res.mailBoxes],
        permissions: allUsersPermission,
        pricePlans: allPricePlans,
      },
      {
        path: DASHBOARD,
        label: 'Dashboard',
        icon: <DashboardIcon />,
        dependencies: [res.mailBoxes, res.webHooks, res.genericSources],
        permissions: allUsersPermission,
        pricePlans: allPricePlans,
      },
      {
        path: CUSTOM_TYPES,
        label: 'Custom Types',
        icon: <CustomTypesIcon />,
        dependencies: [res.mailBoxes, res.webHooks, res.genericSources],
        permissions: allUsersPermission,
        pricePlans: allPricePlans,
      },
      {
        path: DEVICE_MONITORS,
        label: 'Uptime Monitors',
        icon: <DeviceMonitorIcon />,
        dependencies: [res.uptimeMonitors],
        permissions: allUsersPermission,
        pricePlans: allPricePlans,
      },
    ],
  },
  {
    label: 'Data Sources',
    icon: <NewDataViewIcon />,
    permissions: allUsersPermission,
    dependencies: [],
    pricePlans: [PricePlans.Pro],
    items: [
      {
        path: MAIL_BOXES,
        label: 'Mail Boxes',
        icon: <MailboxIcon />,
        dependencies: [res.mailBoxes],
        permissions: allUsersPermission,
        pricePlans: allPricePlans,
      },
      {
        path: WEB_HOOKS,
        label: 'Web Hooks',
        icon: <WebHookIcon />,
        dependencies: [res.webHooks],
        permissions: allUsersPermission,
        pricePlans: allPricePlans,
      },
      {
        path: GENERIC_SOURCES,
        label: 'App Bots',
        icon: <AppBotIcon />,
        dependencies: [res.genericSources],
        permissions: allUsersPermission,
        pricePlans: allPricePlans,
      },
    ],
  },
  {
    label: 'Integrations',
    icon: <NewIntegrationsIcon />,
    permissions: adminsPermission,
    dependencies: [],
    pricePlans: allPricePlans,
    items: [
      {
        path: DATA_SOURCES_INTEGRATION,
        label: 'Alert Integrations',
        icon: <AlertIntegrationIcon />,
        dependencies: [res.alertSystemIntegrations],
        permissions: allUsersPermission,
        pricePlans: [PricePlans.Pro],
      },
      {
        path: INTEGRATIONS,
        label: 'PSA Integrations',
        icon: <NewIntegrationsIcon />,
        dependencies: [
          res.mailBoxes,
          res.webHooks,
          res.genericSources,
          res.triggers,
          res.uptimeMonitors,
          res.smsMessaging,
          res.webHookIntegrations,
          res.autotaskNoteToSms,
        ],
        permissions: allUsersPermission,
        pricePlans: allPricePlans,
      },
      {
        path: SECURITY_INTEGRATION,
        label: 'Security Integrations',
        icon: <SecureIntegrationIcon />,
        dependencies: [],
        permissions: allUsersPermission,
        pricePlans: [PricePlans.VerificationPlus, PricePlans.Pro],
      },
      {
        path: TEMPLATES,
        label: 'Alert Templates',
        icon: <EditNoteIcon />,
        dependencies: [res.alertSystemIntegrations],
        permissions: allUsersPermission,
        pricePlans: [PricePlans.Pro],
      },
      {
        path: AT_HOOKS,
        label: 'Autotask Webhooks',
        icon: <WebHookIcon />,
        dependencies: [res.webHookIntegrations],
        permissions: allUsersPermission,
        pricePlans: [PricePlans.Pro],
      },
      {
        path: CW_HOOKS,
        label: 'ConnectWise Webhooks',
        icon: <WebHookIcon />,
        dependencies: [res.webHookIntegrations],
        permissions: allUsersPermission,
        pricePlans: [PricePlans.Pro],
      },

      {
        path: AUTOTASK_SMS,
        label: 'Autotask Note to SMS',
        icon: <NewMessagingIcon />,
        dependencies: [res.autotaskNoteToSms],
        permissions: allUsersPermission,
        pricePlans: [PricePlans.Pro],
      },

      {
        path: FILE_UPLOAD,
        label: 'Upload files',
        icon: <UploadFileIcon />,
        dependencies: [],
        permissions: allUsersPermission,
        pricePlans: [PricePlans.Pro],
      },
    ],
  },
  {
    label: 'Incidents',
    icon: <NewIncidentsIcon />,
    permissions: allUsersPermission,
    dependencies: [],
    pricePlans: [PricePlans.Pro],
    items: [
      {
        path: INCIDENTS_LIST,
        label: 'Alerts',
        icon: <WarningIcon />,
        dependencies: [res.triggers],
        permissions: allUsersPermission,
        pricePlans: [PricePlans.Pro],
      },
      {
        path: TRIGGER,
        label: 'Triggers',
        icon: <PlayIcon />,
        dependencies: [res.triggers],
        permissions: allUsersPermission,
        pricePlans: [PricePlans.Pro],
      },
      {
        path: ESCALATION_RULES,
        label: 'Escalation Rules',
        icon: <RulesIcon />,
        dependencies: [res.triggers],
        permissions: allUsersPermission,
        pricePlans: [PricePlans.Pro],
      },
    ],
  },
  {
    label: 'Account Settings',
    icon: <NewSettingsIcon />,
    permissions: adminsPermission,
    dependencies: [],
    pricePlans: allPricePlans,
    items: [
      {
        path: BILLING,
        label: 'Billing',
        icon: <PaymentIcon />,
        dependencies: [],
        permissions: allUsersPermission,
        pricePlans: allPricePlans,
      },
      {
        path: OUTGOING_MAIL,
        label: 'Outgoing Mail',
        icon: <MailboxIcon />,
        dependencies: [],
        permissions: allUsersPermission,
        pricePlans: [PricePlans.VerificationPlus, PricePlans.Pro],
      },
      {
        path: TENANT_STATISTIC,
        label: 'Usage Statistics',
        icon: <LogIcon />,
        dependencies: [],
        permissions: allUsersPermission,
        pricePlans: [PricePlans.Pro],
      },
      {
        path: SETUP_WIZARD,
        label: 'Setup Wizard',
        icon: <NewSettingsIcon />,
        dependencies: [],
        permissions: allUsersPermission,
        pricePlans: allPricePlans,
      },
      {
        path: TENANT_SETTINGS,
        label: 'Settings',
        icon: <NewSettingsIcon />,
        dependencies: [],
        permissions: allUsersPermission,
        pricePlans: allPricePlans,
      },
      {
        path: CUSTOM_FIELDS,
        label: 'Custom Fields',
        icon: <CustomTypesIcon />,
        dependencies: [
          res.mailBoxes,
          res.webHooks,
          res.genericSources,
          res.triggers,
          res.uptimeMonitors,
        ],
        permissions: allUsersPermission,
        pricePlans: [PricePlans.Pro],
      },
      {
        path: API_KEYS,
        label: 'API Keys',
        icon: <WebHookIcon />,
        dependencies: [res.mailBoxes, res.webHooks, res.genericSources],
        permissions: allUsersPermission,
        pricePlans: [PricePlans.Pro],
      },
      {
        path: PASSWORDS,
        label: 'Passwords',
        icon: <KeyIcon />,
        dependencies: [],
        permissions: allUsersPermission,
        pricePlans: [PricePlans.VerificationPlus, PricePlans.Pro],
      },
      {
        path: ROADMAP,
        label: 'Roadmap',
        icon: <MapIcon />,
        dependencies: [],
        permissions: allUsersPermission,
        pricePlans: allPricePlans,
      },
      {
        path: CUSTOM_PALETTE,
        label: 'Custom Palette',
        icon: <PaletteIcon />,
        dependencies: [],
        permissions: allUsersPermission,
        pricePlans: [PricePlans.VerificationPlus, PricePlans.Pro],
      },
      {
        path: FTP_CONNECTIONS,
        label: 'FTP Connections',
        icon: <FtpIcon />,
        dependencies: [res.mailBoxes, res.webHooks, res.genericSources],
        permissions: allUsersPermission,
        pricePlans: [PricePlans.Pro],
      },
      {
        path: TEAMS_CONNECTIONS,
        label: 'Teams Connections',
        icon: <NewTeamsIcon />,
        dependencies: [],
        permissions: allUsersPermission,
        pricePlans: [PricePlans.Pro],
      },
      {
        path: TEAMS_BOT_CONFIGURATION,
        label: 'Teams Bot Configuration',
        icon: <NewSettingsIcon />,
        dependencies: [],
        permissions: adminsPermission,
        pricePlans: [PricePlans.VerificationPlus],
      },
      {
        path: TEST_EMAIL,
        label: 'Test Email',
        icon: <NewMessagingIcon />,
        dependencies: [],
        permissions: allUsersPermission,
        pricePlans: allPricePlans,
      },
      {
        path: TWILIO_ACCOUNTS,
        label: 'Twilio Accounts',
        icon: <TwilioIcon />,
        dependencies: [],
        permissions: adminsPermission,
        pricePlans: [PricePlans.VerificationPlus, PricePlans.Pro],
      },
      {
        path: SECURE_DATA_SETTINGS,
        label: 'Secure Data Settings',
        icon: <NewSettingsIcon />,
        dependencies: [],
        permissions: allUsersPermission,
        pricePlans: [PricePlans.VerificationPlus, PricePlans.Pro],
      },
    ],
  },
  {
    label: 'System Administration',
    icon: <NewAdminIcon />,
    permissions: superAdminPermission,
    pricePlans: allPricePlans,
    dependencies: [],
    items: [
      {
        path: TENANTS,
        label: 'Tenants',
        icon: <PersonIcon />,
        dependencies: [],
        permissions: superAdminPermission,
        pricePlans: allPricePlans,
      },
      {
        path: CUSTOM_AGENT_AI,
        label: 'Custom AI Agents',
        icon: <AiAgentIcon />,
        dependencies: [],
        permissions: superAdminPermission,
        pricePlans: allPricePlans,
      },
      {
        path: ADMIN_PARSERS,
        label: 'Parsers',
        icon: <ParserIcon />,
        dependencies: [],
        permissions: superAdminPermission,
        pricePlans: allPricePlans,
      },
      {
        path: SUBSCRIPTIONS,
        label: 'Subscriptions',
        icon: <PaymentIcon />,
        dependencies: [],
        permissions: superAdminPermission,
        pricePlans: allPricePlans,
      },
      {
        path: DEMO_LINKS,
        label: 'Demo Links',
        icon: <LinkIcon />,
        dependencies: [],
        permissions: superAdminPermission,
        pricePlans: allPricePlans,
      },
      {
        path: NOTIFICATION_LOGS,
        label: 'Notification Logs',
        icon: <NotificationCircleIcon />,
        dependencies: [],
        permissions: superAdminPermission,
        pricePlans: allPricePlans,
      },
      {
        path: COUPONS,
        label: 'Coupons',
        icon: <PaymentIcon />,
        dependencies: [],
        permissions: superAdminPermission,
        pricePlans: allPricePlans,
      },
      {
        path: PARSING_STATUS,
        label: 'Parsing Status',
        icon: <ParserIcon />,
        dependencies: [],
        permissions: superAdminPermission,
        pricePlans: allPricePlans,
      },
      {
        path: BILLING_LINKS,
        label: 'Billing Links',
        icon: <LinkIcon />,
        dependencies: [],
        permissions: superAdminPermission,
        pricePlans: allPricePlans,
      },
      {
        path: MESSAGING_NUMBERS,
        label: 'Messaging Numbers',
        icon: <PhoneIcon />,
        dependencies: [],
        permissions: superAdminPermission,
        pricePlans: allPricePlans,
      },
      {
        path: PURCHASE_PHONE_NUMBERS,
        label: 'Purchase Numbers',
        icon: <PhoneIcon />,
        dependencies: [],
        permissions: superAdminPermission,
        pricePlans: allPricePlans,
      },
      {
        path: TENANT_MODULES,
        label: 'Tenant modules',
        icon: <PersonIcon />,
        dependencies: [],
        permissions: superAdminPermission,
        pricePlans: allPricePlans,
      },
      {
        path: TENANT_MODULES_TEMPLATE,
        label: 'New tenant modules',
        icon: <EditNoteIcon />,
        dependencies: [],
        permissions: superAdminPermission,
        pricePlans: allPricePlans,
      },
    ],
  },
];

export default {};
