import moment from 'moment';

export const getDateTab = (from, to) => {
  if (!from || !to) {
    return 9; // Date Range
  }

  const startDate = moment(from).startOf('day');
  const endDate = moment(to).startOf('day');
  const today = moment().startOf('day');
  const yesterday = today.clone().subtract(1, 'day');

  const daysDiff = endDate.diff(startDate, 'days');

  if (daysDiff > 1000) {
    return 8; // Max Available
  }

  if (daysDiff === 0 && startDate.isSame(today)) {
    return 0; // Today
  }
  if (daysDiff === 0 && startDate.isSame(yesterday)) {
    return 1; // Yesterday
  }
  if (daysDiff === 7 && endDate.isSame(today)) {
    return 2; // Last 7 Days
  }
  if (daysDiff > 29 && daysDiff < 32 && endDate.isSame(today)) {
    return 3; // Last 30 Days
  }

  if (
    startDate.isSame(today, 'month') &&
    endDate.isSame(today, 'month') &&
    daysDiff >= 28
  ) {
    return 4; // This Month
  }

  const lastMonth = today.clone().subtract(1, 'month');

  if (
    startDate.isSame(lastMonth, 'month') &&
    endDate.isSame(lastMonth, 'month') &&
    daysDiff >= 28
  ) {
    return 5; // Last Month
  }

  if (
    startDate.isSame(today, 'year') &&
    endDate.isSame(today, 'year') &&
    daysDiff > 360
  ) {
    return 6; // This Year
  }

  const lastYear = today.clone().subtract(1, 'year');
  if (startDate.isSame(lastYear, 'year') && endDate.isSame(lastYear, 'year')) {
    return 7; // Last Year
  }

  return 9; // Date Range
};

export default getDateTab;
