import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';
import { actions } from '@store/actions';
import { listSelector } from '@store/selectors';
import { deleteAuditView } from '@components/Ticketing/Integrations/helpers';
import iconDelete from '@assets/icons/delete.svg';
import { enqueueSnackbar } from 'notistack';
import { TEXT_DELETE_SUCESS } from '@constants/texts/common';

const DeleteActionField = ({ record = {} }) => {
  const list = useSelector(listSelector);

  const dispatch = useDispatch();

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handleOpenDeleteDialog = () => {
    setDeleteDialogOpen(true);
  };

  const handleDeleteAlert = () => {
    setDeleteDialogOpen(false);
    deleteAuditView(record.id)
      .then(result => {
        if (result) {
          const newList = list.filter(item => item.id !== record.id);
          dispatch(actions.saveList(newList));
          enqueueSnackbar(TEXT_DELETE_SUCESS, { variant: 'success' });
        } else {
          enqueueSnackbar('Unable to delete item', { variant: 'error' });
        }
      })
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }));
  };

  return (
    <>
      <ActionButton
        icon={<img src={iconDelete} alt="iconDelete" />}
        handler={handleOpenDeleteDialog}
      />
      <ConfirmDialog
        open={!!deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        onAccept={handleDeleteAlert}
        onCancel={() => setDeleteDialogOpen(false)}
        title="Delete current alert"
        content="Warning! This operation will delete current alert and cannot be undone."
      />
    </>
  );
};

DeleteActionField.propTypes = {
  record: PropTypes.objectOf(PropTypes.any),
};

export default DeleteActionField;
