export const LOGIN_ROUTE = '/login';
export const REGISTRATION_ROUTE = '/registration';
export const FORGOT_PASSWORD_ROUTE = '/forgot-password';
export const EMAIL_HANDLER_ROUTE = '/email-handler';
export const PROFILE_ROUTE = '/profile';
export const CREATE_COMMENT = '/comments/create';
export const COMMENTS_ROOT = '/comments';
export const INVITATION = '/invitation';
export const FIRST_MAILBOX = '/firstMailbox';
export const PRICING_PLAN = '/pricingPlan';
export const BILLING = '/billing';
export const OUTGOING_MAIL = '/outgoingMail';
export const DATA = '/data';
export const INTEGRATIONS = '/integrations';
export const CREATE_PARSER =
  '/mailBox/:mailBoxId/letter/:letterId/createParser';
export const UPDATE_PARSER =
  '/mailBox/:mailBoxId/letter/:letterId/parser/:parserId/updateParser';
export const MAIL_BOX = '/mailBox/:id/:name';
export const LETTER = '/mailBox/:mailBoxId/letter/:letterId';
export const MAILBOX_SETTINGS = 'MailBoxSettings';
export const PARSER = 'Parsers';
export const UNRECOGNIZED_TYPES = '/UnrecognizedTypes/:mailBoxId';
export const DATA_VIEWS = '/dataViews';
export const CRM_CREATE = '/integrations/integrationCreate';
export const CRM_UPDATE = '/integrations/:id';
export const TICKETING = '/notificationSettings';
export const TICKETING_CREATE = '/notificationSettingsCreate';
export const TICKETING_UPDATE = '/notificationSettings/:id';
export const DEVICE_MONITORS = '/deviceMonitors';
export const DEVICE_MONITORS_CREATE = '/deviceMonitors/create';
export const DEVICE_MONITORS_UPDATE = '/deviceMonitors/:id';
export const DEVICE_MONITORS_DETAILS = '/deviceMonitors/:id/details';
export const DEVICE_MONITORS_DETAILS_TV_MODE =
  '/deviceMonitors/:id/details/TVMode';

export const NOTIFICATIONS_DATA = '/notifications-data';
export const NOTIFICATIONS_DATA_MONITOR = '/notifications-monitor';
export const NOTIFICATION_LOGS = '/notificationLogs';

export const DM_SETTINGS_UPDATE = `/notifications/dmSetting/:id/monitor`;
export const DM_SETTINGS_CREATE = `/notifications/dmSetting/dmCreate`;
export const DM_SETTINGS_GROUP_CREATE = `/notifications/dmSetting/createGroup`;

export const TICKETING_TICKET_SETTINGS = '/notificationSettings/:id/Ticket';
export const TICKETING_EMAIL_SETTINGS = '/notificationSettings/:id/Email';
export const TICKETING_SMS_SETTINGS = '/notificationSettings/:id/Sms';
export const TICKETING_WH_SETTINGS = '/notificationSettings/:id/WebHook';
export const TICKETING_SLACK_SETTINGS = '/notificationSettings/:id/Slack';
export const TICKETING_TEAMS_SETTINGS = '/notificationSettings/:id/teams';
export const TICKETING_FTP_SETTINGS = '/notificationSettings/:id/ftp';
export const TICKETING_VOICE_SETTINGS = '/notificationSettings/:id/voice';

export const NOTIFICATION_MONITOR_TICKET_DM_SETTINGS =
  '/notifications/dmSetting/:id/Ticket';

export const NOTIFICATION_MONITOR_EMAIL_DM_SETTINGS =
  '/notifications/dmSetting/:id/Email';
export const NOTIFICATION_MONITOR_SMS_DM_SETTINGS =
  '/notifications/dmSetting/:id/Sms';
export const NOTIFICATION_MONITOR_WEB_HOOK_DM_SETTINGS =
  '/notifications/dmSetting/:id/WebHook';
export const NOTIFICATION_MONITOR_SLACK_DM_SETTINGS =
  '/notifications/dmSetting/:id/Slack';
export const NOTIFICATION_MONITOR_TEAMS_DM_SETTINGS =
  '/notifications/dmSetting/:id/Teams';

export const NOTIFICATION_MONITOR_VOICE_DM_SETTINGS =
  '/notifications/dmSetting/:id/Voice';

export const NOTIFICATION_SETTING = '/notifications/dmSetting';

export const MULTI_FACTOR_SETUP = '/multiFactorSetup';
export const DATA_EXPORT = '/dataExport';
export const DEVICE_MONITORS_EXPORT = '/monitorsExport';

export const HOME_ROUTE = '/home';
export const HELP_PAGE = '/help';

export const WEB_HOOKS_CREATE = '/webHooks/webHooksCreate';
export const WEB_HOOKS_EDIT = '/webHooks/:id';
export const WEB_HOOKS = '/webHooks';

export const DATA_SOURCES_INTEGRATION_CREATE =
  '/dataSourcesIntegrations/integrationsCreate';
export const DATA_SOURCES_INTEGRATION_EDIT = '/dataSourcesIntegrations/:id';
export const DATA_SOURCES_INTEGRATION = '/dataSourcesIntegrations';
export const SECURITY_INTEGRATION = '/securityIntegrations';
export const SECURITY_INTEGRATION_CREATE =
  '/securityIntegrations/securityCreate';
export const SECURITY_INTEGRATION_EDIT = '/securityIntegrations/:id';
export const TENANT_MODULES = '/tenantModules';
export const TENANT_MODULES_TEMPLATE = '/tenantModulesTemplate';

export const MERAKI = '/meraki';
export const MERAKI_VIEW = '/meraki/:id';
export const SOPHOS = '/sophos';
export const SOPHOS_VIEW = '/sophos/:id';

export const DUO = '/duo';
export const MERAKI_DASHBOARD = '/meraki/dashboard/:id';
export const SOPHOS_DASHBOARD = '/sophos/dashboard';
export const DASHBOARD = '/dashboard';
export const DEMO_REGISTRATION = '/demoRegistration';
export const PSA_VALIDATION = '/psaValidation/:crmId';
export const SHARED_EMAIL = '/email/:letterId';
export const SHARED_BILLING = '/billing/public';
export const EMAIL_GROUP_DETAILS = '/notificationGroups/:id';
export const TRIGGER_CREATE = '/triggers/triggerCreate';
export const TRIGGER_UPDATE = '/triggers/:id';
export const TRIGGER = '/triggers';
export const DOCUMENTS = '/documents';
export const INCIDENTS = '/incidents/:id';
export const INCIDENT = '/incident/:id';
export const WEB_HOOKS_RESULTS = '/webHooks/:id/results';
export const WEB_HOOKS_RESULT = '/viewWebHooks/results/:id';
export const WEB_HOOKS_RESULT_UNRECOGNIZED =
  '/webHooks/results/unrecognized/:id';

export const GENERIC_SOURCES = '/genericSources';
export const EDIT_GENERIC_SOURCE = `${GENERIC_SOURCES}/:id`;
export const CREATE_GENERIC_SOURCE = `${GENERIC_SOURCES}/createBot`;
export const GENERIC_SOURCE_VIEW_LOGS = `${GENERIC_SOURCES}/:id/logs`;

export const SCHEDULE_GROUP = '/schedule/group';
export const SCHEDULE_USER = '/schedule/group/:groupId/user/:userId';
export const EDIT_SCHEDULE = '/schedule/group/:groupId/rule/:ruleId';
export const CREATE_SCHEDULE = '/schedule/group/:groupId/rule/scheduleCreate';
export const SCHEDULER_PAGE = '/schedule';

export const CHATS = '/chats';
export const ESCALATION_RULES = '/escalationRules';
export const EDIT_ESCALATION_RULES = `${ESCALATION_RULES}/:id`;
export const CREATE_ESCALATION_RULES = `${ESCALATION_RULES}/createRule`;
export const INCIDENTS_LIST = '/incidents';
export const TEMPLATES = '/alertTemplates';
export const AT_HOOKS = '/autotaskHooks';
export const CW_HOOKS = '/connectWiseHooks';
export const MAIL_BOXES = '/mailBoxes';
export const CUSTOM_TYPES = '/types';
export const USERS = '/users';
export const PSA_TICKETS_LOGS = '/psaTicketsLogs';
export const USER_ACTIVITY_LOGS = '/userActivityLogs';
export const TENANT_STATISTIC = '/tenantStatistic';
export const TENANT_SETTINGS = '/tenantSettings';
export const EMAIL_SETTINGS = '/emailSettings';
export const NOTIFICATIONS_GROUPS = '/notificationGroups';
export const COMPANY_MAPPING = '/localCompany';
export const API_KEYS = '/apiKeys';
export const PASSWORDS = '/passwords';
export const ROADMAP = '/roadmap';
export const TENANTS = '/tenants';
export const ADMIN_PARSERS = '/parsers';
export const SUBSCRIPTIONS = '/subscriptions';
export const DEMO_LINKS = '/demoReferralLinks';
export const COUPONS = '/coupons';
export const SMS_LOGS = '/smsLogs';
export const PARSING_STATUS = '/parsingStatus';
export const BILLING_LINKS = '/billingLinks';
export const CUSTOM_PALETTE = '/customPalette';
export const FTP_CONNECTIONS = '/ftpConnections';
export const CUSTOM_FIELDS = '/customFields';
export const AUTOTASK_SMS = '/autotask/sms';
export const TEST_EMAIL = '/testEmail';
export const PURCHASE_PHONE_NUMBERS = '/purchasePhoneNumbers';
export const AVAILABLE_PHONE_NUMBERS = '/availablePhoneNumbers';
export const MESSAGING_CONFIGURATION = '/messagingConfiguration';
export const MESSAGING_CHANNELS = '/messagingChannels';
export const MESSAGING_CHANNEL =
  '/messagingChannels/:configurationId/:channelId';
export const MESSAGING_TEMPLATES = '/messagingTemplates';
export const MESSAGING_DASHBOARD = '/messagingDashboard';
export const CRM_TICKET_EDIT = '/crmTicket/:crmName/:crmDefaultId/:ticketId';
export const CREATE_MESSAGING_CHANNEL = `/createMessagingChannel/:configurationId`;
export const CRM_TICKET_TIME_ENTRIES =
  '/cw/timeEntries/:crmDefaultId/:ticketId';
export const CRM_TICKET_SCHEDULE_ENTRIES =
  '/scheduleEntries/:crmDefaultId/:ticketId';
export const PSA_TICKETS = '/psaTickets';
export const AT_TICKET_TIME_ENTRIES = '/at/timeEntries/:crmDefaultId/:ticketId';
export const TEAMS_CONNECTIONS = '/teamsConnections';
export const MESSAGE_TEMPLATES = '/messageTemplates';
export const ONE_TIME_PASSWORD = '/oneTimePassword';
export const CONNECTWISE_POD = '/pod/connectwise/:crmId';
export const HALO_POD = '/pod/halo/:crmId';
export const VERIFICATION_SETTINGS = '/verificationSettings';
export const SMS_BLACK_LIST = '/smsBlackList';
export const BLOCKED_SENDERS = '/blockedSenders';
export const OPT_IN_LIST = '/optIn';
export const OPT_IN_FORM = '/optInForm';
export const PSA_CONTACTS = '/contacts';
export const FILE_UPLOAD = '/fileUpload';
export const CREATE_TICKET = '/createTicket';
export const LIVE_CHAT = '/liveChat';
export const LIVE_CHAT_CONVERSATIONS = '/liveChatConversations';
export const LIVE_CHAT_DETAILS = `${LIVE_CHAT}/:id`;
export const LIVE_CHAT_CONFIGURATIONS = '/liveChatConfigurations';
export const CHAT_BOTS = '/chatBots';
export const LIVE_CHAT_CREATE_TICKET =
  '/liveChatCreateTicket/:psaType/:psaId/:chatId';
export const CLIENT_PORTAL_CHATS = '/clientChats';
export const CLIENT_PORTAL_CONFIGS = '/clientPortalConfigurations';
export const CLIENT_PORTAL_DEFAULTS = '/clientPortalDefaults';
export const CLIENT_PORTAL_CHAT_DETAILS = `${CLIENT_PORTAL_CHATS}/:id`;
export const CLIENT_PORTAL_COMPANY_MAPPING = `${CLIENT_PORTAL_CONFIGS}/:configId/mapping`;
export const DATA_VIEW_EDIT = '/dataViews/:dataViewId';
export const DATA_VIEW_CREATE = '/createDataView';
export const ISSUE_TYPE = `/issueTypes/:id`;
export const ISSUE_TYPES = '/issueTypes';
export const ISSUE_TYPE_CREATE = '/issueTypeCreate';
export const SECURE_DATA = '/retrieve/:code';
export const USER_INVITE = '/invite';
export const INVITED_USERS = '/invitedUsers';
export const AUTOTASK_POD = '/pod/autotask/:crmId';
export const VERIFICATION_HISTORY = '/verificationHistory';
export const SMS_CAMPAIGNS = '/smsCampaigns';
export const RESET_PASSWORD = '/resetPassword';
export const SMS_CAMPAIGN_BUILDER = '/smsCampaignBuilder';
export const SECURE_DATA_SETTINGS = '/secureDataSettings';
export const PSA_TECH_VERIFICATION = '/psaTechVerification';
export const COMPANIES_VERIFICATION_SETTINGS = '/companiesVerificationSettings';
export const OPTIN_SETTINGS = '/OptInSettings';
export const SMS_GROUP_IMPORT_FROM_PSA = '/smsGroupImportFromPSA/:smsGroupId';
export const MESSAGING_NUMBERS = '/messagingNumbers';
export const AUTOTASK_POD_WIDGET = '/widget/autotask/:crmId';
export const SETUP_WIZARD = '/wizard';
export const CLIENT_PORTAL_USERS = '/clientPortalUsers';
export const ASSIGN_TICKET_TO_TECH_REQUEST = '/assignToTechRequest/:requestId';
export const EDIT_CLIENT_PORTAL_USER = '/editClientPortalUsers/:psaId/:userId';
export const DUO_LOGIN = '/duoLogin';
export const INVOICES = '/invoices';
export const EDIT_PRICE_PLANS = '/editPricePlan';
export const EDIT_USER = '/users/:id';
export const CREATE_USER = '/createUser';
export const HELPDESK_AI = '/helpdeskAI';
export const CUSTOM_AGENT_AI = '/customAgentAI';
export const AI_PRICE_PLAN = '/aiPricePlan';
export const TEAMS_BOT_CONFIGURATION = '/teamsBotConfiguration';
export const DYNAMIC_CRM_FILTERS = '/dynamic-crm-filters';
export const TWILIO_ACCOUNTS = '/twilio-accounts';
export const TWILIO_ACCOUNTS_NUMBERS = `${TWILIO_ACCOUNTS}-numbers/:id`;
export const DYNAMIC_CRM_FILTER_CREATE = `${DYNAMIC_CRM_FILTERS}/dynamic-filter-create`;
export const DYNAMIC_CRM_FILTER_EDIT = `${DYNAMIC_CRM_FILTERS}/:id`;
