export const TIME_FROM = '00:00';
export const TIME_TO = '23:59';
export const DATE_FORMAT = 'MM/DD/YYYY';
export const DEFAULT_TAB_INDEX = 8;

export const tabList = [
  'Today',
  'Yesterday',
  'Last 7 days',
  'Last 30 days',
  'This month',
  'Last month',
  'This year',
  'Last year',
  'Custom range',
];

export const popoverPosition = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
};

export const resetDateRangeValue = { from: undefined, to: undefined };
