import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import SwitchInput from '@common/FilterInputs/Switch';
import DropDown from '@components/Auth/Common/DropDown';
import { Field } from 'react-final-form';
import { getTemplateVariables } from '@components/Ticketing/Notifications/TicketSettings/helpers';
import FieldWrapper from '@common/form/FieldWrapper';
import { getCrmSources } from '@services/requests/treeView';
import {
  getGenericPipelineFields,
  getGenericPipelinesOptions,
} from './helpers';
import InputFields from './InputFields';
import FieldsBadges from './FieldsBadges';
import PipelineHelp from './PipelineHelp';
import useStyles from './styles';

const initialFields = {
  inputFields: [],
  outputFields: [],
};

const GenericPipelines = ({
  form,
  setInputFields,
  dataViewId,
  checkboxId = 'useGenericPipelines',
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [pipelineOptions, setPipelineOptions] = useState([]);
  const [fields, setFields] = useState(initialFields);
  const [templateVariables, setTemplateVariables] = useState([]);
  const [crmVariables, setCrmVariables] = useState([]);

  useEffect(() => {
    getGenericPipelinesOptions()
      .then(setPipelineOptions)
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }));
  }, []);

  useEffect(() => {
    getCrmSources()
      .then(setCrmVariables)
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }));
  }, []);

  useEffect(() => {
    if (dataViewId) {
      getTemplateVariables(dataViewId, true)
        .then(setTemplateVariables)
        .catch(e => enqueueSnackbar(e.message, { variant: 'error' }));
    }
  }, [dataViewId]);

  useEffect(() => {
    if (form.getFieldState('pipelineId')?.value) {
      getGenericPipelineFields(form.getFieldState('pipelineId')?.value)
        .then(d => {
          setFields(d);
          setInputFields(d.inputFields);
        })
        .catch(e => enqueueSnackbar(e.message, { variant: 'error' }));
    }
  }, [form.getFieldState('pipelineId')?.value]);

  return (
    <>
      <FieldWrapper
        label="Enable"
        labelSize={1}
        content={
          <Field
            id={checkboxId}
            name={checkboxId}
            type="checkbox"
            component={SwitchInput}
          />
        }
      />
      {form.getFieldState(checkboxId)?.value && (
        <>
          <div className={classes.displayFlex}>
            <Field
              id="pipelineId"
              name="pipelineId"
              component={DropDown}
              showLabel
              options={pipelineOptions}
              label="Post-processing steps"
            />
            <PipelineHelp form={form} pipelineOptions={pipelineOptions} />
          </div>
          <FieldsBadges input={templateVariables} selector={i => i.label} />
          <InputFields
            inputFields={fields.inputFields}
            form={form}
            inputOptions={templateVariables}
            crmVariables={crmVariables}
          />
          {/* <FieldsBadges input={fields.outputFields} /> */}
        </>
      )}
    </>
  );
};

GenericPipelines.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  setInputFields: PropTypes.func.isRequired,
  dataViewId: PropTypes.number,
};

export default GenericPipelines;
