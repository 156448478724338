import { createAsyncThunk } from '@reduxjs/toolkit';
import { TWILIO_ACCOUNTS } from '@store/slices/resources';
import { editTwilioAccount } from '@services/requests/twilioAccounts';

export const ThunkEditTwilioAccount = createAsyncThunk(
  `${TWILIO_ACCOUNTS}/editTwilioAccount `,
  payload => editTwilioAccount(payload),
);

export default {};
