import { createAsyncThunk } from '@reduxjs/toolkit';
import { TWILIO_ACCOUNTS } from '@store/slices/resources';
import { getTwilioAccounts } from '@services/requests/twilioAccounts';

export const ThunkGetTwilioAccounts = createAsyncThunk(
  `${TWILIO_ACCOUNTS}/getTwilioAccounts `,
  () => getTwilioAccounts(),
);

export default {};
