import React, { useMemo } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Tooltip } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

import getFormattedDate from '@utils/getFormattedDate';
import { getPricePlanCount } from '@ui/pages/billing/Billing/helpers';
import { EDIT_PRICE_PLANS } from '@constants/routes';

import {
  BillingDataSelector,
  BillingEmailSelector,
  DiscountDataSelector,
  LastInvoicesSelector,
  MinTechCountSelectorSelector,
  PricePlansSelector,
  TechCountSelector,
} from '@store/slices/billing';

import ListItem from '@ui/pages/billing/Billing/components/ListItem';

import useStyles from '../styles';

const SubscriptionDetails = () => {
  const { push } = useHistory();
  const classes = useStyles();

  const billingData = useSelector(BillingDataSelector);
  const pricePlans = useSelector(PricePlansSelector);
  const techCount = useSelector(TechCountSelector);
  const billingEmail = useSelector(BillingEmailSelector);
  const invoices = useSelector(LastInvoicesSelector);
  const minTechCount = useSelector(MinTechCountSelectorSelector);
  const discountData = useSelector(DiscountDataSelector);

  const status = useMemo(() => {
    if (!billingData || billingData.isSubscriptionEnabled === undefined)
      return { label: 'Error during getting info', color: 'red' };

    if (!billingData.isSubscriptionEnabled) {
      if (billingData.status && billingData.status !== 'active') {
        return { label: billingData.status, color: 'red' };
      }
      return { label: 'Canceled', color: 'red' };
    }

    if (billingData.isDemoSubscription) return { label: 'Demo', color: 'blue' };
    if (billingData.isSubscriptionObsolete)
      return { label: 'Obsolete', color: 'orange' };

    if (billingData.isSubscriptionEnabled)
      return { label: 'Active', color: 'green' };

    return '';
  }, [billingData]);

  const priceName = useMemo(() => {
    if (pricePlans.length === 0) return '';
    const selectedPlan = pricePlans.find(p => p.id === billingData.id);
    return selectedPlan ? selectedPlan.name : '';
  }, [billingData.id, pricePlans]);

  const nextPaymentDate = useMemo(() => {
    if (invoices.length) {
      const lastInvoiceDate = invoices[0].created;
      const date = moment(lastInvoiceDate)
        .add(1, 'month')
        .toISOString();
      return getFormattedDate(date);
    }
    return undefined;
  }, [invoices]);

  return (
    <div style={{ width: '100%' }}>
      <div className={classes.header}>
        Subscription Details
        <Tooltip title="Edit">
          <Edit
            onClick={() => {
              push(EDIT_PRICE_PLANS);
            }}
          />
        </Tooltip>
      </div>

      <div className={classes.list} style={{ margin: '20px 0px' }}>
        <ListItem text={status?.label} title="Status:" color={status.color} />
        {billingData?.name && (
          <ListItem text={billingData.name} title="Selected Plan:" />
        )}
        {billingData?.basePriceCents && (
          <ListItem
            title="Base Price:"
            text={`$${billingData?.basePriceCents /
              100} / month per ${minTechCount} techs`}
          />
        )}
        {billingData?.pricePerTechCents && (
          <ListItem
            title="Price per additional tech:"
            text={`$${billingData.pricePerTechCents / 100}`}
          />
        )}
        <ListItem text={techCount} title="Technician Licences:" />
        {billingEmail && (
          <ListItem text={billingEmail} title="Billing Email:" />
        )}
      </div>

      <div className={classes.list} style={{ marginTop: '45px' }}>
        {discountData?.data && (
          <ListItem text={discountData?.data} title="Discount:" />
        )}

        {discountData?.data && (
          <ListItem
            text={getPricePlanCount(billingData, techCount, minTechCount)}
            title="Base Price:"
          />
        )}

        {billingData.basePriceCents > 0 && !billingData.isDemoSubscription && (
          <ListItem
            text={getPricePlanCount(
              billingData,
              techCount,
              minTechCount,
              discountData,
            )}
            title="Total Price:"
          />
        )}
        {billingData.basePriceCents > 0 && nextPaymentDate && (
          <ListItem text={nextPaymentDate} title="Next Payment Date:" />
        )}
      </div>
    </div>
  );
};

export default SubscriptionDetails;
