import React, { useState } from 'react';

import ReusableButton from '@ui/components/common/Button/Button';
import MessageTemplateDialog from '../dialogs/MessageTemplateDialog';

import useStyles from '../styles';

const MessageTemplatesTableActions = () => {
  const [openTemplateDialog, setOpenTemplateDialog] = useState(false);

  const classes = useStyles();
  return (
    <div className={classes.templatesTableActions}>
      <ReusableButton
        label="Add Template"
        onClick={() => {
          setOpenTemplateDialog(true);
        }}
      />

      {openTemplateDialog && (
        <MessageTemplateDialog
          open={openTemplateDialog}
          setOpen={setOpenTemplateDialog}
        />
      )}
    </div>
  );
};

MessageTemplatesTableActions.propTypes = {};

export default MessageTemplatesTableActions;
