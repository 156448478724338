import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import useStyles from '../styles';

const Section = ({ title, children }) => {
  const classes = useStyles();

  return (
    <div className={classes.section}>
      <Typography className={classes.title}>{title}</Typography>
      {children}
    </div>
  );
};

Section.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

export default Section;
