import crmSources from '@constants/crmSources';
import { getCrmLabel } from '../../helpers';

const CWInitialValues = (data, crmType) => {
  return {
    name: data ? data.name : getCrmLabel(crmType),
    uri: data ? data.uri : null,
    publicKey: data ? data.publicKey : null,
    privateKey: data ? data.privateKey : null,
    companyId: data ? data.companyId : null,
    enableAutomaticSync: data?.enableAutomaticSync ?? false,
  };
};

const ATInitialValues = (data, crmType) => {
  return {
    name: data ? data.name : getCrmLabel(crmType),
    publicKey: data ? data.publicKey : null,
    privateKey: data ? data.privateKey : null,
    serverRegion: data ? data.serverRegion : null,
    enableAutomaticSync: data?.enableAutomaticSync || false,
  };
};

const ServiceNowInitialValues = (data, crmType) => {
  return {
    name: data ? data.name : getCrmLabel(crmType),
    publicKey: data ? data.publicKey : null,
    privateKey: data ? data.privateKey : null,
    companyQuery: data ? data.companyQuery : null,
    companyTableSource: data ? data.companyTableSource : null,
  };
};

const KaseyaInitialValues = (data, crmType) => {
  return {
    name: data ? data.name : getCrmLabel(crmType),
    publicKey: data ? data.publicKey : null,
    privateKey: data ? data.privateKey : null,
    companyId: data ? data.companyId : null,
    uri: data ? data.uri : null,
  };
};

const SyncroMspInitialValues = (data, crmType) => {
  return {
    name: data ? data.name : getCrmLabel(crmType),
    uri: data ? data.uri : null,
    privateKey: data ? data.privateKey : null,
    enableAutomaticSync: data?.enableAutomaticSync || false,
  };
};

const HaloInitialValues = (data, crmType) => ({
  name: data ? data.name : getCrmLabel(crmType),
  publicKey: data ? data.publicKey : null,
  privateKey: data ? data.privateKey : null,
  uri: data ? data.uri : null,
  enableAutomaticSync: data?.enableAutomaticSync ?? false,
});

const ZendeskInitialValues = (data, crmType) => ({
  name: data ? data.name : getCrmLabel(crmType),
  publicKey: data ? data.publicKey : null,
  privateKey: data ? data.privateKey : null,
  uri: data ? data.uri : null,
});

const SuperOpsInitialValues = (data, crmType) => ({
  name: data ? data.name : getCrmLabel(crmType),
  apiToken: data ? data.apiToken : null,
  companyId: data ? data.companyId : null,
});

const microsoft365InitialValues = (data, crmType) => ({
  name: data ? data.name : getCrmLabel(crmType),
  publicKey: data ? data.publicKey : null,
  companyId: data ? data.companyId : null,
});

const internalIntegrationValues = (data, crmType) => ({
  name: data ? data.name : getCrmLabel(crmType),
});

const getInitialValues = (data, crmType) => {
  switch (crmType) {
    case crmSources.ConnectWise.name:
      return CWInitialValues(data, crmType);
    case crmSources.Autotask.name:
      return ATInitialValues(data, crmType);
    case crmSources.ServiceNow.name:
      return ServiceNowInitialValues(data, crmType);
    case crmSources.Kaseya.name:
      return KaseyaInitialValues(data, crmType);
    case crmSources.SyncroMsp.name:
      return SyncroMspInitialValues(data, crmType);
    case crmSources.Halo.name:
      return HaloInitialValues(data, crmType);
    case crmSources.Zendesk.name:
      return ZendeskInitialValues(data, crmType);
    case crmSources.SuperOps.name:
      return SuperOpsInitialValues(data, crmType);
    case crmSources.Microsoft.name:
      return microsoft365InitialValues(data, crmType);
    case crmSources.ImportIntegration.name:
      return internalIntegrationValues(data, crmType);
    default:
      return null;
  }
};

export default getInitialValues;
