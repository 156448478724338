import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import getFormattedDate from '@utils/getFormattedDate';

import ImportErrorRecord from './ImportErrorRecord';

import useStyles from '../styles';

const successColor = '#67AD5B';
const failedColor = '#f00016';

const ImportStatisticItem = ({ label, value }) => {
  const { statisticItem, flexOne } = useStyles();
  return (
    <div className={statisticItem}>
      <div className={flexOne}>{label}</div>
      <div className={flexOne}>{value}</div>
    </div>
  );
};

ImportStatisticItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};

const ImportContactsStatistic = ({ statistic }) => {
  const { colorBlock, textCenter, minDialogWidth, errorBox } = useStyles({
    color: statistic.isSuccess ? successColor : failedColor,
  });
  const {
    fileName,
    dateTime,
    isSuccess,
    processedEntities,
    created,
    updated,
    deleted,
    skipped,
    unprocessed,
    withErrors,
    errors,
  } = statistic;

  return (
    <div className={minDialogWidth}>
      <div className={textCenter}>{fileName}</div>
      <div className={textCenter}>{getFormattedDate(dateTime)}</div>
      <div className={cx(colorBlock, textCenter)}>
        {isSuccess ? 'SUCCESS' : 'FAILED'}
      </div>
      <hr />
      <ImportStatisticItem label="Processed" value={processedEntities} />
      <ImportStatisticItem label="Created" value={created} />
      <ImportStatisticItem label="Updated" value={updated} />
      <ImportStatisticItem label="Deleted" value={deleted} />
      <ImportStatisticItem label="Skipped" value={skipped} />
      <ImportStatisticItem label="Unprocessed" value={unprocessed} />
      <ImportStatisticItem label="With errors" value={withErrors} />
      {errors && (
        <>
          <hr />
          <div className={errorBox}>
            {errors.map(error => (
              <ImportErrorRecord key={error} error={error} />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

ImportContactsStatistic.propTypes = {
  statistic: PropTypes.shape({
    fileName: PropTypes.string.isRequired,
    dateTime: PropTypes.string.isRequired,
    isSuccess: PropTypes.bool.isRequired,
    processedEntities: PropTypes.number.isRequired,
    created: PropTypes.number.isRequired,
    updated: PropTypes.number.isRequired,
    deleted: PropTypes.number.isRequired,
    skipped: PropTypes.number.isRequired,
    unprocessed: PropTypes.number.isRequired,
    withErrors: PropTypes.number.isRequired,
    errors: PropTypes.string,
  }),
};

export default ImportContactsStatistic;
