import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  dateRangePicker: {
    width: '100%',
  },
  dayPicker: { display: 'flex', flexDirection: 'column' },
  root: {
    paddingTop: '16px',
  },
  tabWrapper: { width: ' 100%', display: 'flex' },
  date: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  calendar: {
    '& .DayPicker-Body': {
      display: 'flex',
      flexDirection: 'column',
      height: '235px',
    },
    '& .DayPicker-Weekdays': {
      display: 'flex',
    },
    '& .DayPicker-WeekdaysRow': {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
    },
    '& .DayPicker-Day': {
      width: '25px',
      height: '25px',
    },
  },
  tabs: {
    height: '370px',
    borderLeft: '1px solid #F2F2F2',
    '& .MuiTab-root': {
      maxHeight: '30px',
    },
    '& .Mui-selected': {
      '& span': {
        background: '#F0F8FF',
      },
    },
    '& .MuiButtonBase-root': {
      display: 'flex',
      alignItems: 'center',
      width: 160,
      textAlign: 'left',
      maxHeight: 40,
      minHeight: 40,
      fontSize: 16,
      padding: '0 0 0 20px',
      textTransform: 'capitalize',
      fontWeight: 400,
      color: '#050121',
      zIndex: 1,
      '& span': {
        position: 'absolute',
        height: 24,
        top: '50%',
        transform: 'translate(-50%, -50%)',
        left: '50%',
        zIndex: -1,
        borderRadius: 4,
        width: '85%',
      },
    },
  },
  input: {
    '& input': {
      cursor: 'pointer',
    },
    '& svg': {
      cursor: 'pointer',
      color: '#828282',
    },
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 8,
    padding: '16px 24px 16px',
  },
  timePickerWrapper: {
    display: 'flex',
  },
  timePicker: {
    width: '50%',
    paddingLeft: '24px',
    '& input': {
      width: '70px',
      fontSize: '16px',
      fontFamily: 'Nunito sans-serif',
    },
  },
  resetButton: {
    fontSize: '20px',
    padding: 0,
    width: '20px',
    height: '20px',
    marginBottom: '4px',
    '& svg': {
      height: '18px',
      width: '18px',
    },
  },
}));

export default useStyles;
