import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { PSA_CONTACTS } from '@services/requests/requestResources';
import { ThunkGetImportIntegrationCompanies } from '@store/slices/integration/thunks';
import crmSources from '@constants/crmSources';
import { getImportIntegrationTableColumns } from '@components/PsaContacts/constants';

import Table from '@components/Table';
import PsaContactsTableBulkActions from '@components/PsaContacts/components/PsaContactsTableBulkActions';

const ImportIntegrationContacts = ({ psaId }) => {
  const dispatch = useDispatch();
  const [companies, setCompanies] = useState([]);
  const resource = useMemo(() => `${PSA_CONTACTS}/importIntegration/${psaId}`, [
    psaId,
  ]);

  const onMount = useCallback(() => {
    dispatch(ThunkGetImportIntegrationCompanies({ crmId: psaId }))
      .unwrap()
      .then(res => {
        setCompanies(res);
      });
  }, [dispatch, psaId]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <Table
      resource={resource}
      columns={getImportIntegrationTableColumns(psaId, companies)}
      bulkActions={
        <PsaContactsTableBulkActions
          psaId={psaId}
          psaName={crmSources.ImportIntegration.name}
          isClientPortalButton={false}
        />
      }
    />
  );
};

ImportIntegrationContacts.propTypes = {
  psaId: PropTypes.number.isRequired,
};

export default ImportIntegrationContacts;
