import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent } from '@material-ui/core';
import ReusableButton from '@ui/components/common/Button/Button';
import DialogTitle from '@common/Dialog/DialogTitle';

import useStyles from './styles';

const Modal = ({
  open,
  setOpen,
  isCloseButton = true,
  title,
  className,
  isCloseCross = false,
  children,
}) => {
  const classes = useStyles();

  const onClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <Dialog open={open} onClose={onClose} className={className}>
      <DialogTitle onClose={isCloseCross ? onClose : undefined}>
        {title}
      </DialogTitle>
      <DialogContent>
        <div className={classes.content}>
          {children}
          {isCloseButton && (
            <div className={classes.dialogActionsContainer}>
              <ReusableButton
                label="Close"
                onClick={() => {
                  setOpen(false);
                }}
              />
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  isCloseButton: PropTypes.bool,
  title: PropTypes.string,
  className: PropTypes.string,
  isCloseCross: PropTypes.bool,
  children: PropTypes.node,
};

export default Modal;
