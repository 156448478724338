import { createAsyncThunk } from '@reduxjs/toolkit';
import { addDynamicCrmContact } from '@services/requests/dynamicCrmContacts';
import { DYNAMIC_CRM_CONTACTS } from '@store/slices/resources';

export const ThunkAddDynamicCrmContact = createAsyncThunk(
  `${DYNAMIC_CRM_CONTACTS}/addDynamicCrmContacts`,
  payload => {
    return addDynamicCrmContact(payload);
  },
);

export default {};
