import getData from '@services/api/common/getData';
import appConfig from '@configs/appConfig';
import { integration } from '@constants/appRoutes';

export const baseFieldName = 'crmSpecificSettings.';

export const getAutotaskResources = id =>
  getData(`${appConfig.baseUrl}${integration}autotask/${id}/resources`);

export const getAutotaskRoles = id =>
  getData(`${appConfig.baseUrl}${integration}autotask/${id}/roles`);

export const getAutotaskResourceRoles = id =>
  getData(`${appConfig.baseUrl}${integration}autotask/${id}/resourceRoles`);

export const getConnectwiseMembers = (id, includeApiMember = false) =>
  getData(
    `${appConfig.baseUrl}${integration}connectwise/${id}/members?includeApiMembers=${includeApiMember}`,
  );

export const getConnectwiseCompanies = id =>
  getData(`${appConfig.baseUrl}${integration}connectwise/${id}/companies`);

export const getKaseyaAssignees = id =>
  getData(`${appConfig.baseUrl}${integration}kaseya/${id}/users`);

export const getKaseyaWorkTypes = id =>
  getData(`${appConfig.baseUrl}${integration}kaseya/${id}/workTypes`);

export const getKaseyaStatuses = id =>
  getData(`${appConfig.baseUrl}${integration}kaseya/${id}/statuses`);
