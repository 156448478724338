import appConfig from '@configs/appConfig';
import { dmNotificationSettings } from '@constants/appRoutes';
import putData from '@services/api/common/putData';
import getData from '@services/api/common/getData';
import postWithResponse from '@services/api/common/postWithResponse';
import deleteData from '@services/api/common/deleteData';

export const getAlertDescription = (
  lastN,
  type,
  value,
  notifyAboutRecovering,
) => {
  const part1 = `Whenever last  `;
  const part2 = lastN > 1 ? `${lastN} metrics in a row has` : 'metric has';
  const part3 =
    type === '0'
      ? ' not success status.'
      : ` duration greater than ${value} ms.`;
  const part4 = ' Notify when recovered.';
  const base = part1 + part2 + part3;
  return notifyAboutRecovering ? base + part4 : base;
};

export const getDmNotificationSettingsById = id =>
  getData(`${appConfig.baseUrl.concat(dmNotificationSettings)}${id}`);

export const getDeviceMonitorsNames = () =>
  getData(`${appConfig.baseUrl.concat(dmNotificationSettings)}deviceMonitors`);

export const updateDeviceMonitorDmSettings = (id, data) =>
  putData(`${appConfig.baseUrl.concat(dmNotificationSettings)}${id}`, data);

export const createDeviceMonitorDmSettings = data =>
  postWithResponse(`${appConfig.baseUrl.concat(dmNotificationSettings)}`, data);

export const deleteDeviceMonitorDmSetting = id =>
  deleteData(`${appConfig.baseUrl.concat(dmNotificationSettings)}${id}`);

export const getTemplates = () =>
  getData(`${appConfig.baseUrl}${dmNotificationSettings}getTemplateVariables`);

export const getDeviceMonitorsCompanies = () =>
  getData(`${appConfig.baseUrl}/DeviceMonitors/getCompanies`);

export const createNotificationGroup = data =>
  postWithResponse(
    `${appConfig.baseUrl}${dmNotificationSettings}groupCreate`,
    data,
  );
