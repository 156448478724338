import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import { actions } from '@store/actions';
import { MessagingConfigurationAutocompleteSelector } from '@store/slices/messaging';
import {
  ThunkGetMessagingConfigurationsAutocomplete,
  ThunkReprocessBlockedMessage,
  ThunkUpdateBlockedMessage,
} from '@store/slices/messaging/thunks';

import { SMS_BLOCKED } from '@services/requests/requestResources';

import EyeClosedIcon from '@assets/icons/eye-closed.svg';
import RefreshIcon from '@assets/icons/refreshItem.svg';
import UserIcon from '@assets/icons/user.svg';

import ActionFieldItem from '@common/ActionButtons/ActionFieldItem';
import BooleanAccessor from '@common/MuiVirtualizedTable/components/accessors/BooleanAccessor';
import DateTimeField from '@common/TableComponents/DateTimeField';
import Table from '@components/Table';
import { TableTypes } from '@components/Table/constants';
import booleanOptions from '@utils/constants/options';
import DropDown from '../Auth/Common/DropDown';
import AddContactDialog from './AddContactDialog';

import useStyles from './styles';
import Loading from '../common/Loading/Loading';

const BlockedSmsList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [openAddContactDialog, setOpenAddContactDialog] = useState(false);
  const [currentBlockedSms, setCurrentBlockedSms] = useState(null);
  const [selectedConfiguration, setSelectedConfiguration] = useState(
    localStorage.getItem('selectedMessagingConfiguration') || undefined,
  );

  const handleDropdownChange = useCallback(configurationID => {
    setSelectedConfiguration(configurationID);
    localStorage.setItem('selectedMessagingConfiguration', configurationID);
  }, []);

  const configurations = useSelector(
    MessagingConfigurationAutocompleteSelector,
  );

  const onMount = useCallback(async () => {
    await dispatch(ThunkGetMessagingConfigurationsAutocomplete());
  }, [dispatch]);

  const makeBlockedSmsInvisible = useCallback(
    async data => {
      const item = { ...data, isVisible: false };
      await dispatch(ThunkUpdateBlockedMessage(item)).then(response => {
        dispatch(actions.updateItemInList(response.payload));
      });
    },
    [dispatch],
  );

  const reprocessBlockedSms = useCallback(
    async data => {
      setLoading(true);
      await dispatch(ThunkReprocessBlockedMessage(data.id))
        .then(response => {
          dispatch(actions.updateItemInList(response.payload));
          enqueueSnackbar('SMS was reprocessed', { variant: 'success' });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [dispatch, enqueueSnackbar],
  );

  useEffect(() => {
    onMount();
  }, [onMount]);

  const columns = useMemo(
    () => [
      {
        name: 'From',
        key: 'from',
        type: TableTypes.phoneNumber,
        sortable: true,
        searchable: true,
      },
      {
        name: 'To',
        key: 'to',
        type: TableTypes.phoneNumber,
        sortable: true,
        searchable: true,
      },
      {
        name: 'Message',
        key: 'message',
      },
      {
        name: 'Contact Name',
        key: 'contactName',
      },
      {
        name: 'Is Visible',
        labelAlignment: 'center',
        key: 'isVisible',
        type: TableTypes.dropdown,
        isCustomAccessor: true,
        sortable: true,
        searchable: true,
        dropDownValues: booleanOptions,
        filterByAutocomplete: 'isVisible',
        accessor: rowData => (
          <BooleanAccessor value={rowData.isVisible} useMultiColor />
        ),
      },
      {
        name: 'Date',
        key: 'dateOfBlocking',
        sortable: true,
        isCustomAccessor: true,
        accessor: rowData => (
          <DateTimeField
            record={rowData}
            key="dateOfBlocking"
            source="dateOfBlocking"
            label="Date"
            sortable
          />
        ),
      },
      {
        name: 'Actions',
        key: TableTypes.actions,
        type: TableTypes.actions,
        maxWidth: 200,
        accessor: rowData => (
          <div className={classes.actionsContainer}>
            {rowData?.isVisible && (
              <ActionFieldItem
                handler={() => {
                  makeBlockedSmsInvisible(rowData);
                }}
                alt="Hide Blocked SMS"
                toolTip="Hide Blocked SMS"
                icon={EyeClosedIcon}
              />
            )}
            {!rowData?.isReprocessed && (
              <ActionFieldItem
                handler={() => {
                  reprocessBlockedSms(rowData);
                }}
                alt="Reprocess"
                toolTip="Reprocess"
                icon={RefreshIcon}
              />
            )}
            {!rowData?.contactId && (
              <ActionFieldItem
                icon={UserIcon}
                alt="Create Contact"
                toolTip="Create Contact"
                handler={() => {
                  setCurrentBlockedSms(rowData);
                  setOpenAddContactDialog(true);
                }}
              />
            )}
          </div>
        ),
      },
    ],
    [classes.actionsContainer, makeBlockedSmsInvisible, reprocessBlockedSms],
  );

  if (loading) {
    return <Loading />;
  }

  return (
    <div className={classes.container}>
      <div className={classes.tableActions}>
        <div className={classes.tableActionContainer}>
          <DropDown
            options={configurations}
            input={{
              onChange: e => {
                handleDropdownChange(e.target.value);
              },
              value: selectedConfiguration,
            }}
          />
        </div>
      </div>
      {selectedConfiguration && (
        <Table
          defaultFilters={{
            isVisible: true,
          }}
          columns={columns}
          defaultSort={{ fieldName: 'dateOfBlocking', order: 'DESC' }}
          resource={`${SMS_BLOCKED}?messagingConfigurationId=${selectedConfiguration}`}
        />
      )}
      {openAddContactDialog && (
        <AddContactDialog
          open={openAddContactDialog}
          setOpen={setOpenAddContactDialog}
          crmDefaultId={currentBlockedSms?.crmDefaultId}
          phoneNumber={currentBlockedSms?.from}
          blockedMessageId={currentBlockedSms?.id}
        />
      )}
    </div>
  );
};

export default BlockedSmsList;
