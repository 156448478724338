import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { Field, useForm } from 'react-final-form';
import Input from '@common/Input/Input';
import SwitchComponent from '@common/FilterInputs/Switch';

const SyncroMspForm = ({ classes, loading, useLabels }) => {
  const { getFieldState } = useForm();

  return (
    <>
      {useLabels && (
        <Typography className={classes.inputLabel}>Domain:</Typography>
      )}
      <Field
        id="uri"
        name="uri"
        styleType="main"
        fullWidth
        inputView="text"
        type="text"
        component={Input}
        classNameWrapper={classes.inputWrapper}
        placeholder="Domain"
        disabled={loading}
      />
      {useLabels && (
        <Typography className={classes.inputLabel}>Password:</Typography>
      )}
      <Field
        id="privateKey"
        name="privateKey"
        styleType="main"
        fullWidth
        inputView="text"
        type="password"
        component={Input}
        classNameWrapper={classes.inputWrapper}
        placeholder="Password"
        disabled={loading}
      />

      <div className={classes.checkbox}>
        <Typography className={classes.inputLabel}>
          Store Contacts in MSP Process
        </Typography>
        <Field
          id="enableAutomaticSync"
          name="enableAutomaticSync"
          styleType="main"
          fullWidth
          inputView="text"
          component={SwitchComponent}
          checked={getFieldState('enableAutomaticSync')?.value || false}
        />
      </div>
    </>
  );
};

SyncroMspForm.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  loading: PropTypes.bool.isRequired,
  useLabels: PropTypes.bool,
};

export default SyncroMspForm;
