import { makeStyles } from '@material-ui/core/styles/index';

const useStyles = makeStyles(() => ({
  container: {
    margin: '0 16px 0 11px',
    '& tbody': {
      maxHeight: '70vh',
    },
  },
  tableActionContainer: {
    width: '40%',
    margin: '20px 26px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    zIndex: '2',
  },
  tableActions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    margin: '10px 0px',
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  alignLeft: {
    textAlign: 'start',
  },
  contactDialogActions: {
    margin: '1rem 0px 0px 0px',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    '& > button': {
      marginLeft: '5px',
    },
  },
}));

export default useStyles;
