import React from 'react';
import { useTranslate } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { actions } from '@store/actions';
import { listSelector } from '@store/selectors';
import roles from '@constants/roles';

import Table from '@components/Table';
import { retrieveFieldsFromHeaders } from '@utils/exporter';
import { getDataFromToken } from '@utils/getDataFromToken';
import BulkAction from './BulkAction';
import { toggleEnabled, toggleGlobal } from '../../../helpers';
import StatusCircle from './StatusCircle';
import ActionField from './ActionField';

import useStyles from './styles';

const ParsersList = ({ onParserSelect, onDelete, mailBoxId }) => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { roleId } = getDataFromToken();
  const isSuperAdmin = roleId === roles.SUPER_ADMIN;

  const list = useSelector(listSelector);

  const parserResource = 'parsers';
  const getResName = resource =>
    'resources.'.concat(resource).concat('.fields.');

  const parserFieldConfig = retrieveFieldsFromHeaders(
    ['name', 'tenantName'],
    getResName(parserResource),
    translate,
  );

  const handleToggleActive = async (id, isActive) => {
    await toggleEnabled(id, mailBoxId, !isActive);
    const item = list.find(p => p.id === id);
    const newItem = {
      ...item,
      isEnabled: !isActive,
    };
    dispatch(actions.updateItemInList(newItem));
  };

  const handleToggleGlobal = async (id, isTenant) => {
    const { tenantName } = await toggleGlobal(id, isTenant);
    const item = list.find(p => p.id === id);
    const newItem = {
      ...item,
      tenantName: tenantName ?? null,
      isGlobal: !tenantName,
    };
    dispatch(actions.updateItemInList(newItem));
  };

  const queryString = `${parserResource}/mailbox/${mailBoxId}`;
  return (
    <div className={classes.tableWrapper}>
      <Table
        bulkActions={<BulkAction mailBoxId={mailBoxId} />}
        defaultSort={{ fieldName: 'name', order: 'asc' }}
        columns={[
          ...parserFieldConfig.map(({ source, label }) => ({
            sortable: true,
            searchable: true,
            name: label,
            key: source,
            minWidth: 200,
          })),
          {
            name: 'Enabled',
            sortable: true,
            sort: 'isEnabled',
            key: 'isEnabled',
            isCustomAccessor: true,
            accessor: rowData => (
              <div className={classes.center}>
                <StatusCircle
                  record={rowData}
                  label="Enabled"
                  handleClick={handleToggleActive}
                />
              </div>
            ),
            width: 125,
            minWidth: 125,
          },
          {
            name: 'Actions',
            key: 'actions',
            type: 'actions',
            width: 125,
            minWidth: 125,
            accessor: rowData => (
              <ActionField
                label="Actions"
                handleUpdateClick={onParserSelect}
                handleDeleteClick={onDelete}
                isUserAdmin={isSuperAdmin}
                handleMakeGlobal={handleToggleGlobal}
                record={rowData}
              />
            ),
          },
        ]}
        resource={queryString}
      />
    </div>
  );
};

ParsersList.propTypes = {
  onParserSelect: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  mailBoxId: PropTypes.string.isRequired,
};

export default ParsersList;
