import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { Dialog, DialogContent, Typography } from '@material-ui/core';
import Input from '@components/Auth/Common/Input';
import ReusableButton from '@common/Button/Button';
import { Field, Form } from 'react-final-form';
import { requiredValidator } from '@utils/validators';
import { createLink } from '@components/Admin/DemoReferralLinks/helpers';
import useStyles from './styles';

const CreateDialog = ({ open, onClose, onCreate }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const validate = values => {
    return {
      name: requiredValidator(values.name) ? 'Required' : undefined,
    };
  };

  const submit = values => {
    setLoading(true);
    createLink(values)
      .then(d => onCreate(d))
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }))
      .finally(() => setLoading(false));
  };

  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogContent>
        <div className={classes.textCenter}>
          <Typography className={classes.title}>
            Create new referral link
          </Typography>
          <Typography>Enter the name of the link</Typography>
          <Form
            onSubmit={submit}
            validate={validate}
            initialValues={{ name: null }}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit} noValidate>
                <Field
                  id="name"
                  name="name"
                  styleType="main"
                  inputView="text"
                  fullWidth
                  component={Input}
                  disabled={loading}
                />
                <div className={classes.marginTop}>
                  <ReusableButton
                    size="xl"
                    viewType="black"
                    type="submit"
                    disabled={loading}
                    loading={loading}
                    label="Create"
                  />
                </div>
              </form>
            )}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

CreateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
};

export default CreateDialog;
