import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CrmSourcesSelector } from '@store/slices/treeView/selectors';
import { ThunkGetCrmSources } from '@store/slices/treeView/thunks';
import { ThunkGetClientPortalRoles } from '@store/slices/clientPortalAdmin/thunks';

import DropDown from '@components/Auth/Common/DropDown';
import Table from '@components/Table';
import ClientPortalUserActions from '@ui/pages/clientPortal/clientPortalUsers/ClientPortalUserActions';

import crmSources from '@constants/crmSources';
import { TableTypes } from '@components/Table/constants';
import userRoles from '@constants/roles';

import { getDataFromToken } from '@utils/getDataFromToken';

import useStyles from './styles';

const allowedPsaTypes = [
  crmSources.ConnectWise.name,
  crmSources.Autotask.name,
  crmSources.Halo.name,
  crmSources.SuperOps.name,
];

const ClientPortalUsers = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [selectedConfig, setSelectedConfig] = useState(undefined);
  const [roles, setRoles] = useState([]);

  const { roleId } = getDataFromToken();
  const allowEdit = roleId !== userRoles.READ_ONLY;

  const configurationOptions = useSelector(CrmSourcesSelector);

  useEffect(() => {
    if (!!configurationOptions && configurationOptions.length === 1) {
      setSelectedConfig(configurationOptions[0].value);
    }
  }, [configurationOptions]);

  const onMount = useCallback(async () => {
    await dispatch(ThunkGetCrmSources());
    dispatch(ThunkGetClientPortalRoles())
      .unwrap()
      .then(res => {
        setRoles(res);
      });
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  const getColumns = useCallback(
    () => [
      {
        name: 'Login',
        key: 'login',
        sortable: true,
        searchable: true,
      },
      {
        name: 'Role',
        key: 'role',
        sortable: true,
        searchable: true,
        type: TableTypes.dropdown,
        dropDownValues: roles,
        filterByAutocomplete: 'role',
      },
    ],
    [roles],
  );

  const columns = useMemo(
    () =>
      allowEdit
        ? getColumns().concat([
            {
              name: 'Actions',
              key: TableTypes.actions,
              type: TableTypes.actions,
              width: 150,
              accessor: item => (
                <ClientPortalUserActions id={item.id} psaId={selectedConfig} />
              ),
            },
          ])
        : getColumns(),
    [allowEdit, selectedConfig],
  );

  return (
    <div className={classes.container}>
      {!selectedConfig && (
        <div className={classes.selectLanguage}>Select PSA</div>
      )}
      <div className={classes.tableActions}>
        <DropDown
          options={configurationOptions.filter(p =>
            allowedPsaTypes.includes(p.crmType),
          )}
          input={{
            onChange: e => setSelectedConfig(e.target.value),
            value: +selectedConfig,
          }}
          labelName="name"
          valueName="id"
          classNameWrapper={classes.configSelector}
        />
      </div>
      {selectedConfig && (
        <div className={classes.tableContainer}>
          <Table
            defaultSort={{ fieldName: 'login', order: 'ASC' }}
            actions={null}
            columns={columns}
            resource={`ClientPortalUsers/crm/${selectedConfig}`}
          />
        </div>
      )}
    </div>
  );
};

export default ClientPortalUsers;
