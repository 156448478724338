import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  filterContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '16px',
    '& > div': {
      width: 'calc(25% - 8px)',
      '& .MuiAutocomplete-root': {
        margin: 0,
      },
    },
  },
  container: {
    margin: '0 16px',
    '& table': {
      maxHeight: '80vh',
      gridTemplateRows: '1fr 0px',
    },
  },
}));

export default useStyles;
