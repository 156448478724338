import React, { useState, memo } from 'react';
import { useDispatch } from 'react-redux';
import { Field, Form } from 'react-final-form';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';

import Input from '@components/Auth/Common/Input';
import FieldWrapper from '@common/form/FieldWrapper';
import ReusableButton from '@common/Button/Button';
import Modal from '@ui/common/Modal/Modal';

import {
  ThunkCreateTwilioAccount,
  ThunkEditTwilioAccount,
} from '@store/slices/twilioAccounts/thunks';

import { testCheckTwilioAccounts } from '@services/requests/twilioAccounts';
import { validate } from '../helpers';

import useStyles from '../styles';

const TwilioAccountForm = ({ item, setModalData }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [processing, setProcessing] = useState(false);

  const isEditMode = !!item.id;

  const submit = values => {
    setProcessing(true);
    const currentThunk = isEditMode
      ? ThunkEditTwilioAccount
      : ThunkCreateTwilioAccount;
    dispatch(currentThunk(values)).then(() => {
      setModalData(null);
      enqueueSnackbar(`Successfully ${isEditMode ? 'Updated' : 'Created'}`, {
        variant: 'success',
      });
    });
  };

  return (
    <Modal
      open
      setOpen={() => setModalData(null)}
      title={`${isEditMode ? 'Edit' : 'Create'} Twilio Account`}
      isCloseButton={false}
    >
      <Form
        onSubmit={submit}
        initialValues={item}
        validate={validate}
        render={({ handleSubmit, values, form, errors }) => (
          <form onSubmit={handleSubmit} className={classes.form}>
            <FieldWrapper
              label="Name"
              labelSize={12}
              contentSize={12}
              showLabel
              isRequired
              content={
                <Field
                  name="name"
                  id="name"
                  render={Input}
                  disabled={processing}
                />
              }
            />
            <FieldWrapper
              label="Account SID"
              labelSize={12}
              contentSize={12}
              showLabel
              isRequired
              content={
                <Field
                  name="accountSid"
                  id="accountSid"
                  render={Input}
                  disabled={processing}
                />
              }
            />
            <FieldWrapper
              label="Auth Token"
              labelSize={12}
              contentSize={12}
              showLabel
              isRequired
              content={
                <Field
                  name="authToken"
                  id="authToken"
                  render={Input}
                  disabled={processing}
                  className={classes.secretField}
                />
              }
            />
            <div className={classes.dialogActionsContainer}>
              <ReusableButton
                label="Close"
                onClick={() => {
                  setModalData(null);
                }}
                disabled={processing}
              />
              <ReusableButton
                label="Test"
                onClick={() => {
                  form.blur('accountSid');
                  form.blur('authToken');
                  const isValidFilter = !(
                    errors.authToken || errors.accountSid
                  );

                  if (isValidFilter) {
                    setProcessing(true);
                    testCheckTwilioAccounts({
                      accountSid: values.accountSid,
                      authToken: values.authToken,
                    })
                      .then(() => {
                        enqueueSnackbar('Authorized', { variant: 'success' });
                      })
                      .catch(error => {
                        enqueueSnackbar(error.message, { variant: 'error' });
                      })
                      .finally(() => setProcessing(false));
                  }
                }}
                disabled={
                  processing ||
                  !values.authToken ||
                  values.authToken === '******'
                }
              />
              <ReusableButton
                label="Submit"
                type="submit"
                disabled={processing}
                viewType="blue"
              />
            </div>
          </form>
        )}
      />
    </Modal>
  );
};

TwilioAccountForm.propTypes = {
  setModalData: PropTypes.func.isRequired,
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default memo(TwilioAccountForm);
