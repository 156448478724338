import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: '0.9375rem 0.25rem',
    },
    width: '19.375rem',
    padding: '1rem 0',
    margin: '0.9375rem 1.25rem',
    borderRadius: '0.8125rem',
    background: theme.palette.mailBoxCard.background,
    fontStyle: 'normal',
    boxShadow: '0 0 0.6875rem 0.25rem rgb(0 0 0 / 19%)',
    maxHeight: '400px',
  },
  titleWrapper: {
    padding: '0 0.625rem 0.9375rem',
    borderBottom: `0.0625rem solid ${theme.palette.info.light}`,
  },
  createTitle: {
    color: 'white',
    fontSize: '1rem',
    fontWeight: 'bold',
    width: '100%',
    height: '3.125rem',
    background: 'linear-gradient(180deg, #3A3E45 0%, #000000 100%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '0.8125rem 0.8125rem 0 0',
  },
  title: {
    color: theme.palette.mailBoxCard.text,
    fontSize: '1rem',
    fontWeight: 'bold',
  },
  createDesc: {
    fontSize: '1rem',
    color: 'white',
    padding: '0.9375rem 1.25rem 1.1875rem',
    backgroundColor: '#6A6A6A',
  },
  createForm: {
    width: '100%',
    marginTop: '3.5rem',
    padding: '0 0.9375rem',
  },
  emailWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    padding: '0.3125rem 0.3125rem 0.3125rem 0.625rem',
    backgroundColor: 'rgba(75, 80, 88, 0.1)',
    borderRadius: '0.3125rem',
    marginTop: '0.9375rem',
    '& svg': {
      '& path': {
        stroke: theme.palette.mailBoxCard.copyIcon,
      },
    },
  },
  titleMail: {
    msTextOverflow: 'ellipsis',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontSize: '0.875rem',
    fontWeight: 'bold',
    color: theme.palette.mailBoxCard.text,
  },
  buttonCopy: {
    width: '1.9375rem',
    height: '1.75rem',
    backgroundColor: theme.palette.mailBoxCard.icon,
    borderRadius: '0.3125rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  body: {
    padding: '0.9375rem 1rem 0',
    margin: '15px 0px',
    '& svg': {
      '& path': {
        stroke: theme.palette.mailBoxCard.icon,
      },
    },
  },
  iconsWrapper: {
    marginTop: '0.5rem',
    marginLeft: '2.1rem',
  },
  createCardBody: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: '0 10px',
  },
  miniItem: {
    marginTop: '3px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontSize: '13px',
  },
  miniItemContent: {
    color: theme.palette.mailBoxCard.text,
    marginLeft: '10px',
  },
  link: {
    cursor: 'pointer',
  },
  emailIcon: {
    height: '22px',
    width: '22px',
  },
  button: {
    marginTop: '3.75rem',
    backgroundColor: theme.palette.mailBoxCard.icon,
  },
  field: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'stretch',
    color: theme.palette.mailBoxCard.text,
    width: '97%',
    marginTop: '0.875rem',

    '&:hover': {
      backgroundColor: theme.palette.info.light,
    },
  },
  label: {
    width: '0.9375rem',
    height: '0.75rem',
    textAlign: 'right',
    paddingRight: '10px',
    alignSelf: 'baseline',
  },
  component: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    marginLeft: '10px',
  },
  hyperlink: {
    color: '#2853ff',
    '&:hover': {
      color: '#001597',
    },
  },
  zeroPadding: {
    padding: '0',
  },
}));

export default useStyles;
