import { createAsyncThunk } from '@reduxjs/toolkit';
import { MESSAGE_TEMPLATES } from '@store/slices/resources';
import { getMessagingConversations } from '@services/requests/smsConversations';

export const ThunkGetMessageTemplates = createAsyncThunk(
  `${MESSAGE_TEMPLATES}/getMessageTemplates`,
  async (channelId = null) => getMessagingConversations(channelId),
);

export default {};
