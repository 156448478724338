import React, { memo } from 'react';
import PropTypes from 'prop-types';

import ActionFieldItem from '@common/ActionButtons/ActionFieldItem';

import DeleteIcon from '@assets/icons/delete.svg';
import EditIcon from '@assets/icons/edit.svg';

import useStyles from '../styles';

const Actions = ({ row, setRemovalId, setItem }) => {
  const classes = useStyles();

  return (
    <div className={classes.alignCenter}>
      <ActionFieldItem
        handler={() => {
          setItem({
            id: row.id,
            tenantId: row.tenantId,
            name: row.name,
            isVisible: row.isVisible,
          });
        }}
        toolTip="Edit"
        icon={EditIcon}
        id={`aiAssist-${row.id}-edit`}
      />

      <ActionFieldItem
        handler={() => {
          setRemovalId(row.id);
        }}
        toolTip="Delete"
        icon={DeleteIcon}
        id={`aiAssist-${row.id}-delete`}
      />
    </div>
  );
};

Actions.propTypes = {
  setItem: PropTypes.func.isRequired,
  setRemovalId: PropTypes.func.isRequired,
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
    tenantId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    isVisible: PropTypes.string.isRequired,
  }).isRequired,
};

export default memo(Actions);
