import { makeRequest } from '@services/requests/makeRequest';
import { OPTIN_SETTINGS } from '@services/requests/requestResources';

export const getOptInSettings = () => makeRequest('GET', `${OPTIN_SETTINGS}`);

export const getOptInPhoneTypes = crmId =>
  makeRequest('GET', `${OPTIN_SETTINGS}/${crmId}/phoneTypes`);

export const saveOptInSettings = payload =>
  makeRequest('POST', `${OPTIN_SETTINGS}`, payload);

export const removeOptInLogo = () =>
  makeRequest('POST', `${OPTIN_SETTINGS}/remove-logo`);
