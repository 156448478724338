import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  colorBlock: {
    fontWeight: 'bold',
    color: props => props.color,
    padding: '5px 10px',
    borderRadius: '0.5rem',
  },
}));

export default useStyles;
