import { createAsyncThunk } from '@reduxjs/toolkit';
import { OPT_IN } from '@store/slices/resources';
import { getContactInformationByCode } from '@services/requests/optIn';

export const ThunkGetContactInformationByCode = createAsyncThunk(
  `${OPT_IN}/getContactInformationByCode`,
  async ({ key }) => {
    const res = await getContactInformationByCode(key);
    return res;
  },
);

export default {};
