import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dialog, DialogContent, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import { ThunkGetMsAuthSetuperLink } from '@store/slices/securityIntegrations/thunks';

import DialogTitle from '@common/Dialog/DialogTitle';
import ReusableButton from '@common/Button/Button';
import M365GDAPSetupDialog from './M365GDAPSetupDialog';

import useStyles from '../styles';

const M365Dialog = ({ onClose, open }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [processing, setProcessing] = useState(false);
  const [openGDAPDialog, setOpenGDAPDialog] = useState(false);

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Connect MSP Process to Microsoft 365</DialogTitle>
        <DialogContent className={classes.m365Dialog}>
          <Typography>
            Hey there! Ready to start using Microsoft Authenticator to verify
            your client&#39;s identity? You can either connect MSP Process
            directly to your client&#39;s M365 tenant, or connect us to your
            CSP-model M365 tenant. Inside your CSP-model M365 tenant, we&#39;ll
            use GDAP relationships to make the deployment scalable and easy. For
            more details, check out our Knowledge Base articles:
          </Typography>
          <ul>
            <li>
              <a
                href="https://kb.mspprocess.com/details/144/11"
                target="_blank"
                rel="noreferrer"
              >
                Deploying Microsoft Authenticator via GDAP for CSP M365 Tenants
              </a>
            </li>
            <li>
              <a
                href="https://kb.mspprocess.com/details/109/11"
                target="_blank"
                rel="noreferrer"
              >
                Microsoft Authenticator: Deploying to a Specific M365 Tenant
              </a>
            </li>
          </ul>
          <div className={classes.buttonsContainer}>
            <ReusableButton
              onClick={() => {
                setProcessing(true);
                dispatch(ThunkGetMsAuthSetuperLink())
                  .unwrap()
                  .then(res => {
                    if (res?.data) {
                      window.location.href = res?.data;
                    }
                    setProcessing(false);
                  });
              }}
              disabled={processing}
              viewType="blue"
            >
              Directly to one M365 Tenant
            </ReusableButton>
            <ReusableButton
              onClick={() => {
                setOpenGDAPDialog(true);
                onClose();
              }}
              disabled={processing}
              viewType="blue"
            >
              CSP-model M365 Tenant
            </ReusableButton>
          </div>
        </DialogContent>
      </Dialog>
      {openGDAPDialog && (
        <M365GDAPSetupDialog
          onClose={() => {
            setOpenGDAPDialog(false);
          }}
        />
      )}
    </>
  );
};

M365Dialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default M365Dialog;
