import React from 'react';
import PropTypes from 'prop-types';

import {
  NOTIFICATIONS_DATA,
  NOTIFICATIONS_DATA_MONITOR,
} from '@constants/routes';

import TabPanel from '@common/TabPanel/TabPanel';
import DmSettings from '@components/Ticketing/Notifications/DmSettings/DmSettings';
import useSearch from '@components/Ticketing/Notifications/hooks/useSearch';
import NotificationSettings from './TicketSettings/NotificationSettings';

import useStyles from './styles';

const Notifications = props => {
  const { match } = props;
  const classes = useStyles();

  const notificationSettingsIdFromRoute = useSearch();

  const tabsData = [
    {
      label: 'Data Notifications',
      id: NOTIFICATIONS_DATA,
      value: <NotificationSettings {...props} />,
    },
    {
      label: 'Uptime Monitor Notifications',
      id: NOTIFICATIONS_DATA_MONITOR,
      value: <DmSettings id={notificationSettingsIdFromRoute} />,
    },
  ];

  return (
    <TabPanel
      classNameWrapperTabs={classes.tabsWrapper}
      match={{ params: { name: match.path } }}
      tabs={tabsData}
      viewTypeLink="flooded"
    />
  );
};

Notifications.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

export default Notifications;
