import { createSlice } from '@reduxjs/toolkit';
import { ThunkGetOptInSmsTemplate } from '@store/slices/optIn/thunks';
import { OPT_IN } from '../resources';

const optInSlice = createSlice({
  name: OPT_IN,
  initialState: {
    smsTemplate: {},
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(
      ThunkGetOptInSmsTemplate.fulfilled,
      (state, { payload }) => {
        state.smsTemplate = payload;
      },
    );
  },
});

export default optInSlice.reducer;

export const SmsTemplateSelector = state => state.optIn.smsTemplate;
