import React from 'react';
import PropTypes from 'prop-types';

import ReusableButton from '@ui/components/common/Button/Button';

import useStyles from '../styles';

const StepperHeader = ({
  handleForward,
  handleBackward,
  activeStep,
  disableMoveForward = false,
  lastStep = false,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.stepperHeader}>
      <ReusableButton
        label="Back"
        onClick={() => {
          handleBackward();
        }}
        disabled={activeStep === 0}
      />
      <ReusableButton
        label={lastStep ? 'Finish' : 'Next'}
        onClick={handleForward}
        disabled={disableMoveForward}
      />
    </div>
  );
};

StepperHeader.propTypes = {
  handleBackward: PropTypes.func.isRequired,
  handleForward: PropTypes.func.isRequired,
  activeStep: PropTypes.number.isRequired,
  disableMoveForward: PropTypes.bool.isRequired,
  lastStep: PropTypes.bool.isRequired,
};

export default StepperHeader;
