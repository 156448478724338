import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useRedirect, useTranslate } from 'react-admin';
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';

import ReusableButton from '@common/Button/Button';
import DateTimeField from '@common/TableComponents/DateTimeField';
import Table from '@components/Table';
import StatusField from '../../Emails/StatusField';
import LettersActions from './LettersActions';

import useStyles from './styles';

const LettersList = ({ parserId, mailBoxId, goBack }) => {
  const redirect = useRedirect();
  const translate = useTranslate();

  const resource = `letters/${parserId}/parser`;
  const classes = useStyles();

  const onLetterEdit = useCallback(
    value => {
      redirect(
        `/mailbox/${mailBoxId}/letter/${value.messageId}/parser/${parserId}/updateParser`,
      );
    },
    [mailBoxId, parserId, redirect],
  );

  const columns = [
    {
      name: 'Sender',
      key: 'mailFrom',
      sort: 'mailFrom',
      minWidth: 150,
    },
    {
      name: 'Subject',
      sortable: true,
      searchable: true,
      key: 'subject',
      minWidth: 150,
    },
    {
      name: 'Status',
      key: 'status',
      isCustomAccessor: true,
      accessor: rowData => (
        <StatusField
          key="status"
          record={rowData}
          source="status"
          label="Status"
          sortable
        />
      ),
      width: 210,
      minWidth: 210,
    },
    {
      name: 'Received date',
      key: 'dateTime',
      isCustomAccessor: true,
      accessor: rowData => (
        <DateTimeField
          source="dateTime"
          label="Received date"
          record={rowData}
          sortable
        />
      ),
      width: 175,
      minWidth: 175,
    },
    {
      name: 'Last parser date',
      key: 'dateParsed',
      isCustomAccessor: true,
      accessor: rowData => (
        <DateTimeField
          record={rowData}
          source="dateParsed"
          label="Last parser date"
          sortable={false}
        />
      ),
      width: 175,
      minWidth: 175,
    },
    {
      name: 'Actions',
      key: 'actions',
      type: 'actions',
      accessor: rowData => (
        <LettersActions
          label="Actions"
          handleUpdateClick={onLetterEdit}
          record={rowData}
        />
      ),
      width: 100,
      minWidth: 100,
    },
  ];

  return (
    <div className={cx(classes.tableWrapper, classes.lettersListWrapper)}>
      <ReusableButton
        classNameWrapper={classes.buttonWrapper}
        size="md"
        onClick={goBack}
      >
        <>
          <ArrowBackIcon className={classes.iconBack} />
          {translate('resources.letter.back')}
        </>
      </ReusableButton>
      <div className={classes.header}>
        <span>
          Please, select a message to use as a template to edit parser
        </span>
      </div>
      <Table columns={columns} resource={resource} />
    </div>
  );
};

LettersList.propTypes = {
  parserId: PropTypes.number.isRequired,
  mailBoxId: PropTypes.string.isRequired,
  goBack: PropTypes.func.isRequired,
};

export default LettersList;
