import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  wrapper: {
    height: 'calc(100vh - 64px)',
    overflow: 'auto',
  },
  container: {
    margin: '20px',
    padding: '20px',
    background: 'white',
    height: '90%',
    borderRadius: '15px',
    display: 'grid',
    gridTemplateColumns: props => (props.hideInfo ? '1fr' : '1fr 1fr'),
  },
  box: {
    margin: '5px',
    padding: '15px',
    borderRadius: '15px',
    display: 'grid',
    gridTemplateRows: '50px 1fr',
    overflow: 'auto',
    height: '100%',
  },
  boxExtended: {
    margin: '5px',
    padding: '15px',
    borderRadius: '15px',
    display: 'grid',
    gridTemplateRows: '50px 50px 1fr',
    overflow: 'auto',
    height: '100%',
  },
  tripleBox: {
    margin: '5px',
    padding: '15px',
    borderRadius: '15px',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    gridTemplateRows: '50px 1fr 60px',
    overflow: 'auto',
    height: '100%',
    maxHeight: props => (props.smallerVersion ? '65vh' : '85vh'),
  },
  boxHeader: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: '800',
    fontSize: '18px',
    justifyContent: 'space-between',
  },
  itemContainer: {
    display: 'grid',
    gridTemplateColumns: 'minmax(200px, 250px) 1fr',
  },
  rightSideItem: {
    alignSelf: 'flex-end',
    textAlign: 'right',
    background: '#0066e9 !important',
    color: 'white !important',
  },
  rightSideItemHeader: {
    flexDirection: 'row !important',
  },
  historyItem: {
    margin: '5px 0px',
    padding: '5px 15px',
    background: '#fff',
    borderRadius: '15px',
    display: 'grid',
    gridTemplateRows: '30px 1fr',
    maxWidth: '70%',
    '@media (max-height: 700px)': {
      gridTemplateRows: 'unset',
    },
  },
  historyItemHeader: {
    fontWeight: '800',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '5px 0px',
    flexDirection: 'row-reverse',
  },
  historyItemList: {
    display: 'flex',
    height: '70%',
    flexDirection: 'column',
    alignItems: 'flex-start',
    background: '#dedede',
    padding: '10px',
    borderRadius: '10px',
    overflow: 'auto',
  },
  historyItemDate: { color: '#bbbbbb', fontSize: '14px' },
  column: {
    display: 'grid',
    gridTemplateRows: '2fr 2fr',
    overflow: 'hidden',
    padding: '10px',
  },
  secondColumn: {
    display: 'grid',
    gridTemplateRows: '1fr',
    overflow: 'hidden',
    padding: '10px',
  },
  chatInput: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .MuiSvgIcon-root': {
      margin: '0px 10px',
      color: '#356d9e',
      cursor: 'pointer',
    },
  },
  chatTextInput: {
    width: '100%',
    height: '90%',
    justifyContent: 'center',
    '& > div': {
      padding: '10px 10px',
    },
  },
  progressStyle: { width: '94%', marginTop: '-14px' },
  detailsActions: {
    '& > button': {
      margin: '10px 10px 0px 0px',
    },
  },
  deleteDialogActions: {
    margin: '10px 0px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& > button': {
      marginLeft: '5px',
    },
  },
  deleteDialogInput: {
    minWidth: '550px',
  },
  deleteDialogFieldLabelInner: {
    maxWidth: '100%',
  },
  deleteDialogFieldLabelOuter: {
    textAlign: 'start',
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  inputActionButton: {
    marginLeft: '10px',
  },
  ticketIdLink: {
    cursor: 'pointer',
    color: 'blue',
    textDecoration: 'underline',
  },
  channelDetailsAlignment: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  channelDetailsLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  image: {
    height: '200px',
    cursor: 'pointer',
    objectFit: 'contain',
    borderRadius: '20px',
  },
  fullSizeImage: {
    top: '50px',
    width: '100vw',
    left: '0',
    height: '100vh',
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#7d7d7dde',
    '& > img': {
      width: '75%',
      height: '75%',
      objectFit: 'contain',
    },
  },
  imageContainer: {
    background: 'transparent !important',
  },
  messagingCheckbox: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
  },
  checkBoxesGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '1rem',
  },
  dialogHeader: {
    margin: '10px 0px',
    display: 'grid',
    gridTemplateColumns: '1fr 150px',
    alignItems: 'center',
    gap: '20px',
  },
  dialogTable: {
    '& .MuiTableCell-root': {
      padding: '7px',
    },
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  dialogActionsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '20px 0px',
    '& > button': {
      marginLeft: '10px',
    },
  },
  alignLeft: {
    textAlign: 'start',
    minWidth: '200px',
  },
  twoColumns: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '30px',
  },
  subListHeader: {
    height: '45px',
    alignItems: 'center',
    display: 'flex',
    fontWeight: 800,
    justifyContent: 'space-between',
  },
  subListItem: {
    margin: '10px 0px',
  },
  subListItemDate: {
    fontSize: '15px',
    fontWeight: 400,
    color: '#777777bd',
  },
  subList: {
    height: '500px',
    overflow: 'auto',
  },
  contactDialogActions: {
    margin: '1rem 0px 0px 0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > button': {
      marginLeft: '5px',
    },
  },
  linkToHtml: {
    color: 'blue',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
}));

export default useStyles;
