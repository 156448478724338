import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { ThunkSetCommonTenantImage } from '@store/slices/common/thunks';
import { TenantSettingsSelector } from '@store/slices/common';
import { setTenantImageUrlToStorage } from '@services/api';

import UploadImageField from '@components/Profile/components/UploadImageField/UploadImageField';
import ReusableButton from '@common/Button/Button';

import useUploadImage from '@components/Profile/hook/useUploadImage';

const TenantLogo = () => {
  const [processing, setProcessing] = useState(false);

  const dispatch = useDispatch();
  const tenantSettings = useSelector(TenantSettingsSelector);

  const {
    imageUri,
    showLoader,
    startLoading,
    finishLoading,
    setImage,
    removeImage,
  } = useUploadImage(tenantSettings?.tenantImageUrl);

  const parseResult = async result => {
    const parsedResult = await result.json();
    return parsedResult?.tenantImageUrl;
  };

  const updateTenantImage = () => {
    setProcessing(true);
    dispatch(ThunkSetCommonTenantImage(imageUri))
      .then(() => {
        setTenantImageUrlToStorage(imageUri);
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  useEffect(() => {
    if (tenantSettings) setImage(tenantSettings.tenantImageUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantSettings]);

  return (
    <Accordion>
      <AccordionSummary>Upload your MSP Logo</AccordionSummary>
      <AccordionDetails>
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <UploadImageField
            title="MSP Logo"
            uploadApiUrl="/tenantSettings/tenant-image"
            imageUrl={parseResult}
            imageActions={{
              imageUri,
              showLoader,
              startLoading,
              finishLoading,
              removeImage,
            }}
            avatarVariant="rounded"
          />
          <ReusableButton
            size="xl"
            label="Update MSP Logo"
            onClick={updateTenantImage}
            viewType="blue"
            loading={processing}
            disabled={processing}
          />
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default TenantLogo;
