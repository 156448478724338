import { createAsyncThunk } from '@reduxjs/toolkit';
import { OPT_IN } from '@store/slices/resources';
import { getContactInformation } from '@services/requests/optIn';

export const ThunkGetContactInformation = createAsyncThunk(
  `${OPT_IN}/getContactInformation`,
  async ({ crmId, contactId }) => {
    const res = await getContactInformation(crmId, contactId);
    return res;
  },
);

export default {};
