import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import {
  getIntegrationBoardStatuses,
  getSyncroIntegrationCompanies,
} from '@services/requests/integration';
import { INTEGRATION } from '@services/requests/requestResources';
import { TableTypes } from '@components/Table/constants';

import { optInColumn } from '@components/PsaContacts/helpers';
import SyncroMspTicketTableActions from '@components/PsaTickets/components/SyncroMsp/SyncroMspTicketTableActions';
import Table from '@components/Table';
import Loading from '@common/Loading/Loading';
import { useCrmSyncSettings } from '../../../../../hooks/useCrmSyncSettings';

const SyncroMspTicketTable = ({
  defaultId,
  crmId,
  resource,
  tableType = 'tickets',
  defaultSortName = 'ticketNumber',
}) => {
  const {
    loading,
    crmSettings: { syncDataIntoDatabase },
  } = useCrmSyncSettings(crmId);

  const [statusOptions, setStatusOptions] = useState([]);
  const [companies, setCompanies] = useState([]);

  const handleTicketIdClick = useCallback(ticketLink => {
    window.open(ticketLink, '_blank');
  }, []);

  const onMount = useCallback(async () => {
    if (tableType === 'tickets') {
      const options = await getIntegrationBoardStatuses(crmId, defaultId);
      setStatusOptions(
        options.map(option => ({ label: option.name, value: option.name })),
      );
    }
    if (tableType === 'contacts' && !syncDataIntoDatabase) {
      const options = await getSyncroIntegrationCompanies(crmId);
      setCompanies(options);
    }
  }, [crmId, defaultId, tableType, syncDataIntoDatabase]);

  useEffect(() => {
    if (!loading) {
      onMount();
    }
  }, [loading, onMount]);

  const columns = {
    tickets: [
      {
        name: 'Ticket Number',
        key: 'ticketNumber',
        isCustomAccessor: true,
        minWidth: 175,
        width: 175,
        accessor: item => (
          <div
            style={{ color: 'blue', cursor: 'pointer' }}
            onClick={() => {
              handleTicketIdClick(item.ticketLink);
            }}
            role="presentation"
          >
            {item.ticketNumber}
          </div>
        ),
        searchable: true,
      },
      {
        name: 'Subject',
        key: 'subject',
        minWidth: 150,
      },
      {
        name: 'Status',
        key: 'status',
        searchable: true,
        type: TableTypes.dropdown,
        dropDownValues: statusOptions,
        minWidth: 150,
        width: 150,
      },
      {
        name: 'Customer',
        key: 'customer',
        minWidth: 150,
      },
      {
        name: 'Contact',
        key: 'contactName',
        isCustomAccessor: true,
        minWidth: 150,
        width: 150,
        accessor: record => (
          <>{record.contactName ? record.contactName : 'None Assigned'}</>
        ),
      },
      {
        name: 'Actions',
        key: TableTypes.actions,
        type: TableTypes.actions,
        minWidth: 100,
        width: 100,
        accessor: record => (
          <SyncroMspTicketTableActions record={record} crmId={crmId} />
        ),
      },
    ],
    contacts: [
      {
        name: 'Name',
        key: 'name',
        searchable: true,
        sortable: true,
        minWidth: 150,
      },
      {
        name: 'Customer',
        key: 'companyName',
        sortable: true,
        searchable: true,
        type: TableTypes.dropdown,
        dropDownValues: companies,
        filterByAutocomplete: 'companyId',
        optionsResource: syncDataIntoDatabase
          ? `${INTEGRATION}/v2/syncro/${crmId}/companies?_sort=label`
          : null,
        minWidth: 200,
      },
      {
        name: 'Phone',
        key: 'phone',
        searchable: true,
        type: TableTypes.phoneNumber,
        minWidth: 150,
      },
      {
        name: 'Email',
        key: 'email',
        searchable: true,
        sortable: true,
        minWidth: 150,
      },
      {
        name: 'Is Verified',
        key: 'isVerified',
        type: TableTypes.boolean,
        labelAlignment: 'center',
        minWidth: 125,
        width: 125,
      },
      { ...optInColumn(syncDataIntoDatabase) },
      {
        name: 'Client Portal',
        key: 'hasClientPortal',
        type: TableTypes.boolean,
        labelAlignment: 'center',
        minWidth: 150,
        width: 150,
      },
      {
        name: 'Actions',
        key: TableTypes.actions,
        type: TableTypes.actions,
        minWidth: 165,
        width: 165,
        accessor: record => (
          <SyncroMspTicketTableActions
            record={{ ...record, contactId: record.id }}
            crmId={crmId}
            tableType={tableType}
          />
        ),
      },
    ],
  };

  const customPaginationParams = useMemo(() => {
    if (tableType === 'tickets') {
      return {
        setCustomPaginationProps: ({ setPage, totalCount, page }) => ({
          count: totalCount,
          page: page - 1,
          onPageChange: (event, newPage) => setPage(newPage + 1),
          rowsPerPage: 1,
          rowsPerPageOptions: [],
          labelDisplayedRows: ({ page: currentPage }) =>
            `${currentPage + 1} of ${totalCount}`,
        }),
        defaultPageNumber: 1,
        setCustomPaginationParams: page => ({
          _page: page,
        }),
      };
    }
    return {};
  }, [tableType]);

  if (loading) return <Loading />;

  return (
    <Table
      resource={resource || `TicketUpdate/syncro/v2/${defaultId}/tickets`}
      columns={columns[tableType]}
      defaultSort={{ field: defaultSortName, order: 'DESC' }}
      {...customPaginationParams}
    />
  );
};

SyncroMspTicketTable.propTypes = {
  defaultId: PropTypes.number,
  crmId: PropTypes.number.isRequired,
  resource: PropTypes.string,
  tableType: PropTypes.string,
  defaultSortName: PropTypes.string,
};

export default SyncroMspTicketTable;
