import { createAsyncThunk } from '@reduxjs/toolkit';
import { editConversationTemplate } from '@services/requests/messaging';
import { MESSAGE_TEMPLATES } from '@store/slices/resources';

export const ThunkEditMessageTemplate = createAsyncThunk(
  `${MESSAGE_TEMPLATES}/editMessageTemplate`,
  async ({ id, payload }) => {
    await editConversationTemplate(id, payload);
    return payload;
  },
);

export default {};
