import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    '& tbody': {
      maxHeight: '65vh',
    },
  },
  uploaderText: {
    marginTop: theme.spacing(1),
    color: '#9D9D9DFF',
    fontWeight: 'bold',
    fontSize: 'small',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.7rem',
    },
  },
  uploadArea: {
    marginTop: '2rem',
  },
  colorBlock: {
    fontWeight: 'bold',
    color: props => props.color,
  },
  textCenter: {
    textAlign: 'center',
  },
  statisticItem: {
    display: 'flex',
    gap: '1rem',
    margin: '1rem 0',
  },
  flexOne: {
    flex: '1',
  },
  halfScreenWidth: {
    maxWidth: '50%',
    margin: '0 auto',
  },
  minDialogWidth: {
    minWidth: '360px',
  },
  downloadText: {
    fontWeight: 'bold',
    fontFamily: 'Montserrat',
  },
  downloadSubText: {
    fontWeight: 'bold',
    fontFamily: 'Montserrat',
    color: '#bbbbbb',
    fontSize: 'small',
  },
  errorBox: {
    maxHeight: '10rem',
    overflowY: 'auto',
  },
}));

export default useStyles;
