import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ThunkGetListInformation } from '@store/slices/userActivityLogs/thunks';

import getFormattedDate from '@utils/getFormattedDate';
import { TableTypes } from '@components/Table/constants';
import { USER_ACTION_LOGS } from '@services/requests/requestResources';

import ViewIcon from '@assets/icons/visibility.svg';
import Table from '@components/Table';
import ActionFieldItem from '@common/ActionButtons/ActionFieldItem';
import Loading from '@common/Loading/Loading';
import ColoredAccessor from '@common/MuiVirtualizedTable/components/accessors/ColoredAccessor';
import ViewDetails from './ViewDetails';

import useStyles from './styles';

const UserActivityLogs = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [rowData, setRowData] = useState(null);
  const [data, setData] = useState({});

  const onMount = useCallback(() => {
    dispatch(ThunkGetListInformation())
      .unwrap()
      .then(res => {
        setData(res);
      });
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  const columns = useMemo(
    () => [
      {
        name: 'Date',
        key: 'dateTime',
        type: TableTypes.date,
        sortable: true,
        isCustomAccessor: true,
        accessor: row => <div>{getFormattedDate(row.dateTime)}</div>,
        minWidth: 275,
        width: 275,
      },
      {
        name: 'Scope',
        key: 'scope',
        type: TableTypes.dropdown,
        dropDownValues: data?.scopes ?? [],
        sortable: false,
        searchable: true,
        filterByAutocomplete: 'scope',
        minWidth: 200,
      },
      {
        name: 'Operation',
        key: 'operation',
        type: TableTypes.dropdown,
        dropDownValues: data?.operations ?? [],
        sortable: false,
        searchable: true,
        filterByAutocomplete: 'operation',
        minWidth: 200,
      },
      {
        name: 'Status',
        key: 'isSuccess',
        type: TableTypes.dropdown,
        dropDownValues: [
          { label: 'Completed', value: true },
          { label: 'Error', value: false },
        ],
        filterByAutocomplete: 'isSuccess',
        sortable: false,
        searchable: true,
        minWidth: 200,
        isCustomAccessor: true,
        accessor: row => (
          <ColoredAccessor
            item={row.isSuccess ? 'Completed' : 'Error'}
            colorField={row.isSuccess}
          />
        ),
      },
      {
        name: 'Entity Name',
        key: 'entityDescription',
        minWidth: 200,
      },
      {
        name: 'User Name',
        key: 'accountName',
        type: TableTypes.dropdown,
        dropDownValues: data?.accounts ?? [],
        sortable: false,
        searchable: true,
        filterByAutocomplete: 'accountId',
        minWidth: 200,
      },
      {
        name: 'User Role',
        key: 'accountRole',
        type: TableTypes.dropdown,
        dropDownValues: data?.roles ?? [],
        sortable: false,
        searchable: true,
        filterByAutocomplete: 'accountRole',
        minWidth: 200,
      },
      {
        name: 'Actions',
        key: 'actions',
        type: TableTypes.actions,
        width: 100,
        minWidth: 100,
        accessor: row => (
          <div className={classes.center}>
            <ActionFieldItem
              handler={() => setRowData(row)}
              toolTip="View details"
              icon={ViewIcon}
            />
          </div>
        ),
      },
    ],
    [classes, data],
  );

  if (!data) return <Loading />;

  return (
    <div className={classes.container}>
      <Table
        resource={USER_ACTION_LOGS}
        columns={columns}
        defaultSort={{ fieldName: 'dateTime', order: 'DESC' }}
      />
      {rowData && <ViewDetails setRowData={setRowData} rowData={rowData} />}
    </div>
  );
};

export default UserActivityLogs;
