import React, { useEffect, useState, memo } from 'react';
import DayPickerComponent from 'react-day-picker';
import PropTypes from 'prop-types';
import moment from 'moment/moment';

import { DEFAULT_TAB_INDEX } from '@common/DateRangePicker/constants';

import 'react-day-picker/lib/style.css';
import useStyles from '../../styles';

const DayPicker = ({ range, setRange, setTabIndex }) => {
  const classes = useStyles();

  const [currentMonth, setCurrentMonth] = useState(
    range.from || moment().toDate(),
  );

  useEffect(() => {
    setCurrentMonth(range.from || new Date());
  }, [range]);

  const handleMonthChange = month => {
    setCurrentMonth(month);
  };

  const handleRangeSelect = date => {
    let newRange = { ...range };

    if (!newRange.from || (newRange.from && newRange.to)) {
      // If no start date is selected yet or both dates are selected, set the start date
      newRange.from = date;
      newRange.to = undefined;
    } else if (newRange.from && !newRange.to) {
      // If the start date is selected, set the end date
      newRange.to = date;
      if (newRange.from > newRange.to) {
        // If the start date is later than the end date, swap them
        newRange = { from: newRange.to, to: newRange.from };
      }
    }
    setRange(newRange);
    setTabIndex(DEFAULT_TAB_INDEX);
  };

  return (
    <DayPickerComponent
      onDayClick={handleRangeSelect}
      numberOfMonths={2}
      selectedDays={[range.from, { from: range.from, to: range.to }]}
      modifiers={{
        start: range.from,
        end: range.to,
      }}
      pagedNavigation
      onMonthChange={handleMonthChange}
      month={currentMonth}
      className={classes.calendar}
    />
  );
};

DayPicker.propTypes = {
  range: PropTypes.shape({
    from: PropTypes.instanceOf(Date),
    to: PropTypes.instanceOf(Date),
  }).isRequired,
  setRange: PropTypes.func.isRequired,
  setTabIndex: PropTypes.func.isRequired,
};

export default memo(DayPicker);
