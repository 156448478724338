import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  table: {
    margin: '36px auto 0',
    width: '97%',
    maxHeight: '85%',
  },
  root: {
    backgroundColor: 'transparent',
    flexWrap: 'wrap',
  },
  m365Dialog: {
    width: '550px',
  },
  dialogContent: {
    width: '420px',
  },
  buttonsContainer: {
    display: 'flex',
    gap: '16px',
    padding: '16px 0',
    justifyContent: 'flex-end',
  },
  url: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    marginTop: '16px',
  },
  secretField: { '& input': { WebkitTextSecurity: 'disc' } },
  errorMessage: {
    color: '#f44336',
  },
}));

export default useStyles;
