import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { ThunkCheckIfTenantHasPhone } from '@store/slices/phoneNumbers/thunks';

import Loading from '@ui/components/common/Loading/Loading';
import MessagingConfigurationForm from '@ui/components/Messaging/MessagingConfigurations/components/MessagingConfigurationsForm';

import useStyles from '../styles';

const MessagingSetup = ({ handleForward, phoneNumber }) => {
  const [loading, setLoading] = useState(true);
  const [showStepper, setShowStepper] = useState(false);
  const [alreadyHaveConfigurations, setAlreadyHaveConfigurations] = useState(
    false,
  );

  const classes = useStyles();
  const dispatch = useDispatch();

  const onMount = useCallback(async () => {
    setLoading(true);
    await dispatch(ThunkCheckIfTenantHasPhone())
      .unwrap()
      .then(res => {
        if (res.data) {
          setAlreadyHaveConfigurations(true);
        } else {
          setAlreadyHaveConfigurations(false);
          setShowStepper(true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (loading) return <Loading />;
  if (alreadyHaveConfigurations)
    return (
      <div className={classes.itemContainer}>
        A Messaging Configuration has already been configured, and can be
        accessed from the
        <strong> Messaging → Configurations </strong>
        page
      </div>
    );
  return (
    <div className={classes.itemContainer}>
      {showStepper ? (
        <MessagingConfigurationForm
          handleForward={handleForward}
          fromWizard
          phoneNumber={phoneNumber}
          item={
            phoneNumber
              ? {
                  mainPhoneNumber: phoneNumber.phoneNumber,
                  mainPhoneNumberId: phoneNumber.id,
                }
              : null
          }
        />
      ) : (
        <>
          You need to create Connectwise or Autotask or Halo Service Board
          integration to use this feature.
          <br />
        </>
      )}
    </div>
  );
};

MessagingSetup.propTypes = {
  handleForward: PropTypes.func,
  phoneNumber: PropTypes.shape({
    phoneNumber: PropTypes.string,
  }),
};
export default MessagingSetup;
