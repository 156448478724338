import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Menu, MenuItem } from '@material-ui/core';

import ReusableButton from '@common/Button/Button';
import iconPlus from '@assets/icons/plusWhite.svg';

import { FilterSetsAutocompleteSelector } from '@store/slices/dynamicCrmContacts';
import {
  ThunkGetFilteredData,
  ThunkGetFilterSetsAutocomplete,
} from '@store/slices/dynamicCrmContacts/thunks';

import generateUniqueId from '@utils/generateUniqueId';

import useStyles from '../styles';

const DynamicFilterMenu = ({ setRecipients }) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const dynamicCrmFilters = useSelector(FilterSetsAutocompleteSelector);

  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    dispatch(ThunkGetFilterSetsAutocomplete());
  }, [dispatch]);

  return (
    <div className={classes.FilterDropDownWrapper}>
      <ReusableButton
        onClick={event => setAnchorEl(event.currentTarget)}
        viewType="orange"
        disabled={!dynamicCrmFilters.length}
      >
        <>
          <img src={iconPlus} alt="iconPlus" />
          Apply Dynamic Filter
        </>
      </ReusableButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {dynamicCrmFilters.map(filter => (
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              dispatch(ThunkGetFilteredData(filter.value))
                .unwrap()
                .then(data => {
                  const contacts = data.map(item => ({
                    id: generateUniqueId(),
                    name: item.name,
                    phone: item.phone,
                    type: item.phoneType,
                  }));
                  setRecipients(recipients =>
                    recipients.length ? [...recipients, ...contacts] : contacts,
                  );
                });
            }}
            key={filter.value}
          >
            {filter.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

DynamicFilterMenu.propTypes = {
  setRecipients: PropTypes.func,
};

export default memo(DynamicFilterMenu);
