import { makeRequest } from '@services/requests/makeRequest';
import { HELPDESK_AI_ASSISTANTS_TYPES } from '@services/requests/requestResources';

export const helpdeskTypesToggleState = (id, state) =>
  makeRequest(
    'PUT',
    `${HELPDESK_AI_ASSISTANTS_TYPES}/${id}//makeVisible`,
    state,
    false,
    false,
  );

export const deleteHelpdeskType = id =>
  makeRequest(
    'DELETE',
    `${HELPDESK_AI_ASSISTANTS_TYPES}/${id}`,
    undefined,
    false,
    false,
  );

export const createHelpdeskType = data =>
  makeRequest('POST', HELPDESK_AI_ASSISTANTS_TYPES, data);

export const editHelpdeskType = data =>
  makeRequest('PUT', `${HELPDESK_AI_ASSISTANTS_TYPES}/${data.id}`, data);

export default {};
