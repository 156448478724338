export const INCIDENT = 'incident';
export const CRM_TICKET = 'crmTicket';
export const TEST_EMAIL = 'testEmail';
export const CHATS = 'chats';
export const LIVE_CHATS = 'liveChats';
export const CLIENT_PORTALS = 'clientPortals';
export const CLIENT_PORTAL_CHATS = 'ClientPortalChats';
export const PHONE_NUMBERS = 'phoneNumbers';
export const COUNTRIES = 'countries';
export const BUFFER_PHONE_NUMBERS = 'bufferPhoneNumbers';
export const MESSAGING = 'messaging';
export const CRM = 'crm';
export const MESSAGING_CHANNEL = 'messagingChannel';
export const TENANT_MODULES = 'tenantModules';
export const MESSAGING_DASHBOARD = 'messagingDashboard';
export const INTEGRATION = 'integration';
export const INTEGRATION_IMPORT = 'integrationImport';
export const SECURITY_INTEGRATIONS = 'securityIntegrations';
export const CONNECT_WISE_TICKET = 'connectWiseTicket';
export const HALO_TICKET = 'haloTicket';
export const USER = 'user';
export const COMMON = 'common';
export const NOTIFICATION_GROUPS = 'notificationGroups';
export const ADMIN = 'admin';
export const TEAMS_CONNECTIONS = 'teamsConnections';
export const MESSAGE_TEMPLATES = 'messageTemplates';
export const LIVE_CHAT_CONVERSATIONS = 'liveChatConversations';
export const CONNECTWISE_POD = 'ConnectwisePod';
export const COMMON_POD = 'CommonPod';
export const OPT_IN = 'OptIn';
export const PASSWORDS = 'passwords';
export const VERIFICATION_SETTINGS = 'verificationSettings';
export const TICKET_TIMER = 'ticketTimer';
export const TREE_VIEW = 'treeView';
export const SMS_BLACK_LIST = 'smsBlackList';
export const SMS_BLOCKED = 'blockedSms';
export const AUTOTASK_POD = 'autotaskPod';
export const HALO_POD = 'haloPod';
export const EXTRA_RESOURCES = 'extraResources';
export const FILE_UPLOAD = 'fileUpload';
export const CREATE_TICKET = 'createTicket';
export const TICKET_CREATION_TEMPLATES = 'ticketCreationTemplates';
export const KASEYA_TICKET = 'kaseyaTicket';
export const AUTOTASK_TICKET = 'autotaskTicket';
export const CHATS_BOTS = 'chatBots';
export const CLIENT_PORTAL_DEFAULT = 'clientPortalDefaults';
export const CLIENT_PORTAL_MAPPINGS = 'clientPortalDefaultsMapping';
export const ISSUE_TYPES = 'issueTypes';
export const CLIENT_PORTAL_USERS = 'psaContactClientPortal';
export const TENANT = 'tenant';
export const SMS_CAMPAIGN_GROUPS = 'smsCampaignGroups';
export const USER_DATA = 'userData';
export const VERIFIED_PSA_TECH_BY_SMS = 'verifiedPsaTechBySms';
export const BILLING = 'billing';
export const SECURE_DATA = 'secureData';
export const AUTH = 'auth';
export const PRICE_PLANS = 'pricePlans';
export const SECURE_DATA_SETTINGS = 'secureDataSettings';
export const OPTIN_SETTINGS = 'optInSettings';
export const EMAIL_SETTINGS = 'emailSettings';
export const GENERIC_INTEGRATIONS = 'genericIntegrations';
export const LOG_RECORDS = 'logRecords';
export const OUTGOING_MAIL = 'outgoingMail';
export const DYNAMIC_CRM_CONTACTS = 'dynamicCrmContacts';
export const TWILIO_ACCOUNTS = 'twilioAccounts';
export const USER_ACTION_LOGS = 'userActionLogs';
export default {};
