import React from 'react';
import PropTypes from 'prop-types';

import { importIntegration } from '@constants/appRoutes';

import ReusableButton from '@common/Button/Button';
import ImportContactsDialog from '@components/Ticketing/Integrations/pages/updateCrm/Table/InternalIntegrationTable/components/ImportContactsDialog';
import DateTimeField from '@common/TableComponents/DateTimeField';
import Table from '@components/Table';

import { TableTypes } from '@components/Table/constants';
import useStyles from './styles';

const InternalIntegrationTable = ({ id }) => {
  const classes = useStyles();

  const [dialogOpen, setDialogOpen] = React.useState(false);

  return (
    <div className={classes.container}>
      <ReusableButton
        label="Import Data"
        onClick={() => setDialogOpen(true)}
        size="lg"
        disabled={false}
        loading={false}
      />
      <Table
        resource={`${importIntegration}${id}`}
        defaultSort={{ fieldName: 'dateTime', order: 'DESC' }}
        columns={[
          {
            name: 'File',
            key: 'fileName',
            sortable: true,
            searchable: true,
            minWidth: 150,
          },
          {
            name: 'User',
            key: 'accountName',
            sortable: true,
            searchable: true,
            minWidth: 150,
          },
          {
            name: 'Date',
            key: 'dateTime',
            sortable: true,
            minWidth: 175,
            type: TableTypes.actions,
            accessor: rowData => (
              <DateTimeField
                record={rowData}
                key="dateTime"
                source="dateTime"
              />
            ),
          },
        ]}
      />
      {dialogOpen && (
        <ImportContactsDialog
          open={dialogOpen}
          onClose={setDialogOpen}
          crmId={id}
        />
      )}
    </div>
  );
};

InternalIntegrationTable.propTypes = {
  id: PropTypes.string.isRequired,
};

export default InternalIntegrationTable;
