import React from 'react';
import PropTypes from 'prop-types';

import Modal from '@ui/common/Modal/Modal';

import ViewDetailsItem, { ItemType } from './ViewDetailsItem';

import useStyles from './styles';

const ViewDetails = ({ rowData, setRowData }) => {
  const classes = useStyles();

  return (
    <Modal open setOpen={setRowData} title="Log record details">
      <div className={classes.contentWrapper}>
        <ViewDetailsItem
          name="Date"
          value={rowData.dateTime}
          type={ItemType.dateTime}
        />
        <hr />
        <ViewDetailsItem name="Entity Id" value={rowData.entityId} />
        <ViewDetailsItem name="Entity Name" value={rowData.entityDescription} />
        <ViewDetailsItem name="Id" value={rowData.id} />
        <ViewDetailsItem name="Scope" value={rowData.scope} />
        <ViewDetailsItem name="Operation" value={rowData.operation} />
        <ViewDetailsItem
          name="Is Success"
          value={rowData.isSuccess}
          type={ItemType.boolean}
        />
        <hr />
        <ViewDetailsItem name="Account Id" value={rowData.accountId} />
        <ViewDetailsItem name="Account Name" value={rowData.accountName} />
        <ViewDetailsItem name="Account Role" value={rowData.accountRole} />
        {rowData.detail && (
          <ViewDetailsItem name="Error Detail" value={rowData.detail} />
        )}
      </div>
    </Modal>
  );
};

export default ViewDetails;

ViewDetails.propTypes = {
  rowData: PropTypes.shape({
    id: PropTypes.string,
    scope: PropTypes.string,
    operation: PropTypes.string,
    entityId: PropTypes.number,
    entityDescription: PropTypes.string,
    isSuccess: PropTypes.bool,
    detail: PropTypes.string,
    dateTime: PropTypes.string,
    accountId: PropTypes.number,
    accountName: PropTypes.string,
    accountRole: PropTypes.string,
  }),
  setRowData: PropTypes.func.isRequired,
};
