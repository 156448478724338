import { useEffect, useState } from 'react';
import { ThunkGetCrmSyncSettings } from '@store/slices/integration/thunks';
import { useDispatch } from 'react-redux';

export const useCrmSyncSettings = crmId => {
  const dispatch = useDispatch();
  const [crmSettings, setCrmSettings] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    dispatch(ThunkGetCrmSyncSettings(crmId))
      .unwrap()
      .then(res => {
        setCrmSettings(res);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, crmId]);

  return { loading, crmSettings };
};

export default {};
