import { OPT_IN } from '../requestResources';
import { makeRequest } from '../makeRequest';

export const getOptInUrl = payload =>
  makeRequest('POST', `${OPT_IN}/getOptInUrl`, payload);

export const sendOptInUrl = payload =>
  makeRequest('POST', `${OPT_IN}/sendOptInUrl`, payload);

const getWithApiKey = (url, key) =>
  makeRequest('GET', url, undefined, undefined, true, [
    { key: 'X-Api-Key', value: key },
  ]);

// Unauthorized
export const validateOptInKey = apiKey =>
  getWithApiKey(`${OPT_IN}/validateOptInKey`, apiKey);

// Unauthorized
export const getOptInSettings = key => getWithApiKey(`${OPT_IN}/settings`, key);

// Unauthorized
export const getContactMethodsOptions = key =>
  getWithApiKey(`${OPT_IN}/preferredContactMethods`, key);

// Unauthorized
export const getContactInformationByCode = key =>
  getWithApiKey(`${OPT_IN}/getContactData`, key);

export const getContactInformation = (crmId, contactId) =>
  makeRequest(
    'GET',
    `${OPT_IN}/getContactInformation?crmId=${crmId}&contactId=${contactId}`,
  );

export const saveOptInForm = (apiKey, payload) =>
  makeRequest('POST', `${OPT_IN}/saveOptInForm?key=${apiKey}`, payload);

export const getOptInSmsTemplate = () =>
  makeRequest('GET', `${OPT_IN}/getOptInSmsTemplate`);

export const addOptInSmsTemplate = payload =>
  makeRequest('POST', `${OPT_IN}/addOptInSmsTemplate`, payload);

export const groupOptIn = payload =>
  makeRequest('POST', `${OPT_IN}/groupOptIn`, payload);

export const deleteOptInItem = id => makeRequest('DELETE', `${OPT_IN}/${id}`);

export const getGroupOptInData = (crmId, ids) =>
  makeRequest(
    'GET',
    `${OPT_IN}/${crmId}/groupOptIn?${ids.map(i => `id=${i}`).join('&')}`,
  );

export const addPhoneToPsaContact = payload =>
  makeRequest('POST', `${OPT_IN}/addCellPhone`, payload);
