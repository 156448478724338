import appConfig from '@configs/appConfig';
import { importIntegration, upload } from '@constants/appRoutes';
import { downLoadFile } from '@services/api';

const downLoadTemplate = type =>
  downLoadFile(`${appConfig.baseUrl.concat(upload)}/templates?type=${type}`);

export const downloadImportIntegrationTemplate = () =>
  downLoadFile(`${appConfig.baseUrl.concat(importIntegration)}templates`);

export default downLoadTemplate;
