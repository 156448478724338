import { createAsyncThunk } from '@reduxjs/toolkit';
import { INTEGRATION_IMPORT } from '@store/slices/resources';
import { sendFormData } from '@services/api';
import appConfig from '@configs/appConfig';
import { IMPORT_INTEGRATION } from '@services/requests/requestResources';

export const ThunkImportContactsToIntegration = createAsyncThunk(
  `${INTEGRATION_IMPORT}/importContacts`,
  async ({ file, crmId, removeUnprocessed, skipExisting }) => {
    const formData = new FormData();
    formData.append('file', file);
    const response = await sendFormData(
      `${appConfig.baseUrl}${IMPORT_INTEGRATION}/import-contacts?crmId=${crmId}&removeUnprocessed=${removeUnprocessed}&skipExisting=${skipExisting}`,
      formData,
    );
    return response.json();
  },
);

export default {};
