import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { createTheme, ThemeProvider } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import { lightTheme } from '@services/themes/mainTheme';
import {
  ThunkGetBaseTechCount,
  ThunkGetPricePlans,
} from '@store/slices/billing/thunks';

import LoginPageBanner from '@ui/common/LoginPageBanner';
import AppLogo from '@ui/common/AppLogo';

import ErrorProvider from '@components/ErrorProvider/ErrorProvider';
import UserData from './components/UserData';
import BillingPlans from './components/BillingPlans';
import CardData from './components/CardData';

import {
  SignInContext,
  SingInDispatchContext,
  useSignContext,
} from './context';
import { signUpPages } from './constants';
import useLoginStyles from '../Login/styles';

const theme = createTheme(lightTheme());

const SignUp = () => {
  const [pricePlans, setPricePlans] = useState([]);
  const [page, setPage] = useState(signUpPages.userData);

  const { signInData, contextDispatch } = useSignContext();

  const classes = useLoginStyles();
  const dispatch = useDispatch();

  const handleForward = useCallback(currentPage => {
    if (currentPage === signUpPages.userData) {
      setPage(signUpPages.billingPlan);
    }
    if (currentPage === signUpPages.billingPlan) {
      setPage(signUpPages.cardData);
    }
  }, []);

  const handleBack = useCallback(currentPage => {
    if (currentPage === signUpPages.billingPlan) {
      setPage(signUpPages.userData);
    }
    if (currentPage === signUpPages.cardData) {
      setPage(signUpPages.billingPlan);
    }
  }, []);

  const onMount = useCallback(async () => {
    await Promise.all([
      new Promise(res => res(dispatch(ThunkGetPricePlans()).unwrap())),
      new Promise(res => res(dispatch(ThunkGetBaseTechCount()).unwrap())),
    ]).then(res => {
      setPricePlans(res[0]?.filter(i => i.isAvailable));
      contextDispatch({
        type: 'setTechnicianCount',
        technicianCount: res[1].count,
      });
      contextDispatch({
        type: 'setInitTechnicianCount',
        initTechnicianCount: res[1].count,
      });
    });
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  const content = useMemo(() => {
    switch (page) {
      case signUpPages.userData:
        return (
          <div className={classes.container}>
            <LoginPageBanner />
            <div className={classes.activeContainer}>
              <AppLogo />
              <UserData handleForward={handleForward} />
            </div>
          </div>
        );
      case signUpPages.billingPlan:
        return (
          <div className={classes.container}>
            <BillingPlans
              pricePlans={pricePlans}
              handleBack={handleBack}
              handleForward={handleForward}
            />
          </div>
        );
      case signUpPages.cardData:
        return (
          <div className={classes.container}>
            <CardData
              pricePlans={pricePlans}
              handleBack={handleBack}
              handleForward={handleForward}
            />
          </div>
        );
      default:
        return <></>;
    }
  }, [handleForward, page, pricePlans]);

  return (
    <ThemeProvider theme={theme}>
      <ErrorProvider>
        <SignInContext.Provider value={signInData}>
          <SingInDispatchContext.Provider value={contextDispatch}>
            {content}
          </SingInDispatchContext.Provider>
        </SignInContext.Provider>
      </ErrorProvider>
    </ThemeProvider>
  );
};

export default SignUp;
