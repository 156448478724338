import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { enqueueSnackbar } from 'notistack';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import PropTypes from 'prop-types';

import {
  OptInPhoneTypesSelector,
  OptInSettingsInit,
} from '@store/slices/optInSettings';
import { ThunkGetOptInPhoneTypes } from '@store/slices/optInSettings/thunks/getOptInPhoneTypes';
import { ThunkSaveOptInSettings } from '@store/slices/optInSettings/thunks';

import { TEXT_UPDATE_SUCESS } from '@constants/texts/common';
import { validateRequired } from '@common/functions/validators';

import ReusableButton from '@common/Button/Button';
import AutocompleteFormInput from '@common/AutocompleteFormInput/AutocompleteFormInput';
import FieldWrapper from '@ui/components/common/form/FieldWrapper';

import useStyles from '../styles';

const OptInNumberDestination = ({ crmId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const initialValues = useSelector(OptInSettingsInit);
  const phoneTypes = useSelector(OptInPhoneTypesSelector);

  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    if (crmId) {
      dispatch(ThunkGetOptInPhoneTypes(crmId));
    }
  }, [crmId, dispatch]);

  const submit = values => {
    setProcessing(true);
    dispatch(ThunkSaveOptInSettings(values))
      .then(() => enqueueSnackbar(TEXT_UPDATE_SUCESS, { variant: 'success' }))
      .finally(() => {
        setProcessing(false);
      });
  };

  const validate = values => ({
    phoneType: validateRequired(values.phoneType),
  });

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />} disabled={!crmId}>
        {crmId
          ? 'Phone Number Destination Settings'
          : 'This section is only relevant for ConnectWise'}
      </AccordionSummary>
      <AccordionDetails>
        <Form
          onSubmit={submit}
          validate={validate}
          initialValues={initialValues}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className={classes.form}>
              <FieldWrapper
                label="Select Phone Type"
                labelSize={8}
                contentSize={8}
                fullWidth
                isRequired
                content={
                  <Field
                    name="phoneType"
                    id="phoneType"
                    render={AutocompleteFormInput}
                    size="small"
                    items={phoneTypes}
                    disabled={processing}
                  />
                }
              />
              <div className={classes.actions}>
                <ReusableButton
                  label="Update"
                  type="submit"
                  loading={processing}
                  disabled={processing}
                />
              </div>
            </form>
          )}
        />
      </AccordionDetails>
    </Accordion>
  );
};

OptInNumberDestination.propTypes = {
  crmId: PropTypes.string,
};

export default OptInNumberDestination;
