import { createAsyncThunk } from '@reduxjs/toolkit';
import { getFilteredContacts } from '@services/requests/dynamicCrmContacts';
import { DYNAMIC_CRM_CONTACTS } from '@store/slices/resources';

export const ThunkGetFilteredContacts = createAsyncThunk(
  `${DYNAMIC_CRM_CONTACTS}/getFilteredContacts`,
  ({ crmId, filters }) => {
    return getFilteredContacts(crmId, filters);
  },
);

export default {};
