import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    margin: '4rem 16px 16px 11px',
    width: 'auto',
  },
  filterWrapper: {
    boxShadow: '0px 0px 10px rgba(102, 124, 158, 0.3)',
    marginTop: '24px',
    padding: '16px 16px 24px',
    border: '2px solid #afafaf',
  },
  buttonsWrapper: {
    display: 'flex',
    gap: '8px',
    marginTop: '24px',
    justifyContent: 'flex-end',
  },
  filterItem: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    marginTop: '24px',
  },
  input: { width: '90%' },
  FilterDropDownWrapper: {
    width: '100%',
    textAlign: 'right',
  },
  multiselect: {
    width: '90%',
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      bottom: '-21px',
    },
  },
  crmIdField: {
    '& .MuiInputBase-root ': { height: '40px' },
    paddingLeft: '8px',
  },
  nameField: {
    paddingRight: '8px',
  },
  helperInfoText: {
    color: '#f44336',
    position: 'absolute',
    bottom: '-22px',
    width: '295px',
    right: 0,
  },
  deleteButton: { paddingLeft: '8px' },
  fieldWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  filters: {
    position: 'relative',
  },
  filtersError: {
    position: 'absolute',
    bottom: '-20px',
    right: '14px !important',
  },
  filterSeparator: {
    fontWeight: 'bold',
  },
  filterLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    paddingRight: '8px',
  },
  selected: { paddingLeft: '9px' },
}));

export default useStyles;
