import { validateRequired } from '@common/functions/validators';

export const initialValues = {
  name: '',
  accountSid: '',
  authToken: '',
};

export const validate = values => ({
  name: validateRequired(values.name),
  accountSid: validateRequired(values.accountSid),
  authToken: validateRequired(values.authToken),
});
