import React, { useEffect, useState, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';

import Input from '@components/Auth/Common/Input';
import FieldWrapper from '@common/form/FieldWrapper';
import ReusableButton from '@common/Button/Button';
import Modal from '@ui/common/Modal/Modal';
import FormSwitch from '@components/Auth/Common/FormSwitch';
import AutocompleteFormInput from '@common/AutocompleteFormInput/AutocompleteFormInput';

import { ThunkGetTenantsOptions } from '@store/slices/admin/thunks';
import { TenantsOptionsSelector } from '@store/slices/admin';
import { actions } from '@store/actions';
import {
  createHelpdeskType,
  editHelpdeskType,
} from '@services/requests/helpdeskTypesAI';
import { validate } from '../helpers';

import useStyles from '../styles';

const AgentFormAI = ({ item, setModalData }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const options = useSelector(TenantsOptionsSelector);
  const { enqueueSnackbar } = useSnackbar();

  const [processing, setProcessing] = useState(false);

  const isEditMode = !!item.id;

  useEffect(() => {
    setProcessing(true);
    dispatch(ThunkGetTenantsOptions()).then(() => {
      setProcessing(false);
    });
  }, [dispatch]);

  const submit = values => {
    setProcessing(true);
    const currentRequest = isEditMode ? editHelpdeskType : createHelpdeskType;
    currentRequest(values).then(res => {
      dispatch(actions.updateItemInList(res));
      setModalData(null);
      setProcessing(false);
      enqueueSnackbar(`Successfully ${isEditMode ? 'Updated' : 'Created'}`, {
        variant: 'success',
      });
    });
  };
  return (
    <Modal
      open
      setOpen={() => setModalData(null)}
      title={`${isEditMode ? 'Edit' : 'Create'} Custom Agent`}
      isCloseButton={false}
    >
      <Form
        onSubmit={submit}
        initialValues={item}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className={classes.form}>
            <FieldWrapper
              label="Select Tenant"
              labelSize={12}
              contentSize={12}
              isRequired
              content={
                <Field
                  id="tenantId"
                  name="tenantId"
                  showLabel
                  component={AutocompleteFormInput}
                  items={options}
                  size="small"
                  disabled={processing}
                />
              }
            />
            <FieldWrapper
              label="Name"
              labelSize={12}
              contentSize={12}
              showLabel
              isRequired
              content={
                <Field
                  name="name"
                  id="name"
                  render={Input}
                  disabled={processing}
                />
              }
            />
            <Field
              id="isVisible"
              name="isVisible"
              type="checkbox"
              component={FormSwitch}
              label="Make Visible"
              className={classes.switch}
            />
            <div className={classes.dialogActionsContainer}>
              <ReusableButton
                label="Close"
                onClick={() => {
                  setModalData(null);
                }}
                disabled={processing}
              />
              <ReusableButton
                label="Submit"
                type="submit"
                disabled={processing}
                viewType="blue"
              />
            </div>
          </form>
        )}
      />
    </Modal>
  );
};

AgentFormAI.propTypes = {
  setModalData: PropTypes.func.isRequired,
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    tenantId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    isVisible: PropTypes.string.isRequired,
  }).isRequired,
};

export default memo(AgentFormAI);
