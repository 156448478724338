import { PRICE_PLAN } from '../requestResources';
import { makeRequest } from '../makeRequest';

export const getPricePlan = () => makeRequest('GET', `${PRICE_PLAN}`);

export const getBaseTechnicianCount = () =>
  makeRequest('GET', `${PRICE_PLAN}/min-users-count`);

export const getTenantTechnicianCount = () =>
  makeRequest('GET', `${PRICE_PLAN}/tenant-min-users-count`);

export const getMyPlan = () => makeRequest('GET', `${PRICE_PLAN}/myPlan`);

export const getBillingEmail = () =>
  makeRequest('GET', '/Billing/billingEmail');

export const getLastInvoices = () =>
  makeRequest('GET', '/Billing/invoices?_end=5');

export const updateCardData = payload =>
  makeRequest('POST', '/Billing/cardInfo', payload);

export const updateBillingEmail = payload =>
  makeRequest('POST', '/Billing/billingEmail', payload);

export const getBillingAddress = () =>
  makeRequest('GET', '/Billing/billingCustomer');

export const updateBillingAddress = payload =>
  makeRequest('POST', '/Billing/billingCustomer', payload);

export const updatePricePlan = payload =>
  makeRequest('POST', '/PricePlan', payload);

export const createSubscription = data =>
  makeRequest('POST', `/Account/create-subscription`, data);
export default {};
