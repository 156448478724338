import React, { useCallback, useMemo, useState } from 'react';
import cx from 'classnames';
import { useTranslate } from 'react-admin';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import isArray from 'lodash/isArray';
import isFunction from 'lodash/isFunction';
import get from 'lodash/get';
import compact from 'lodash/compact';
import { useField, useForm } from 'react-final-form';
import PropTypes from 'prop-types';

import ArrowDownIcon from '@assets/icons/arrow.svg';
import CloseIcon from '@assets/icons/cross.svg';

import Icon from '@common/Icon/Icon';
import { TypeLabelValue } from '@common/propTypes/common';

import useStyles from './styles';

const CustomPaper = ({ children, ...props }) => {
  return children.filter(Boolean).length ? (
    <Paper variant="outlined" square {...props}>
      {children}
    </Paper>
  ) : null;
};

const InputWithAutocomplete = ({
  name,
  label,
  options = [],
  disabled = false,
  disableClearable = false,
  limitTags = 2,
  disableCloseOnSelect = true,
  margin = 'dense',
  freeSolo = false,
  loading = false,
  helperText,
  onInputChange,
  onChange: parentOnChange,
  renderOption,
  getOptionValue,
  getOptionLabel,
  getOptionSelected,
  onKeyDown,
  className,
  ...restProps
}) => {
  const classes = useStyles();
  const translate = useTranslate();
  const form = useForm();

  const [inputValue, setInputValue] = useState('');

  const {
    input: { onChange, value, ...restFieldProps },
    meta: { invalid, touched },
  } = useField(name);

  const errors = useMemo(() => form.getState().errors, [
    invalid,
    touched,
    value,
  ]);

  const fieldValue = useMemo(() => {
    if (!isArray(value)) return [];

    let selectedValue = value;

    if (isFunction(getOptionValue)) {
      selectedValue = value.map(valueOption => {
        return (
          options.find(
            option => getOptionValue(option) === String(valueOption),
          ) || valueOption
        );
      });
    }

    return selectedValue;
  }, [getOptionValue, value, options]);

  const errorProps = useMemo(() => {
    if (!touched) {
      return {
        error: false,
        helperText: null,
      };
    }

    const fieldErrors = get(errors, name);
    if (!fieldErrors)
      return {
        error: invalid,
        helperText: invalid ? 'Invalid value' : helperText,
      };

    if (isArray(fieldErrors))
      return {
        error: invalid,
        helperText: invalid ? (
          <div
            name={name}
            message={compact(fieldErrors.map(({ message }) => message))
              .join(', ')
              .replaceAll(name, label)}
          />
        ) : (
          helperText
        ),
      };

    return {
      error: invalid,
      helperText: invalid ? translate(fieldErrors) : helperText,
    };
  }, [errors, name, invalid, helperText, label, touched]);

  const handleInputChange = useCallback(
    (e, data, reason) => {
      if (onInputChange) {
        onInputChange(data);
      }
      if (reason !== 'reset') {
        setInputValue(data);
      }
    },
    [onInputChange],
  );

  const handleKeyDown = e => {
    onKeyDown(e, setInputValue);
  };

  const handleChange = useCallback(
    (e, data) => {
      let changedData = data || [];

      if (isFunction(getOptionValue)) {
        changedData = data.map(d => getOptionValue(d));
      }

      onChange(changedData);

      if (parentOnChange) {
        parentOnChange(changedData);
      }
      setInputValue('');
    },
    [getOptionValue, onChange, parentOnChange],
  );

  return (
    <Autocomplete
      fullWidth
      inputValue={inputValue}
      options={options}
      onChange={handleChange}
      filterSelectedOptions
      className={cx(classes.autocomplete, className)}
      renderInput={params => (
        <TextField
          {...params}
          {...errorProps}
          label={label}
          margin={margin}
          className={classes.autocompleteInput}
          size="small"
          inputProps={{ ...params?.inputProps, 'data-lpignore': true }}
          autoComplete="off"
          onKeyDown={onKeyDown && handleKeyDown}
        />
      )}
      renderTags={(values, getTagProps) =>
        values.map((option, index) => {
          const getLabel = () => {
            if (loading) return 'loading...';

            return isFunction(getOptionLabel) ? getOptionLabel(option) : option;
          };

          const tagProps = getTagProps({ index });

          return (
            <Chip
              key={option}
              variant="outlined"
              label={getLabel()}
              size="small"
              className={classes.autocompleteChip}
              {...tagProps}
            />
          );
        })
      }
      onInputChange={handleInputChange}
      defaultValue={fieldValue}
      value={fieldValue.filter(i => !!i)}
      disabled={disabled}
      disableClearable={disableClearable}
      disableCloseOnSelect={disableCloseOnSelect}
      renderOption={renderOption}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      multiple
      freeSolo={freeSolo}
      loading={loading}
      size="small"
      limitTags={limitTags}
      getLimitTagsText={more => `+${more} more`}
      PaperComponent={CustomPaper}
      popupIcon={
        <Icon
          icon={ArrowDownIcon}
          alt="down"
          style={{ width: 20, height: 20 }}
        />
      }
      closeIcon={
        <Icon icon={CloseIcon} alt="close" style={{ width: 15, height: 15 }} />
      }
      {...restFieldProps}
      {...restProps}
    />
  );
};

InputWithAutocomplete.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: TypeLabelValue,
  disabled: PropTypes.bool,
  disableClearable: PropTypes.bool,
  limitTags: PropTypes.number,
  disableCloseOnSelect: PropTypes.bool,
  margin: PropTypes.oneOf(['none', 'dense', 'normal']),
  freeSolo: PropTypes.bool,
  loading: PropTypes.bool,
  helperText: PropTypes.string,
  onInputChange: PropTypes.func,
  onChange: PropTypes.func,
  renderOption: PropTypes.func,
  getOptionValue: PropTypes.func,
  getOptionLabel: PropTypes.func,
  getOptionSelected: PropTypes.func,
  onKeyDown: PropTypes.func,
  className: PropTypes.string,
};

export default InputWithAutocomplete;
