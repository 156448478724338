import { createAsyncThunk } from '@reduxjs/toolkit';
import { addConversationTemplate } from '@services/requests/messaging';
import { MESSAGE_TEMPLATES } from '@store/slices/resources';

export const ThunkAddMessageTemplate = createAsyncThunk(
  `${MESSAGE_TEMPLATES}/addMessagingChannel`,
  async payload => addConversationTemplate(payload),
);

export default {};
