import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    margin: '20px',
    boxSizing: 'border-box',
  },
  alignCenter: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    alignContent: 'center',
  },
  actions: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-start',
    padding: '10px 0px',
    boxSizing: 'border-box',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    paddingBottom: '16px',
    boxSizing: 'border-box',
  },
  dialogActionsContainer: {
    display: 'flex',
    gap: '10px',
    justifyContent: 'flex-end',
    marginTop: '14px',
  },
  secretField: { '& input': { WebkitTextSecurity: 'disc' } },
}));

export default useStyles;
