import React, { useEffect, useState } from 'react';
import { Admin, Resource } from 'react-admin';
import { createHashHistory } from 'history';

import authProvider from '@services/raProviders/authProvider';
import createI18nProvider from '@services/raProviders/i18nProvider';
import dataProvider from '@services/raProviders/dataProvider';
import { lightTheme } from '@services/themes/mainTheme';

import resourcesName from '@constants/resources';
import {
  adminPermissions,
  basePermissions,
  dataSourcesPermissions,
  defaultPermissions,
  settingsPermissions,
} from '@constants/permissions';
import customRoutes from '@ui/customRoutes';
import resources from '@ui/resources';
import Login from '@ui/pages/auth/Login';
import Layout from '@components/Layout';
import roles from '@constants/roles';
import { Provider } from 'react-redux';
import createAdminStore from '@store/createAdminStore';
import appConfig from '@configs/appConfig';
import storage from '@constants/localStorage';
import { getDataFromToken } from '@utils/getDataFromToken';
import AppProviders from '../../providers';

const checkViewPermission = permission =>
  permission[basePermissions.read] ||
  permission[basePermissions.update] ||
  permission[basePermissions.readMine];

const App = () => {
  const [i18nProvider, setI18nProvider] = useState(null);
  const { roleId } = getDataFromToken();

  useEffect(() => {
    fetch(`${appConfig.baseUrl}/common/baseAppLogo`)
      .then(res => res.json())
      .then(res => {
        sessionStorage.setItem(storage.BASE_APP_LOGO, res.data);
      });
  }, []);

  useEffect(() => {
    createI18nProvider().then(provider => {
      setI18nProvider(provider);
    });
  }, []);
  const history = createHashHistory();

  useEffect(() => {
    authProvider.startRefreshTimer();
  }, []);

  if (!i18nProvider) return null;
  return (
    <Provider
      store={createAdminStore({
        authProvider,
        dataProvider,
        history,
      })}
    >
      <AppProviders>
        <Admin
          i18nProvider={i18nProvider}
          dataProvider={dataProvider}
          authProvider={authProvider}
          loginPage={Login}
          customRoutes={customRoutes}
          layout={Layout}
          theme={lightTheme}
          history={history}
        >
          {permissions =>
            Object.keys(resources).map(name => {
              // If the resource is enabled by default we don't need to filter edit, show..
              if (defaultPermissions[name]) {
                return <Resource key={name} name={name} {...resources[name]} />;
              }

              if (adminPermissions[name] && permissions?.admin) {
                return <Resource key={name} name={name} {...resources[name]} />;
              }

              if (settingsPermissions[name] && roleId <= roles.TENANT_ADMIN) {
                return <Resource key={name} name={name} {...resources[name]} />;
              }

              if (dataSourcesPermissions[name]) {
                return <Resource key={name} name={name} {...resources[name]} />;
              }

              if (
                name === resourcesName.comments &&
                Object.values(permissions).some(
                  p => p[basePermissions.comments],
                )
              ) {
                return <Resource key={name} name={name} {...resources[name]} />;
              }

              const permission = permissions[name] || {};
              const { list, show, edit, create, ...resourceData } = resources[
                name
              ];

              return (
                <Resource
                  key={name}
                  name={name}
                  edit={permission.update && edit ? edit : null}
                  create={permission.create && create ? create : null}
                  list={checkViewPermission(permission) && list ? list : null}
                  show={checkViewPermission(permission) && show ? show : null}
                  {...resourceData}
                />
              );
            })
          }
        </Admin>
      </AppProviders>
    </Provider>
  );
};

export default App;
