import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button, Tab, Tabs } from '@material-ui/core';

import { tabList } from '@common/DateRangePicker/constants';

import useStyles from '../../styles';

const Root = ({
  children,
  tabIndex,
  setTabIndex,
  setRange,
  handleClose,
  handleApply,
}) => {
  const classes = useStyles();

  const handleTabChange = useCallback(
    (event, newValue) => {
      setTabIndex(newValue);

      const today = moment().startOf('day');
      const yesterday = moment()
        .subtract(1, 'day')
        .startOf('day');
      const lastMonth = moment().subtract(1, 'month');
      const lastYear = moment().subtract(1, 'year');

      switch (newValue) {
        case 0: // Today
          setRange({ from: today.toDate(), to: today.toDate() });
          break;
        case 1: // Yesterday
          setRange({ from: yesterday.toDate(), to: yesterday.toDate() });
          break;
        case 2: // Last 7 Days
          setRange({
            from: today
              .clone()
              .subtract(7, 'days')
              .toDate(),
            to: today.toDate(),
          });
          break;
        case 3: // Last 30 Days
          setRange({
            from: today
              .clone()
              .subtract(30, 'days')
              .toDate(),
            to: today.toDate(),
          });
          break;
        case 4: // This Month
          setRange({
            from: today
              .clone()
              .startOf('month')
              .toDate(),
            to: today
              .clone()
              .endOf('month')
              .toDate(),
          });
          break;
        case 5: // Last Month
          setRange({
            from: lastMonth
              .clone()
              .startOf('month')
              .toDate(),
            to: lastMonth
              .clone()
              .endOf('month')
              .toDate(),
          });
          break;
        case 6: // This Year
          setRange({
            from: today
              .clone()
              .startOf('year')
              .toDate(),
            to: today
              .clone()
              .endOf('year')
              .toDate(),
          });
          break;
        case 7: // Last Year
          setRange({
            from: lastYear
              .clone()
              .startOf('year')
              .toDate(),
            to: lastYear
              .clone()
              .endOf('year')
              .toDate(),
          });
          break;
        default:
          break;
      }
    },
    [setRange, setTabIndex],
  );

  return (
    <div className={classes.root}>
      <div className={classes.tabWrapper}>
        {children}
        <Tabs
          orientation="vertical"
          value={tabIndex}
          onChange={handleTabChange}
          indicatorColor="none"
          aria-label="Quick Select Tabs"
          className={classes.tabs}
        >
          {tabList.map((label, index) => (
            <Tab label={label} value={index} key={label} />
          ))}
        </Tabs>
      </div>
      <div className={classes.buttonWrapper}>
        <Button
          type="button"
          color="secondary"
          size="small"
          className={classes.button}
          onClick={handleClose}
        >
          cancel
        </Button>
        <Button
          type="button"
          variant="contained"
          color="secondary"
          size="small"
          className={classes.button}
          onClick={handleApply}
        >
          apply
        </Button>
      </div>
    </div>
  );
};

Root.propTypes = {
  children: PropTypes.node,
  tabIndex: PropTypes.number.isRequired,
  setTabIndex: PropTypes.func.isRequired,
  setRange: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleApply: PropTypes.func.isRequired,
};

export default memo(Root);
