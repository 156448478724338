import { createAsyncThunk } from '@reduxjs/toolkit';
import { getImportIntegrationCompanies } from '@services/requests/integration';
import { INTEGRATION } from '@store/slices/resources';

export const ThunkGetImportIntegrationCompanies = createAsyncThunk(
  `${INTEGRATION}/getImportIntegrationCompanies`,
  async ({ crmId }) => {
    const companies = await getImportIntegrationCompanies(crmId);
    return companies;
  },
);

export default {};
