import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-admin';
import cx from 'classnames';

import { FormHelperText, TextField } from '@material-ui/core';

import useStyles from './inputStyles';

const Input = ({
  meta: { touched, error },
  input: inputProps,
  dateProps,
  endAdornment = undefined,
  disabled = false,
  extraClasses = {},
  autoComplete = 'off',
  helperInfoText,
  classNameWrapper,
  ...props
}) => {
  const classes = useStyles();
  const translate = useTranslate();
  const errorMessage =
    error && error.startsWith('ra.') ? translate(error) : error;
  return (
    <div className={cx(classes.inputWrapper, classNameWrapper)}>
      <TextField
        autoComplete="off"
        error={!!(touched && error)}
        helperText={touched && errorMessage}
        InputProps={{
          inputProps: { ...dateProps },
          endAdornment,
          classes: extraClasses,
          autoComplete,
        }}
        {...inputProps}
        {...props}
        fullWidth
        variant="outlined"
        size="small"
        classes={classes}
        disabled={disabled}
      />
      {helperInfoText && (
        <FormHelperText className={classes.helperInfoText}>
          {helperInfoText}
        </FormHelperText>
      )}
    </div>
  );
};

Input.propTypes = {
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  input: PropTypes.object,
  dateProps: PropTypes.object,
  endAdornment: PropTypes.node,
  disabled: PropTypes.bool,
  extraClasses: PropTypes.object,
  autoComplete: PropTypes.string,
  helperInfoText: PropTypes.string,
  classNameWrapper: PropTypes.string,
};

export default Input;
