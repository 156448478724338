import React from 'react';
import PropTypes from 'prop-types';

import useStyles from './ColoredAccessorStyles';

const successColorDefault = '#67AD5B';
const failedColorDefault = '#f00016';

const ColoredAccessor = ({
  item,
  colorField,
  successColor = successColorDefault,
  failedColor = failedColorDefault,
}) => {
  const { colorBlock } = useStyles({
    color: colorField ? successColor : failedColor,
  });

  return <span className={colorBlock}>{item}</span>;
};

ColoredAccessor.propTypes = {
  item: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  colorField: PropTypes.bool.isRequired,
  successColor: PropTypes.string,
  failedColor: PropTypes.string,
};

export default ColoredAccessor;
