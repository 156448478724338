import React, { useState, useMemo } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { usePermissions, useTranslate, ListContextProvider } from 'react-admin';
import { useSnackbar } from 'notistack';
import cx from 'classnames';

import DataGrid from '@lib/bundle';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import iconEdit from '@assets/icons/edit.svg';
import iconClone from '@assets/icons/copy_blue.svg';
import iconDelete from '@assets/icons/delete.svg';
import CreateButton from '@common/CreateButton/CreateButton';
import { sortOrders } from '@constants/filters';
import { basePermissions } from '@constants/permissions';
import Authorize from '@common/Authorize/Authorize';
import Cell from '@common/MuiVirtualizedTable/components/Cell';
import StatusCircle from '@common/StatusCircle/StatusCircle';
import { cloneCrmDefault } from '@components/Ticketing/Integrations/helpers';
import LinearProgress from '@material-ui/core/LinearProgress/LinearProgress';
import useStyles from './styles';
import Header from './HeaderTable';

const rowHeight = 40;
const headerHeight = 40;

const DefaultsTable = ({
  data,
  handleAddRecord,
  handleEditRecord,
  handleMakeDefaultRecord,
  handleDeleteRecord,
  setData,
}) => {
  const translate = useTranslate();
  const { enqueueSnackbar } = useSnackbar();
  const tableHeight = rowHeight * data.length + headerHeight;

  const classes = useStyles({ tableHeight });
  const { permissions } = usePermissions();

  const { allowDelete, allowUpdate } = useMemo(
    () =>
      permissions
        ? {
            allowDelete: permissions.integrations[basePermissions.delete],
            allowUpdate: permissions.integrations[basePermissions.update],
          }
        : {},
    [permissions],
  );

  const [sortOrder, setSortOrder] = useState(sortOrders.desc);
  const [loading, setLoading] = useState(false);

  const handleMakeDefault = (id, isDefault) => {
    if (!isDefault && allowUpdate) {
      handleMakeDefaultRecord(id);
    }
  };

  const handleSortData = () => {
    const sortedData = _.orderBy(data, 'name', sortOrder.toLowerCase());
    setSortOrder(prevOrderState =>
      prevOrderState === sortOrders.desc ? sortOrders.asc : sortOrders.desc,
    );
    setData(sortedData);
  };

  const defaultColumns = [
    { key: 'name', name: 'Name', sort: true },
    { key: 'defaultCompany', name: 'Catch-all Company' },
    { key: 'defaultBoard', name: 'Default Board' },
    {
      key: 'default',
      name: 'Default',
      accessor: row => (
        <StatusCircle
          row={row}
          handleClick={handleMakeDefault}
          rowKey="default"
        />
      ),
    },
  ];

  const handleCloneItem = value => {
    setLoading(true);
    cloneCrmDefault(value)
      .then(d => {
        const newData = [...data, d];
        setData(newData);
      })
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }))
      .finally(() => setLoading(false));
  };

  const updatedColumns = useMemo(() => {
    const columns =
      allowUpdate || allowDelete
        ? [
            ...defaultColumns,
            {
              name: 'Actions',
              key: 'actions',
              accessor: row => (
                <>
                  <Authorize
                    requiredPermissions={[basePermissions.update]}
                    customResource="integrations"
                  >
                    <ActionButton
                      icon={<img src={iconEdit} alt="iconEdit" />}
                      handler={() => handleEditRecord(row)}
                      toolTip="Edit"
                    />
                  </Authorize>
                  <Authorize
                    requiredPermissions={[basePermissions.delete]}
                    customResource="integrations"
                  >
                    <ActionButton
                      icon={<img src={iconDelete} alt="iconDelete" />}
                      handler={() => handleDeleteRecord(row.id)}
                      toolTip="Delete"
                    />
                  </Authorize>
                  <Authorize
                    requiredPermissions={[basePermissions.create]}
                    customResource="integrations"
                  >
                    <ActionButton
                      icon={<img src={iconClone} alt="clone" />}
                      handler={() => handleCloneItem(row.id)}
                      toolTip="Clone"
                    />
                  </Authorize>
                </>
              ),
            },
          ]
        : defaultColumns;

    return columns.map(col => ({
      ...col,
      headerRenderer: Header,
      formatter: Cell,
    }));
  }, [
    handleDeleteRecord,
    handleEditRecord,
    defaultColumns,
    allowUpdate,
    allowDelete,
  ]);

  const controllerProps = {
    setSort: handleSortData,
  };

  return (
    <div className={classes.container}>
      <ListContextProvider value={controllerProps}>
        <DataGrid
          rows={data}
          rowsCount={data.length}
          rowGetter={({ index }) => data[index] || {}}
          rowHeight={rowHeight}
          headerRowHeight={headerHeight}
          rowKeyGetter={row => row.id}
          className={cx('rdg-light', classes.dataGridWrapper)}
          columns={updatedColumns}
        />
        {loading && <LinearProgress className={classes.linearProgress} />}
      </ListContextProvider>
      <div className={classes.createButtonContainer}>
        <Authorize
          requiredPermissions={[basePermissions.create]}
          customResource="integrations"
        >
          <CreateButton
            classNameWrapper={classes.buttonAddWrapper}
            onClick={handleAddRecord}
            label={translate('common.create')}
            size="md"
          />
        </Authorize>
      </div>
    </div>
  );
};

DefaultsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  handleAddRecord: PropTypes.func.isRequired,
  handleEditRecord: PropTypes.func.isRequired,
  handleMakeDefaultRecord: PropTypes.func.isRequired,
  handleDeleteRecord: PropTypes.func.isRequired,
  setData: PropTypes.func.isRequired,
};

export default DefaultsTable;
