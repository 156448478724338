import React, { useEffect, useMemo, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-final-form';

import DropDown from '@components/Auth/Common/DropDown';

import { CrmSourcesSelector } from '@store/slices/treeView/selectors';
import { ThunkGetDynamicCrmFilters } from '@store/slices/dynamicCrmContacts/thunks';
import crmSources from '@constants/crmSources';

const PsaIntegrationDropDown = props => {
  const dispatch = useDispatch();
  const { change } = useForm();
  const crmSourcesList = useSelector(CrmSourcesSelector);

  const crmOptions = useMemo(
    () =>
      crmSourcesList.filter(crm => crm.crmType === crmSources.ConnectWise.name),
    [crmSourcesList],
  );

  useEffect(() => {
    if (crmOptions.length === 1) {
      change('crmId', crmOptions[0].id);
      dispatch(ThunkGetDynamicCrmFilters(crmOptions[0].id));
    }
  }, [change, crmOptions, dispatch]);

  return (
    <DropDown
      {...props}
      onInputChange={crmId => {
        if (crmOptions.length > 1) {
          dispatch(ThunkGetDynamicCrmFilters(crmId));
        }
        change('filters', []);
      }}
      options={crmOptions}
    />
  );
};

export default memo(PsaIntegrationDropDown);
