import { createAsyncThunk } from '@reduxjs/toolkit';
import { getDynamicFilters } from '@services/requests/dynamicCrmContacts';
import { DYNAMIC_CRM_CONTACTS } from '@store/slices/resources';

export const ThunkGetDynamicCrmFilters = createAsyncThunk(
  `${DYNAMIC_CRM_CONTACTS}/getDynamicCrmFilters`,
  crmId => {
    return getDynamicFilters(crmId);
  },
);

export default {};
