import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Link } from '@material-ui/core';

import {
  ThunkGetClientPortalConfigurations,
  ThunkGetCPDefaultsAutocomplete,
} from '@store/slices/clientPortalAdmin/thunks';
import { ThunkDeleteClientPortalConfigurations } from '@store/slices/clientPortalAdmin/thunks/deleteClientPortalConfigurations';
import {
  SelectClientPortalConfigurations,
  SelectClientPortalConfigurationsLoading,
} from '@store/slices/clientPortalAdmin/selectors';

import ReusableButton from '@common/Button/Button';
import SimpleTable from '@components/Triggers/pages/Incident/components/SimpleTable';
import Loading from '@common/Loading/Loading';
import ActionFieldItem from '@common/ActionButtons/ActionFieldItem';
import EditIcon from '@assets/icons/edit.svg';
import SettingIcon from '@assets/icons/setting.svg';
import DeleteIcon from '@assets/icons/delete.svg';
import ClientPortalConfigDialog from '@components/ClientPortal/dialogs/ClientPortalConfigDialog';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';

import {
  CLIENT_PORTAL_COMPANY_MAPPING,
  CLIENT_PORTAL_DEFAULTS,
} from '@constants/routes';

import useStyles from './styles';

const ClientPortalConfigurations = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { push } = useHistory();

  const loading = useSelector(SelectClientPortalConfigurationsLoading);

  const [selectedItem, setSelectedItem] = useState(null);
  const [formOpen, setFormOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [processing, setProcessing] = useState(false);

  const handleDelete = () => {
    setProcessing(true);
    dispatch(ThunkDeleteClientPortalConfigurations({ id: selectedItem.id }))
      .unwrap()
      .finally(() => {
        setSelectedItem(null);
        setProcessing(false);
        setDeleteDialogOpen(false);
      });
  };

  const columns = useMemo(
    () => [
      {
        label: 'Portal Name',
        value: 'portalName',
      },
      {
        label: 'Allowed Domain',
        handler: item => (
          <Link target="_blank" href={item.domain}>
            {item.domain}
          </Link>
        ),
      },
      {
        label: 'PSA',
        value: 'crmName',
      },
      {
        label: 'Ticket Properties',
        value: 'defaultName',
      },
      {
        label: 'Actions',
        handler: item => (
          <div>
            <ActionFieldItem
              icon={EditIcon}
              handler={() => {
                setSelectedItem(item);
                setFormOpen(true);
              }}
              toolTip="Edit"
            />
            <ActionFieldItem
              icon={SettingIcon}
              handler={() => {
                push(
                  CLIENT_PORTAL_COMPANY_MAPPING.replace(':configId', item.id),
                );
              }}
              toolTip="Company Mapping"
            />
            <ActionFieldItem
              icon={DeleteIcon}
              handler={() => {
                setSelectedItem(item);
                setDeleteDialogOpen(true);
              }}
              toolTip="Delete"
            />
          </div>
        ),
      },
    ],
    [push],
  );

  const onMount = useCallback(async () => {
    await dispatch(ThunkGetClientPortalConfigurations());
    await dispatch(ThunkGetCPDefaultsAutocomplete());
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (loading) return <Loading />;

  return (
    <>
      <div className={classes.container}>
        <div>
          <ReusableButton
            label="Create Configuration"
            classNameWrapper={classes.buttonMargin}
            onClick={() => {
              setSelectedItem(null);
              setFormOpen(true);
            }}
          />

          <ReusableButton
            label="Client Portal Defaults"
            classNameWrapper={classes.buttonMargin}
            onClick={() => {
              push(CLIENT_PORTAL_DEFAULTS);
            }}
          />
        </div>
        <SimpleTable
          selector={SelectClientPortalConfigurations}
          columns={columns}
        />
      </div>
      {formOpen && (
        <ClientPortalConfigDialog
          open={formOpen}
          setOpen={setFormOpen}
          item={selectedItem}
        />
      )}
      <ConfirmDialog
        onAccept={handleDelete}
        title="Deleting configuration"
        content="Are you sure to delete this Client Portal configuration? This action cannot be undone."
        onCancel={() => setDeleteDialogOpen(false)}
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        loading={processing}
      />
    </>
  );
};

export default ClientPortalConfigurations;
