import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SelectPhoneDialog from '@components/Settings/VerificationSettings/components/verificationPhone/SelectPhoneDialog';
import ReusableButton from '@common/Button/Button';
import useStyles from '../../styles';

const SelectPhone = ({ refreshScreenHandler }) => {
  const classes = useStyles();
  const [purchaseDialogOpen, setPurchaseDialogOpen] = useState(false);

  return (
    <div className={classes.noPhonePage}>
      <div>
        You do not have a phone number for messaging, please, select one
      </div>
      <ReusableButton
        size="lg"
        viewType="black"
        label="Select phone number"
        onClick={() => setPurchaseDialogOpen(true)}
      />
      {purchaseDialogOpen && (
        <SelectPhoneDialog
          setOpen={setPurchaseDialogOpen}
          refreshScreenHandler={refreshScreenHandler}
          open={purchaseDialogOpen}
        />
      )}
    </div>
  );
};

SelectPhone.propTypes = {
  refreshScreenHandler: PropTypes.func.isRequired,
};

export default SelectPhone;
