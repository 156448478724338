import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';

import { TwilioAccountsSelector } from '@store/slices/twilioAccounts';
import {
  ThunkDeleteTwilioAccount,
  ThunkGetTwilioAccounts,
} from '@store/slices/twilioAccounts/thunks';

import ReusableButton from '@common/Button/Button';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';
import SimpleTable from '@components/Triggers/pages/Incident/components/SimpleTable';
import TwilioAccountForm from './components/TwilioAccountForm';
import Actions from './components/Actions';

import { initialValues } from './helpers';

import useStyles from './styles';

const TwilioAccounts = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [item, setItem] = useState(null);
  const [removalId, setRemovalId] = useState(false);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    dispatch(ThunkGetTwilioAccounts());
  }, [dispatch]);

  const handleDelete = useCallback(() => {
    setProcessing(true);
    dispatch(ThunkDeleteTwilioAccount(removalId)).then(() => {
      setProcessing(false);
      setRemovalId(null);
      enqueueSnackbar(`Successfully deleted`, {
        variant: 'success',
      });
    });
  }, [dispatch, enqueueSnackbar, removalId]);

  return (
    <div className={classes.container}>
      <div className={classes.actions}>
        <ReusableButton
          viewType="blue"
          onClick={() => {
            setItem(initialValues);
          }}
          label="Add New"
          id="aiAssists-add-new"
        />
      </div>
      <SimpleTable
        selector={TwilioAccountsSelector}
        columns={[
          {
            label: 'Name',
            handler: row => row.name,
          },
          {
            label: 'Actions',
            handler: row => (
              <Actions
                row={row}
                setRemovalId={setRemovalId}
                setItem={setItem}
              />
            ),
            width: 100,
          },
        ]}
      />

      {!!item && (
        <TwilioAccountForm
          item={item}
          setModalData={() => {
            setItem(null);
          }}
        />
      )}
      {!!removalId && (
        <ConfirmDialog
          open
          onAccept={handleDelete}
          title="Confirm Action"
          onCancel={() => {
            setRemovalId(null);
          }}
          content="Are you sure you want to delete this item?"
          loading={processing}
        />
      )}
    </div>
  );
};

export default TwilioAccounts;
