import { createAsyncThunk } from '@reduxjs/toolkit';
import { TWILIO_ACCOUNTS } from '@store/slices/resources';
import { createTwilioAccount } from '@services/requests/twilioAccounts';

export const ThunkCreateTwilioAccount = createAsyncThunk(
  `${TWILIO_ACCOUNTS}/createTwilioAccount `,
  payload => createTwilioAccount(payload),
);

export default {};
