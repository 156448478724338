import { DYNAMIC_CRM_CONTACTS } from '@services/requests/requestResources';
import { makeRequest } from '../makeRequest';

export const getFilterSetsAutocomplete = () =>
  makeRequest('GET', `${DYNAMIC_CRM_CONTACTS}/filter-sets-autocomplete`);

export const getDynamicFilters = crmId =>
  makeRequest('GET', `${DYNAMIC_CRM_CONTACTS}/${crmId}/get-filters/v2`);

export const deleteFilter = id =>
  makeRequest('DELETE', `${DYNAMIC_CRM_CONTACTS}/${id}`);

export const getFilteredContacts = (crmId, payload) =>
  makeRequest(
    'POST',
    `${DYNAMIC_CRM_CONTACTS}/${crmId}/get-data-by-filters`,
    payload,
  );

export const getFilteredData = id =>
  makeRequest('GET', `${DYNAMIC_CRM_CONTACTS}/${id}/get-data`);

export const addDynamicCrmContact = payload =>
  makeRequest('POST', `${DYNAMIC_CRM_CONTACTS}`, payload);

export const editDynamicCrmContact = payload =>
  makeRequest('PUT', `${DYNAMIC_CRM_CONTACTS}/${payload.id}`, payload);

export const getDynamicCrmContact = id =>
  makeRequest('GET', `${DYNAMIC_CRM_CONTACTS}/${id}`);
export default {};
