import React, { useContext } from 'react';
import { Chip } from '@material-ui/core';
import {
  Clear as ClearIcon,
  EditOutlined as EditOutlinedIcon,
} from '@material-ui/icons/';
import cx from 'classnames';
import PropTypes from 'prop-types';

import EditContext from '@components/MailBox/components/Emails/BulkActions/Context/index';
import useStyles from './styles';

const Icon = ({ child, onEdit }) => {
  const classes = useStyles();
  return (
    <>
      <EditOutlinedIcon
        className={classes.editIcon}
        onClick={onEdit}
        classes={{ root: cx(classes.iconWrapper) }}
      />
      {child}
    </>
  );
};

const ChipComponent = React.forwardRef((props, ref) => {
  const { children, showEdit, onEdit, ...rest } = props;
  return (
    <div {...rest} ref={ref}>
      {children.map((child, index, arr) => {
        if (showEdit && index === arr.length - 1) {
          return <Icon child={child} onEdit={onEdit} />;
        }
        return child;
      })}
    </div>
  );
});

const CustomChip = ({
  classNameIconWrapper,
  classNameWrapper,
  children,
  onEdit,
  ...rest
}) => {
  const classes = useStyles();
  const showEdit = useContext(EditContext);

  const labelClasses = cx({ [classes.chipLabel]: showEdit });

  return (
    <Chip
      {...rest}
      classes={{
        root: cx(classes.chipWrapper, classNameWrapper),
        label: labelClasses,
      }}
      component={React.forwardRef((props, ref) => (
        <ChipComponent
          ref={ref}
          {...props}
          onEdit={onEdit}
          showEdit={showEdit}
        />
      ))}
      deleteIcon={
        <ClearIcon
          classes={{ root: cx(classes.iconWrapper, classNameIconWrapper) }}
        />
      }
    >
      {children}
    </Chip>
  );
};

Icon.propTypes = {
  onEdit: PropTypes.func,
  child: PropTypes.node,
};

ChipComponent.propTypes = {
  children: PropTypes.node,
  showEdit: PropTypes.bool,
  onEdit: PropTypes.func,
};

CustomChip.propTypes = {
  classNameWrapper: PropTypes.string,
  classNameIconWrapper: PropTypes.string,
  children: PropTypes.node,
  onEdit: PropTypes.func,
};

export default CustomChip;
