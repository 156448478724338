import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { Grid, Typography } from '@material-ui/core';

import Input from '@components/Auth/Common/Input';
import FormMultiselect from '@components/FormMultiselect';
import ActionFieldItem from '@common/ActionButtons/ActionFieldItem';
import RadioInput from '@common/Radio/RadioInput';
import DeleteIcon from '@assets/icons/delete.svg';

import { TypeLabelValue } from '@common/propTypes/common';
import AutocompleteWithPagination from '@common/AutocompleteWithPagination/AutocompleteWithPagination';
import { transformFilterOperationLabel } from '../../helpers';
import { filterFieldsType } from '../../constants';

import useStyles from '../styles';

const FilterItem = ({ removeFilter, data, index, isDefaultFilter }) => {
  const classes = useStyles();

  const content = useCallback(
    (filterDataType, options, fieldName, optionsLink) => {
      if (filterDataType === filterFieldsType.NumberArray && optionsLink) {
        return (
          <Field
            name={fieldName}
            id={fieldName}
            size="small"
            containerClassName={classes.input}
            showLabel
            filterName="filter"
            render={props => (
              <AutocompleteWithPagination
                {...props}
                multiple
                renderTags={selected => (
                  <div className={classes.selected}>
                    {`Selected: ${selected.length}`}
                  </div>
                )}
              />
            )}
            resource={optionsLink}
          />
        );
      }

      switch (filterDataType) {
        case filterFieldsType.NumberArray:
          return (
            <Field
              name={fieldName}
              id={fieldName}
              component={FormMultiselect}
              className={classes.multiselect}
              multiple
              options={options}
            />
          );
        case filterFieldsType.Boolean:
          return (
            <Field
              name={fieldName}
              id={fieldName}
              component={RadioInput}
              choices={[
                { label: isDefaultFilter ? 'Yes' : 'Enabled', value: 'true' },
                {
                  label: isDefaultFilter ? 'No' : 'Disabled',
                  value: 'false',
                },
              ]}
            />
          );
        default:
          return (
            <Field
              name={fieldName}
              id={fieldName}
              component={Input}
              classNameWrapper={classes.input}
            />
          );
      }
    },
    [classes, isDefaultFilter],
  );

  return (
    <Grid item xs={12} className={classes.filterItem}>
      <Grid container>
        <Grid item xs={3} className={classes.filterLabel}>
          <Typography>{`${data.name}`}</Typography>
          <Typography>{transformFilterOperationLabel(data)}</Typography>
        </Grid>
        <Grid item xs={4} className={classes.fieldWrapper}>
          {content(
            data.filterDataType,
            data.options,
            `filters[${index}].value`,
            data.optionsLink,
          )}
          <ActionFieldItem
            handler={() => removeFilter(index)}
            toolTip="Delete"
            icon={DeleteIcon}
            classNameWrapper={classes.deleteButton}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

FilterItem.propTypes = {
  removeFilter: PropTypes.func,
  index: PropTypes.number,
  isDefaultFilter: PropTypes.bool,
  data: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    options: PropTypes.arrayOf(TypeLabelValue),
    filterDataType: PropTypes.string,
    filterOperation: PropTypes.string,
    optionsLink: PropTypes.string,
  }),
};

export default memo(FilterItem);
