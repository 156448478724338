import { parsePhoneNumberFromString } from 'libphonenumber-js';
import sanitizedNumber from '@utils/sanitizedPhoneNumber';

const phoneValidator = (
  contactPhoneNumber,
  phoneCountryCode,
  isRequired = true,
  regexRule,
) => {
  if (!isRequired && !contactPhoneNumber) {
    return undefined;
  }
  if (!contactPhoneNumber) {
    return 'Required field';
  }

  const sanitizedPhoneNumber = sanitizedNumber(contactPhoneNumber);

  if ((regexRule || /\D/).test(sanitizedPhoneNumber)) {
    return 'Phone number contains invalid characters';
  }

  const [countryCode = undefined, phoneCode = ''] = (
    phoneCountryCode || ''
  ).split(' ');
  const phoneNumber = parsePhoneNumberFromString(
    `${phoneCode}${sanitizedPhoneNumber}`,
    countryCode,
  );

  return phoneNumber?.isValid() ? undefined : 'Invalid phone number';
};

export default phoneValidator;
