import React, { useEffect, useState } from 'react';
import { translate } from 'react-admin';
import PropTypes from 'prop-types';
import useStyles from '@components/Auth/Common/dropDownStyles';
import {
  Checkbox,
  FormHelperText,
  ListItem,
  TextField,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';
import cx from 'classnames';
import {
  TypeInput,
  TypeLabelValue,
  TypeMeta,
  TypeSize,
} from '@common/propTypes/common';

const AutocompleteFormInput = ({
  isIconShow,
  initialIcon,
  items,
  disabled,
  input,
  size = 'medium',
  classNameWrapper,
  meta,
  getOptionLabel = o => {
    return o.inputValue || o.label || o;
  },
  loading,
  onInputChange,
  freeSolo,
  label,
  variant = 'outlined',
  listItemClassName,
  id = 'group-by-autocomplete',
  multiple = false,
  limitTags,
  containerClassName,
}) => {
  const [startAdornment, setStartAdornment] = useState();

  const classes = useStyles();
  const isError = meta && meta.error && meta.touched;
  const errorMessage =
    meta?.error && meta.error.startsWith('ra.')
      ? translate(meta.error)
      : meta?.error;

  const getValue = () => {
    if (multiple) {
      return items.filter(p => input.value.includes(p.value));
    }
    return items.find(p => p.value === input.value) || input.value;
  };

  const onChange = (event, data, _, option) => {
    if (isIconShow) {
      setStartAdornment(option?.option?.icon);
    }
    if (multiple) {
      input.onChange(
        data.map(item => (typeof item === 'string' ? item : item?.value)),
      );
    } else {
      input.onChange(
        typeof data === 'string' ? data : data?.inputValue || data?.value,
      );
    }
  };

  const onFilterOptions = (options, params) => {
    const { inputValue } = params;

    if (inputValue !== '' && options.length === 0) {
      options.push({
        inputValue,
        label: `Add "${inputValue}"`,
      });
    }
    return options;
  };

  useEffect(() => {
    if (initialIcon) {
      setStartAdornment(initialIcon);
    }
  }, [initialIcon]);

  return (
    <div
      className={cx(containerClassName, classes.fieldWrapper, {
        [classes.multipleField]: multiple,
      })}
    >
      <Autocomplete
        multiple={multiple}
        disableCloseOnSelect={multiple}
        limitTags={limitTags}
        variant="outlined"
        className={cx(
          classes.formControl,
          classes.selectWrapper,
          classNameWrapper,
        )}
        fullWidth
        classes={{ popper: listItemClassName }}
        size={size}
        id={id}
        forcePopupIcon
        freeSolo={freeSolo}
        options={items}
        getOptionLabel={getOptionLabel}
        renderInput={params => {
          return (
            <TextField
              {...params}
              variant={variant}
              label={label}
              InputProps={{
                ...params.InputProps,
                startAdornment: isIconShow
                  ? startAdornment
                  : params.InputProps.startAdornment,
              }}
              error={!!isError}
            />
          );
        }}
        renderOption={option => (
          <ListItem component="div">
            {option?.icon && (
              <span className={classes.icon}>{option?.icon}</span>
            )}
            {multiple && (
              <Checkbox
                key={getValue().find(item => item.value === option.value)}
                checked={getValue().find(item => item.value === option.value)}
              />
            )}
            {option.label}
          </ListItem>
        )}
        disabled={disabled || loading}
        {...input}
        value={getValue()}
        onChange={onChange}
        onInputChange={onInputChange && onInputChange}
        filterOptions={freeSolo && onFilterOptions}
      />
      {isError && (
        <FormHelperText error={isError} className={classes.helperText}>
          {errorMessage}
        </FormHelperText>
      )}
    </div>
  );
};

AutocompleteFormInput.propTypes = {
  input: TypeInput,
  meta: TypeMeta,
  size: TypeSize,
  items: PropTypes.arrayOf(TypeLabelValue),
  disabled: PropTypes.bool,
  classNameWrapper: PropTypes.string,
  key: PropTypes.number,
  getOptionLabel: PropTypes.func,
  onInputChange: PropTypes.func,
  loading: PropTypes.bool,
  freeSolo: PropTypes.bool,
  isIconShow: PropTypes.bool,
  initialIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  label: PropTypes.string,
  variant: PropTypes.string,
  listItemClassName: PropTypes.string,
  multiple: PropTypes.bool,
  limitTags: PropTypes.number,
  id: PropTypes.string,
  containerClassName: PropTypes.string,
};

export default AutocompleteFormInput;
