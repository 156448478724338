import React from 'react';
import PropTypes from 'prop-types';

const ImportErrorRecord = ({ error }) => {
  if (!error) return null;

  const parts = error.split(':');
  const firstPart = parts.shift();
  const restText = parts.join(':');

  return (
    <div>
      <strong>{firstPart}</strong>
      {restText && (
        <span>
          <span>:</span>
          {restText}
        </span>
      )}
    </div>
  );
};

ImportErrorRecord.propTypes = {
  error: PropTypes.string.isRequired,
};

export default ImportErrorRecord;
