import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FormControlLabel, FormControl, Switch } from '@material-ui/core';

import useStyles from './dropDownStyles';

const FormSwitch = ({
  disabled,
  input,
  label,
  wrapperStyles = {},
  labelPlacement,
  className,
  ...props
}) => {
  const classes = useStyles();
  return (
    <FormControl
      variant="outlined"
      size="small"
      className={cx(classes.formControl, className)}
      disabled={disabled}
      style={wrapperStyles}
    >
      <FormControlLabel
        control={<Switch {...input} {...props} />}
        label={label}
        disabled={disabled}
        labelPlacement={labelPlacement}
      />
    </FormControl>
  );
};

FormSwitch.propTypes = {
  disabled: PropTypes.bool,
  input: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
    ]),
  }).isRequired,
  label: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  wrapperStyles: PropTypes.object,
  labelPlacement: PropTypes.oneOf(['end', 'start', 'top', 'bottom']),
  className: PropTypes.string,
};

export default FormSwitch;
