import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Field, Form } from 'react-final-form';

import {
  ThunkGetContactInformation,
  ThunkSendOptInUrl,
} from '@store/slices/optIn/thunks';
import { ThunkBeautifyPhoneNumber } from '@store/slices/common/thunks';
import { ThunkGetOptInSettings } from '@store/slices/optInSettings/thunks';
import { OptInSettingsInit } from '@store/slices/optInSettings';
import { emailValidator, requiredValidator } from '@utils/validators';

import Loading from '@ui/components/common/Loading/Loading';
import FieldWrapper from '@ui/components/common/form/FieldWrapper';
import Checkbox from '@common/Checkbox';
import Input from '@ui/components/Auth/Common/Input';
import ReusableButton from '@ui/components/common/Button/Button';
import CreatePhoneForPsaContactDialog from '@components/integrations/connectwise/dialogs/CreatePhoneForPsaContactDialog';

import useSharedStyles from '../../../CrmTicket/sharedStyles';
import useStyles from './styles';

const OptInDialog = ({ open, setOpen, data, psaId, hideNote = false }) => {
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [contactData, setContactData] = useState();
  const [openCreatePhoneDialog, setOpenCreatePhoneDialog] = useState(false);

  const dispatch = useDispatch();
  const sharedClasses = useSharedStyles();
  const classes = useStyles();
  const optInSettings = useSelector(OptInSettingsInit);
  const { crmId = psaId } = useParams();

  const beautifyPhone = useCallback(
    phone => {
      return dispatch(ThunkBeautifyPhoneNumber(phone))
        .unwrap()
        .then(res => res.data);
    },
    [dispatch],
  );

  const validate = values => {
    return {
      mobilePhone:
        values.sendSms && contactData.mobilePhone
          ? requiredValidator(values.mobilePhone)
          : undefined,
      email: values.sendEmail ? emailValidator(values.email) : undefined,
    };
  };

  const submit = async values => {
    setProcessing(true);
    let phone = null;

    if (values.sendSms && contactData.mobilePhone) {
      phone = await beautifyPhone(values.mobilePhone);
    }

    const payload = {
      crmId,
      ticketId: data.TicketId,
      contactId: data.ContactId,
      contactEmail: values.sendEmail ? values.email : null,
      contactPhone: values.sendSms && contactData.mobilePhone ? phone : null,
      updateContactPhone: values.updateContactPhone,
      updateContactEmail: values.updateContactEmail,
      sendSms: contactData.mobilePhone ? values.sendSms : false,
      sendEmail: values.sendEmail,
    };

    await dispatch(ThunkSendOptInUrl({ payload }))
      .unwrap()
      .then(() => {
        setOpen(false);
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const onMount = useCallback(async () => {
    setLoading(true);
    await dispatch(ThunkGetOptInSettings());
    await dispatch(
      ThunkGetContactInformation({
        crmId,
        contactId: data.ContactId,
      }),
    )
      .unwrap()
      .then(res => {
        setContactData(res);
      });
    setLoading(false);
  }, [crmId, data.ContactId, dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle>Opt-in</DialogTitle>
      <DialogContent>
        {loading ? (
          <Loading classNameWrapper={classes.loadingWrapper} />
        ) : (
          <>
            <Form
              onSubmit={submit}
              initialValues={{
                sendSms: optInSettings.sendSms,
                sendEmail: optInSettings.sendEmail,
                updateContactPhone: optInSettings.updatePhone,
                updateContactEmail: optInSettings.updateEmail,
                addTicketNote: optInSettings.notateTicket,
                mobilePhone: contactData.mobilePhone || null,
                email: contactData.email || null,
              }}
              validate={validate}
              render={({ handleSubmit, values }) => (
                <form onSubmit={handleSubmit}>
                  {contactData.mobilePhone && (
                    <div className={sharedClasses.checkBoxAndInputContainer}>
                      <FieldWrapper
                        label="Send SMS"
                        labelSize={12}
                        contentSize={12}
                        showLabel
                        classNameLabelInner={sharedClasses.alignLeft}
                        content={
                          <Field
                            name="sendSms"
                            id="sendSms"
                            render={Checkbox}
                            type="checkbox"
                            disabled={processing || optInSettings.lock}
                          />
                        }
                      />
                      <FieldWrapper
                        label="Phone"
                        labelSize={12}
                        contentSize={12}
                        showLabel
                        classNameLabelInner={sharedClasses.alignLeft}
                        content={
                          <Field
                            name="mobilePhone"
                            id="mobilePhone"
                            render={Input}
                            disabled={
                              !values.sendSms ||
                              processing ||
                              !contactData.mobilePhone
                            }
                            extraClasses={{
                              disabled: sharedClasses.disabledInput,
                            }}
                          />
                        }
                      />
                    </div>
                  )}

                  {values.sendSms && contactData.mobilePhone && (
                    <FieldWrapper
                      label="Update PSA Contact Phone"
                      labelSize={8}
                      contentSize={4}
                      showLabel
                      classNameLabelInner={sharedClasses.alignLeft}
                      classNameContainer={classes.formContainer}
                      content={
                        <Field
                          name="updateContactPhone"
                          id="updateContactPhone"
                          render={Checkbox}
                          type="checkbox"
                          disabled={processing || optInSettings.lock}
                        />
                      }
                    />
                  )}

                  {!contactData.mobilePhone && (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div>Phone is not provided on PSA.</div>
                      <ReusableButton
                        label="Create Phone on PSA"
                        onClick={() => {
                          setOpenCreatePhoneDialog(true);
                        }}
                        viewType="blue"
                      />
                    </div>
                  )}

                  <div className={sharedClasses.checkBoxAndInputContainer}>
                    <FieldWrapper
                      label="Send Email"
                      labelSize={12}
                      contentSize={12}
                      showLabel
                      classNameLabelInner={sharedClasses.alignLeft}
                      content={
                        <Field
                          name="sendEmail"
                          id="sendEmail"
                          render={Checkbox}
                          type="checkbox"
                          disabled={processing || optInSettings.lock}
                        />
                      }
                    />
                    <FieldWrapper
                      label="Email"
                      labelSize={12}
                      contentSize={12}
                      showLabel
                      classNameLabelInner={sharedClasses.alignLeft}
                      content={
                        <Field
                          name="email"
                          id="email"
                          render={Input}
                          extraClasses={{
                            disabled: sharedClasses.disabledInput,
                          }}
                          disabled={!values.sendEmail || processing}
                        />
                      }
                    />

                    {values.sendEmail && (
                      <FieldWrapper
                        label="Update PSA Contact Email"
                        labelSize={8}
                        contentSize={4}
                        showLabel
                        classNameLabelInner={sharedClasses.alignLeft}
                        classNameContainer={classes.formContainer}
                        content={
                          <Field
                            name="updateContactEmail"
                            id="updateContactEmail"
                            render={Checkbox}
                            type="checkbox"
                            disabled={processing || optInSettings.lock}
                          />
                        }
                      />
                    )}
                  </div>

                  {!hideNote && (
                    <FieldWrapper
                      label="Add Ticket Note"
                      labelSize={8}
                      contentSize={4}
                      showLabel
                      classNameLabelInner={sharedClasses.alignLeft}
                      classNameContainer={classes.formContainer}
                      content={
                        <Field
                          name="addTicketNote"
                          id="addTicketNote"
                          render={Checkbox}
                          type="checkbox"
                          disabled={processing || optInSettings.lock}
                        />
                      }
                    />
                  )}
                  <div className={sharedClasses.dialogActionsContainer}>
                    <ReusableButton
                      label="Cancel"
                      onClick={() => {
                        setOpen(false);
                      }}
                      disabled={processing}
                    />
                    <ReusableButton
                      label="Submit"
                      type="submit"
                      disabled={processing}
                      loading={processing}
                      viewType="blue"
                    />
                  </div>
                </form>
              )}
            />

            {openCreatePhoneDialog && (
              <CreatePhoneForPsaContactDialog
                open={openCreatePhoneDialog}
                onClose={() => {
                  setOpenCreatePhoneDialog(false);
                }}
                contactId={data.ContactId}
                crmId={crmId}
                onSuccess={onMount}
              />
            )}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

OptInDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  data: PropTypes.shape({
    MobilePhone: PropTypes.string,
    Email: PropTypes.string,
    TicketId: PropTypes.number,
    ContactId: PropTypes.number,
  }),
  psaId: PropTypes.number,
  hideNote: PropTypes.bool,
};

export default OptInDialog;
