import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';

import { ThunkImportContactsToIntegration } from '@store/slices/integrationImport/thunks/importIntegrationContact';
import { actions } from '@store/actions';
import { downloadImportIntegrationTemplate } from '@components/UploadFileDialog/helpers';

import FileUploader from '@common/FileUploader/FileUploader';
import beautifyBytes from '@components/LiveChatConfigurations/helpers';
import FieldWrapper from '@common/form/FieldWrapper';
import SwitchInput from '@common/FilterInputs/Switch';
import Loading from '@common/Loading/Loading';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import { ReactComponent as ExportIcon } from '@assets/icons/export.svg';
import ImportContactsStatistic from '@components/Ticketing/Integrations/pages/updateCrm/Table/InternalIntegrationTable/components/ImportContactsStatistic';
import Modal from '@ui/common/Modal/Modal';

import useStyles from '../styles';

const importFormats = {
  csv: { type: 'text/csv', name: 'CSV' },
};

const availableFormats = [importFormats.csv];

const maxFileSize = 20000000;

const getFormats = formats => formats.map(format => format.type).join(', ');
const getDescription = (formats, maxSize) => {
  const verb = formats?.length === 1 ? ' is ' : 's are';
  const formatNames = formats.map(format => format.name).join(' or ');
  const maxSizeText = beautifyBytes(maxSize);
  return `The accepted file format${verb} ${formatNames}. Max file size ${maxSizeText}.`;
};

const initialValues = {
  removeUnprocessed: false,
  skipExisting: true,
};

const ImportContactsDialog = ({ open, onClose, crmId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [statistic, setStatistic] = useState(undefined);

  const handleImageUpload = useCallback(
    (file, values) => {
      setLoading(true);
      const { removeUnprocessed, skipExisting } = values;
      dispatch(
        ThunkImportContactsToIntegration({
          file,
          crmId,
          removeUnprocessed,
          skipExisting,
        }),
      )
        .unwrap()
        .then(data => {
          dispatch(actions.updateItemInList({ id: -1 }));
          setStatistic(data);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [crmId, dispatch],
  );

  const handleDownLoad = async () => {
    const blob = await (await downloadImportIntegrationTemplate()).blob();
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `ImportIntegrationContacts.csv`;
    link.click();
  };

  const content = useMemo(() => {
    if (loading) {
      return <Loading />;
    }

    if (statistic) {
      return <ImportContactsStatistic statistic={statistic} />;
    }

    return (
      <Form
        onSubmit={() => null}
        validate={() => null}
        initialValues={initialValues}
        render={({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <div>
              <div>
                <FieldWrapper
                  classNameContainer={classes.downLoadWrapper}
                  content={
                    <>
                      <div className={classes.downloadText}>Download file</div>
                      <div className={classes.downloadSubText}>
                        Native M365 and Google *.csv supported
                      </div>
                      <ActionButton
                        handler={handleDownLoad}
                        icon={<ExportIcon stroke="#000" />}
                      />
                    </>
                  }
                />
                <FieldWrapper
                  label="Remove other records"
                  labelSize={8}
                  contentSize={4}
                  classNameLabelInner={classes.alignLeft}
                  content={
                    <Field
                      id="removeUnprocessed"
                      name="removeUnprocessed"
                      type="checkbox"
                      component={SwitchInput}
                    />
                  }
                />
                <FieldWrapper
                  label="Skip existing records"
                  labelSize={8}
                  contentSize={4}
                  classNameLabelInner={classes.alignLeft}
                  content={
                    <Field
                      id="skipExisting"
                      name="skipExisting"
                      type="checkbox"
                      component={SwitchInput}
                    />
                  }
                />
              </div>
              <div className={classes.uploadArea}>
                <FileUploader
                  handleUpload={file => handleImageUpload(file, values)}
                  type="file"
                  accept={getFormats(availableFormats)}
                  maxSizeBytes={maxFileSize}
                  maxFiles={1}
                />
              </div>
              <div className={classes.uploaderText}>
                {getDescription(availableFormats, maxFileSize)}
              </div>
            </div>
          </form>
        )}
      />
    );
  }, [classes, handleImageUpload, loading, statistic]);

  return (
    <Modal
      open={open}
      setOpen={onClose}
      title="Import contacts"
      isCloseButton={false}
      isCloseCross
      className={classes.halfScreenWidth}
    >
      {content}
    </Modal>
  );
};

ImportContactsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  crmId: PropTypes.string.isRequired,
};

export default ImportContactsDialog;
