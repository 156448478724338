import React from 'react';
import { useSelector } from 'react-redux';

import { PricePlansSelector } from '@store/slices/billing';

import PricePlan from './PricePlan';

import useStyles from '../styles';

const PricePlansList = ({ form, values }) => {
  const classes = useStyles();
  const pricePlans = useSelector(PricePlansSelector);

  return (
    <div>
      <div className={classes.header}>Price Plans</div>
      <div className={classes.pricePlans}>
        {pricePlans?.map(i => (
          <PricePlan
            key={i.id}
            {...i}
            selected={i.id === values.pricingPlanId}
            handlePlanSelected={id => {
              form.change('pricingPlanId', id);
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default PricePlansList;
