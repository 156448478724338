import { createAsyncThunk } from '@reduxjs/toolkit';
import { uploadFile } from '@services/api';
import appConfig from '@configs/appConfig';

export const ThunkUploadImage = createAsyncThunk(
  `microsoft/uploadImage`,
  ({ file, maxWidth = undefined, maxHeight = undefined }) => {
    const formData = new FormData();
    formData.append('file', file);

    return uploadFile(
      `${appConfig.baseUrl}/image-upload?maxWidth=${maxWidth}&maxHeight=${maxHeight}`,
      formData,
    );
  },
);

export default {};
