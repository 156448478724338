import React, { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import ActionFieldItem from '@common/ActionButtons/ActionFieldItem';

import PhoneIcon from '@assets/icons/phone.svg';

import { getMessagingService } from '@services/requests/twilioAccounts';
import { TwilioAccountsServicesSelector } from '@store/slices/twilioAccounts';
import { ThunkGetTwilioAccountsServices } from '@store/slices/twilioAccounts/thunks';

import useStyles from '../styles';

const Actions = ({ twilioAccountId, row, setItem }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const services = useSelector(TwilioAccountsServicesSelector);

  const [processing, setProcessing] = useState(false);
  return (
    <div className={classes.alignCenter}>
      <ActionFieldItem
        handler={async () => {
          setProcessing(true);
          if (!services.length) {
            await dispatch(ThunkGetTwilioAccountsServices(twilioAccountId));
          }
          getMessagingService(twilioAccountId, row.sid).then(async res => {
            const messagingServiceSid = await res.text?.();
            setItem({
              phoneNumber: row.number,
              twilioAccountId,
              phoneNumberSid: row.sid,
              messagingServiceSid: messagingServiceSid || '',
            });
            setProcessing(false);
          });
        }}
        toolTip="Import number"
        icon={PhoneIcon}
        id={`aiAssist-${row.sid}-edit`}
        disabled={processing}
      />
    </div>
  );
};

Actions.propTypes = {
  setItem: PropTypes.func.isRequired,
  twilioAccountId: PropTypes.string.isRequired,
  row: PropTypes.shape({
    sid: PropTypes.string.isRequired,
    number: PropTypes.string.isRequired,
  }).isRequired,
};

export default memo(Actions);
