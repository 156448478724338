import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCrmSources } from '@services/requests/treeView';
import { TREE_VIEW } from '@store/slices/resources';

export const ThunkGetCrmSources = createAsyncThunk(
  `${TREE_VIEW}/getCrmSources`,
  () => getCrmSources(),
);

export default {};
