import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { CircularProgress, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import cx from 'classnames';
import { OnChange } from 'react-final-form-listeners';
import { useTranslate } from 'react-admin';

import {
  ThunkGetIntegrationBoardStatuses,
  ThunkGetIntegrationBoardTypes,
  ThunkGetIntegrationCompanies,
  ThunkGetIntegrationPriorities,
  ThunkGetIntegrationServiceBoards,
  ThunkGetIntegrationSources,
} from '@store/slices/integration/thunks';
import crmSources from '@constants/crmSources';

import Loading from '@ui/components/common/Loading/Loading';
import Input from '@ui/components/Auth/Common/Input';
import AutocompleteFormInput from '@common/AutocompleteFormInput/AutocompleteFormInput';
import DropDown from '@components/Auth/Common/DropDown';
import MultiSelectDialog from '@ui/components/common/MultiSelectDialog/MultiSelectDialog';
import ReusableButton from '@ui/components/common/Button/Button';
import { ThunkGetTimeZones } from '@store/slices/common/thunks';
import ConnectwiseSubForm from '../updateCrm/CrmDefaults/SubForms/ConnectwiseSubForm';

import crmDefaultFields from '../updateCrm/CrmDefaults/constants';
import {
  getInitFormData,
  validateFormData,
} from '../updateCrm/CrmDefaults/formData';

import useStyles from '../updateCrm/styles';

const ConnectWiseDefaultForm = ({ crmId, onSubmit, onBack, data }) => {
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);

  // OnMount options
  const [serviceBoards, setServiceBoards] = useState([]);
  const [priorities, setPriorities] = useState([]);
  const [sources, setSources] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [timeZones, setTimeZones] = useState([]);

  // Board related options
  const [boardTypes, setBoardTypes] = useState([]);
  const [boardStatuses, setBoardStatuses] = useState([]);
  const [boardSubTypes, setSubTypes] = useState({});
  const [boardItems, setBoardItems] = useState([]);

  const [ignoredValues, setIgnoredValues] = useState([]);

  const [selectedTicketType, setSelectedTicketType] = useState();

  const classes = useStyles();
  const dispatch = useDispatch();
  const translate = useTranslate();

  const submit = values => {
    const payload = {
      id: data ? data.id : 0,
      name: values.name,
      defaultCompany: values.defaultCompany,
      ticketPriority: values.ticketPriority,
      defaultBoardId: values.defaultBoardId,
      defaultBoard: serviceBoards.find(
        board => +board.id === +values.defaultBoardId,
      )?.name,
      sourceId: values.sourceId,
      typeId: values.typeId,
      subTypeId: values.subTypeId,
      openBoardStatusId: values.openBoardStatusId,
      acknowledgedBoardStatusId: values.acknowledgedBoardStatusId,
      smsReceivedBoardStatusId: values.smsReceivedBoardStatusId,
      closedBoardStatusId: values.closedBoardStatusId,
      ticketTimeZone: values.ticketTimeZone,
      ignoreStatusIds: ignoredValues.map(i => i.value),
      ticketCategoryId: values.ticketCategoryId,
      crmSpecificSettings: values.crmSpecificSettings,
      itemId: values.itemId,
    };

    onSubmit(payload);
  };

  const handleTypeSelect = (value, form) => {
    setSelectedTicketType(value);
    form.batch(() => {
      form.change(crmDefaultFields.subTypeId, '');
      form.change(crmDefaultFields.itemId, '');
    });
  };

  const handleSubTypeSelect = (value, form) => {
    form.change(crmDefaultFields.itemId, '');
    if (value !== '') {
      setBoardItems(
        boardSubTypes[selectedTicketType || form.getState().values?.typeId]
          .find(i => i.id === value)
          .items.map(({ id, name }) => ({ value: id, label: name })),
      );
    } else {
      setBoardItems([]);
    }
  };

  const handleBoardIdChange = useCallback(
    async (value, form, ignoreDrop = false) => {
      // Dropping related form values
      if (!ignoreDrop) {
        form.batch(() => {
          form.change(crmDefaultFields.typeId, '');
          form.change(crmDefaultFields.subTypeId, '');
          form.change(crmDefaultFields.openBoardStatusId, '');
          form.change(crmDefaultFields.acknowledgedBoardStatusId, '');
          form.change(crmDefaultFields.closedBoardStatusId, '');
          form.change(crmDefaultFields.smsReceivedBoardStatusId, '');
        });
        setIgnoredValues([]);
      }

      setProcessing(true);
      await Promise.all([
        new Promise(res =>
          res(
            dispatch(
              ThunkGetIntegrationBoardStatuses({
                id: crmId,
                boardId: value,
              }),
            ).unwrap(),
          ),
        ),
        new Promise(res =>
          res(
            dispatch(
              ThunkGetIntegrationBoardTypes({
                id: crmId,
                boardId: value,
              }),
            ).unwrap(),
          ),
        ),
      ]).then(async res => {
        setBoardStatuses(
          res[0].map(({ id, name }) => ({
            label: name,
            value: id,
          })),
        );
        setBoardTypes(res[1]);
        // search options for ticket item dropdown according to typeId and subTypeId
        if (data?.typeId && data?.subTypeId) {
          const boardOptions = res[1]
            .find(({ id }) => id === data?.typeId)
            ?.boardSubTypes?.find(({ id }) => +id === data?.subTypeId)?.items;

          if (boardOptions) {
            setBoardItems(
              boardOptions.map(({ id, name }) => ({
                label: name,
                value: +id,
              })),
            );
          }
        }

        await setSubTypes(
          res[1].reduce((acc, cur) => {
            acc[cur.id] = cur.boardSubTypes;
            return acc;
          }, {}),
        );
      });

      setProcessing(false);
    },
    [dispatch, crmId],
  );

  const onMount = useCallback(async () => {
    setLoading(true);
    if (data && data.defaultBoardId) {
      await handleBoardIdChange(data.defaultBoardId, {}, true);
    }

    await Promise.all([
      new Promise(res =>
        res(
          dispatch(
            ThunkGetIntegrationServiceBoards({
              id: crmId,
            }),
          ).unwrap(),
        ),
      ),
      new Promise(res =>
        res(
          dispatch(
            ThunkGetIntegrationPriorities({
              id: crmId,
            }),
          ).unwrap(),
        ),
      ),
      new Promise(res =>
        res(
          dispatch(
            ThunkGetIntegrationSources({
              id: crmId,
            }),
          ).unwrap(),
        ),
      ),
      new Promise(res =>
        res(
          dispatch(
            ThunkGetIntegrationCompanies({
              id: crmId,
            }),
          ).unwrap(),
        ),
      ),
      new Promise(res => res(dispatch(ThunkGetTimeZones()).unwrap())),
    ])
      .then(res => {
        setServiceBoards(res[0]);
        setPriorities(res[1]);
        setSources(res[2]);
        setCompanies(res[3]);
        setTimeZones(res[4]);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, crmId, handleBoardIdChange, data]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (loading) return <Loading />;

  return (
    <div className={classes.container}>
      <div className={classes.block}>
        <Form
          onSubmit={submit}
          initialValues={data ? getInitFormData(data) : {}}
          validate={values =>
            validateFormData(values, translate, crmSources.ConnectWise.name)
          }
          render={({ handleSubmit, values, form }) => (
            <form onSubmit={handleSubmit}>
              <div className={classes.form}>
                <div>
                  <Typography className={classes.inputLabel}>Name:</Typography>
                  <Field
                    id="name"
                    name="name"
                    styleType="main"
                    inputView="text"
                    component={Input}
                    placeholder="Name"
                    fullWidth
                    disabled={processing}
                  />
                </div>
                <div>
                  <Typography className={classes.inputLabel}>
                    Catch-all Company:
                  </Typography>
                  <Field
                    id={crmDefaultFields.defaultCompany}
                    name={crmDefaultFields.defaultCompany}
                    size="small"
                    component={AutocompleteFormInput}
                    items={companies}
                    disabled={processing}
                    suppressInlineLabel
                  />
                </div>
                <div>
                  <Typography className={classes.inputLabel}>
                    Default Board:
                  </Typography>
                  <Field
                    id={crmDefaultFields.defaultBoardId}
                    name={crmDefaultFields.defaultBoardId}
                    size="small"
                    suppressInlineLabel
                    labelName="name"
                    valueName="id"
                    component={DropDown}
                    options={serviceBoards}
                    disabled={processing}
                  />
                  {form
                    .getRegisteredFields()
                    .some(item => item === crmDefaultFields.defaultBoardId) && (
                    <OnChange name={crmDefaultFields.defaultBoardId}>
                      {value => {
                        handleBoardIdChange(value, form);
                      }}
                    </OnChange>
                  )}
                </div>
                <div>
                  <Typography className={classes.inputLabel}>
                    Ticket Type:
                  </Typography>
                  <Field
                    id={crmDefaultFields.typeId}
                    name={crmDefaultFields.typeId}
                    size="small"
                    component={DropDown}
                    labelName="name"
                    valueName="id"
                    allowEmpty
                    options={boardTypes}
                    disabled={
                      processing || !values[crmDefaultFields.defaultBoardId]
                    }
                  />
                  {form
                    .getRegisteredFields()
                    .some(item => item === crmDefaultFields.typeId) && (
                    <OnChange name={crmDefaultFields.typeId}>
                      {value => {
                        handleTypeSelect(value, form);
                      }}
                    </OnChange>
                  )}
                </div>
                <div>
                  <Typography className={classes.inputLabel}>
                    Ticket SubType:
                  </Typography>
                  <Field
                    id={crmDefaultFields.subTypeId}
                    name={crmDefaultFields.subTypeId}
                    size="small"
                    labelName="name"
                    valueName="id"
                    component={DropDown}
                    allowEmpty
                    options={
                      boardSubTypes[values[crmDefaultFields.typeId]] || []
                    }
                    disabled={processing || !values[crmDefaultFields.typeId]}
                  />

                  {form
                    .getRegisteredFields()
                    .some(item => item === crmDefaultFields.subTypeId) && (
                    <OnChange name={crmDefaultFields.subTypeId}>
                      {value => {
                        handleSubTypeSelect(value, form);
                      }}
                    </OnChange>
                  )}
                </div>
                <div>
                  <Typography className={classes.inputLabel}>
                    Ticket Item:
                  </Typography>
                  <Field
                    id={crmDefaultFields.itemId}
                    name={crmDefaultFields.itemId}
                    size="small"
                    suppressInlineLabel
                    component={AutocompleteFormInput}
                    allowEmpty
                    items={boardItems}
                    loading={processing || !values[crmDefaultFields.subTypeId]}
                  />
                </div>
                <div>
                  <Typography className={classes.inputLabel}>
                    New Ticket Status:
                  </Typography>
                  <Field
                    id={crmDefaultFields.openBoardStatusId}
                    name={crmDefaultFields.openBoardStatusId}
                    size="small"
                    component={DropDown}
                    options={boardStatuses}
                    disabled={
                      processing || !values[crmDefaultFields.defaultBoardId]
                    }
                  />
                </div>
                <div>
                  <Typography className={classes.inputLabel}>
                    Acknowledged Status:
                  </Typography>
                  <Field
                    id={crmDefaultFields.acknowledgedBoardStatusId}
                    name={crmDefaultFields.acknowledgedBoardStatusId}
                    size="small"
                    component={DropDown}
                    options={boardStatuses}
                    disabled={
                      processing || !values[crmDefaultFields.defaultBoardId]
                    }
                  />
                </div>

                <div>
                  <Typography className={classes.inputLabel}>
                    Closed Ticket Status:
                  </Typography>
                  <Field
                    id={crmDefaultFields.closedBoardStatusId}
                    name={crmDefaultFields.closedBoardStatusId}
                    size="small"
                    component={DropDown}
                    options={boardStatuses}
                    disabled={
                      processing || !values[crmDefaultFields.defaultBoardId]
                    }
                  />
                </div>

                <div>
                  <Typography className={classes.inputLabel}>
                    SMS received Status:
                  </Typography>
                  <Field
                    id={crmDefaultFields.smsReceivedBoardStatusId}
                    name={crmDefaultFields.smsReceivedBoardStatusId}
                    size="small"
                    component={DropDown}
                    options={[
                      { value: undefined, label: '-' },
                      ...boardStatuses,
                    ]}
                    disabled={
                      processing || !values[crmDefaultFields.defaultBoardId]
                    }
                  />
                </div>

                <div>
                  <MultiSelectDialog
                    title="Ignored statuses"
                    values={ignoredValues}
                    setValues={v => setIgnoredValues(v)}
                    fullArray={boardStatuses}
                    disabled={
                      processing || !values[crmDefaultFields.defaultBoardId]
                    }
                  />
                </div>
                <div>
                  <Typography className={classes.inputLabel}>
                    Ticket Priority:
                  </Typography>
                  <Field
                    id={crmDefaultFields.ticketPriority}
                    name={crmDefaultFields.ticketPriority}
                    size="small"
                    suppressInlineLabel
                    labelName="name"
                    valueName="name"
                    component={DropDown}
                    options={priorities}
                    disabled={processing}
                  />
                </div>
                <div>
                  <Typography className={classes.inputLabel}>
                    Ticket Source:
                  </Typography>
                  <Field
                    id={crmDefaultFields.sourceId}
                    name={crmDefaultFields.sourceId}
                    size="small"
                    suppressInlineLabel
                    labelName="name"
                    valueName="id"
                    component={DropDown}
                    options={sources}
                    disabled={processing}
                  />
                </div>
                <div>
                  <Typography className={classes.inputLabel}>
                    Ticketing Time Zone:
                  </Typography>
                  <Field
                    id={crmDefaultFields.ticketTimeZone}
                    name={crmDefaultFields.ticketTimeZone}
                    size="small"
                    suppressInlineLabel
                    component={DropDown}
                    options={timeZones}
                    disabled={processing}
                  />
                </div>

                <ConnectwiseSubForm crmId={crmId} />
              </div>
              {processing ? (
                <CircularProgress size={18} thickness={2} />
              ) : (
                <div className={classes.buttonsWrapper}>
                  <ReusableButton
                    size="md"
                    type="button"
                    label="Cancel"
                    classNameWrapper={cx(
                      classes.buttonCancel,
                      classes.buttonStyle,
                    )}
                    onClick={onBack}
                  />
                  <ReusableButton
                    size="md"
                    classNameWrapper={classes.buttonStyle}
                    viewType="black"
                    type="submit"
                    label="resources.buttons.submit"
                  />
                </div>
              )}
            </form>
          )}
        />
      </div>
    </div>
  );
};

ConnectWiseDefaultForm.propTypes = {
  onSubmit: PropTypes.func,
  onBack: PropTypes.func,
  crmId: PropTypes.number,
  data: PropTypes.shape({
    defaultBoardId: PropTypes.number,
    id: PropTypes.string,
    typeId: PropTypes.string,
    subTypeId: PropTypes.string,
  }),
};
export default ConnectWiseDefaultForm;
