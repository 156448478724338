import React, { useState, useMemo } from 'react';
import { usePermissions, useRedirect } from 'react-admin';
import { useToggle } from '@components/Hooks/useToggle';
import useAsync from '@services/api/common/useAsync';
import Loading from '@common/Loading/Loading';
import { Redirect } from 'react-router-dom';
import {
  DATA_SOURCES_INTEGRATION,
  DATA_SOURCES_INTEGRATION_CREATE,
  MERAKI_DASHBOARD,
  SOPHOS_DASHBOARD,
  MERAKI,
  SOPHOS,
} from '@constants/routes';
import { basePermissions } from '@constants/permissions';
import { partial } from 'lodash';
import Card from '@components/DataSources/Integrations/components/Card';
import AddCard from '@components/DataSources/Integrations/components/AddCard';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';
import { integrationSources } from '@constants/integrationSources';
import iconDashboard from '@assets/icons/dashboard.svg';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import {
  deleteIntegration,
  getIntegrationImage,
  getIntegrations,
} from '../helpers';

import useStyles from './styles';

const Integrations = () => {
  const redirect = useRedirect();
  const classes = useStyles();
  const { permissions } = usePermissions();
  const [integrationForDelete, setIntegrationForDelete] = useState('');
  const [deleteDialogOpen, toggleDeleteDialogOpen] = useToggle(false);

  const allowCreate = useMemo(
    () => permissions && permissions.integrations[basePermissions.create],
    [permissions],
  );

  const [data, setData] = useState({});
  useAsync(getIntegrations, setData);

  const isDataLoaded = Array.isArray(data);

  if (isDataLoaded && !data?.length) {
    return allowCreate ? (
      <Redirect to={DATA_SOURCES_INTEGRATION_CREATE} />
    ) : (
      <span className={classes.noIntegrations}>
        No integrations in the current tenant
      </span>
    );
  }

  const handleAddClick = () => {
    redirect(DATA_SOURCES_INTEGRATION_CREATE);
  };

  const editHandler = (itemId, event) => {
    event.stopPropagation();
    redirect(`${DATA_SOURCES_INTEGRATION}/${itemId}`);
  };

  const deleteHandler = async () => {
    await deleteIntegration(integrationForDelete);
    const result = await getIntegrations();
    setData(result);
    toggleDeleteDialogOpen();
  };

  const openDeleteDialog = (itemId, event) => {
    event.stopPropagation();
    setIntegrationForDelete(itemId);
    toggleDeleteDialogOpen();
  };

  const onCancelDeleteDialog = () => {
    setIntegrationForDelete('');
    toggleDeleteDialogOpen();
  };

  const getRedirectToDashboard = (name, id = '') => {
    switch (name) {
      case integrationSources.Meraki.name:
        return MERAKI_DASHBOARD.replace(':id', id);
      case integrationSources.Sophos.name:
        return SOPHOS_DASHBOARD;
      default:
        return '/';
    }
  };

  const getRedirect = name => {
    switch (name) {
      case integrationSources.Meraki.name:
        return MERAKI;
      case integrationSources.Sophos.name:
        return SOPHOS;
      default:
        return '/';
    }
  };

  const content = () => {
    if (isDataLoaded) {
      return (
        <div className={classes.screenContainer}>
          {Object.entries(integrationSources).map(is => {
            return data.some(p => p.type === is[1].name) ? (
              <div key={is[1].name} className={classes.div}>
                <span className={classes.groupHeader}>
                  {is[1].label}
                  &nbsp; integrations &nbsp;
                  <ActionButton
                    icon={<img src={iconDashboard} alt="iconDashboard" />}
                    handler={() =>
                      redirect(
                        getRedirectToDashboard(
                          is[1].name,
                          is[1].label === 'Meraki' ? data[1].id : undefined,
                        ),
                      )
                    }
                    toolTip="to dashboard"
                  />
                </span>
                {data
                  .filter(i => i.type === is[1].name)
                  .map(i => (
                    <Card
                      key={i.id}
                      item={i}
                      readOnly={false}
                      getImage={getIntegrationImage}
                      handleClick={partial(
                        redirect,
                        `${getRedirect(is[1].name)}/${i.id}`,
                      )}
                      editHandler={partial(editHandler, i.id)}
                      deleteHandler={partial(openDeleteDialog, i.id)}
                    />
                  ))}
              </div>
            ) : null;
          })}
          <div>
            {allowCreate && (
              <AddCard
                handleAddClick={handleAddClick}
                className={classes.addCard}
              />
            )}
            <ConfirmDialog
              open={deleteDialogOpen}
              setOpen={toggleDeleteDialogOpen}
              onAccept={() => deleteHandler()}
              onCancel={() => onCancelDeleteDialog()}
              title="Delete current alert"
              content="Warning! This operation will delete current integration and cannot be undone."
            />
          </div>
        </div>
      );
    }
    return <Loading />;
  };
  return content();
};

export default Integrations;
