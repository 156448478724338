import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    height: props => (props.useRelativeSizing ? '100%' : '89vh'),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: props => (props.useRelativeSizing ? '30px' : 'unset'),
    boxSizing: 'border-box',
  },
  greyBox: {
    backgroundColor: '#ededed',
    width: '410px',
    height: '460px',
    paddingBottom: '16px',
  },
  microsoftForm: {
    height: '90%',
    padding: '0 38px',
    display: 'flex',
    // justifyContent: 'space-between',
    gap: '16px',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    marginTop: '75px',
    width: '250px',
    height: '60px',
  },
  buttonWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '24px',
  },
  infoText: {
    textAlign: 'center',
    opacity: 0.5,
  },
  email: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  secondButtonsWrapper: {
    display: 'flex',
    padding: '0 24px',
    justifyContent: 'flex-end',
    gap: '8px',
  },
  secondButton: {
    width: '80px',
  },
  linkWrapper: {
    textAlign: 'center',
  },
  link: {
    marginLeft: '4px',
  },
}));

export default useStyles;
