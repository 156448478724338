import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { MessageTemplatesSelector } from '@store/slices/messaging';
import { ThunkGetMessageTemplates } from '@store/slices/messaging/thunks';

import Loading from '../common/Loading/Loading';
import SimpleTable from '../Triggers/pages/Incident/components/SimpleTable';
import MessageTemplatesTableActions from './components/MessageTemplatesTableActions';

import useStyles from './styles';
import MessageTemplatesTableItemActions from './components/MessageTemplatesTableItemActions';

const MessageTemplates = () => {
  const [loading, setLoading] = useState(true);

  const classes = useStyles();
  const dispatch = useDispatch();

  const columns = [
    { label: 'Name', value: 'name' },
    {
      label: 'Text',
      value: 'text',
    },
    {
      label: 'Actions',
      handler: item => <MessageTemplatesTableItemActions record={item} />,
      minWidth: 60,
      width: 60,
    },
  ];

  const onMount = useCallback(async () => {
    setLoading(true);
    await dispatch(ThunkGetMessageTemplates());
    setLoading(false);
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (loading) return <Loading />;
  return (
    <div className={classes.container}>
      <MessageTemplatesTableActions />
      <SimpleTable selector={MessageTemplatesSelector} columns={columns} />
    </div>
  );
};

export default MessageTemplates;
