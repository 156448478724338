import { createAsyncThunk } from '@reduxjs/toolkit';
import { USER_ACTION_LOGS } from '@store/slices/resources';
import { getListInformation } from '@services/requests/userActionLogs';

export const ThunkGetListInformation = createAsyncThunk(
  `${USER_ACTION_LOGS}/getInformation`,
  () => getListInformation(),
);

export default {};
