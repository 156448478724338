import React from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import cx from 'classnames';

const useStyles = makeStyles(() => ({
  selectedRow: {
    background: '#e9e9e9',
  },
  upperCase: { textTransform: 'uppercase' },
}));

const SimpleTable = React.memo(({ columns, selector, selectedId }) => {
  const items = useSelector(selector);
  const classes = useStyles();
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            {columns.map(i => (
              <TableCell
                key={i.label}
                style={{ fontWeight: 800 }}
                className={i.headerClassName}
              >
                {i.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {items &&
            items.length > 0 &&
            items.map(item => (
              <TableRow
                key={item.id}
                className={+item.id === +selectedId && classes.selectedRow}
              >
                {columns.map(
                  ({
                    value,
                    label,
                    handler,
                    color,
                    accessor,
                    isUpperCase,
                    width,
                    minWidth,
                  }) => (
                    <TableCell
                      className={cx({
                        [classes.upperCase]: isUpperCase,
                      })}
                      key={label}
                      style={{
                        color: value && color && item[color],
                        width,
                        minWidth,
                      }}
                    >
                      {value
                        ? accessor
                          ? accessor(item[value])
                          : item[value]
                        : handler(item)}
                    </TableCell>
                  ),
                )}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
});
SimpleTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      headerClassName: PropTypes.string,
      value: PropTypes.value,
      handler: PropTypes.func,
    }).isRequired,
  ),
  selector: PropTypes.func.isRequired,
  selectedId: PropTypes.number,
};
export default SimpleTable;
