import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { ThunkGetVerificationPhone } from '@store/slices/verificationSettings/thunks';
import { verificationSettingsSelector } from '@store/slices/verificationSettings';

import InfoBox from '@common/InfoBox/InfoBox';
import Loading from '@common/Loading/Loading';
import ExistingPhone from '@components/Settings/VerificationSettings/components/verificationPhone/ExistingPhone';
import SelectPhone from '@components/Settings/VerificationSettings/components/verificationPhone/SelectPhone';

const VerificationPhone = () => {
  const { verificationPhone, loading } = useSelector(
    verificationSettingsSelector,
  );
  const dispatch = useDispatch();

  const onMount = useCallback(() => {
    dispatch(ThunkGetVerificationPhone);
  }, [dispatch]);

  const content = useMemo(() => {
    if (loading) return <Loading />;
    if (verificationPhone) return <ExistingPhone phone={verificationPhone} />;
    return <SelectPhone refreshScreenHandler={onMount} />;
  }, [loading, onMount, verificationPhone]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        Messaging Number
        <InfoBox text="Select the phone number for messaging process" />
      </AccordionSummary>
      <AccordionDetails>{content}</AccordionDetails>
    </Accordion>
  );
};

export default VerificationPhone;
