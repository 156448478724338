import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteFilter } from '@services/requests/dynamicCrmContacts';
import { DYNAMIC_CRM_CONTACTS } from '@store/slices/resources';

export const ThunkDeleteDynamicCrmFilters = createAsyncThunk(
  `${DYNAMIC_CRM_CONTACTS}/deleteDynamicCrmFilters`,
  async id => {
    await deleteFilter(id);
    return id;
  },
);

export default {};
