import { createAsyncThunk } from '@reduxjs/toolkit';
import { getDynamicCrmContact } from '@services/requests/dynamicCrmContacts';
import { DYNAMIC_CRM_CONTACTS } from '@store/slices/resources';

export const ThunkGetDynamicCrmContact = createAsyncThunk(
  `${DYNAMIC_CRM_CONTACTS}/getDynamicCrmContact`,
  id => {
    return getDynamicCrmContact(id);
  },
);

export default {};
