import React, { memo } from 'react';
import { FieldArray } from 'react-final-form-arrays';

import FilterDropDown from './FilterDropDown';
import FilterItem from './FilterItem';

import useStyles from '../styles';

const Filters = () => {
  const classes = useStyles();

  const validateFilters = (filters = []) => {
    return filters.map(filter => {
      const errors = {};
      if (!filter.value?.toString()) {
        errors.value = 'Field is required';
      }
      return Object.keys(errors).length ? errors : undefined;
    });
  };

  return (
    <FieldArray name="filters" validate={validateFilters}>
      {({ fields }) => (
        <div className={classes.filterWrapper}>
          <FilterDropDown />
          {fields.value?.length > 0 && (
            <>
              {fields.value.map((field, index) => {
                const isDefaultFilter = field.id <= 100;
                const isFirstDefault =
                  isDefaultFilter &&
                  fields.value.findIndex(f => f.id <= 100) === index;
                const isFirstCustom =
                  !isDefaultFilter &&
                  fields.value.findIndex(f => f.id > 100) === index;

                return (
                  <React.Fragment key={field.id}>
                    {isFirstDefault && (
                      <p className={classes.filterSeparator}>Default Filters</p>
                    )}
                    {isFirstCustom && (
                      <p className={classes.filterSeparator}>
                        Custom Properties
                      </p>
                    )}
                    <FilterItem
                      data={field}
                      index={index}
                      removeFilter={fields.remove}
                      isDefaultFilter={isDefaultFilter}
                    />
                  </React.Fragment>
                );
              })}
            </>
          )}
        </div>
      )}
    </FieldArray>
  );
};

export default memo(Filters);
