import getData from '@services/api/common/getData';
import appConfig from '@configs/appConfig';
import { gapi } from 'gapi-script';
import { account } from '@constants/appRoutes';
import { UserAgentApplication } from 'msal';
import externalLoginType from '@constants/externalLoginType';
import {
  getPermissionsFromStorage,
  parseJwtToken,
  setAllowedResources,
  setPermissionsToStorage,
  setTenantImageUrlToStorage,
} from '@services/api';
import { HOME_ROUTE } from '@constants/routes';
import { getHeaders } from '@utils/transfered';
import { getTenantImage } from '@components/Settings/TenantSettings/helpers';

const getMicrosoft = () =>
  getData(`${appConfig.baseUrl}${account}get-msal-settings`);

const getGoogle = () =>
  getData(`${appConfig.baseUrl}${account}get-google-api-settings`);

const loginMicrosoft = () => {
  return new Promise((resolve, reject) => {
    getMicrosoft().then(data => {
      const { clientId, authority, redirectUri } = data?.auth;
      const myMSALObj = new UserAgentApplication({
        auth: {
          clientId,
          authority,
          redirectUri,
        },
      });
      // scopes - list of permissions to ask from a user
      // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-core/docs/scopes.md
      const loginRequest = {
        scopes: ['User.Read'],
      };
      // Open a pop-up page and ask user to authorize in Microsoft
      myMSALObj
        .loginPopup(loginRequest)
        .then(loginResponse => {
          // User successfully authorized
          resolve(loginResponse.idToken.rawIdToken);
        })
        .catch(error => {
          reject(error);
        });
    });
  });
};

const loginGoogle = () => {
  return new Promise((resolve, reject) => {
    getGoogle()
      .then(data => {
        // Load Google API client - we need authorization only
        gapi.load(
          'client:auth2',
          () => {
            // Initialize client with our credentials
            gapi.client
              .init({
                apiKey: data.apiKey,
                clientId: data.clientId,
                scope: 'email', // https://developers.google.com/people/v1/how-tos/authorizing
              })
              .then(
                () => {
                  // Client is ready, let's roll
                  const googleAuth = gapi.auth2.getAuthInstance();
                  if (!googleAuth) {
                    // An issue with configuration
                    reject();
                    // return deferred.promise;
                  }

                  // Ask user to sign in
                  googleAuth.signIn().then(
                    signinResponse => {
                      // User has authorized successfully
                      // console.log(signinResponse.getId());
                      resolve(signinResponse.getAuthResponse().id_token);
                    },
                    errorSignIn => {
                      // Failed to sign in. Return an empty result
                      reject(errorSignIn);
                    },
                  );
                },
                errorInit => {
                  // Failed to initialize (bad credentials or so). Return an empty result
                  reject(errorInit);
                },
              )
              .catch(e => {
                reject(e);
              });
          },
          e => {
            reject(e);
          },
        );
      })
      .catch(e => {
        reject(e);
      });
  });
};

const getExternalLogin = type => {
  switch (type) {
    case externalLoginType.Microsoft:
      return loginMicrosoft();
    case externalLoginType.Google:
      return loginGoogle();
    default:
      return Promise.reject(new Error('Invalid type parameter'));
  }
};

export const fetchPermissions = async (url, clearTokens) => {
  const request = new Request(url, {
    method: 'GET',
    headers: getHeaders(true),
  });
  const response = await fetch(request);

  const data = await response.json();
  if (!response.ok) {
    if (clearTokens) {
      clearTokens();
    }
    throw data.message;
  }
  const permissions = !!data && data.length > 0 ? data[0] : {};
  setPermissionsToStorage(JSON.parse(permissions.properties));
  return getPermissionsFromStorage();
};

export const useOnLogin = async res => {
  try {
    const keys = Object.keys(res);
    keys.forEach(i => {
      localStorage.setItem(i, res[i]);
    });

    const claims = parseJwtToken(res.accessToken);
    setAllowedResources(claims.allowedObjects);

    await fetchPermissions(appConfig.baseUrl.concat('/AccessRights/'));

    const { tenantImageUrl } = await getTenantImage();
    setTenantImageUrlToStorage(tenantImageUrl);

    window.location.replace(`#${HOME_ROUTE}`);
  } catch (err) {
    throw new Error(err);
  }
};

export const loginWithAuthObject = async res => {
  const keys = Object.keys(res);
  keys.forEach(i => {
    localStorage.setItem(i, res[i]);
  });

  const claims = parseJwtToken(res.accessToken);
  setAllowedResources(claims.allowedObjects);

  await fetchPermissions(appConfig.baseUrl.concat('/AccessRights/'));
};
export default getExternalLogin;
