import React, { useState } from 'react';

import ReusableButton from '@common/Button/Button';
import InviteUsersDialog from '@components/InvitedUsers/dialogs/InviteUsersDialog';

import roles from '@constants/roles';
import { getDataFromToken } from '@utils/getDataFromToken';

import useStyles from './styles';

const InviteUsersTableActions = () => {
  const [openInviteUsersDialog, setOpenInviteUsersDialog] = useState(false);
  const classes = useStyles();

  const { roleId } = getDataFromToken();
  const allowEdit = roleId !== roles.READ_ONLY;

  return (
    <div className={classes.actionContainer}>
      {allowEdit && (
        <ReusableButton
          label="Invite"
          onClick={() => {
            setOpenInviteUsersDialog(true);
          }}
        />
      )}

      {openInviteUsersDialog && (
        <InviteUsersDialog
          open={openInviteUsersDialog}
          onClose={(_, reason) => {
            if (reason && reason === 'backdropClick') {
              return;
            }
            setOpenInviteUsersDialog(false);
          }}
        />
      )}
    </div>
  );
};

export default InviteUsersTableActions;
