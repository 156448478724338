import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ThunkGetOptInSettings } from '@store/slices/optInSettings/thunks';
import { ThunkGetOptInSmsTemplate } from '@store/slices/optIn/thunks';
import { ThunkGetCrmSources } from '@store/slices/treeView/thunks';
import { crmSourcesSelector } from '@store/slices/treeView';

import crmSources from '@constants/crmSources';

import Loading from '@common/Loading/Loading';
import OptInNumberDestination from '@ui/pages/optIn/OptInSettings/components/OptInNumberDestination';
import OptInConfiguration from './components/OptInConfiguration';
import OptInBrandingSettings from './components/OptInBrandingSettings';
import OptInTemplate from './components/OptInTemplate';

import useStyles from './styles';

const OptInSettings = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const crmSourceList = useSelector(crmSourcesSelector);

  const [loading, setLoading] = useState(true);

  const onMount = useCallback(() => {
    setLoading(true);
    Promise.all([
      dispatch(ThunkGetOptInSettings()),
      dispatch(ThunkGetOptInSmsTemplate()),
      dispatch(ThunkGetCrmSources()),
    ]).then(() => setLoading(false));
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  const crmId = useMemo(
    () =>
      crmSourceList.find(
        crmSource => crmSource.crmType === crmSources.ConnectWise.name,
      )?.id,
    [crmSourceList],
  );

  if (loading) return <Loading />;

  return (
    <div className={classes.container}>
      <OptInConfiguration />
      <OptInBrandingSettings />
      <OptInTemplate />
      <OptInNumberDestination crmId={crmId} />
    </div>
  );
};

export default OptInSettings;
