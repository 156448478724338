import React from 'react';
import PropTypes from 'prop-types';

const EmailLinkWrapper = ({ text }) => {
  const emailRegex = /([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/g;
  const parts = text.split(emailRegex);

  return (
    <p>
      {parts.map(part =>
        emailRegex.test(part) ? (
          <a key={part} href={`mailto:${part}`}>
            {part}
          </a>
        ) : (
          part
        ),
      )}
    </p>
  );
};

EmailLinkWrapper.propTypes = {
  text: PropTypes.string.isRequired,
};

export default EmailLinkWrapper;
