import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { TWILIO_ACCOUNTS } from '@store/slices/resources';
import {
  ThunkCreateTwilioAccount,
  ThunkDeleteTwilioAccount,
  ThunkEditTwilioAccount,
  ThunkGetTwilioAccounts,
  ThunkGetTwilioAccountsServices,
} from './thunks';

const initialState = {
  twilioAccounts: [],
  services: [],
  testCheckTwilioAccount: false,
  loading: false,
};

const twilioAccounts = createSlice({
  name: TWILIO_ACCOUNTS,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(ThunkGetTwilioAccounts.fulfilled, (state, { payload }) => {
        state.twilioAccounts = payload;
        state.loading = false;
      })
      .addCase(
        ThunkGetTwilioAccountsServices.fulfilled,
        (state, { payload }) => {
          state.services = payload;
        },
      )
      .addCase(ThunkCreateTwilioAccount.fulfilled, (state, { payload }) => {
        state.twilioAccounts = [...state.twilioAccounts, payload];
      })
      .addCase(ThunkEditTwilioAccount.fulfilled, (state, { payload }) => {
        state.twilioAccounts = state.twilioAccounts.map(twilioAccount =>
          twilioAccount.id === payload.id ? payload : twilioAccount,
        );
      })
      .addCase(ThunkDeleteTwilioAccount.fulfilled, (state, { payload }) => {
        state.twilioAccounts = state.twilioAccounts.filter(
          twilioAccount => twilioAccount.id !== payload,
        );
      })
      .addMatcher(isAnyOf(ThunkGetTwilioAccounts.pending), state => {
        state.loading = true;
      });
  },
});

export default twilioAccounts.reducer;

export const TwilioAccountsSelector = state =>
  state[TWILIO_ACCOUNTS].twilioAccounts;

export const TwilioAccountsServicesSelector = state =>
  state[TWILIO_ACCOUNTS].services;
