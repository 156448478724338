import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Form } from 'react-final-form';

import { notificationDataViewSelector } from '@store/selectors';
import { voiceSettings } from '@store/selectors/notifications';
import useTicketSetting from '@components/Ticketing/Notifications/hooks/useTicketSetting';
import { actions } from '@store/actions';

import {
  getVoiceSettings,
  postVoiceSettings,
} from '@components/Ticketing/Notifications/TicketSettings/helpers';
import useTemplateVariables from '@components/Ticketing/Notifications/hooks/useTemplateVariables';
import { handleDoWChange } from '@components/Ticketing/Notifications/utils';
import useDisabled from '@components/Hooks/useDisabled';
import { basePermissions } from '@constants/permissions';
import { smsSettingsValidator } from '@components/Ticketing/Notifications/validators';
import sanitizedNumber from '@utils/sanitizedPhoneNumber';
import { TICKETING, TICKETING_CREATE } from '@constants/routes';

import Paper from '@common/Paper/Paper';
import FieldWrapper from '@common/form/FieldWrapper';
import {
  InputWithTemplate,
  IsScheduledSelector,
  ScheduledPartOfForm,
  SubFormButtons,
} from '@components/Ticketing/Notifications/TicketSettings/components/subComponents';
import Loading from '@common/Loading/Loading';
import PhoneInput from './subComponents/PhoneInput';

import useStyles from './styles';

const VoiceSettings = ({ match }) => {
  const classes = useStyles();

  const dataViewId = useSelector(notificationDataViewSelector);
  const storedData = useSelector(voiceSettings);

  const { submit, loading, handleBack, data, setData } = useTicketSetting({
    storedData,
    paramsId: match.params.id,
    action: actions.saveVoiceSetting(),
    tileKey: 'isSms',
    apiPostRequest: postVoiceSettings,
    apiGetSetting: getVoiceSettings,
    handleBackUrl:
      match.params.id === '0'
        ? TICKETING_CREATE
        : `${TICKETING}/${match.params.id}`,
  });

  const { templateVariables } = useTemplateVariables({
    paramsId: match.params.id,
    dataViewId,
  });

  const onDoWChange = handleDoWChange(setData);

  const { editMode, disabled } = useDisabled(
    loading,
    'notificationSettings',
    basePermissions.update,
  );

  const dataLoaded = typeof data.id !== 'undefined';

  return dataLoaded ? (
    <Paper>
      <Form
        onSubmit={values =>
          submit({
            ...values,
            phoneNumber: values.phoneNumber.map(sanitizedNumber),
          })
        }
        validate={smsSettingsValidator}
        initialValues={data}
        render={({ handleSubmit, values, form }) => (
          <form onSubmit={handleSubmit} noValidate>
            <div>
              <FieldWrapper
                label="Phone number"
                labelSize={3}
                content={
                  <div className={classes.ticketSubject}>
                    <PhoneInput
                      form={form}
                      disabled={disabled}
                      templateVariables={templateVariables}
                    />
                  </div>
                }
              />
              <FieldWrapper
                label="Message"
                labelSize={3}
                content={
                  <InputWithTemplate
                    name="template"
                    disabled={disabled}
                    form={form}
                    templateVariables={templateVariables}
                  />
                }
              />
              <IsScheduledSelector values={values} disabled={disabled} />
              <ScheduledPartOfForm
                data={data}
                values={values}
                disabled={disabled}
                onDoWChange={onDoWChange}
              />
            </div>
            <SubFormButtons
              loading={loading}
              handleBack={handleBack}
              editMode={editMode}
            />
          </form>
        )}
      />
    </Paper>
  ) : (
    <Loading />
  );
};

VoiceSettings.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string }),
  }),
};

export default VoiceSettings;
