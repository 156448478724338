import React from 'react';
import { useLogout } from 'react-admin';
import { ExitToApp as ExitToAppIcon } from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';

import {
  removeTenantImageFromStorage,
  removeUserDataFromStorage,
} from '@services/api';
import { useDispatch } from 'react-redux';

import { actions } from '@store/actions';
import useStyles from './styles';

const Logout = () => {
  const logout = useLogout();
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <div
      role="presentation"
      onClick={e => {
        logout(e).then(() => {
          removeTenantImageFromStorage();
          removeUserDataFromStorage();
          dispatch(actions.deleteProfile());
          dispatch(actions.setPricePlan({}));
        });
      }}
      className={classes.logout}
    >
      <Tooltip title="Log out">
        <ExitToAppIcon />
      </Tooltip>
    </div>
  );
};

export default Logout;
