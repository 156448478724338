import React, { useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';

import ReusableButton from '@ui/components/common/Button/Button';
import ConfirmDialog from '@ui/components/common/ConfirmDialog/ConfirmDialog';
import AssignDialog from '@ui/components/Audit/dialogs/AssignDialog';

import { actions } from '@store/actions';
import { listSelector } from '@store/selectors';
import useBulkDelete from '@ui/components/Hooks/useBulkDelete';
import {
  addIncidentAssignees,
  deleteIncidents,
} from '@ui/components/Triggers/helpers';
import { getDataFromToken } from '@utils/getDataFromToken';

import iconAcknowledge from '@assets/icons/shield.svg';
import iconAcknowledgeActive from '@assets/icons/shield_done.svg';
import { useTableContext } from '../../../../../../hooks/table';

import useStyles from '../../../styles';

const BulkActions = ({ users }) => {
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [assignDialogOpen, setAssignDialogOpen] = useState(false);

  const { selectedIds, deselectAllItems } = useTableContext();
  const { userId } = getDataFromToken();
  const list = useSelector(listSelector);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const selectedIncidences = useMemo(
    () => list.filter(item => selectedIds.includes(item.id)),
    [selectedIds, list],
  );
  const isAllNotAcknowledged = useMemo(
    () => selectedIncidences.every(item => item.incidentStatus === 0),
    [selectedIncidences],
  );
  const incidentIds = useMemo(
    () => selectedIds.map(id => `incidentId=${id}`).join('&'),
    [selectedIds],
  );

  const handleDeleteIncidents = useBulkDelete(
    deleteIncidents,
    setConfirmationDialogOpen,
  );

  const handleAddAssignee = assigneeId => {
    addIncidentAssignees(assigneeId, incidentIds)
      .then(res => {
        res.forEach(item => dispatch(actions.updateItemInList(item)));
      })
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }))
      .finally(() => {
        setAssignDialogOpen(false);
        deselectAllItems();
      });
  };

  const handleAddAcknowledge = assigneeId => {
    addIncidentAssignees(
      assigneeId,
      incidentIds,
      isAllNotAcknowledged ? 'add' : 'remove',
    )
      .then(res => {
        res.forEach(item => dispatch(actions.updateItemInList(item)));
      })
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }))
      .finally(() => deselectAllItems());
  };

  return (
    <div className={classes.bulkContainer}>
      <ReusableButton
        size="md"
        label="Assign"
        onClick={() => {
          setAssignDialogOpen(true);
        }}
      />
      <ReusableButton
        size="md"
        label="Acknowledge"
        onClick={() => {
          handleAddAcknowledge(userId);
        }}
      >
        <img
          src={isAllNotAcknowledged ? iconAcknowledge : iconAcknowledgeActive}
          alt="Acknowledge"
        />
        Acknowledge
      </ReusableButton>
      <ReusableButton
        size="md"
        viewType="red"
        label="Delete"
        onClick={() => setConfirmationDialogOpen(true)}
      />

      <ConfirmDialog
        title="Delete Incidents"
        content="Are you sure you want to delete the selected incidents?"
        open={confirmationDialogOpen}
        setOpen={setConfirmationDialogOpen}
        onCancel={() => setConfirmationDialogOpen(false)}
        onAccept={handleDeleteIncidents}
      />

      <AssignDialog
        open={assignDialogOpen}
        onClose={() => {
          setAssignDialogOpen(false);
        }}
        onSelect={handleAddAssignee}
        users={users}
      />
    </div>
  );
};

export default BulkActions;
