import { TWILIO_ACCOUNTS } from '@services/requests/requestResources';
import { makeRequest } from '../makeRequest';

export const testCheckTwilioAccounts = payload =>
  makeRequest('POST', `${TWILIO_ACCOUNTS}/test`, payload);
export const getTwilioAccounts = () => makeRequest('GET', TWILIO_ACCOUNTS);
export const getTwilioAccount = id =>
  makeRequest('GET', `${TWILIO_ACCOUNTS}/${id}`);

export const getMessagingService = (id, phoneSid) =>
  makeRequest(
    'GET',
    `${TWILIO_ACCOUNTS}/${id}/messaging-service-check/${phoneSid}`,
    undefined,
    undefined,
    false,
  );

export const getMessagingServices = id =>
  makeRequest('GET', `${TWILIO_ACCOUNTS}/${id}/messaging-services`);

export const createTwilioAccount = payload =>
  makeRequest('POST', TWILIO_ACCOUNTS, payload);

export const importPhoneNumber = payload =>
  makeRequest('POST', `${TWILIO_ACCOUNTS}/import-phone-number`, payload);

export const editTwilioAccount = payload =>
  makeRequest('PUT', `${TWILIO_ACCOUNTS}/${payload.id}`, payload);

export const deleteTwilioAccount = id =>
  makeRequest('DELETE', `${TWILIO_ACCOUNTS}/${id}`);
