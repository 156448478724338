import nationalFormatPhoneNumber from '@utils/nationalFormatPhoneNumber';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

export const formatLabelWithPhone = ({ value, label }, countryCode) => {
  const formattedNumber = nationalFormatPhoneNumber(value, countryCode);
  const phoneNumber = parsePhoneNumberFromString(value, countryCode);
  return {
    label: label.replace(`<${value}>`, formattedNumber),
    number: formattedNumber,
    name: label.replace(`<${value}>`, '').trim(),
    value: phoneNumber?.number || value,
  };
};

export const stripSpacesDashesBrackets = (value = '') =>
  value.replace(/[\s\-()]/g, '');
