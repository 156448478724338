import React from 'react';
import PropTypes from 'prop-types';
import LettersList from './LettersList';

const Emails = ({ mailBoxId }) => {
  const resource = 'letters/'.concat(mailBoxId);

  return <LettersList resource={resource} mailBoxId={mailBoxId} />;
};

Emails.propTypes = {
  mailBoxId: PropTypes.string.isRequired,
};

export default Emails;
