import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import PropTypes from 'prop-types';

import {
  notificationDataViewSelector,
  notificationSettingSource,
} from '@store/selectors/notifications';
import { actions } from '@store/actions';
import { basePermissions } from '@constants/permissions';
import parseBoolean from '@utils/parseBoolean';
import useDisabled from '@components/Hooks/useDisabled';
import useTicketingSettings from '@components/Ticketing/Notifications/hooks/useTicketingSettings';
import useTemplateVariables from '@components/Ticketing/Notifications/hooks/useTemplateVariables';

import Loading from '@common/Loading/Loading';
import DropDown from '@components/Auth/Common/DropDown';
import FieldWrapper from '@common/form/FieldWrapper';
import Paper from '@common/Paper/Paper';
import RadioInput from '@common/Radio/RadioInput';
import {
  InputWithTemplate,
  ScheduledPartOfForm,
  SubFormButtons,
} from './subComponents';
import useTicketSetting from '../../hooks/useTicketSetting';
import Section from './subComponents/Section';

import { getTicketSettings, postTicketSettings } from '../helpers';

import useStyles from './styles';

const TicketingSettings = ({ match }) => {
  const classes = useStyles();
  const dataViewId = useSelector(notificationDataViewSelector);
  const settingSource = useSelector(notificationSettingSource);

  const [companyFieldNameOptions, setCompanyFieldNameOptions] = useState([]);

  const paramsId = match.params.id;
  const {
    submit,
    loading,
    handleBack,
    data,
    setData,
    isCreate,
  } = useTicketSetting({
    paramsId: match.params.id,
    action: actions.saveTicketSetting,
    tileKey: 'isTicket',
    preventOnGettingSetting: true,
    apiPostRequest: postTicketSettings,
    apiGetSetting: getTicketSettings,
  });

  const {
    defaultsOptions,
    crmOptions,
    handleCrmChanged,
    allDefaults,
  } = useTicketingSettings({
    paramsId,
    setData,
    isCreate,
    getDetailsFunc: getTicketSettings,
  });

  const { templateVariables } = useTemplateVariables({
    paramsId: match.params.id,
    dataViewId,
  });

  useEffect(() => {
    const options = templateVariables.map(i => ({
      label: i.label,
      value: i.value.substring(2, i.value.length - 1),
    }));
    setCompanyFieldNameOptions(options);
  }, [templateVariables]);

  const { editMode, disabled } = useDisabled(
    loading,
    'notificationSettings',
    basePermissions.update,
  );

  const dataLoaded = typeof data.id !== 'undefined' && !!allDefaults;

  return dataLoaded ? (
    <Paper>
      <Form
        onSubmit={values =>
          submit({
            ...values,
            ticketOperation: parseBoolean(values.ticketOperation),
            isScheduled: parseBoolean(values.isScheduled),
          })
        }
        initialValues={{
          ...data,
          ticketOperation: data.ticketOperation ? 'true' : 'false',
          isScheduled: data.isScheduled ? 'true' : 'false',
        }}
        render={({ handleSubmit, values, form }) => (
          <form className={classes.form} onSubmit={handleSubmit} noValidate>
            <div className={classes.contentFormWrapper}>
              <Section title="Action">
                <Field
                  name="ticketOperation"
                  id="ticketOperation"
                  component={RadioInput}
                  disabled={disabled}
                  choices={[
                    {
                      label: 'Create a new ticket',
                      value: 'false',
                    },
                    {
                      label: 'Close an existing ticket',
                      value: 'true',
                    },
                  ]}
                />
              </Section>
              <Section title="Configuration">
                <FieldWrapper
                  label="PSA"
                  labelSize={3}
                  content={
                    <div>
                      <Field
                        id="crm"
                        name="crm"
                        component={DropDown}
                        options={crmOptions}
                        disabled={disabled}
                      />
                      <OnChange name="crm">
                        {value => {
                          handleCrmChanged(value);
                        }}
                      </OnChange>
                    </div>
                  }
                />
                <FieldWrapper
                  label="Ticket Properties"
                  labelSize={3}
                  content={
                    <Field
                      id="crmTicketDefaultId"
                      name="crmTicketDefaultId"
                      component={DropDown}
                      options={defaultsOptions}
                      disabled={disabled || !defaultsOptions.length}
                    />
                  }
                />
                {!parseBoolean(values.ticketOperation) && (
                  <>
                    <FieldWrapper
                      label="Ticket Subject"
                      labelSize={3}
                      content={
                        <InputWithTemplate
                          name="ticketSubject"
                          disabled={disabled}
                          form={form}
                          templateVariables={templateVariables}
                        />
                      }
                    />
                    <FieldWrapper
                      label="Company name field"
                      labelSize={3}
                      infoText='Select field which will provide company name for ticket. Do not forget to assign <a href="#/localCompany">company to PSA companies</a>'
                      content={
                        <Field
                          id="companyNameField"
                          name="companyNameField"
                          options={companyFieldNameOptions}
                          component={DropDown}
                          disabled={disabled}
                        />
                      }
                    />
                    <FieldWrapper
                      label="Body"
                      labelSize={3}
                      content={
                        <InputWithTemplate
                          name="body"
                          disabled={disabled}
                          form={form}
                          templateVariables={templateVariables}
                          multiline
                          variant="outlined"
                        />
                      }
                    />
                  </>
                )}
              </Section>
              <Section title="Scheduling">
                {settingSource === 1 || (
                  <Field
                    name="isScheduled"
                    id="isScheduled"
                    component={RadioInput}
                    className={classes.isScheduledButtons}
                    choices={[
                      {
                        label: 'Immediate',
                        value: 'false',
                      },
                      { label: 'At a later date/time', value: 'true' },
                    ]}
                  />
                )}
                <ScheduledPartOfForm
                  values={{ isScheduled: parseBoolean(values.isScheduled) }}
                  disabled={disabled}
                />
              </Section>
            </div>
            <SubFormButtons
              loading={loading}
              handleBack={handleBack}
              editMode={editMode}
            />
          </form>
        )}
      />
    </Paper>
  ) : (
    <Loading />
  );
};

TicketingSettings.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string }),
  }),
};

export default TicketingSettings;
