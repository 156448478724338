import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ActionFieldItem from '@common/ActionButtons/ActionFieldItem';
import EditIcon from '@assets/icons/edit.svg';
import DeleteIcon from '@assets/icons/delete.svg';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';
import Loading from '@common/Loading/Loading';
import SimpleTable from '@components/Triggers/pages/Incident/components/SimpleTable';
import ReusableButton from '@common/Button/Button';

import {
  ThunkDeleteDynamicCrmFilters,
  ThunkGetFilterSetsAutocomplete,
} from '@store/slices/dynamicCrmContacts/thunks';
import {
  DynamicCrmLoadingSelector,
  DynamicCrmProcessingSelector,
  FilterSetsAutocompleteSelector,
} from '@store/slices/dynamicCrmContacts';
import {
  DYNAMIC_CRM_FILTER_CREATE,
  DYNAMIC_CRM_FILTER_EDIT,
} from '@constants/routes';

import useStyles from './styles';

const DynamicCrmFilters = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const history = useHistory();

  const list = useSelector(FilterSetsAutocompleteSelector);
  const processing = useSelector(DynamicCrmProcessingSelector);
  const loading = useSelector(DynamicCrmLoadingSelector);

  const [deleteId, setDeleteId] = useState();

  useEffect(() => {
    dispatch(ThunkGetFilterSetsAutocomplete());
  }, [dispatch]);

  const handleDelete = () => {
    dispatch(ThunkDeleteDynamicCrmFilters(deleteId)).then(() =>
      setDeleteId(null),
    );
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className={classes.container}>
      <ReusableButton
        label="Create a Dynamic Filter"
        onClick={() => history.push(DYNAMIC_CRM_FILTER_CREATE)}
        classNameWrapper={classes.createButton}
      />
      {!!list.length && (
        <SimpleTable
          selector={FilterSetsAutocompleteSelector}
          columns={[
            { label: 'Name', value: 'label' },
            {
              label: 'Actions',
              handler: item => (
                <div>
                  <ActionFieldItem
                    handler={() => {
                      history.push(
                        DYNAMIC_CRM_FILTER_EDIT.replace(':id', item.value),
                      );
                    }}
                    toolTip="Edit"
                    icon={EditIcon}
                  />
                  <ActionFieldItem
                    handler={() => {
                      setDeleteId(item.value);
                    }}
                    toolTip="Delete"
                    icon={DeleteIcon}
                  />
                </div>
              ),
              width: 100,
            },
          ]}
        />
      )}
      {deleteId && (
        <ConfirmDialog
          open
          onAccept={handleDelete}
          title="Confirm Action"
          onCancel={() => {
            setDeleteId(null);
          }}
          content="Are you sure you want to delete this item?"
          loading={processing}
        />
      )}
    </div>
  );
};

export default DynamicCrmFilters;
