import React, { useCallback, useState } from 'react';
import { Dialog, DialogContent, FormHelperText } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import PropTypes from 'prop-types';

import DialogTitle from '@common/Dialog/DialogTitle';
import FieldWrapper from '@common/form/FieldWrapper';
import Input from '@components/Auth/Common/Input';
import ReusableButton from '@common/Button/Button';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import iconCopy from '@assets/icons/copy.svg';

import { requiredValidator } from '@utils/validators';
import copyText from '@utils/copy';
import {
  getMsAuthRedirectUri,
  setupMsAuthGdapIntegration,
} from '@services/requests/securityIntegrations';

import useStyles from '../styles';

const M365GDAPSetupDialog = ({ onClose }) => {
  const [processing, setProcessing] = useState(false);

  const classes = useStyles();

  const submit = values => {
    const { redirectUri, ...payload } = values;

    setProcessing(true);
    setupMsAuthGdapIntegration({
      ...payload,
      redirectId: redirectUri.split('/').at(-1),
    })
      .then(({ data }) => {
        window.location.href = data;
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const handleCopy = useCallback(async (redirectUri, onChange) => {
    try {
      setProcessing(true);

      const uri = redirectUri || (await getMsAuthRedirectUri()).data;

      await copyText(uri);

      if (!redirectUri) {
        onChange('redirectUri', uri);
      }
    } catch (error) {
      console.error('Failed to copy redirect URI:', error);
    } finally {
      setProcessing(false);
    }
  }, []);

  const validate = values => ({
    domainName: requiredValidator(values.domainName),
    clientId: requiredValidator(values.clientId),
    clientSecret: requiredValidator(values.clientSecret),
    redirectUri: values.redirectUri
      ? undefined
      : 'Copy the link before proceeding',
  });

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>Connect to Microsoft 365 (GDAP)</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Form
          onSubmit={submit}
          validate={validate}
          initialValues={{
            domainName: '',
            clientSecret: '',
            clientId: '',
            redirectUri: '',
          }}
          render={({ handleSubmit, values, form, errors, submitFailed }) => (
            <form onSubmit={handleSubmit}>
              <FieldWrapper
                label="Domain"
                contentSize={12}
                labelSize={12}
                content={
                  <Field
                    placeholder="Domain"
                    name="domainName"
                    id="domainName"
                    render={Input}
                    disabled={processing}
                  />
                }
              />
              <FieldWrapper
                label="Client ID"
                contentSize={12}
                labelSize={12}
                content={
                  <Field
                    placeholder="Client ID"
                    name="clientId"
                    id="clientId"
                    render={Input}
                    disabled={processing}
                  />
                }
              />
              <FieldWrapper
                label="Client Secret"
                contentSize={12}
                labelSize={12}
                content={
                  <Field
                    className={classes.secretField}
                    placeholder="Client Secret"
                    name="clientSecret"
                    id="clientSecret"
                    render={Input}
                    disabled={processing}
                  />
                }
              />

              <div className={classes.url}>
                <div>Link for Redirect URI</div>
                <ActionButton
                  icon={<img src={iconCopy} alt="iconCopy" />}
                  handler={() => {
                    handleCopy(values.redirectUri, form.change);
                  }}
                  toolTip="Copy URL"
                />
                {submitFailed && errors.redirectUri && (
                  <FormHelperText className={classes.errorMessage}>
                    {errors.redirectUri}
                  </FormHelperText>
                )}
              </div>

              <div className={classes.buttonsContainer}>
                <ReusableButton
                  label="Close"
                  onClick={onClose}
                  disabled={processing}
                />

                <ReusableButton
                  label="Submit"
                  loading={processing}
                  disabled={processing}
                  type="submit"
                  viewType="blue"
                />
              </div>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

M365GDAPSetupDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default M365GDAPSetupDialog;
