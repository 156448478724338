import React, { useContext, useMemo } from 'react';
import { TablePagination as MUITablePagination } from '@material-ui/core';

import PropTypes from 'prop-types';
import { TableContext } from '../context';
import { TablePaginationTypes } from '../constants';

const TablePagination = ({ setCustomPaginationProps }) => {
  const { totalCount, page, rowsPerPage, setPage, setRowsPerPage } = useContext(
    TableContext,
  );

  const paginationProps = useMemo(
    () =>
      setCustomPaginationProps
        ? setCustomPaginationProps({ totalCount, page, setPage })
        : {
            count: totalCount,
            page,
            rowsPerPageOptions: TablePaginationTypes,
            rowsPerPage,
            onRowsPerPageChange: e => {
              if (page * +e.target.value > totalCount) {
                setPage(Math.floor(totalCount / +e.target.value));
              }
              setRowsPerPage(+e.target.value);
            },
            onPageChange: (e, b) => {
              setPage(b);
            },
          },
    [
      page,
      rowsPerPage,
      setCustomPaginationProps,
      setPage,
      setRowsPerPage,
      totalCount,
    ],
  );

  return <MUITablePagination count={totalCount} {...paginationProps} />;
};

TablePagination.propTypes = {
  setCustomPaginationProps: PropTypes.func,
};

export default TablePagination;
