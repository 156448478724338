import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import crmSources from '@constants/crmSources';
import Microsoft365Table from '@components/Ticketing/Integrations/pages/updateCrm/Table/Microsoft365Table/Microsoft365Table';
import CrmDefaults from '@components/Ticketing/Integrations/pages/updateCrm/CrmDefaults';
import InternalIntegrationTable from '@components/Ticketing/Integrations/pages/updateCrm/Table/InternalIntegrationTable/InternalIntegrationTable';

const CrmDefaultsArea = ({ crmType, id, handleShowCrmInfoFormChange }) => {
  return useMemo(() => {
    switch (crmType) {
      case crmSources.Microsoft.name:
        return <Microsoft365Table crmId={id} />;
      case crmSources.ImportIntegration.name:
        return <InternalIntegrationTable id={id} />;
      default:
        return (
          <CrmDefaults
            id={id}
            crmType={crmType}
            handleShowCrmInfoFormChange={handleShowCrmInfoFormChange}
          />
        );
    }
  }, [crmType, handleShowCrmInfoFormChange, id]);
};

CrmDefaultsArea.propTypes = {
  crmType: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  handleShowCrmInfoFormChange: PropTypes.func.isRequired,
};

export default CrmDefaultsArea;
