const minMaxNumberValidator = (
  value: number,
  min: number = 0,
  max: number = 100,
  isRequired = true,
  outputMinTextFunc = minValue => `field has min value: ${minValue}`,
  outputMaxTextFunc = maxValue => `field has max value: ${maxValue}`,
) => {
  if (isRequired && !value) {
    return 'ra.validation.required';
  }

  if (+value === undefined) {
    return 'Value must be a number';
  }

  if (+value > max) {
    return outputMaxTextFunc(max);
  }

  if (+value < min) {
    return outputMinTextFunc(min);
  }

  return undefined;
};

export default minMaxNumberValidator;
