import { createAsyncThunk } from '@reduxjs/toolkit';
import { editDynamicCrmContact } from '@services/requests/dynamicCrmContacts';
import { DYNAMIC_CRM_CONTACTS } from '@store/slices/resources';

export const ThunkEditDynamicCrmContact = createAsyncThunk(
  `${DYNAMIC_CRM_CONTACTS}/editDynamicCrmContacts`,
  payload => {
    return editDynamicCrmContact(payload);
  },
);

export default {};
