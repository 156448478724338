import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    padding: '16px 16px 0 11px',
    '& tbody': {
      maxHeight: '79vh',
    },
  },
  center: {
    textAlign: 'center',
    width: '100%',
  },
}));

export default useStyles;
