import {
  adminsPermission,
  allPricePlans,
  allUsersPermission,
  PricePlans,
} from '@components/Layout/SideBar/sideBarItems';

export const steps = [
  {
    id: 0,
    label: 'Basics',
    pricePlans: allPricePlans,
    roles: allUsersPermission,
  },
  {
    id: 1,
    label: 'PSA',
    pricePlans: allPricePlans,
    roles: adminsPermission,
  },
  {
    id: 2,
    label: 'Messaging',
    pricePlans: [PricePlans.Pro],
    roles: adminsPermission,
  },
  {
    id: 3,
    label: 'Invite Technicians',
    pricePlans: allPricePlans,
    roles: adminsPermission,
  },
  {
    id: 4,
    label: 'Verification',
    pricePlans: [PricePlans.VerificationPlus, PricePlans.Pro],
    roles: adminsPermission,
  },
  {
    id: 5,
    label: 'Outgoing Email',
    pricePlans: [PricePlans.VerificationPlus, PricePlans.Pro],
    roles: adminsPermission,
  },
];

export default {};
