import LocalStorage from '@constants/localStorage';
import { parseJwtToken } from '@services/api';
import roles from '@constants/roles';

const roleClaim =
  'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';

export const getDataFromToken = () => {
  const accessToken = localStorage.getItem(LocalStorage.ACCESS_TOKEN);

  if (!accessToken) {
    return {};
  }

  const { Id, tenantId, ...rest } = parseJwtToken(accessToken);

  return {
    userId: Id ?? undefined,
    tenantId,
    roleId: +rest[roleClaim] ?? roles.READ_ONLY,
  };
};

export default {};
