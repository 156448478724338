import React, { Suspense } from 'react';
import { Route } from 'react-router-dom';
import {
  ASSIGN_TICKET_TO_TECH_REQUEST,
  AT_TICKET_TIME_ENTRIES,
  AUTOTASK_POD,
  AUTOTASK_POD_WIDGET,
  AUTOTASK_SMS,
  AVAILABLE_PHONE_NUMBERS,
  BILLING,
  CHAT_BOTS,
  CHATS,
  MESSAGING_NUMBERS,
  CLIENT_PORTAL_CHATS,
  CLIENT_PORTAL_CHAT_DETAILS,
  CLIENT_PORTAL_COMPANY_MAPPING,
  CLIENT_PORTAL_CONFIGS,
  CLIENT_PORTAL_DEFAULTS,
  CLIENT_PORTAL_USERS,
  COMPANIES_VERIFICATION_SETTINGS,
  CONNECTWISE_POD,
  CREATE_ESCALATION_RULES,
  CREATE_GENERIC_SOURCE,
  CREATE_MESSAGING_CHANNEL,
  CREATE_PARSER,
  CREATE_SCHEDULE,
  CREATE_TICKET,
  CREATE_USER,
  CRM_CREATE,
  CRM_TICKET_EDIT,
  CRM_TICKET_SCHEDULE_ENTRIES,
  CRM_TICKET_TIME_ENTRIES,
  CRM_UPDATE,
  DASHBOARD,
  DATA,
  DATA_EXPORT,
  DATA_SOURCES_INTEGRATION_CREATE,
  DATA_SOURCES_INTEGRATION_EDIT,
  DATA_VIEW_CREATE,
  DATA_VIEW_EDIT,
  DATA_VIEWS,
  DEMO_REGISTRATION,
  DEVICE_MONITORS,
  DEVICE_MONITORS_CREATE,
  DEVICE_MONITORS_DETAILS,
  DEVICE_MONITORS_DETAILS_TV_MODE,
  DEVICE_MONITORS_EXPORT,
  DEVICE_MONITORS_UPDATE,
  DM_SETTINGS_CREATE,
  DM_SETTINGS_GROUP_CREATE,
  DM_SETTINGS_UPDATE,
  DOCUMENTS,
  DUO_LOGIN,
  EDIT_CLIENT_PORTAL_USER,
  EDIT_ESCALATION_RULES,
  EDIT_GENERIC_SOURCE,
  GENERIC_SOURCE_VIEW_LOGS,
  EDIT_PRICE_PLANS,
  EDIT_SCHEDULE,
  EDIT_USER,
  EMAIL_GROUP_DETAILS,
  EMAIL_HANDLER_ROUTE,
  EMAIL_SETTINGS,
  ESCALATION_RULES,
  FILE_UPLOAD,
  FIRST_MAILBOX,
  FORGOT_PASSWORD_ROUTE,
  GENERIC_SOURCES,
  HALO_POD,
  HELP_PAGE,
  HOME_ROUTE,
  INCIDENT,
  INCIDENTS,
  INCIDENTS_LIST,
  INTEGRATIONS,
  INVITED_USERS,
  INVOICES,
  ISSUE_TYPE,
  ISSUE_TYPE_CREATE,
  ISSUE_TYPES,
  LETTER,
  LIVE_CHAT,
  LIVE_CHAT_CONFIGURATIONS,
  LIVE_CHAT_CONVERSATIONS,
  LIVE_CHAT_CREATE_TICKET,
  LIVE_CHAT_DETAILS,
  MAIL_BOX,
  MERAKI_DASHBOARD,
  MERAKI_VIEW,
  MESSAGING_CHANNEL,
  MESSAGING_CHANNELS,
  MESSAGING_CONFIGURATION,
  MESSAGING_DASHBOARD,
  MESSAGING_TEMPLATES,
  MULTI_FACTOR_SETUP,
  NOTIFICATION_MONITOR_EMAIL_DM_SETTINGS,
  NOTIFICATION_MONITOR_SLACK_DM_SETTINGS,
  NOTIFICATION_MONITOR_SMS_DM_SETTINGS,
  NOTIFICATION_MONITOR_TEAMS_DM_SETTINGS,
  NOTIFICATION_MONITOR_TICKET_DM_SETTINGS,
  NOTIFICATION_MONITOR_WEB_HOOK_DM_SETTINGS,
  NOTIFICATIONS_DATA,
  ONE_TIME_PASSWORD,
  OPT_IN_FORM,
  OPT_IN_LIST,
  OPTIN_SETTINGS,
  PASSWORDS,
  PRICING_PLAN,
  PROFILE_ROUTE,
  PSA_CONTACTS,
  PSA_TECH_VERIFICATION,
  PSA_TICKETS,
  PSA_VALIDATION,
  PURCHASE_PHONE_NUMBERS,
  RESET_PASSWORD,
  SCHEDULE_GROUP,
  SCHEDULE_USER,
  SCHEDULER_PAGE,
  SECURE_DATA,
  SECURE_DATA_SETTINGS,
  SECURITY_INTEGRATION,
  SECURITY_INTEGRATION_CREATE,
  SECURITY_INTEGRATION_EDIT,
  SETUP_WIZARD,
  SHARED_BILLING,
  SHARED_EMAIL,
  SMS_BLACK_LIST,
  BLOCKED_SENDERS,
  SMS_CAMPAIGN_BUILDER,
  SMS_CAMPAIGNS,
  MESSAGE_TEMPLATES,
  SMS_GROUP_IMPORT_FROM_PSA,
  SOPHOS_DASHBOARD,
  SOPHOS_VIEW,
  TEAMS_CONNECTIONS,
  TENANT_MODULES,
  TENANT_MODULES_TEMPLATE,
  TEST_EMAIL,
  TICKETING_CREATE,
  TICKETING_EMAIL_SETTINGS,
  TICKETING_FTP_SETTINGS,
  TICKETING_SLACK_SETTINGS,
  TICKETING_SMS_SETTINGS,
  TICKETING_TEAMS_SETTINGS,
  TICKETING_TICKET_SETTINGS,
  TICKETING_UPDATE,
  TICKETING_VOICE_SETTINGS,
  TICKETING_WH_SETTINGS,
  TRIGGER,
  TRIGGER_CREATE,
  TRIGGER_UPDATE,
  UNRECOGNIZED_TYPES,
  UPDATE_PARSER,
  USER_INVITE,
  USERS,
  VERIFICATION_HISTORY,
  VERIFICATION_SETTINGS,
  WEB_HOOKS_CREATE,
  WEB_HOOKS_EDIT,
  WEB_HOOKS_RESULT,
  WEB_HOOKS_RESULT_UNRECOGNIZED,
  WEB_HOOKS_RESULTS,
  OUTGOING_MAIL,
  PSA_TICKETS_LOGS,
  NOTIFICATION_MONITOR_VOICE_DM_SETTINGS,
  HELPDESK_AI,
  COMPANY_MAPPING,
  NOTIFICATIONS_GROUPS,
  API_KEYS,
  AI_PRICE_PLAN,
  TEAMS_BOT_CONFIGURATION,
  NOTIFICATION_LOGS,
  DYNAMIC_CRM_FILTERS,
  DYNAMIC_CRM_FILTER_CREATE,
  DYNAMIC_CRM_FILTER_EDIT,
  USER_ACTIVITY_LOGS,
  NOTIFICATIONS_DATA_MONITOR,
  CUSTOM_AGENT_AI,
  TWILIO_ACCOUNTS,
  TWILIO_ACCOUNTS_NUMBERS,
} from '@constants/routes';
import EmailHandler from '@components/EmailHandler';
import Profile from '@components/Profile';
import PricingPlan from '@components/PricingPlan/PricingPlan';
import FirstMailBox from '@components/FirstMailBox/FirstMailBox';
import Billing from '@ui/pages/billing/Billing';
import CreateParser from '@components/Parsers/components/Create/CreateParser';
import MailBox from '@components/MailBox/MailBox';
import Letter from '@components/Letter/Letter';
import UpdateParser from '@components/Parsers/components/Create/UpdateParser';
import UnrecognizedTypes from '@components/UnrecognizedTypes/UnrecognizedTypes';
import CreateCrm from '@components/Ticketing/Integrations/pages/createCrm/CreateCrm';
import UpdateCrm from '@components/Ticketing/Integrations/pages/updateCrm/UpdateCrm';
import CreateTicketSetting from '@components/Ticketing/Notifications/TicketSettings/CreateNotificationSetting';
import UpdateTicketSettings from '@components/Ticketing/Notifications/TicketSettings/UpdateNotificationSettings';
import TicketingSettings from '@components/Ticketing/Notifications/TicketSettings/components/TicketingSettings';
import EmailSettings from '@components/Ticketing/Notifications/TicketSettings/components/EmailSettings';
import SmsSettings from '@components/Ticketing/Notifications/TicketSettings/components/SmsSettings';
import MultiFactorSetup from '@components/MultiFactorSetup/MultiFactorSetup';
import WebHookSettings from '@components/Ticketing/Notifications/TicketSettings/components/WebHookSettings';
import ExportPage from '@components/Audit/Export/ExportPage';
import SlackSettings from '@components/Ticketing/Notifications/TicketSettings/components/SlackSettings';
import TeamsSettings from '@components/Ticketing/Notifications/TicketSettings/components/TeamsSettings';
import FtpSettings from '@components/Ticketing/Notifications/TicketSettings/components/FtpSettings';
import VoiceSettings from '@components/Ticketing/Notifications/TicketSettings/components/VoiceSettings';
import DmVoiceSettings from '@components/Ticketing/Notifications/DmSettings/components/VoiceSettings';
import HelpPage from '@components/HelpPage/HelpPage';
import UpdateDeviceMonitors from '@components/DeviceMonitors/pages/Edit';
import CreateDeviceMonitors from '@components/DeviceMonitors/pages/Create';
import DetailsDeviceMonitors from '@components/DeviceMonitors/pages/Details';
import DetailsTVMode from '@components/DeviceMonitors/pages/DetailsTVMode';
import DmSettingsFormUpdate from '@components/Ticketing/Notifications/DmSettings/DmSettingsFormUpdate';
import Notifications from '@components/Ticketing/Notifications/Notifications';
import DmSettingFormCreate from '@components/Ticketing/Notifications/DmSettings/DmSettingFormCreate';
import TicketingDMSettings from '@components/Ticketing/Notifications/DmSettings/components/TicketingSettings';
import EmailDMSettings from '@components/Ticketing/Notifications/DmSettings/components/EmailSettings';
import SmsDMSettings from '@components/Ticketing/Notifications/DmSettings/components/SmsSettings';
import WebHookDMSettings from '@components/Ticketing/Notifications/DmSettings/components/WebHookSettings';
import SlackDMSettings from '@components/Ticketing/Notifications/DmSettings/components/SlackSettings';
import TeamsDMSettings from '@components/Ticketing/Notifications/DmSettings/components/TeamsSettings';
import MonitoringExportPage from '@components/DeviceMonitors/Export/MonitoringExportPage';
import DmSettingGroupFormCreate from '@components/Ticketing/Notifications/DmSettings/DmSettingGroupFormCreate';
import Create from '@components/WebHooks/pages/Create';
import Edit from '@components/WebHooks/pages/Edit';
import DataSourcesIntegrationsCreate from '@components/DataSources/Integrations/pages/create/Create';
import DataSourcesIntegrationsEdit from '@components/DataSources/Integrations/pages/Edit';
import MerakiDashboard from '@components/DataSources/Dashboards/Meraki/MerakiDashboard';
import Meraki from '@components/DataSources/Integrations/pages/Meraki/Meraki';
import DemoRegistration from '@ui/pages/auth/DemoRegistration';
import SharedEmail from '@components/UnauthorizedPages/SharedEmail';
import SharedBillingLayout from '@components/UnauthorizedPages/SharedBilling';
import Sophos from '@components/DataSources/Integrations/pages/Sophos/Sophos';
import SophosDashboard from '@components/DataSources/Dashboards/Sophos/SophosDashboard';
import NotificationGroupDetails from '@components/Settings/NotificationGroups/NotificationGroupDetails';
import TriggerCreate from '@components/Triggers/components/TriggerCreate';
import TriggerUpdate from '@components/Triggers/components/TriggerUpdate';
import Chats from '@components/Chats/Chats';
import IncidentDetails from '@components/Triggers/pages/Incidents/IncidentDetails';
import EscalationRules from '@components/Triggers/pages/Escalation/EscalationRules';
import EscalationRulesForm from '@components/Triggers/pages/Escalation/EscalationRulesForm';
import TenantModules from '@components/Admin/TenantModules/TenantModules';
import Passwords from '@components/Settings/Passwords/Passwords';
import VerificationSettings from '@components/Settings/VerificationSettings/VerificationSettings';
import SmsBlackList from '@components/Settings/SmsBlackList/SmsBlackList';
import OptIn from '@components/Ticketing/ConnectwiseOptIn/OptIn';
import FileUpload from '@components/FileUpload';
import CreateTicket from '@components/CreateTicket';
import LiveChat from '@components/LiveChat/LiveChat';
import LiveChatConfigurations from '@components/LiveChatConfigurations';
import ChatBots from '@components/ChatBots';
import CreateTicketFromLiveChatDialog from '@components/LiveChat/dialogs/CreateTicketFromLiveChatDialog';
import ClientPortalDefaults from '@components/ClientPortalDefaults';
import LiveChatDetails from '@components/LiveChat/components/LiveChatDetails';
import ClientPortalConfigurations from '@components/ClientPortal/ClientPortalConfigurations';
import CwCompanyMapping from '@components/ClientPortalDefaults/components/CwCompanyMapping';
import ClientPortalChat from '@components/ClientPortalChat';
import PsaContactValidation from '@components/Validation/PsaContactValidation';
import VerificationHistory from '@components/VerificationHistory';
import SecureData from '@ui/pages/secureData/SecureData';
import UserInvite from '@components/UnauthorizedPages/UserInvite/UserInvite';
import InvitedUsers from '@components/InvitedUsers';
import SignUp from '@ui/pages/auth/SignUp';
import ForgotPassword from '@ui/pages/auth/ForgotPassword';
import IssueType from '@ui/pages/clientPortal/IssueType';
import IssueTypes from '@ui/pages/clientPortal/IssueTypes';
import ResetPassword from '@ui/pages/auth/ResetPassword';
import SmsGroups from '@ui/pages/smsCampaigns/SmsGroups/SmsGroups';
import SmsBroadcast from '@ui/pages/smsCampaigns/SmsBroadcast';
import TechnicianVerificationTable from '@ui/pages/technicianVerification/TechnicianVerificationTable/TechnicianVerificationTable';
import CompaniesVerificationSettings from '@ui/pages/endUserVerification/CompaniesVerificationSettings';
import SmsGroupsImportFromPSA from '@ui/pages/smsCampaigns/SmsGroupsImportFromPSA';
import MessagingNumbers from '@components/Admin/MessagingNumbers/MessagingNumbers';
import SetupWizardPage from '@ui/pages/user/SetupWizardPage';
import ClientPortalUsers from '@ui/pages/clientPortal/clientPortalUsers';
import SecureDataSettings from '@ui/pages/secureData/SecureDataSettings';
import AssignTicketToTechVerification from '@ui/pages/technicianVerification/AssignTicketToTechRequest/AssignTicketToTechVerification';
import SecurityIntegrations from '@ui/pages/securityIntegrations/SecurityIntegrations';
import CreateSecurityIntegrations from '@ui/pages/securityIntegrations/CreateSecurityIntegrations';
import EditSecurityIntegrations from '@ui/pages/securityIntegrations/EditSecurityIntegrations';
import EditClientPortalUser from '@ui/pages/clientPortal/editClientPortalUser';
import DuoLogin from '@ui/pages/duo/duoLogin';
import Invoices from '@ui/pages/billing/Invoices';
import EditPricePlan from '@ui/pages/billing/EditPricePlan';
import Integrations from '@components/Ticketing/Integrations/Integrations';
import Users from '@components/Users/List';
import Dashboard from '@components/Dashboard/Dashboard';
import AuditPage from '@components/Audit/AuditPage';
import AuditViews from '@components/AuditViews/List';
import DeviceMonitors from '@ui/pages/uptimeMonitors/DeviceMonitors';
import Incidents from '@components/Triggers/pages/Incidents/Incidents';
import Triggers from '@components/Triggers/Triggers';
import DataViewForm from '@components/AuditViews/components/DataViewForm';
import UserEdit from '@components/Users/Edit';
import UserCreate from '@components/Users/Create';

import AvailablePhoneNumbers from '@components/AvailablePhoneNumbers';
import OutgoingMail from '@ui/pages/OutgoingMail';
import GenericSourceLogs from '@components/GenericSources/pages/GenericSourcesLogs';
import HelpdeskAI from '@ui/pages/portalSettings/HelpdeskAI';
import PsaTicketsLogs from '@ui/pages/psa/PsaTicketsLogs';
import LocalCompany from '@components/Settings/LocaleCompany/components/LocalCompany';
import NotificationGroups from '@components/Settings/NotificationGroups';
import ApiKeys from '@components/Settings/ApiKeys';
import PodPlaceholder from '@ui/pages/pod/placeholder';
import Loading from '@common/Loading/Loading';
import AIPricePlan from '@ui/pages/billing/AIPricePlan';
import TeamsBotConfiguration from '@ui/pages/microsoft/TeamsBotConfiguration';
import NotificationLogs from '@components/Admin/NotificationLogs';
import DynamicFilters from '@components/Messaging/DynamicFilters';
import DynamicFilterForm from '@components/Messaging/DynamicFilters/DynamicFilterForm';
import UserActivityLogs from '@ui/pages/userActivityLogs/UserActivityLogs';
import CustomAgentAI from '@components/Admin/CustomAgentAI';
import TwilioAccounts from '@ui/pages/twilioAccounts';
import TwilioAccountsNumbers from '@ui/pages/twilioAccountsNumbers';
import DocumentsPage from './components/Documents/DocumentsPage';
import Incident from './components/Triggers/pages/Incident';
import WebHookResults from './components/WebHooks/pages/WebHookResults';
import WebHookResult from './components/WebHooks/pages/WebHookResult';
import WebHookResultUnrecognized from './components/WebHooks/pages/WebHookResultUnrecognized';
import GenericSources from './components/GenericSources/GenericSources';
import EditGenericSource from './components/GenericSources/pages/EditGenericSource';
import Schedule from './components/Schedule';
import EditScheduleRule from './components/Schedule/EditScheduleRule/EditScheduleRule';
import SchedulerPage from './components/Schedule/SchedulerPage';
import AutotaskNoteToSMS from './components/AutotaskNoteToSMS';
import TestEmail from './components/TestEmail';
import BufferPhoneNumbers from './components/BufferPhoneNumbers';
import MessagingConfigurations from './components/Messaging/MessagingConfigurations';
import MessagingControlTable from './components/Messaging/MessagingChannels';
import MessagingChannel from './components/Messaging/MessagingChannel';
import MessagingTemplates from './components/Messaging/MessagingTemplates';
import HomePage from './components/HomePage/HomePage';
import TenantModulesTemplate from './components/Admin/TenantModules/TenantModulesTemplate';
import MessagingDashboard from './components/Messaging/MessagingDashboard/MessagingDashboard';
import CrmTicketEdit from './components/CrmTicket/Edit/CrmTicketEdit';
import CreateMessagingChannel from './components/Messaging/CreateMessagingChannel';
import CWTimeEntries from './components/CrmTicket/CWTimeEntries';
import ScheduleEntries from './components/CrmTicket/ScheduleEntries';
import PsaTickets from './components/PsaTickets';
import ATTimeEntries from './components/CrmTicket/ATTimeEntries';
import TeamsConnections from './components/TeamsConnections';
import MessageTemplates from './components/MessageTemplates';
import OneTimePassword from './components/UnauthorizedPages/OneTimePassword';
import OptInForm from './pages/optIn/OptInForm';
import PsaContacts from './components/PsaContacts';
import ClientChats from './components/ClientPortal/ClientChats';

import EmailTemplateSettings from './pages/portalSettings/emailSettings/EmailTemplateSettings';
import LiveChatConversationTemplates from './components/LiveChatConversationTemplates';
import OptInSettings from './pages/optIn/OptInSettings';
import BlockedSmsList from './components/Sms/BlockedSmsList';

const AutotaskPod = React.lazy(() => import('@ui/pages/pod/autotask'));

const AutotaskPodWrapper = () => (
  <Suspense fallback={<Loading />}>
    <AutotaskPod />
  </Suspense>
);

/*
  We use the render props because we have a warning from React-Router:

    You should not use <Route component> and <Route render> in the same route;
    <Route render> will be ignored'

  if we try to use component={Component}
*/

const customRoutes = [
  <Route
    exact
    path={LIVE_CHAT}
    render={props => <LiveChat {...props} />}
    key="liveChat"
  />,
  <Route
    exact
    path={OPT_IN_LIST}
    render={props => <OptIn {...props} />}
    key="optIn"
  />,
  <Route
    exact
    path={CONNECTWISE_POD}
    render={props => <PodPlaceholder {...props} />}
    key="connectwisePod"
    noLayout
  />,
  <Route
    exact
    path={HALO_POD}
    render={props => <PodPlaceholder {...props} />}
    key="haloPod"
    noLayout
  />,
  <Route
    exact
    path={SMS_BLACK_LIST}
    render={props => <SmsBlackList {...props} />}
    key="smsBlackList"
  />,
  <Route
    exact
    path={BLOCKED_SENDERS}
    render={props => <BlockedSmsList {...props} />}
    key="blockedSms"
  />,
  <Route
    exact
    path={VERIFICATION_SETTINGS}
    render={props => <VerificationSettings {...props} />}
    key="verificationSettings"
  />,
  <Route
    exact
    path={SECURE_DATA_SETTINGS}
    render={props => <SecureDataSettings {...props} />}
    key="secureDataSettings"
  />,
  <Route
    exact
    path={PASSWORDS}
    render={props => <Passwords {...props} />}
    key="passwords"
  />,
  <Route
    exact
    path={TENANT_MODULES_TEMPLATE}
    render={props => <TenantModulesTemplate {...props} />}
    key="tenantModulesTemplate"
  />,
  <Route
    exact
    path={TENANT_MODULES}
    render={props => <TenantModules {...props} />}
    key="tenantModules"
  />,
  <Route
    exact
    path={HOME_ROUTE}
    render={props => <HomePage {...props} />}
    key="homePage"
  />,
  <Route
    exact
    noLayout
    path={DEMO_REGISTRATION}
    render={props => <DemoRegistration {...props} />}
    key="demoRegistration"
  />,
  <Route
    exact
    noLayout
    path={PSA_VALIDATION}
    render={props => <PsaContactValidation {...props} />}
    key="psaValidation"
  />,
  <Route
    exact
    noLayout
    path={SHARED_EMAIL}
    key="sharedEmail"
    component={SharedEmail}
  />,
  <Route
    exact
    noLayout
    path={SHARED_BILLING}
    key="sharedBilling"
    component={SharedBillingLayout}
  />,
  <Route
    exact
    path={EMAIL_GROUP_DETAILS}
    render={NotificationGroupDetails}
    key="emailGroupDetails"
  />,
  <Route
    exact
    path={DATA_SOURCES_INTEGRATION_CREATE}
    render={props => <DataSourcesIntegrationsCreate {...props} />}
    key="dataSourceIntegrationCreate"
  />,
  <Route
    exact
    path={SECURITY_INTEGRATION_CREATE}
    render={props => <CreateSecurityIntegrations {...props} />}
    key="securityIntegrationCreate"
  />,
  <Route
    exact
    path={SECURITY_INTEGRATION_EDIT}
    render={props => <EditSecurityIntegrations {...props} />}
    key="securityIntegrationEdit"
  />,
  <Route
    exact
    path={SECURITY_INTEGRATION}
    render={props => <SecurityIntegrations {...props} />}
    key="securityIntegrations"
  />,
  <Route
    exact
    path={MERAKI_DASHBOARD}
    render={props => <MerakiDashboard {...props} />}
    key="merakiDashboard"
  />,
  <Route
    exact
    path={MERAKI_VIEW}
    render={props => <Meraki {...props} />}
    key="meraki"
  />,
  <Route
    exact
    path={SOPHOS_DASHBOARD}
    render={props => <SophosDashboard {...props} />}
    key="sophosDashboard"
  />,
  <Route
    exact
    path={SOPHOS_VIEW}
    render={props => <Sophos {...props} />}
    key="sophos"
  />,
  <Route
    exact
    path={DATA_SOURCES_INTEGRATION_EDIT}
    render={props => <DataSourcesIntegrationsEdit {...props} />}
    key="dataSourceIntegrationEdit"
  />,
  <Route
    exact
    path={WEB_HOOKS_CREATE}
    render={props => <Create {...props} />}
    key="webHooksCreate"
  />,
  <Route
    exact
    path={WEB_HOOKS_EDIT}
    render={props => <Edit {...props} />}
    key="webHooksEdit"
  />,
  <Route
    exact
    path={DATA_EXPORT}
    render={props => <ExportPage {...props} />}
    key="dataExport"
  />,
  <Route
    exact
    path={DEVICE_MONITORS_EXPORT}
    render={props => <MonitoringExportPage {...props} />}
    key="dataExport"
  />,
  <Route
    exact
    path={MULTI_FACTOR_SETUP}
    render={props => <MultiFactorSetup {...props} />}
    key="multiFactorSetup"
  />,
  <Route
    exact
    path={TICKETING_TICKET_SETTINGS}
    render={props => <TicketingSettings {...props} />}
    key="ticketingSettings"
  />,
  <Route
    exact
    path={TICKETING_EMAIL_SETTINGS}
    render={props => <EmailSettings {...props} />}
    key="emailSettings"
  />,
  <Route
    exact
    path={TICKETING_SMS_SETTINGS}
    render={props => <SmsSettings {...props} />}
    key="smsSettings"
  />,
  <Route
    exact
    path={TICKETING_WH_SETTINGS}
    render={props => <WebHookSettings {...props} />}
    key="webHookSettings"
  />,
  <Route
    exact
    path={TICKETING_SLACK_SETTINGS}
    render={props => <SlackSettings {...props} />}
    key="slackSettings"
  />,
  <Route
    exact
    path={TICKETING_TEAMS_SETTINGS}
    render={props => <TeamsSettings {...props} />}
    key="teamsSettings"
  />,
  <Route
    exact
    path={TICKETING_FTP_SETTINGS}
    render={props => <FtpSettings {...props} />}
    key="ftpSettings"
  />,
  <Route
    exact
    path={TICKETING_VOICE_SETTINGS}
    render={props => <VoiceSettings {...props} />}
    key="ftpSettings"
  />,
  <Route
    exact
    path={UNRECOGNIZED_TYPES}
    render={props => <UnrecognizedTypes {...props} />}
    key="unrecognizedTypes"
  />,
  <Route
    exact
    path={TICKETING_CREATE}
    render={props => <CreateTicketSetting {...props} />}
    key="createTicketSetting"
  />,
  <Route
    exact
    path={TICKETING_UPDATE}
    render={props => <UpdateTicketSettings {...props} />}
    key="updateTicketSetting"
  />,
  <Route
    exact
    path={DEVICE_MONITORS_CREATE}
    render={props => <CreateDeviceMonitors {...props} />}
    key="createDeviceMonitor"
  />,
  <Route
    exact
    path={DEVICE_MONITORS_UPDATE}
    render={props => <UpdateDeviceMonitors {...props} />}
    key="updateDeviceMonitor"
  />,
  <Route
    exact
    path={DEVICE_MONITORS_DETAILS}
    render={props => <DetailsDeviceMonitors {...props} />}
    key="detailsDeviceMonitor"
  />,
  <Route
    noLayout
    exact
    path={DEVICE_MONITORS_DETAILS_TV_MODE}
    render={props => <DetailsTVMode {...props} />}
    key="detailsDeviceMonitorTVMode"
  />,

  <Route
    exact
    path={NOTIFICATION_MONITOR_TICKET_DM_SETTINGS}
    render={props => <TicketingDMSettings {...props} />}
    key="ticketDmSettings"
  />,
  <Route
    exact
    path={NOTIFICATION_MONITOR_EMAIL_DM_SETTINGS}
    render={props => <EmailDMSettings {...props} />}
    key="emailDmSettings"
  />,
  <Route
    exact
    path={NOTIFICATION_MONITOR_SMS_DM_SETTINGS}
    render={props => <SmsDMSettings {...props} />}
    key="smsDmSettings"
  />,
  <Route
    exact
    path={NOTIFICATION_MONITOR_WEB_HOOK_DM_SETTINGS}
    render={props => <WebHookDMSettings {...props} />}
    key="webHookSettings"
  />,
  <Route
    exact
    path={NOTIFICATION_MONITOR_SLACK_DM_SETTINGS}
    render={props => <SlackDMSettings {...props} />}
    key="slackSettings"
  />,
  <Route
    exact
    path={NOTIFICATION_MONITOR_TEAMS_DM_SETTINGS}
    render={props => <TeamsDMSettings {...props} />}
    key="slackSettings"
  />,
  <Route
    exact
    path={NOTIFICATION_MONITOR_VOICE_DM_SETTINGS}
    key={NOTIFICATION_MONITOR_VOICE_DM_SETTINGS}
    render={props => <DmVoiceSettings {...props} />}
  />,
  <Route
    exact
    path={NOTIFICATIONS_DATA}
    render={props => <Notifications {...props} />}
    key="monitorSettings"
  />,
  <Route
    exact
    path={NOTIFICATIONS_DATA_MONITOR}
    render={props => <Notifications {...props} />}
    key="monitorSettings"
  />,
  <Route
    exact
    path={DM_SETTINGS_CREATE}
    render={props => <DmSettingFormCreate {...props} />}
    key="createDmSettings"
  />,
  <Route
    exact
    path={DM_SETTINGS_GROUP_CREATE}
    render={props => <DmSettingGroupFormCreate {...props} />}
    key="createDmSettingsGroup"
  />,
  <Route
    exact
    path={DM_SETTINGS_UPDATE}
    render={props => <DmSettingsFormUpdate {...props} />}
    key="updateDmSettings"
  />,
  <Route
    exact
    path={CRM_CREATE}
    render={props => <CreateCrm {...props} />}
    key="createCrm"
  />,
  <Route
    exact
    path={CRM_UPDATE}
    render={props => <UpdateCrm {...props} />}
    key="updateCrm"
  />,
  <Route
    exact
    path={TRIGGER_CREATE}
    render={props => <TriggerCreate {...props} />}
    key="createTrigger"
  />,
  <Route
    exact
    path={TRIGGER_UPDATE}
    render={props => <TriggerUpdate {...props} />}
    key="updateTrigger"
  />,
  <Route
    exact
    path={INCIDENTS}
    render={props => <IncidentDetails {...props} />}
    key="incidentDetails"
  />,
  <Route
    exact
    path={INCIDENT}
    render={props => <Incident {...props} />}
    key={INCIDENT}
  />,
  <Route
    exact
    path={MAIL_BOX}
    render={props => <MailBox {...props} />}
    key="mailBox"
  />,
  <Route
    exact
    path={LETTER}
    render={props => <Letter {...props} />}
    key="letter"
  />,
  <Route
    exact
    path="/registration"
    render={props => <SignUp {...props} />}
    noLayout
    key="registration"
  />,
  <Route
    exact
    path={FIRST_MAILBOX}
    render={props => <FirstMailBox {...props} />}
    key="firstMailBox"
  />,
  <Route
    exact
    noLayout
    key="emailHandler"
    path={EMAIL_HANDLER_ROUTE}
    render={EmailHandler}
  />,
  <Route
    noLayout
    key="helpPage"
    path={HELP_PAGE}
    render={props => <HelpPage {...props} />}
  />,
  <Route
    exact
    key="pricingPlan"
    path={PRICING_PLAN}
    render={props => <PricingPlan {...props} />}
  />,
  <Route
    exact
    key="billing"
    path={BILLING}
    render={props => <Billing {...props} />}
  />,
  <Route
    exact
    key={OUTGOING_MAIL}
    path={OUTGOING_MAIL}
    render={props => <OutgoingMail {...props} />}
  />,

  <Route
    exact
    key="parserCreate"
    path={CREATE_PARSER}
    render={props => <CreateParser {...props} />}
  />,
  <Route
    exact
    key="parserUpdate"
    path={UPDATE_PARSER}
    render={props => <UpdateParser {...props} />}
  />,
  <Route
    key={FORGOT_PASSWORD_ROUTE}
    exact
    path={FORGOT_PASSWORD_ROUTE}
    component={ForgotPassword}
    noLayout
  />,
  <Route key="profile" exact path={PROFILE_ROUTE} component={Profile.show} />,
  <Route exact key={DOCUMENTS} path={DOCUMENTS} component={DocumentsPage} />,
  <Route
    exact
    key={WEB_HOOKS_RESULTS}
    path={WEB_HOOKS_RESULTS}
    render={props => <WebHookResults {...props} />}
  />,
  <Route
    exact
    key={WEB_HOOKS_RESULT}
    path={WEB_HOOKS_RESULT}
    render={props => <WebHookResult {...props} />}
  />,
  <Route
    exact
    key={WEB_HOOKS_RESULT_UNRECOGNIZED}
    path={WEB_HOOKS_RESULT_UNRECOGNIZED}
    render={props => <WebHookResultUnrecognized {...props} />}
  />,
  <Route
    exact
    key={GENERIC_SOURCES}
    path={GENERIC_SOURCES}
    render={props => <GenericSources {...props} />}
  />,
  <Route
    exact
    key={EDIT_GENERIC_SOURCE}
    path={EDIT_GENERIC_SOURCE}
    render={props => <EditGenericSource {...props} />}
  />,
  <Route
    exact
    key={GENERIC_SOURCE_VIEW_LOGS}
    path={GENERIC_SOURCE_VIEW_LOGS}
    render={props => <GenericSourceLogs {...props} />}
  />,
  <Route
    exact
    key={CREATE_GENERIC_SOURCE}
    path={CREATE_GENERIC_SOURCE}
    render={props => <EditGenericSource {...props} />}
  />,
  <Route
    exact
    key={SCHEDULE_GROUP}
    path={SCHEDULE_GROUP}
    render={props => <Schedule {...props} />}
  />,
  <Route
    exact
    key={SCHEDULE_USER}
    path={SCHEDULE_USER}
    render={props => <Schedule {...props} />}
  />,
  <Route
    exact
    key={EDIT_SCHEDULE}
    path={EDIT_SCHEDULE}
    render={props => <EditScheduleRule {...props} />}
  />,
  <Route
    exact
    key={CREATE_SCHEDULE}
    path={CREATE_SCHEDULE}
    render={props => <EditScheduleRule {...props} />}
  />,
  <Route
    exact
    key={CHATS}
    path={CHATS}
    render={props => <Chats {...props} />}
  />,
  <Route
    exact
    key={EDIT_ESCALATION_RULES}
    path={EDIT_ESCALATION_RULES}
    render={props => <EscalationRulesForm {...props} />}
  />,
  <Route
    exact
    key={CREATE_ESCALATION_RULES}
    path={CREATE_ESCALATION_RULES}
    render={props => <EscalationRulesForm {...props} />}
  />,
  <Route
    exact
    key={ESCALATION_RULES}
    path={ESCALATION_RULES}
    render={props => <EscalationRules {...props} />}
  />,
  <Route
    exact
    key={SCHEDULER_PAGE}
    path={SCHEDULER_PAGE}
    render={props => <SchedulerPage {...props} />}
  />,
  <Route
    exact
    key={AUTOTASK_SMS}
    path={AUTOTASK_SMS}
    render={props => <AutotaskNoteToSMS {...props} />}
  />,
  <Route
    exact
    key={TEST_EMAIL}
    path={TEST_EMAIL}
    render={props => <TestEmail {...props} />}
  />,
  <Route
    exact
    key={PURCHASE_PHONE_NUMBERS}
    path={PURCHASE_PHONE_NUMBERS}
    render={props => <BufferPhoneNumbers {...props} />}
  />,
  <Route
    exact
    key={AVAILABLE_PHONE_NUMBERS}
    path={AVAILABLE_PHONE_NUMBERS}
    render={props => <AvailablePhoneNumbers {...props} />}
  />,
  <Route
    exact
    key={MESSAGING_CONFIGURATION}
    path={MESSAGING_CONFIGURATION}
    render={props => <MessagingConfigurations {...props} />}
  />,
  <Route
    exact
    key={MESSAGING_CHANNELS}
    path={MESSAGING_CHANNELS}
    render={props => <MessagingControlTable {...props} />}
  />,
  <Route
    exact
    key={MESSAGING_CHANNEL}
    path={MESSAGING_CHANNEL}
    render={props => <MessagingChannel {...props} />}
  />,
  <Route
    exact
    key={MESSAGING_TEMPLATES}
    path={MESSAGING_TEMPLATES}
    render={props => <MessagingTemplates {...props} />}
  />,
  <Route
    exact
    key={MESSAGING_DASHBOARD}
    path={MESSAGING_DASHBOARD}
    render={props => <MessagingDashboard {...props} />}
  />,
  <Route
    exact
    key={CRM_TICKET_EDIT}
    path={CRM_TICKET_EDIT}
    render={props => <CrmTicketEdit {...props} />}
  />,
  <Route
    exact
    key={CREATE_MESSAGING_CHANNEL}
    path={CREATE_MESSAGING_CHANNEL}
    render={props => <CreateMessagingChannel {...props} />}
  />,
  <Route
    exact
    key={CRM_TICKET_TIME_ENTRIES}
    path={CRM_TICKET_TIME_ENTRIES}
    render={props => <CWTimeEntries {...props} />}
  />,
  <Route
    exact
    key={CRM_TICKET_SCHEDULE_ENTRIES}
    path={CRM_TICKET_SCHEDULE_ENTRIES}
    render={props => <ScheduleEntries {...props} />}
  />,
  <Route
    exact
    key={PSA_TICKETS}
    path={PSA_TICKETS}
    render={props => <PsaTickets {...props} />}
  />,
  <Route
    exact
    key={AT_TICKET_TIME_ENTRIES}
    path={AT_TICKET_TIME_ENTRIES}
    render={props => <ATTimeEntries {...props} />}
  />,
  <Route
    exact
    key={TEAMS_CONNECTIONS}
    path={TEAMS_CONNECTIONS}
    render={props => <TeamsConnections {...props} />}
  />,
  <Route
    exact
    key={MESSAGE_TEMPLATES}
    path={MESSAGE_TEMPLATES}
    render={props => <MessageTemplates {...props} />}
  />,
  <Route
    exact
    key={LIVE_CHAT_CONVERSATIONS}
    path={LIVE_CHAT_CONVERSATIONS}
    render={props => <LiveChatConversationTemplates {...props} />}
  />,
  <Route
    exact
    noLayout
    key={ONE_TIME_PASSWORD}
    path={ONE_TIME_PASSWORD}
    render={props => <OneTimePassword {...props} />}
  />,
  <Route
    exact
    noLayout
    key={OPT_IN_FORM}
    path={OPT_IN_FORM}
    render={props => <OptInForm {...props} />}
  />,
  <Route
    exact
    key={PSA_CONTACTS}
    path={PSA_CONTACTS}
    render={props => <PsaContacts {...props} />}
  />,
  <Route
    exact
    key={FILE_UPLOAD}
    path={FILE_UPLOAD}
    render={props => <FileUpload {...props} />}
  />,
  <Route
    exact
    key={CREATE_TICKET}
    path={CREATE_TICKET}
    render={props => <CreateTicket {...props} />}
  />,
  <Route
    exact
    key={LIVE_CHAT_CONFIGURATIONS}
    path={LIVE_CHAT_CONFIGURATIONS}
    render={props => <LiveChatConfigurations {...props} />}
  />,
  <Route
    exact
    key={CHAT_BOTS}
    path={CHAT_BOTS}
    render={props => <ChatBots {...props} />}
  />,
  <Route
    exact
    key={LIVE_CHAT_CREATE_TICKET}
    path={LIVE_CHAT_CREATE_TICKET}
    render={props => <CreateTicketFromLiveChatDialog {...props} />}
  />,
  <Route
    exact
    key={LIVE_CHAT_DETAILS}
    path={LIVE_CHAT_DETAILS}
    render={props => <LiveChatDetails {...props} />}
  />,
  <Route
    exact
    key={CLIENT_PORTAL_CHATS}
    path={CLIENT_PORTAL_CHATS}
    render={props => <ClientChats {...props} />}
  />,
  <Route
    exact
    key={CLIENT_PORTAL_CONFIGS}
    path={CLIENT_PORTAL_CONFIGS}
    render={props => <ClientPortalConfigurations {...props} />}
  />,
  <Route
    exact
    key={CLIENT_PORTAL_DEFAULTS}
    path={CLIENT_PORTAL_DEFAULTS}
    render={props => <ClientPortalDefaults {...props} />}
  />,
  <Route
    exact
    key={CLIENT_PORTAL_COMPANY_MAPPING}
    path={CLIENT_PORTAL_COMPANY_MAPPING}
    render={props => <CwCompanyMapping {...props} />}
  />,
  <Route
    exact
    key={CLIENT_PORTAL_CHAT_DETAILS}
    path={CLIENT_PORTAL_CHAT_DETAILS}
    render={props => <ClientPortalChat {...props} />}
  />,
  <Route
    exact
    key={ISSUE_TYPE}
    path={ISSUE_TYPE}
    render={props => <IssueType {...props} />}
  />,
  <Route
    exact
    key={ISSUE_TYPE_CREATE}
    path={ISSUE_TYPE_CREATE}
    render={props => <IssueType {...props} />}
  />,
  <Route
    exact
    key={ISSUE_TYPES}
    path={ISSUE_TYPES}
    render={props => <IssueTypes {...props} />}
  />,
  <Route
    exact
    key={SECURE_DATA}
    path={SECURE_DATA}
    render={props => <SecureData {...props} />}
    noLayout
  />,
  <Route
    exact
    key={USER_INVITE}
    path={USER_INVITE}
    render={props => <UserInvite {...props} />}
    noLayout
  />,
  <Route
    exact
    key={INVITED_USERS}
    path={INVITED_USERS}
    render={props => <InvitedUsers {...props} />}
  />,
  <Route
    exact
    key={VERIFICATION_HISTORY}
    path={VERIFICATION_HISTORY}
    render={props => <VerificationHistory {...props} />}
  />,
  <Route
    exact
    noLayout
    key={RESET_PASSWORD}
    path={RESET_PASSWORD}
    render={props => <ResetPassword {...props} />}
  />,
  <Route
    exact
    key={SMS_CAMPAIGNS}
    path={SMS_CAMPAIGNS}
    render={props => <SmsGroups {...props} />}
  />,
  <Route
    exact
    key={SMS_CAMPAIGN_BUILDER}
    path={SMS_CAMPAIGN_BUILDER}
    render={props => <SmsBroadcast {...props} />}
  />,
  <Route
    exact
    key={PSA_TECH_VERIFICATION}
    path={PSA_TECH_VERIFICATION}
    render={props => <TechnicianVerificationTable {...props} />}
  />,
  <Route
    exact
    key={COMPANIES_VERIFICATION_SETTINGS}
    path={COMPANIES_VERIFICATION_SETTINGS}
    render={props => <CompaniesVerificationSettings {...props} />}
  />,
  <Route
    exact
    key={OPTIN_SETTINGS}
    path={OPTIN_SETTINGS}
    render={props => <OptInSettings {...props} />}
  />,
  <Route
    exact
    key={SMS_GROUP_IMPORT_FROM_PSA}
    path={SMS_GROUP_IMPORT_FROM_PSA}
    render={props => <SmsGroupsImportFromPSA {...props} />}
  />,
  <Route
    exact
    key={MESSAGING_NUMBERS}
    path={MESSAGING_NUMBERS}
    render={props => <MessagingNumbers {...props} />}
  />,
  <Route
    exact
    key={AUTOTASK_POD_WIDGET}
    path={AUTOTASK_POD_WIDGET}
    render={props => <AutotaskPodWrapper {...props} />}
    noLayout
  />,
  <Route
    exact
    noLayout
    key={AUTOTASK_POD}
    path={AUTOTASK_POD}
    render={props => <AutotaskPodWrapper {...props} />}
  />,
  <Route
    exact
    key={SETUP_WIZARD}
    path={SETUP_WIZARD}
    render={props => <SetupWizardPage {...props} />}
  />,
  <Route
    exact
    key={EMAIL_SETTINGS}
    path={EMAIL_SETTINGS}
    render={props => <EmailTemplateSettings {...props} />}
  />,
  <Route
    exact
    key={CLIENT_PORTAL_USERS}
    path={CLIENT_PORTAL_USERS}
    render={props => <ClientPortalUsers {...props} />}
  />,
  <Route
    exact
    key={ASSIGN_TICKET_TO_TECH_REQUEST}
    path={ASSIGN_TICKET_TO_TECH_REQUEST}
    render={props => <AssignTicketToTechVerification {...props} />}
  />,
  <Route
    exact
    key={EDIT_CLIENT_PORTAL_USER}
    path={EDIT_CLIENT_PORTAL_USER}
    render={props => <EditClientPortalUser {...props} />}
  />,
  <Route
    exact
    key={INTEGRATIONS}
    path={INTEGRATIONS}
    render={props => <Integrations {...props} />}
  />,
  <Route
    exact
    key={USERS}
    path={USERS}
    render={props => <Users {...props} />}
  />,
  <Route
    exact
    key={DASHBOARD}
    path={DASHBOARD}
    render={props => <Dashboard {...props} />}
  />,
  <Route
    exact
    key={DATA}
    path={DATA}
    render={props => <AuditPage {...props} />}
  />,
  <Route
    exact
    key={DATA_VIEWS}
    path={DATA_VIEWS}
    render={props => <AuditViews {...props} />}
  />,
  <Route
    exact
    key={DEVICE_MONITORS}
    path={DEVICE_MONITORS}
    render={props => <DeviceMonitors {...props} />}
  />,
  <Route
    exact
    key={INCIDENTS_LIST}
    path={INCIDENTS_LIST}
    render={props => <Incidents {...props} />}
  />,
  <Route
    exact
    key={TRIGGER}
    path={TRIGGER}
    render={props => <Triggers {...props} />}
  />,
  <Route
    exact
    key={DUO_LOGIN}
    path={DUO_LOGIN}
    render={props => <DuoLogin {...props} />}
    noLayout
  />,
  <Route
    exact
    key={INVOICES}
    path={INVOICES}
    render={props => <Invoices {...props} />}
  />,
  <Route
    exact
    key={EDIT_PRICE_PLANS}
    path={EDIT_PRICE_PLANS}
    render={props => <EditPricePlan {...props} />}
  />,
  <Route
    exact
    key={DATA_VIEW_EDIT}
    path={DATA_VIEW_EDIT}
    render={props => <DataViewForm {...props} redirect="list" />}
  />,
  <Route
    exact
    key={DATA_VIEW_CREATE}
    path={DATA_VIEW_CREATE}
    render={props => <DataViewForm {...props} redirect="edit" />}
  />,
  <Route
    exact
    key={EDIT_USER}
    path={EDIT_USER}
    render={props => <UserEdit {...props} />}
  />,
  <Route
    exact
    key={CREATE_USER}
    path={CREATE_USER}
    render={props => <UserCreate {...props} />}
  />,
  <Route
    exact
    key={PSA_TICKETS_LOGS}
    path={PSA_TICKETS_LOGS}
    render={props => <PsaTicketsLogs {...props} />}
  />,
  <Route
    exact
    key={USER_ACTIVITY_LOGS}
    path={USER_ACTIVITY_LOGS}
    render={props => <UserActivityLogs {...props} />}
  />,
  <Route
    exact
    key={HELPDESK_AI}
    path={HELPDESK_AI}
    render={props => <HelpdeskAI {...props} />}
  />,
  <Route
    exact
    key={CUSTOM_AGENT_AI}
    path={CUSTOM_AGENT_AI}
    render={props => <CustomAgentAI {...props} />}
  />,
  <Route
    exact
    key={COMPANY_MAPPING}
    path={COMPANY_MAPPING}
    render={props => <LocalCompany {...props} />}
  />,
  <Route
    exact
    key={NOTIFICATIONS_GROUPS}
    path={NOTIFICATIONS_GROUPS}
    render={props => <NotificationGroups {...props} />}
  />,
  <Route
    exact
    key={API_KEYS}
    path={API_KEYS}
    render={props => <ApiKeys {...props} />}
  />,
  <Route
    exact
    key={AI_PRICE_PLAN}
    path={AI_PRICE_PLAN}
    render={props => <AIPricePlan {...props} />}
  />,
  <Route
    exact
    key={TEAMS_BOT_CONFIGURATION}
    path={TEAMS_BOT_CONFIGURATION}
    render={props => <TeamsBotConfiguration {...props} />}
  />,
  <Route
    exact
    key={NOTIFICATION_LOGS}
    path={NOTIFICATION_LOGS}
    render={props => <NotificationLogs {...props} />}
  />,
  <Route
    exact
    key={DYNAMIC_CRM_FILTERS}
    path={DYNAMIC_CRM_FILTERS}
    render={props => <DynamicFilters {...props} />}
  />,
  <Route
    exact
    key={DYNAMIC_CRM_FILTER_CREATE}
    path={DYNAMIC_CRM_FILTER_CREATE}
    render={props => <DynamicFilterForm {...props} />}
  />,
  <Route
    exact
    key={DYNAMIC_CRM_FILTER_EDIT}
    path={DYNAMIC_CRM_FILTER_EDIT}
    render={props => <DynamicFilterForm {...props} />}
  />,
  <Route
    exact
    key={TWILIO_ACCOUNTS}
    path={TWILIO_ACCOUNTS}
    render={props => <TwilioAccounts {...props} />}
  />,
  <Route
    exact
    key={TWILIO_ACCOUNTS_NUMBERS}
    path={TWILIO_ACCOUNTS_NUMBERS}
    render={props => <TwilioAccountsNumbers {...props} />}
  />,
];

export default customRoutes;
