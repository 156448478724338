import React, { useState, useEffect, useReducer, useCallback } from 'react';
import { useTranslate } from 'react-admin';
import { useSnackbar } from 'notistack';
import Loading from '@common/Loading/Loading';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Paper,
  Typography,
} from '@material-ui/core';
import { crmInfo } from '@store/selectors';
import Input from '@common/Input/Input';
import DropDown from '@components/Auth/Common/DropDown';
import ReusableButton from '@common/Button/Button';
import AutocompleteFormInput from '@common/AutocompleteFormInput/AutocompleteFormInput';
import MultiSelectDialog from '@common/MultiSelectDialog/MultiSelectDialog';
import { reducer, actions, initState } from './reducer';
import { validateFormData, getPayload, getInitFormData } from './formData';
import DisplaySelector from './DisplaySelector';
import {
  getBoardsOptions,
  getBoardStatuses,
  getBoardTypes,
  getIntegrationCompanies,
  getIntegrationSources,
  getPriorityOptions,
  getCallers,
  getCategories,
  getGroups,
  getImpacts,
  getTicketCategories,
  getUrgency,
} from '../../../helpers';
import getDisplayObject from './displayData';
import crmDefaultFields from './constants';
import SubFormSelector from './SubForms/SubFormSelector';
import useStyles from '../styles';

const initOptionsState = {
  boardsOptions: [],
  priorityOptions: [],
  sourcesOptions: [],
  companiesOptions: [],
  ticketCategoryOptions: [],
  callersOptions: [],
  groupsOptions: [],
  urgencyOptions: [],
  impactsOptions: [],
  categoriesOptions: [],
  // TODO add extra logic to get data from categories
  subCategoriesOptions: [],
};

const CrmDefaultForm = ({
  data,
  onSubmit,
  onBack,
  crmId,
  customCancelText,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const selectedCrmType = useSelector(crmInfo);
  const displayObject = getDisplayObject(selectedCrmType.crmType);
  const getFunc = useCallback(
    (func, item) =>
      displayObject.includes(item) ? func : () => Promise.resolve([]),
    [displayObject],
  );
  const isAllow = item => displayObject.includes(item);
  const [state, dispatch] = useReducer(reducer, data, initState);
  const [optionsState, setOptionsState] = useState(initOptionsState);
  const [disabledSubmit, setDisabledSubmit] = useState(true);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [invalidFormFields, setInvalidFormFields] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    Promise.all([
      getFunc(getBoardsOptions, crmDefaultFields.defaultBoard)(crmId),
      getFunc(getPriorityOptions, crmDefaultFields.ticketPriority)(crmId),
      getFunc(getIntegrationSources, crmDefaultFields.sourceId)(crmId),
      getFunc(getIntegrationCompanies, crmDefaultFields.defaultCompany)(crmId),
      getFunc(getTicketCategories, crmDefaultFields.ticketCategoryId)(crmId),
      getFunc(getCallers, crmDefaultFields.caller)(crmId),
      getFunc(getGroups, crmDefaultFields.group)(crmId),
      getFunc(getUrgency, crmDefaultFields.urgency)(crmId),
      getFunc(getImpacts, crmDefaultFields.impact)(crmId),
      getFunc(getCategories, crmDefaultFields.category)(crmId),
    ])
      .then(resp => {
        setOptionsState(prevState => ({
          ...prevState,
          boardsOptions: resp[0]?.sort((a, b) => (a.name > b.name ? 1 : -1)),
          priorityOptions: resp[1],
          sourcesOptions: resp[2]?.sort((a, b) => (a.name > b.name ? 1 : -1)),
          companiesOptions: resp[3]?.sort((a, b) =>
            a.label > b.label ? 1 : -1,
          ),
          ticketCategoryOptions: resp[4]?.sort((a, b) =>
            a.name > b.name ? 1 : -1,
          ),
          callersOptions: resp[5]?.sort((a, b) => (a.name > b.name ? 1 : -1)),
          groupsOptions: resp[6]?.sort((a, b) => (a.name > b.name ? 1 : -1)),
          urgencyOptions: resp[7]?.sort((a, b) => (a.name > b.name ? 1 : -1)),
          impactsOptions: resp[8]?.sort((a, b) => (a.name > b.name ? 1 : -1)),
          categoriesOptions: resp[9]?.sort((a, b) =>
            a.name > b.name ? 1 : -1,
          ),
        }));
      })
      .finally(() => setLoading(false));
  }, [getFunc, crmId]);

  useEffect(() => {
    if (state.currentBoardId && state.currentBoardId !== 0) {
      if (state.currentBoardId) {
        dispatch({ type: actions.loadingOn });
        getBoardTypes(crmId, state.currentBoardId)
          .then(d => {
            const bTypeOptions = d
              .map(i => ({
                label: i.name,
                value: +i.id,
                boardId: state.currentBoardId,
                subTypes: i.boardSubTypes
                  .map(s => ({
                    label: s.name,
                    value: +s.id,
                    items: s.items
                      ? s.items.map(m => ({
                          label: m.name,
                          value: +m.id,
                        }))
                      : [],
                  }))
                  .sort((a, b) => (a.label > b.label ? 1 : -1)),
              }))
              .sort((a, b) => (a.label > b.label ? 1 : -1));
            const sTypeOptions = bTypeOptions.find(
              l => l.value === state.currentTypeId,
            );
            const existingOptions = sTypeOptions ? sTypeOptions.subTypes : [];

            const sSubTypeOptions = existingOptions.find(
              l => l.value === state.currentSubTypeId,
            );
            const existingItemsOptions = sSubTypeOptions
              ? sSubTypeOptions.items
              : [];
            dispatch({
              type: actions.setItemOptions,
              payload: existingItemsOptions.sort((a, b) =>
                a.label > b.label ? 1 : -1,
              ),
            });
            dispatch({
              type: actions.setSubTypeOptions,
              payload: existingOptions.sort((a, b) =>
                a.label > b.label ? 1 : -1,
              ),
            });
            dispatch({
              type: actions.setBoardTypesOptions,
              payload: bTypeOptions.sort((a, b) =>
                a.label > b.label ? 1 : -1,
              ),
            });
          })
          .catch(() =>
            enqueueSnackbar('Unable to fetch board types', {
              variant: 'error',
            }),
          )
          .finally(() => dispatch({ type: actions.loadingOff }));
      } else {
        dispatch({
          type: actions.setItemOptions,
          payload: [],
        });
        dispatch({
          type: actions.setSubTypeOptions,
          payload: [],
        });
        dispatch({
          type: actions.setBoardTypesOptions,
          payload: [],
        });
      }
    }
  }, [
    crmId,
    enqueueSnackbar,
    state.currentSubTypeId,
    state.currentTypeId,
    state.currentBoardId,
  ]);

  useEffect(() => {
    if (state.currentBoardId && state.currentBoardId !== 0) {
      dispatch({ type: actions.loadingOn });
      getBoardStatuses(crmId, state.currentBoardId)
        .then(d => {
          const statusOptions = d
            .map(i => ({
              label: i.name,
              value: +i.id,
              boardId: state.currentBoardId,
            }))
            .sort((a, b) => (a.label > b.label ? 1 : -1));
          dispatch({
            type: actions.setStatusesOptions,
            payload: statusOptions,
          });
          const dataIgnoredStatuses =
            data && data.ignoreStatusIds ? data.ignoreStatusIds : [];
          dispatch({
            type: actions.setIgnoredStatuses,
            payload: statusOptions.filter(p =>
              dataIgnoredStatuses.includes(p.value),
            ),
          });
        })
        .catch(() =>
          enqueueSnackbar('Unable to fetch board statuses', {
            variant: 'error',
          }),
        )
        .finally(() => dispatch({ type: actions.loadingOff }));
    }
  }, [state.currentBoardId, crmId, data, enqueueSnackbar]);

  const classes = useStyles();
  const translate = useTranslate();

  const submit = async values => {
    dispatch({ type: actions.loadingOn });
    const payload = getPayload(data, values, state, optionsState.boardsOptions);
    dispatch({ type: actions.setCurrentBoardId, payload: 0 });
    onSubmit(payload);
  };

  const handleBoardChanged = (newId, form) => {
    dispatch({ type: actions.setCurrentBoardId, payload: newId });
    dispatch({ type: actions.setCurrentTypeId, payload: '' });
    dispatch({ type: actions.setCurrentSubTypeId, payload: '' });
    form.batch(() => {
      form.change(crmDefaultFields.typeId, '');
      form.change(crmDefaultFields.subTypeId, '');
      form.change(crmDefaultFields.itemId, '');
    });
    setDisabledSubmit(true);
  };

  const handleTypeChanged = (newId, form) => {
    const sTypeOptions = state.boardTypesOptions.find(l => l.value === newId);
    const existingOptions = sTypeOptions ? sTypeOptions.subTypes : [];
    dispatch({ type: actions.setSubTypeOptions, payload: existingOptions });
    dispatch({ type: actions.setCurrentTypeId, payload: newId });
    dispatch({ type: actions.setCurrentSubTypeId, payload: '' });
    form.batch(() => {
      form.change(crmDefaultFields.subTypeId, '');
      form.change(crmDefaultFields.itemId, '');
    });
    setDisabledSubmit(true);
  };

  const handleSubTypeChanged = (newId, form) => {
    const a = state.subTypeOptions.find(l => l.value === newId);
    const existingOptions = a ? a.items : [];
    dispatch({ type: actions.setItemOptions, payload: existingOptions });
    dispatch({ type: actions.setCurrentSubTypeId, payload: newId });
    form.change(crmDefaultFields.itemId, '');
    setDisabledSubmit(true);
  };

  if (loading) {
    return <Loading />;
  }

  const onValidation = values => {
    const invalidFields = [];
    const type = state.boardTypesOptions.find(
      i => i.boardId === values.defaultBoardId && i.value === values.typeId,
    );
    const subType = type?.subTypes.find(i => i.value === values.subTypeId);
    const item = subType?.items.find(i => i.value === values.itemId);
    const fields = [
      {
        name: 'Ticket Type',
        isValid: !values.typeId || Boolean(type),
      },
      {
        name: 'Ticket SubType',
        isValid: !values.subTypeId || Boolean(subType),
      },
      {
        name: 'Ticket Item',
        isValid: !values.itemId || Boolean(item),
      },
    ];
    fields.forEach(field => !field.isValid && invalidFields.push(field.name));
    if (invalidFields.length) {
      setInvalidFormFields(invalidFields);
      setIsOpenDialog(Boolean(invalidFields.length));
    }
    setDisabledSubmit(Boolean(invalidFields.length));
  };

  return (
    <Paper>
      <div className={classes.container}>
        <div className={classes.block}>
          <Form
            onSubmit={submit}
            validate={values =>
              validateFormData(values, translate, selectedCrmType.crmType)
            }
            initialValues={getInitFormData(data)}
            render={({ handleSubmit, form: { change, batch }, values }) => (
              <form onSubmit={handleSubmit} noValidate>
                <div>
                  <Typography className={classes.inputLabel}>Name:</Typography>
                  <Field
                    id="name"
                    name="name"
                    styleType="main"
                    inputView="text"
                    component={Input}
                    placeholder="Name"
                    fullWidth
                    disabled={state.loading}
                  />
                  <DisplaySelector
                    isAllow={isAllow(crmDefaultFields.defaultCompany)}
                  >
                    <Typography className={classes.inputLabel}>
                      Catch-all Company:
                    </Typography>
                    <Field
                      id={crmDefaultFields.defaultCompany}
                      name={crmDefaultFields.defaultCompany}
                      size="small"
                      component={AutocompleteFormInput}
                      items={optionsState.companiesOptions}
                      disabled={state.loading}
                      suppressInlineLabel
                    />
                  </DisplaySelector>
                  <DisplaySelector
                    isAllow={isAllow(crmDefaultFields.defaultBoardId)}
                  >
                    <Typography className={classes.inputLabel}>
                      Default Queue:
                    </Typography>
                    <Field
                      id={crmDefaultFields.defaultBoardId}
                      name={crmDefaultFields.defaultBoardId}
                      size="small"
                      labelName="name"
                      valueName="id"
                      component={DropDown}
                      options={optionsState.boardsOptions}
                      disabled={state.loading}
                    />
                  </DisplaySelector>
                  <OnChange name="defaultBoardId">
                    {value => {
                      handleBoardChanged(value, { change, batch });
                    }}
                  </OnChange>
                  <DisplaySelector isAllow={isAllow(crmDefaultFields.typeId)}>
                    <Typography className={classes.inputLabel}>
                      Ticket Type:
                    </Typography>
                    <Field
                      id={crmDefaultFields.typeId}
                      name={crmDefaultFields.typeId}
                      size="small"
                      component={DropDown}
                      allowEmpty
                      options={state.boardTypesOptions}
                      disabled={
                        state.loading || !state.boardTypesOptions.length
                      }
                    />
                  </DisplaySelector>
                  <OnChange name="typeId">
                    {value => {
                      handleTypeChanged(value, { change, batch });
                    }}
                  </OnChange>
                  <DisplaySelector
                    isAllow={isAllow(crmDefaultFields.subTypeId)}
                  >
                    <Typography className={classes.inputLabel}>
                      Ticket SubType:
                    </Typography>
                    <Field
                      id={crmDefaultFields.subTypeId}
                      name={crmDefaultFields.subTypeId}
                      size="small"
                      component={DropDown}
                      allowEmpty
                      options={state.subTypeOptions}
                      disabled={state.loading || !state.subTypeOptions.length}
                    />
                  </DisplaySelector>
                  <OnChange name="subTypeId">
                    {value => {
                      handleSubTypeChanged(value, { change });
                    }}
                  </OnChange>
                  <DisplaySelector isAllow={isAllow(crmDefaultFields.itemId)}>
                    <Typography className={classes.inputLabel}>
                      Ticket Item:
                    </Typography>
                    <Field
                      id={crmDefaultFields.itemId}
                      name={crmDefaultFields.itemId}
                      size="small"
                      suppressInlineLabel
                      component={AutocompleteFormInput}
                      allowEmpty
                      items={state.itemOptions}
                      disabled={state.loading || !state.itemOptions.length}
                    />
                  </DisplaySelector>
                  <DisplaySelector
                    isAllow={isAllow(crmDefaultFields.openBoardStatusId)}
                  >
                    <Typography className={classes.inputLabel}>
                      New Ticket Status:
                    </Typography>
                    <Field
                      id={crmDefaultFields.openBoardStatusId}
                      name={crmDefaultFields.openBoardStatusId}
                      size="small"
                      component={DropDown}
                      options={state.statusesOptions}
                      disabled={state.loading || !state.statusesOptions.length}
                    />
                  </DisplaySelector>
                  <DisplaySelector
                    isAllow={isAllow(
                      crmDefaultFields.acknowledgedBoardStatusId,
                    )}
                  >
                    <Typography className={classes.inputLabel}>
                      Acknowledged Status:
                    </Typography>
                    <Field
                      id={crmDefaultFields.acknowledgedBoardStatusId}
                      name={crmDefaultFields.acknowledgedBoardStatusId}
                      size="small"
                      component={DropDown}
                      options={state.statusesOptions}
                      disabled={state.loading || !state.statusesOptions.length}
                    />
                  </DisplaySelector>
                  <DisplaySelector
                    isAllow={isAllow(crmDefaultFields.closedBoardStatusId)}
                  >
                    <Typography className={classes.inputLabel}>
                      Closed Ticket Status:
                    </Typography>
                    <Field
                      id={crmDefaultFields.closedBoardStatusId}
                      name={crmDefaultFields.closedBoardStatusId}
                      size="small"
                      component={DropDown}
                      options={state.statusesOptions}
                      disabled={state.loading || !state.statusesOptions.length}
                    />
                  </DisplaySelector>
                  <DisplaySelector
                    isAllow={isAllow(crmDefaultFields.smsReceivedBoardStatusId)}
                  >
                    <Typography className={classes.inputLabel}>
                      SMS received Status:
                    </Typography>
                    <Field
                      id={crmDefaultFields.smsReceivedBoardStatusId}
                      name={crmDefaultFields.smsReceivedBoardStatusId}
                      size="small"
                      component={DropDown}
                      options={[
                        { value: undefined, label: '-' },
                        ...state.statusesOptions,
                      ]}
                      disabled={state.loading || !state.statusesOptions.length}
                    />
                  </DisplaySelector>
                  <DisplaySelector
                    isAllow={isAllow(crmDefaultFields.ignoredStatuses)}
                  >
                    <MultiSelectDialog
                      title="Ignored statuses"
                      values={state.ignoredStatuses}
                      setValues={v =>
                        dispatch({
                          type: actions.setIgnoredStatuses,
                          payload: v,
                        })
                      }
                      fullArray={state.statusesOptions}
                      disabled={state.loading || !state.statusesOptions.length}
                    />
                  </DisplaySelector>
                  <DisplaySelector
                    isAllow={isAllow(crmDefaultFields.ticketCategoryId)}
                  >
                    <Typography className={classes.inputLabel}>
                      Ticket Category:
                    </Typography>
                    <Field
                      id={crmDefaultFields.ticketCategoryId}
                      name={crmDefaultFields.ticketCategoryId}
                      size="small"
                      labelName="name"
                      valueName="id"
                      component={DropDown}
                      options={optionsState.ticketCategoryOptions}
                      disabled={
                        state.loading ||
                        !optionsState.ticketCategoryOptions.length
                      }
                    />
                  </DisplaySelector>
                  <DisplaySelector
                    isAllow={isAllow(crmDefaultFields.ticketPriority)}
                  >
                    <Typography className={classes.inputLabel}>
                      Ticket priority:
                    </Typography>
                    <Field
                      id={crmDefaultFields.ticketPriority}
                      name={crmDefaultFields.ticketPriority}
                      size="small"
                      labelName="name"
                      valueName="name"
                      component={DropDown}
                      options={optionsState.priorityOptions}
                      disabled={state.loading}
                    />
                  </DisplaySelector>
                  <DisplaySelector isAllow={isAllow(crmDefaultFields.sourceId)}>
                    <Typography className={classes.inputLabel}>
                      Ticket source:
                    </Typography>
                    <Field
                      id={crmDefaultFields.sourceId}
                      name={crmDefaultFields.sourceId}
                      size="small"
                      labelName="name"
                      valueName="id"
                      component={DropDown}
                      options={optionsState.sourcesOptions}
                      disabled={state.loading}
                    />
                  </DisplaySelector>
                  <DisplaySelector isAllow={isAllow(crmDefaultFields.category)}>
                    <Typography className={classes.inputLabel}>
                      Category:
                    </Typography>
                    <Field
                      id={crmDefaultFields.category}
                      name={crmDefaultFields.category}
                      size="small"
                      labelName="name"
                      valueName="id"
                      component={DropDown}
                      options={optionsState.categoriesOptions}
                      disabled={state.loading}
                    />
                  </DisplaySelector>
                  {/* TODO Create extra logic to extract sub categories from categories response */}
                  <DisplaySelector
                    isAllow={isAllow(crmDefaultFields.subcategory)}
                  >
                    <Typography className={classes.inputLabel}>
                      Sub category:
                    </Typography>
                    <Field
                      id={crmDefaultFields.subcategory}
                      name={crmDefaultFields.subcategory}
                      size="small"
                      labelName="name"
                      valueName="id"
                      component={DropDown}
                      options={optionsState.subCategoriesOptions}
                      disabled={state.loading}
                    />
                  </DisplaySelector>
                  <DisplaySelector isAllow={isAllow(crmDefaultFields.urgency)}>
                    <Typography className={classes.inputLabel}>
                      Urgency:
                    </Typography>
                    <Field
                      id={crmDefaultFields.urgency}
                      name={crmDefaultFields.urgency}
                      size="small"
                      labelName="name"
                      valueName="id"
                      component={DropDown}
                      options={optionsState.urgencyOptions}
                      disabled={state.loading}
                    />
                  </DisplaySelector>
                  <DisplaySelector isAllow={isAllow(crmDefaultFields.impact)}>
                    <Typography className={classes.inputLabel}>
                      Impact:
                    </Typography>
                    <Field
                      id={crmDefaultFields.impact}
                      name={crmDefaultFields.impact}
                      size="small"
                      labelName="name"
                      valueName="id"
                      component={DropDown}
                      options={optionsState.impactsOptions}
                      disabled={state.loading}
                    />
                  </DisplaySelector>
                  <DisplaySelector isAllow={isAllow(crmDefaultFields.group)}>
                    <Typography className={classes.inputLabel}>
                      Group:
                    </Typography>
                    <Field
                      id={crmDefaultFields.group}
                      name={crmDefaultFields.group}
                      size="small"
                      labelName="name"
                      valueName="id"
                      component={DropDown}
                      options={optionsState.groupsOptions}
                      disabled={state.loading}
                    />
                  </DisplaySelector>
                  <DisplaySelector isAllow={isAllow(crmDefaultFields.caller)}>
                    <Typography className={classes.inputLabel}>
                      Caller:
                    </Typography>
                    <Field
                      id={crmDefaultFields.caller}
                      name={crmDefaultFields.caller}
                      size="small"
                      labelName="name"
                      valueName="id"
                      component={DropDown}
                      options={optionsState.callersOptions}
                      disabled={state.loading}
                    />
                  </DisplaySelector>
                  <SubFormSelector selectedCrmType={selectedCrmType} />
                </div>
                {state.loading ? (
                  <CircularProgress size={18} thickness={2} />
                ) : (
                  <div className={classes.buttonsWrapper}>
                    <ReusableButton
                      size="md"
                      type="button"
                      label="Validation"
                      classNameWrapper={cx(
                        classes.buttonCancel,
                        classes.buttonStyle,
                      )}
                      onClick={() => onValidation(values)}
                    />
                    <ReusableButton
                      size="md"
                      type="button"
                      label={customCancelText || 'resources.buttons.cancel'}
                      classNameWrapper={cx(
                        classes.buttonCancel,
                        classes.buttonStyle,
                      )}
                      onClick={onBack}
                    />
                    <ReusableButton
                      size="md"
                      classNameWrapper={classes.buttonStyle}
                      viewType="black"
                      type="submit"
                      label="resources.buttons.submit"
                      disabled={disabledSubmit}
                    />
                  </div>
                )}
              </form>
            )}
          />
          <Dialog onClose={() => setIsOpenDialog(false)} open={isOpenDialog}>
            <DialogContent>
              <Typography className={classes.inputLabel}>
                {invalidFormFields.join(', ')}
              </Typography>
              <Typography>This fields don't exist</Typography>
            </DialogContent>
            <DialogActions>
              <ReusableButton
                label="resources.buttons.cancel"
                classNameWrapper={classes.button}
                onClick={() => setIsOpenDialog(false)}
              />
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </Paper>
  );
};

CrmDefaultForm.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  onSubmit: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  crmId: PropTypes.string.isRequired,
  customCancelText: PropTypes.string,
};

export default CrmDefaultForm;
