import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import ActionFieldItem from '@common/ActionButtons/ActionFieldItem';

import DeleteIcon from '@assets/icons/delete.svg';
import EditIcon from '@assets/icons/edit.svg';
import ViewIcon from '@assets/icons/visibility.svg';

import { getTwilioAccount } from '@services/requests/twilioAccounts';
import { TWILIO_ACCOUNTS_NUMBERS } from '@constants/routes';

import useStyles from '../styles';

const Actions = ({ row, setRemovalId, setItem }) => {
  const classes = useStyles();
  const { push } = useHistory();

  const [processing, setProcessing] = useState(false);

  return (
    <div className={classes.alignCenter}>
      <ActionFieldItem
        handler={() => {
          if (row.authToken) {
            setItem(row);
          } else {
            setProcessing(true);
            getTwilioAccount(row.id).then(res => {
              setItem(res);
              setProcessing(false);
            });
          }
        }}
        toolTip="Edit"
        icon={EditIcon}
        id={`aiAssist-${row.id}-edit`}
        disabled={processing}
      />
      <ActionFieldItem
        handler={() => {
          push(TWILIO_ACCOUNTS_NUMBERS.replace(':id', row.id));
        }}
        toolTip="View"
        icon={ViewIcon}
        id={`aiAssist-${row.id}-delete`}
      />
      <ActionFieldItem
        handler={() => {
          setRemovalId(row.id);
        }}
        toolTip="Delete"
        icon={DeleteIcon}
        id={`aiAssist-${row.id}-delete`}
      />
    </div>
  );
};

Actions.propTypes = {
  setItem: PropTypes.func.isRequired,
  setRemovalId: PropTypes.func.isRequired,
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
    accountSid: PropTypes.string.isRequired,
    authToken: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default memo(Actions);
