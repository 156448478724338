import resourcesNames from '@constants/resources';
import user from '@components/Profile';
import dataSourcesIntegrations from '@components/DataSources/Integrations';
import alertTemplates from '@components/DataSources/HookTemplates';
import tenants from '@components/Admin/Tenants';
import parsers from '@components/Parsers';
import subscriptions from '@components/Admin/Subscriptions';
import mailBoxes from '@components/MailBoxesPage';
import pricingPlan from '@components/PricingPlan';
import types from '@components/Settings/Types';
import notificationSettings from '@components/Ticketing/Notifications';
import tenantSettings from '@components/Settings/TenantSettings';
import customPalette from '@components/Settings/CustomPalette';
import roadMap from '@components/RoadMap';
import ftpConnections from '@components/Settings/FtpConnections';
import parsingStatus from '@components/Admin/ParsingStatus';
import customFields from '@components/Settings/CustomFields';
import smsLogs from '@components/Billing/SmsLogs';
import webHooks from '@components/WebHooks';
import demoReferralLinks from '@components/Admin/DemoReferralLinks';
import coupons from '@components/Admin/Coupons';
import billingLinks from '@components/Admin/BillingLinks';
import autotaskHooks from '@components/DataSources/AutotaskHooks';
import connectWiseHooks from '@components/DataSources/ConnectWiseHooks';
import documents from '@components/Documents';
import genericSources from '@components/GenericSources';
import chats from '@components/Chats';
import tenantStatistic from '@components/Settings/TenantStatistic';
import home from '@components/HomePage/HomePage';

const resources = {
  home,
  mailBoxes,
  chats,
  documents,
  webHooks,
  dataSourcesIntegrations,
  alertTemplates,
  pricingPlan,
  notificationSettings,
  tenants,
  parsers,
  subscriptions,
  demoReferralLinks,
  coupons,
  smsLogs,
  parsingStatus,
  tenantStatistic,
  user,
  types,
  tenantSettings,
  customPalette,
  ftpConnections,
  customFields,
  billingLinks,
  autotaskHooks,
  connectWiseHooks,
  files: resourcesNames.files,
  roadmap: roadMap,
  genericSources,
};

export default resources;
